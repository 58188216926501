import { ReactComponent as AndroidIcon } from 'assets/images/left-menu/android.svg'
import { ReactComponent as IosIcon } from 'assets/images/left-menu/ios.svg'
import {
  ANDROID_APP_URL,
  IOS_APP_URL,
} from 'constants/links'

export const appLinks = [
  { title: 'App Store', Icon: IosIcon, link: IOS_APP_URL },
  { title: 'Android', Icon: AndroidIcon, link: ANDROID_APP_URL },
]
