import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'
import { responseHandler } from 'store/line/utils'

export const getTop = betOnOur => async dispatch => {
  try {
    const { data: top } = await Api.getTopGames(betOnOur)

    dispatch({ type: ACTIONS.LINE.GET_TOP, payload: top })
  } catch (error) {
    console.error(error)
  }
}

export const getLeagueById = (id, period, betOnOur) => async dispatch => {
  dispatch({ type: ACTIONS.LINE.LEAGUES_FETCHING, payload: true })

  try {
    const { data: { leagues } } = await Api.getLineSelect(id, period, betOnOur)

    dispatch({
      type:    ACTIONS.LINE.LEAGUES_BY_ID,
      payload: { [id]: responseHandler(leagues) },
    })
  } catch (error) {
    console.error(error)
  } finally {
    dispatch({ type: ACTIONS.LINE.LEAGUES_FETCHING, payload: false })
  }
}

export const getLeaguesSlider = () => async dispatch => {
  try {
    const { data } = await Api.getLeaguesSlider()

    if (data.length) {
      dispatch({ type: ACTIONS.LINE.SET_SLIDES, payload: data })
    }
  } catch (error) {
    console.error(error)
  }
}
