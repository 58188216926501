import styles from './styles.module.scss'

export const HideBtn = ({ onClick }) => (
  <button
    className={styles.hideBtn}
    onClick={onClick}
  >
    Больше не показывать
  </button>
)
