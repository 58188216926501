import { Api } from 'api/Api'
import { CasinoSpinner } from 'components/Casino/Spinner'
import { Page } from 'components/Page'
import { paths } from 'constants/paths'
import { useAuth } from 'hooks/useAuth'
import { initBetGames } from 'pages/Casino/TVGame/BetGames/utils'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const BetGamesPage = ({ id }) => {
  const isAuth   = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    Api.getBetgamesData()
       .then(({ data }) => initBetGames(data, id))
       .catch(console.error)
  }, [isAuth])

  return (
    <Page
      title="BetGames"
      bg="black"
      onGoBack={() => navigate(paths.TV_GAMES)}
    >
      <div id="betgames_div_iframe">
        <CasinoSpinner />
      </div>
    </Page>
  )
}
