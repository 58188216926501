import { combineReducers } from 'redux'
import { allSportsReducer } from 'store/all-sports/reducers'
import auth_history from 'store/auth-history/reducer'
import { authReducer } from 'store/auth/reducers'
import banks from 'store/banks/reducers'
import bet_data from 'store/bet_data/reducers'
import { cashbackReducer } from 'store/cashback/reducers'
import { cashoutReducer } from 'store/cashout/reducers'
import casino from 'store/casino/reducer'
import championship from 'store/championship/reducer'
import { configReducer } from 'store/config/reducers'
import { couponReducer } from 'store/coupon/reducers'
import { dataReducer } from 'store/data/reducers'
import favorites from 'store/favorites/reducer'
import { forecastReducer } from 'store/forecast/reducers'
import leftPanel from 'store/left-panel/reducers'
import { lineReducer } from 'store/line/reducers'
import liveBySport from 'store/live-by-sport/reducers'
import { liveTop } from 'store/live-top/reducer'
import { liveVideo } from 'store/live-video/reducer'
import margin0 from 'store/margin0/reducer'
import { maxlinePaysReducer } from 'store/maxline-pays/reducers'
import messages from 'store/messages/reducers'
import modals from 'store/modal/reducer'
import { newSignUpReducer } from 'store/new-sign-up/reducers'
import news from 'store/news/reducers'
import oneClickBets from 'store/one-click-bets/reducer'
import openedbets from 'store/openedbets/reducers'
import { paymentsReducer } from 'store/payments/reducers'
import user_account from 'store/personal-data/reducers'
import { preloaderReducer } from 'store/preloader/reducers'
import resultLive from 'store/result-live/reducers'
import { lineResultsReduces } from 'store/results/reducers'
import search_event from 'store/search-event/reducers'
import { serviceReducer } from 'store/service/reducers'
import { sportsFilterReducer } from 'store/sports-filter/reducer'
import { topExpressReducer } from 'store/top-express/reducer'
import { tournamentsReducer } from 'store/tournaments/reducer'
import userBets from 'store/user-bets/reducer'
import { userReducer } from 'store/user/reducers'
import whats_new from 'store/whats_new/reducers'

export const rootReducer = combineReducers({
  auth:         authReducer,
  data:         dataReducer,
  coupon:       couponReducer,
  bet_data,
  liveBySport,
  openedbets,
  messages,
  resultLive,
  news,
  leftPanel,
  whats_new,
  search_event,
  user_account,
  auth_history,
  favorites,
  modals,
  banks,
  oneClickBets,
  margin0,
  championship,
  userBets,
  liveTop,
  liveVideo,
  casino,
  user:         userReducer,
  forecast:     forecastReducer,
  payments:     paymentsReducer,
  maxlinePays:  maxlinePaysReducer,
  cashout:      cashoutReducer,
  service:      serviceReducer,
  config:       configReducer,
  cashback:     cashbackReducer,
  line:         lineReducer,
  sportsFilter: sportsFilterReducer,
  lineResults:  lineResultsReduces,
  allSports:    allSportsReducer,
  topExpress:   topExpressReducer,
  preloader:    preloaderReducer,
  tournaments:  tournamentsReducer,
  newSignUp:    newSignUpReducer,
})
