import { GuestBanner } from 'components/SmartBanner/Guest'
import { UserBanner } from 'components/SmartBanner/User'
import {
  BANNER_DELAY,
  shouldShowBanner,
  updateStorageValues,
} from 'components/SmartBanner/utils'
import {
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'

const SmartBanner = ({ isAuth, didInit }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const shouldShow = shouldShowBanner()

    if (didInit && shouldShow) {
      const timeout = setTimeout(() => setIsOpen(true), BANNER_DELAY)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [didInit])

  const onClose = () => {
    updateStorageValues()
    setIsOpen(false)
  }

  const Banner = isAuth ? UserBanner : GuestBanner

  return (
    isOpen ? <Banner onClose={onClose} /> : null
  )
}

const mapStateToProps = state => ({
  isAuth:  state.auth.isAuth,
  didInit: state.data.initialized,
})

export default connect(mapStateToProps)(SmartBanner)
