import { AllFilter } from 'pages/Casino/constants'
import ACTIONS from 'store/constants/actions'

const initialGamesData = {
  page_id:  0,
  games:    [],
  tags:     [],
  count:    0,
  has_more: false,
  a_type:   0,
}

const data = {
  slides:          [],
  providers:       [],
  categories:      [],
  themes:          [],
  themesInfo:      {},
  games:           [],
  gamesInfo:       {},
  favorites:       [],
  populars:        {},
  game_for_pay:    [],
  appOnlyGamesIDs: [],
  recentGames:     initialGamesData,
}

const initialState = {
  ...data,
  counters:       {},
  pageId:         0,
  gamesSearch:    '',
  isFavoritePage: false,
  activeIds:      {
    provider: AllFilter.id,
    category: AllFilter.id,
    theme:    AllFilter.id,
  },
  loadings:       {
    games:        true,
    categories:   true,
    game_for_pay: true,
    recentGames:  true,
  },
}

export default function casino(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.CASINO.SET_SLIDES: {
      return { ...state, slides: payload }
    }

    case ACTIONS.CASINO.SET_PROVIDERS: {
      return { ...state, providers: payload }
    }

    case ACTIONS.CASINO.SET_CATEGORIES: {
      return { ...state, categories: payload }
    }

    case ACTIONS.CASINO.SET_THEMES: {
      const { themes, count } = payload
      return { ...state, themes, themesInfo: { count } }
    }

    case ACTIONS.CASINO.SET_GAMES: {
      const { data, isAddGames } = payload

      const { games = [], has_more = false, page_id = 0, count = 0, tags = [] } = data

      const newGames = isAddGames ? [...state.games, ...games] : games

      return { ...state, games: newGames, gamesInfo: { has_more, page_id, count, tags } }
    }

    case ACTIONS.CASINO.SET_RECENT_GAMES: {
      return { ...state, recentGames: payload?.data || initialGamesData }
    }

    case ACTIONS.CASINO.SET_GAMES_LIST: {
      return { ...state, games: payload }
    }

    case ACTIONS.CASINO.SET_IS_FAVORITE_PAGE: {
      return { ...state, isFavoritePage: payload }
    }

    case ACTIONS.CASINO.SET_FAVORITES: {
      return { ...state, favorites: payload }
    }

    case ACTIONS.CASINO.SET_COUNTERS: {
      return { ...state, counters: payload }
    }

    case ACTIONS.CASINO.CHANGE_LOADING: {
      return { ...state, loadings: { ...state.loadings, ...payload } }
    }

    case ACTIONS.CASINO.CHANGE_GAMES_SEARCH: {
      return { ...state, gamesSearch: payload }
    }

    case ACTIONS.CASINO.CHANGE_ACTIVE_IDS: {
      return { ...state, pageId: 0, activeIds: { ...state.activeIds, ...payload } }
    }

    case ACTIONS.CASINO.SET_PAGE_ID: {
      return { ...state, pageId: payload }
    }

    case ACTIONS.CASINO.CLEAR_DATA: {
      return { ...state, ...data }
    }

    case ACTIONS.CASINO.SET_POPULARS: {
      return { ...state, populars: payload }
    }

    case ACTIONS.CASINO.SET_MAXLINE_PAY: {
      return { ...state, game_for_pay: payload }
    }

    case ACTIONS.CASINO.APP_ONLY_GAMES_IDS: {
      return { ...state, appOnlyGamesIDs: payload || [] }
    }

    case ACTIONS.CASINO.CLEAR_STATE: {
      return initialState
    }
  }

  return state
}
