import { useIntersect } from 'hooks/useIntersect'
import { CasinoItem } from 'pages/UserHistoryPage/Casino/History/CasinoItem/index'
import { useEffect } from 'react'

export const InfiniteCasino = ({ transactions, getMore }) => {
  const [lastNode, needRequest] = useIntersect()

  useEffect(() => {
    needRequest && getMore()
  }, [needRequest])

  return (
    <div className="infinite-casino">
      {!!transactions?.length && (
        <>
          {transactions.map(t => <CasinoItem key={t.id} {...t} />)}
          {lastNode}
        </>
      )}
    </div>
  )
}
