const currencyCode = 'byn'

export const setDataLayer = dataLayer => {
  try {
    window.dataLayer?.push?.(dataLayer)
  } catch {
  }
}

export const setUserId = user_id => setDataLayer({ user_id })

export const setPurchase = (transaction_id, value, isFirst, ...utmProps) => setDataLayer({
  event:        `${isFirst ? 'first_' : ''}purchase`,
  transaction_id,
  value,
  currency:     currencyCode,
  payment_type: 'Ipay (visa/mastercard)',
  ...utmProps,
})

export const setRegistration = () => setDataLayer({
  event:         'registration',
  eventCategory: 'rega',
  eventAction:   'button',
})

export const setSignUpStart = () => setDataLayer({
  event: 'sign_up_1',
})

export const setSignUpStartError = () => setDataLayer({
  event: 'sign_up_1_error',
})

export const setSignUpSuccess = (time, user_id) => setDataLayer({
  event: 'sign_up_2',
  time,
  user_id,
})

export const setSignUpError = () => setDataLayer({
  event: 'sign_up_2_error',
})

export const setVerifySuccess = time => setDataLayer({
  event: 'verify_docs_submit',
  time,
})

export const setVerifyError = () => setDataLayer({
  event: 'verify_error',
})

export const setSignIn = user_id => setDataLayer({
  event: 'login',
  user_id,
})

export const setBetSuccess = (bet_id, bet_type) => setDataLayer({
  event: 'bet',
  bet_id,
  bet_type,
})

export const setVerification = () => setDataLayer({
  event:         'verification',
  eventCategory: 'complete',
  eventAction:   'page',
})

export const setAddToCart = products => setDataLayer({
  event:     'addToCart',
  ecommerce: {
    currencyCode,
    add: { products },
  },
})

export const setCheckout = (products, step) => setDataLayer({
  event:     'checkout',
  ecommerce: {
    currencyCode,
    checkout: { actionField: { step }, products },
  },
})
