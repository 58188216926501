import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { rootReducer } from 'store/rootReducer'

const isDev = process.env.NODE_ENV === 'development'

const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export const configureStore = () => {
  const store     = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
  const persistor = persistStore(store)

  return {
    store,
    persistor,
    dispatch: store.dispatch,
  }
}
