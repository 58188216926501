import { Api } from 'api/Api'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import { STATUSES } from 'constants/statuses'

export const onPayReturn = async (betId, onSuccess) => {
  const { status, data } = await Api.payoutMaxlinePayReturn(betId)

  if (status === STATUSES.SUCCESS) {
    onSuccess?.()
    return showSuccessModal({ message: 'Запрос принят' })
  }

  showErrorModal({ message: data.messages.toString() })
}
