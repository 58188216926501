import { Page } from 'components/Page'
import { RulesLink } from 'components/RulesLink'
import { useTabs } from 'hooks/useTabs'
import {
  CashbackScreen,
  cashbackTabs,
} from 'pages/Cashback/utils'
import { RULES_URL } from 'pages/VipCashback/utils'

export const VipCashbackPage = () => {
  const [Tabs, activeTab] = useTabs(cashbackTabs)

  const Screen = CashbackScreen[activeTab]

  return (
    <Page
      title="VIP CashBack"
      bg="gray"
      headerContent={<RulesLink to={RULES_URL} />}
    >
      <Tabs className="p-8" />
      <Screen />
    </Page>
  )
}
