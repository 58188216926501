import { Api } from 'api/Api'
import { showPaymentHybridModal } from 'components/PaymentModal/HybridModal'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { STATUSES } from 'constants/statuses'
import { PAYMENT_COMPLETED } from 'pages/SuccessPay/utils'
import { setPurchase } from 'utils/google-analytics/EcommerceGTM'
import { parseJSON } from 'utils/parse-json'
import { sleep } from 'utils/time'

export const DEFAULT_CARD = {
  id:     0,
  number: 'Новая карта',
  icon:   'add',
  exp:    '',
}

export const showSuccessPaymentMessage = callback => {
  showSuccessModal({
    title:     'Платеж принят',
    message:   'Ваш баланс был успешно пополнен',
    submitBtn: {
      title: 'Продолжить',
      callback,
    },
    cancelBtn: {
      title: 'Пополнить ещё',
    },
  })
}

export const showFailedPaymentMessage = callback => {
  showErrorModal({
    title:     'Платеж отклонен',
    subtitle:  'Попробуйте повторить попытку или обратитесь в службу поддержки',
    submitBtn: { title: 'Повторить' },
    cancelBtn: {
      title: 'Служба поддержки',
      to:    '/contacts',
      callback,
    },
  })
}

const preloaderTitle = (
  <>
    <div className="font-18 bold">Идет обработка платежа</div>
    <div className="font-13 mt-8">Пожалуйста дождитесь завершения</div>
  </>
)

export const handlePaymentMessage = async ({ data }, { closeModal, onSuccess, onIframeClose, isHybridModal }) => {
  const message = parseJSON(data)

  if (message?.action === PAYMENT_COMPLETED) {
    showPreloader()
    onIframeClose()

    let attempts = 10

    const check = async () => {
      if (attempts > 0) {
        const {
                status,
                data: {
                        id,
                        value,
                        need_gtm,
                        is_first_deposit,
                        session_source,
                        session_medium,
                        session_campaign,
                        session_content,
                        session_keyword,
                      },
              } = await Api.getSuccessPay(message.payload)

        const isOk = status === STATUSES.SUCCESS && id && value

        if (!isOk) {
          attempts -= 1

          if (attempts > 0) {
            await sleep(1000)
          }

          return await check()
        }

        if (need_gtm) {
          setPurchase(
            id,
            value,
            is_first_deposit,
            session_source,
            session_medium,
            session_campaign,
            session_content,
            session_keyword,
          )
        }

        return (isHybridModal ? showPaymentHybridModal : showSuccessPaymentMessage)(onSuccess)
      }

      return showFailedPaymentMessage(closeModal)
    }

    try {
      await check()
    } catch {
      showFailedPaymentMessage(closeModal)
    } finally {
      hidePreloader()
    }
  }
}
