import { appLinks } from 'components/Footer/AppLinks/utils'
import { LinksGroup } from 'components/Footer/LinksGroup'
import styles from './styles.module.scss'

export const AppLinks = ({ dark }) => (
  <LinksGroup
    links={appLinks}
    dark={dark}
    className={styles.appLinks}
  />
)
