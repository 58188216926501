import { Filters } from 'components/Casino/Filters/Filters'
import { Select } from 'components/Casino/SelectBlock/Select'
import {
  CasinoFilter,
  useGamesFilter,
  useSelectContext,
} from 'components/Casino/SelectBlock/utils'
import SwipeModal from 'components/SwipeModal'
import { AllFilter } from 'pages/Casino/constants'
import { themesToFilters } from 'pages/Casino/utils'
import { useSelector } from 'react-redux'
import {
  all,
  selectCounters,
} from 'store/casino/selectors'

export const ThemesSelect = () => {
  const [activeID, setFilter]         = useGamesFilter(CasinoFilter.THEME)
  const { isOpen, onChange, onClose } = useSelectContext(CasinoFilter.THEME)

  const { themes: rawThemes, themesInfo } = useSelector(all)

  const { count_all = 0 } = useSelector(selectCounters)

  const filters   = [{ ...AllFilter, count: count_all }, ...themesToFilters(rawThemes, themesInfo)]
  const theme     = filters.find(({ id }) => String(id) === String(activeID))
  const hasThemes = filters?.find(({ count }) => count > 0)

  const onClick = theme => {
    setFilter(theme)
    onClose()
  }

  return (
    hasThemes ?
      <Select
        title="Тематика"
        subTitle={theme?.name}
        onClick={() => onChange(CasinoFilter.THEME)}
      >
        <SwipeModal
          title="Тематика"
          isOpen={isOpen}
          onClose={onClose}
          className="casino-swipe-modal"
        >
          <Filters
            onClick={onClick}
            items={filters}
            activeId={activeID}
          />
        </SwipeModal>
      </Select> : null
  )
}
