import ACTIONS from 'store/constants/actions'

const initialState = {
  isOpen:  false,
  loading: false,
  data:    {
    error: false,
  },
}

export default function coupon(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.BET_DATA.UPDATE: {
      return { ...state, data: action.data }
    }
    case ACTIONS.BET_DATA.CLEAR: {
      return { ...state, data: {} }
    }
    case ACTIONS.BET_DATA.SET_IS_OPEN: {
      return { ...state, isOpen: action.payload }
    }
    case ACTIONS.BET_DATA.LOADING: {
      return { ...state, loading: action.payload }
    }
    default:
      return state
  }
}
