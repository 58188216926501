import { Placeholder } from 'components/Placeholder'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import SwipeModal from 'components/SwipeModal'
import { useIntersect } from 'hooks/useIntersect'
import { BetItem } from 'pages/UserHistoryPage/Bets/History/BetItem'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { getCashOut } from 'store/cashout/actions'
import { getMaxlinePays } from 'store/maxline-pays/actions'
import { renderOptions } from 'utils'
import {
  BETS_PER_PAGE,
  FETCH_INTERVAL,
  getHistory,
  getPeriodFilters,
  getTypeFilters,
  INITIAL_PAGE,
  initialState,
  transformBets,
} from './utils'

const BetsHistory = ({
  period,
  type,
  setFilter,
  setPeriod,
  setType,
  filter,
  getCashOut,
  notCalc,
  getMaxlinePays,
}) => {
  const [history, setHistory] = useState(initialState)
  const [page, setPage]       = useState(INITIAL_PAGE)

  const [lastNode, isLastVisible] = useIntersect()

  const hasMore  = history.bets.length - (BETS_PER_PAGE * page) > 0
  const isPeriod = filter === 'period'

  useEffect(() => {
    if (isLastVisible && hasMore) {
      setPage(prevPage => prevPage + 1)
    }
  }, [isLastVisible, hasMore])

  const updateHistory = useCallback(async (resetPage = false) => {
    if (resetPage) {
      setPage(INITIAL_PAGE)
    }

    const betHistory = await getHistory(period, notCalc)

    setHistory(betHistory)
    getCashOut()
    getMaxlinePays()
  }, [period, notCalc])

  useEffect(() => {
    showPreloader()
    updateHistory(true).then(hidePreloader)

    const interval = setInterval(updateHistory, FETCH_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [updateHistory])

  const onChangeFilter = action => nextFilter => {
    action(nextFilter)
    setFilter(null)
  }

  const transformedBets = useMemo(() => transformBets(type, history), [type, history])

  const visibleBets = transformedBets.slice(0, page * BETS_PER_PAGE)
  const hasHistory  = visibleBets.length > 0

  return (
    <>
      {(hasHistory && (
        <div className="account__betHistory bet-page">
          {visibleBets.map(bet => (
            <BetItem
              key={bet.number}
              bet={bet}
              onRefresh={updateHistory}
            />
          ))}
          {lastNode}
        </div>
      ) || history.isFetched && <Placeholder name="bets" />)}
      <SwipeModal
        title={isPeriod ? 'Период' : 'Тип ставки'}
        onClose={() => setFilter(null)}
        isOpen={!!filter}
      >
        {renderOptions(isPeriod
          ? getPeriodFilters(onChangeFilter(setPeriod), period)
          : getTypeFilters(onChangeFilter(setType), type))}
      </SwipeModal>
    </>
  )
}

const mapDispatchToProps = {
  getCashOut,
  getMaxlinePays,
}

export default connect(null, mapDispatchToProps)(BetsHistory)
