import { LeagueHeader } from 'pages/Home/components/LeagueHeader'
import { LeagueInfo } from 'pages/Home/components/LeagueInfo'
import styles from 'pages/Home/components/LineLeagues/styles.module.scss'
import { useState } from 'react'

const MIN_EVENTS = 10

export const LineLeaguesBySport = ({
  countEvents,
  countryCode,
  name,
  description,
  opened,
  onClick,
  fetching,
  children = [],
}) => {
  const [isFull, setIsFull] = useState(false)

  const events  = isFull ? children : children.slice(0, MIN_EVENTS)
  const hasMore = children.length > MIN_EVENTS && !isFull

  return (
    <>
      <LeagueHeader
        name={name}
        onClick={onClick}
        opened={opened}
        count={countEvents}
        countryCode={countryCode}
        fetching={fetching}
      />
      {opened && (
        <div>
          <LeagueInfo description={description} />
          <div>
            {events}
            {hasMore && (
              <div className={styles.footer}>
                <button
                  className={styles.link}
                  onClick={() => setIsFull(true)}
                >
                  Турнир полностью
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
