import payStep1Img from 'assets/images/oplati/pay-step-1.jpg'
import payStep2Img from 'assets/images/oplati/pay-step-2.jpg'
import payStep3Img from 'assets/images/oplati/pay-step-3.jpg'
import payStep4Img from 'assets/images/oplati/pay-step-4.jpg'
import payStep5Img from 'assets/images/oplati/pay-step-5.jpg'
import payStep6Img from 'assets/images/oplati/pay-step-6.jpg'
import payStep7Img from 'assets/images/oplati/pay-step-7.jpg'
import { ExternalLink } from 'components/ExternalLink'
import { videoGuidLink } from 'components/OplatiTerms/Pay/utils'
import { OplatiAppLinks } from 'pages/Payments/components/Oplati/AppLinks'

export const Pay = () => (
  <>
    <h2>Инструкция пополнения:</h2>
    <ol>
      <li>
        <div>Скачайте приложение «Оплати»</div>
        <OplatiAppLinks className="mt-14" />
      </li>
      <li>
        <div>Пройдите регистрацию</div>
        <div className="font-normal">
          <p>Введите идентификационный номер паспорта (14 символов). Нажмите «Продолжить».</p>
          <img
            src={payStep1Img}
            alt="step-1"
          />
          <p>
            Вас перенаправит на страницу МСИ (Межбанковской Системы Идентификации). После этого введите номер телефона и
            код из SMS, который будет отправлен на Ваш номер.
          </p>
          <img
            src={payStep2Img}
            alt="step-2"
          />
          <p>
            Далее подтвердите Ваши данные, нажав «Продолжить». Затем установите PIN.
          </p>
          <img
            src={payStep3Img}
            alt="step-3"
          />
        </div>
      </li>
      <li>
        <div>Пополните свой электронный кошелек.</div>
        <div className="font-normal">
          <p>Нажмите "Пополнить".</p>
          <img
            src={payStep4Img}
            alt="step-4"
          />
          <p>Выберите вариант пополнения.</p>
          <img
            src={payStep5Img}
            alt="step-5"
          />
          <p className="bold">Пополнение с карты другого банка</p>
          <p>Введите данные карты, сумму для оплаты. Нажмите «Пополнить».</p>
          <p>Подтвердите оплату с помощью SMS-кода, отправленного на телефон.</p>
          <img
            src={payStep6Img}
            alt="step-6"
          />
          <p className="bold">Пополнение кошелька с карты банка-партнёра</p>
          <p>
            Если Вы являетесь клиентом ОАО «Белинвестбанка», карта отобразится автоматически. Выберите сумму для оплаты
            и
            переведите денежные средства на кошелёк.
          </p>
          <p className="bold">Пополнение кошелька через ЕРИП</p>
          <p>Скопируйте номер договора текущего счета для пополнения кошелька.</p>
          <p>
            Зайдите в ЕРИП в любом мобильном или интернет-банкинге, инфокиоске и выберите: Банковские, финансовые услуги
            -
            Банки, НКФО - Белинвестбанк - Пополнение счёта - скопированный номер договора-сумма.
          </p>
          <img
            src={payStep7Img}
            alt="step-7"
          />
          <p>
            Со всеми способами пополнения можно ознакомиться в
            &nbsp;
            <ExternalLink
              title="видеоинструкции"
              to={videoGuidLink}
            />.
          </p>
        </div>
      </li>
      <li>
        <div>Пополните игровой счет в Maxline.</div>
        <div className="font-normal">
          <p>
            Нажмите на кнопку «Пополнить счёт» в личном кабинете maxline.by. Выберите способ «Оплати», введите сумму и
            нажмите кнопку «Пополнить». Далее необходимо нажать на кнопку «Пополнить через «Оплати» и подтвердить
            операцию.
          </p>
          <p>
            Также для пополнения игрового счёта можно воспользоваться следующими этапами в приложении «Оплати»:
          </p>
          <div>>> Платежи</div>
          <div>>> Сделать новый платеж</div>
          <div>>> Интернет-магазины/сервисы</div>
          <div>>> Букмекерские конторы</div>
          <div>>> Пополнение баланса Maxline.by</div>
          <p>
            Далее необходимо ввести номер игрового счета и сумму для оплаты.
          </p>
        </div>
      </li>
    </ol>
  </>
)
