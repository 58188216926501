import { Api } from 'api/Api'
import { showErrorModal } from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { STATUSES } from 'constants/statuses'
import { onSuccessPayout } from 'pages/Takemoney/Forms/utils'
import { getCardsInfo } from 'utils'

export const MIN_AMOUNT           = 5
export const MAX_AMOUNT           = 10000
export const PAYOUT_EXTRA_LOCK_MS = 1000

export const getCardsList = async () => {
  const { data } = await Api.getCardsList()

  return {
    cards:     getCardsInfo(data.cards),
    hasHidden: !!data.hidden.length,
    defaultId: data.default,
  }
}

export const getIsError = amount => amount > MAX_AMOUNT || amount < MIN_AMOUNT

export const onPayout = async data => {
  showPreloader()

  try {
    const { status, messages } = await Api.cardOut(data)

    if (status === STATUSES.SUCCESS) {
      onSuccessPayout()
      return true
    }

    showErrorModal({
      message:   messages.toString(),
      submitBtn: { title: 'Продолжить' },
    })

    return false
  } catch {
    const subject = 'Ошибка при выводе средств'
    const body    = 'Здравствуйте, у меня не получилось заказать на выплату. Вот мои действия: '

    showErrorModal({
      title:     'Что-то пошло не так',
      message:   'Попробуйте повторить запрос или обратитесь в службу поддержки',
      submitBtn: { title: 'Повторить' },
      cancelBtn: {
        title:    'Служба поддержки',
        callback: () => window.open(`mailto:support@maxline.by?subject=${subject}&body=${body}`),
      },
    })

    return false
  } finally {
    hidePreloader()
  }
}
