import { useEventListener } from 'hooks/useEventListener'
import { debounce } from 'lodash'
import {
  useRef,
  useState,
} from 'react'

const EVENT_TYPE = 'scroll'
const WAIT       = 75

export const useIsScrolling = (element, condition) => {
  const [value, setValue] = useState(false)

  const valueRef = useRef(false)

  const onScrollEnd = debounce(() => {
    valueRef.current = false
    setValue(false)
  }, WAIT)

  const onScroll = () => {
    if (!valueRef.current) {
      valueRef.current = true
      setValue(true)
    }

    onScrollEnd()
  }

  useEventListener(element, EVENT_TYPE, onScroll, condition)

  return value
}
