import {
  useEffect,
  useState,
} from 'react'

export const useDeferredValue = (value, delay = 300) => {
  const [delayedValue, setDelayedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => setDelayedValue(value), delay)

    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  return delayedValue
}
