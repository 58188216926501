import { Api } from 'api/Api'
import { Collapse } from 'components/Collapse'
import { Page } from 'components/Page'
import { Placeholder } from 'components/Placeholder'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { STATUSES } from 'constants/statuses'
import { useAuth } from 'hooks/useAuth'
import { useAuthModal } from 'hooks/useAuthModal'
import {
  getHasRules,
  showFailureNewsModal,
  showSuccessNewsModal,
} from 'pages/News/Item/utils'
import { getFormattedDate } from 'pages/News/utils/getFormattedDate'
import { handleHTMLString } from 'pages/News/utils/handleHTMLString'
import {
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import TEXT from 'store/constants/text'
import styles from './styles.module.scss'

export const NewsItem = () => {
  const [news, setNews]                 = useState({})
  const [isError, setIsError]           = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { slug }      = useParams()
  const isAuth        = useAuth()
  const openAuthModal = useAuthModal()

  const getNews = async () => {
    showPreloader()
    setIsError(false)

    try {
      const { data: { news } } = await Api.getNewsOne(slug)

      const currentNews = news[0]

      if (!currentNews) {
        return setIsError(true)
      }

      setNews(currentNews)
    } catch {
      setIsError(true)
    } finally {
      hidePreloader()
    }
  }

  useEffect(() => {
    getNews()
  }, [isAuth])

  const onSubscribe = async () => {
    if (!isAuth) {
      return openAuthModal()
    }

    setIsSubmitting(true)

    try {
      const { data, status, messages } = await Api.subscribeToNews(id)

      if (status === STATUSES.SUCCESS) {
        showSuccessNewsModal(data.toString())
        return await getNews()
      }

      showFailureNewsModal(messages)
    } catch {
      showFailureNewsModal()
    } finally {
      setIsSubmitting(false)
    }
  }

  const { date, title, text, id, button_text, is_button, rules, is_rules } = news

  const hasButton = button_text && !!+is_button
  const hasRules  = getHasRules(is_rules, rules)

  return (
    <Page
      title={TEXT.news.title}
      {...(isError && { bg: 'gray' })}
    >
      {(isError && <Placeholder name="news" />) || (id && (
        <div className={styles.newsPage}>
          <div className={styles.content}>
            <div className={styles.date}>
              {getFormattedDate(date)}
            </div>
            <div className={styles.title}>
              {title}
            </div>
            <div
              className={styles.news}
              dangerouslySetInnerHTML={{ __html: handleHTMLString(text) }}
            />
            {hasRules && (
              <Collapse title="Применяются правила и условия">
                <div className={styles.rules}>
                  {rules}
                </div>
              </Collapse>
            )}
          </div>
          {hasButton && (
            <button
              className={styles.btn}
              disabled={isSubmitting}
              onClick={onSubscribe}
            >
              {button_text}
            </button>
          )}
        </div>
      ))}
    </Page>
  )
}
