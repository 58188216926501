export const RULES_URL = 'https://pub-f80a7e9af5284ae9874affa26c3e7eec.r2.dev/booster.pdf'

export const steps = [
  {
    num:  3,
    text: <>
            Сделайте экспресс-ставку, включающую <b>как минимум 3 события</b> с
            минимальным <b>коэффициентом 1,2</b> каждое, и активируйте бустер.
          </>,
  },
  { num: 2, text: <>Чем больше событий в экспрессе, <b>тем выше бустер.</b></> },
  { num: 1, text: <>Создайте экспресс-ставку, включающую 20 подходящих исходов, и мы <b>удвоим Ваш выигрыш!</b></> },
]
