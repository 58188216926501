import ACTIONS from 'store/constants/actions'

const initialState = {
  data:            [],
  unreadNewsCount: 0,
  loading:         true,
}


export default function news(state = initialState, action) {
  const { type, data } = action

  switch (type) {
    case ACTIONS.NEWS.GET_NEWS_SUCCESS: {
      return { ...state, data: data.news, loading: false }
    }

    case ACTIONS.NEWS.GET_UNREAD: {
      return { ...state, unreadNewsCount: data }
    }

    default:
      return state
  }
}
