import { Portal } from 'components/Portal'
import { useStopScroll } from 'hooks/useStopScroll'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

const SwipeModal = ({
  isOpen,
  onClose,
  children,
  title,
  subTitle,
  className,
  dark,
  innerRef,
}) => {
  const [isClosing, setIsClosing] = useState(false)

  useStopScroll(isOpen)

  const closeModal = useCallback(callback => {
    setIsClosing(true)

    setTimeout(() => {
      setIsClosing(false)
      onClose()

      if (typeof callback === 'function') {
        callback()
      }
    }, 400)
  }, [])

  useImperativeHandle(innerRef, () => ({
    onClose: closeModal,
  }), [])

  return (
    <Portal>
      {isOpen && (
        <>
          <div className={cn(styles.swipeModal, isClosing && styles.closing, className, dark && styles.dark)}>
            <div className={styles.header}>
              <button
                aria-label="Close"
                className={styles.closeBtn}
                onClick={closeModal}
              />
              {title && (
                <div className={styles.title}>
                  {title}
                </div>
              )}
              {subTitle && (
                <div className={styles.subtitle}>
                  {subTitle}
                </div>
              )}
            </div>
            <div className={styles.content}>
              {children}
            </div>
          </div>
          <div
            role="presentation"
            className={styles.backdrop}
            onClick={closeModal}
          />
        </>
      )}
    </Portal>
  )
}

SwipeModal.propTypes = {
  children:  PropTypes.node,
  className: PropTypes.string,
  isOpen:    PropTypes.bool,
  onClose:   PropTypes.func,
  subTitle:  PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title:     PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

SwipeModal.defaultProps = {
  children:  null,
  className: '',
  isOpen:    false,
  onClose:   () => null,
  subTitle:  '',
  title:     '',
}

export default SwipeModal
