import styles from 'components/Collapse/styles.module.scss'
import React, { useState } from 'react'
import { cn } from 'utils'

export const Collapse = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={styles.collapse}>
      <div
        className={cn(styles.toggleBtn, isOpen && styles.open)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </div>
      {isOpen && <div className={styles.content}>{children}</div>}
    </div>
  )
}
