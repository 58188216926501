import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'

export const getUserBets = () => async dispatch => {
  try {
    const { data } = await Api.getUserBets()

    dispatch({
      type:    ACTIONS.USER_BETS.GET,
      payload: data,
    })
  } catch (e) {
    console.error(e)
  }
}

export const updateUserBets = ({ key, value }) => async (dispatch, getState) => {
  const { userBets } = getState()

  dispatch({
    type:    ACTIONS.USER_BETS.ERROR,
    payload: false,
  })

  dispatch({
    type:    ACTIONS.USER_BETS.FETCHING,
    payload: key,
  })

  try {
    const { code, messages } = await Api.updateUserBets({ ...userBets, [key]: value })

    if (code === 200) {
      const { data } = await Api.getUserBets()

      dispatch({
        type:    ACTIONS.USER_BETS.GET,
        payload: data,
      })
    }

    if (code === 500) {
      dispatch({
        type:    ACTIONS.USER_BETS.ERROR,
        payload: messages.join(''),
      })
    }
  } catch (e) {
    console.error(e)
  } finally {
    dispatch({
      type:    ACTIONS.USER_BETS.FETCHING,
      payload: false,
    })
  }
}
