import { NewSignUpActionTypes } from 'store/new-sign-up/types'

const initialState = {
  modalKey: '',
}

export const newSignUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case NewSignUpActionTypes.SET: {
      return { ...state, modalKey: action.payload }
    }

    default: {
      return state
    }
  }
}
