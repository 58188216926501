import { Booster } from 'components/Coupon/Booster'
import styles from 'components/Coupon/Status/styles.module.scss'
import { usePrevious } from 'hooks/usePrevious'
import PropTypes from 'prop-types'
import {
  useEffect,
  useState,
} from 'react'

export const CouponStatus = ({ isOpen, onOpen, couponsCount, totalCoeff, isFetching, booster, freeBet }) => {
  const [isAnimate, setIsAnimate] = useState(false)

  const prevCount = usePrevious(couponsCount)

  useEffect(() => {
    let timeout

    if (couponsCount > prevCount) {
      setIsAnimate(true)
      timeout = setTimeout(() => setIsAnimate(false), 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [couponsCount])

  return (
    !isOpen && (
      <div
        className={styles.status}
        data-animate={isAnimate}
        onClick={onOpen}
      >
        {!freeBet && <Booster value={booster} />}
        <div className={styles.counter}>
          <div className={styles.count}>
            {isFetching ? <div className="ml-spinner" /> : couponsCount}
          </div>
          <div className={styles.label}>Купон</div>
        </div>
        {!isFetching && (
          <div className={styles.totalCoeff}>
            {`${couponsCount > 1 ? 'Экспресс' : 'Одиночная'} ${totalCoeff}`}
          </div>
        )}
      </div>
    )
  )
}

CouponStatus.propTypes = {
  isOpen:       PropTypes.bool,
  onOpen:       PropTypes.func,
  couponsCount: PropTypes.number,
  isFetching:   PropTypes.bool,
}

CouponStatus.defaultProps = {
  isOpen:       false,
  onOpen:       () => null,
  couponsCount: 0,
  isFetching:   true,
}
