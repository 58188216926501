import { sq } from 'api/utils'
import { buildGameURI } from 'components/Casino/Slots/Game/utils'
import { paths } from 'constants/paths'
import { GameParam } from 'pages/Casino/Game/utils'
import { getBetTime } from 'pages/UserHistoryPage/utils'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import { getUTCDate } from 'utils/time'

export const CasinoItem = ({ bet, created_at, name, win, type_name, game_id, provider, virtual, live }) => {
  const isWin   = !!+win
  const search  = sq({ [GameParam.NO_DEMO]: !!(+virtual || +live) })
  const gameURL = `${paths.CASINO_SLOTS_GAMES}/${buildGameURI(provider, name, game_id, search)}`

  return (
    <div className="casino-item">
      <div className="casino-item__left">
        <div className="casino-item__left-top">
          {!!type_name && <div className="casino-item__text">{type_name}</div>}
        </div>
        <Link
          to={gameURL}
          className="casino-item__game"
        >
          {name}
        </Link>
      </div>
      <div className="casino-item__right">
        <div className={cn('casino-item__right-top', isWin && 'win')}>
          <div className="casino-item__text">
            {getBetTime(getUTCDate(created_at))}
          </div>
        </div>
        <div className="casino-item__right-content">
          <div className="casino-item__status">
            <div className="casino-item__text">Сумма ставки</div>
            <div className="casino-item__amount">{parseFloat(bet)} BYN</div>
          </div>
          {isWin && <div className="casino-item__status">
            <div className="casino-item__text">Выигрыш</div>
            <div className="casino-item__amount win">{parseFloat(win)} BYN</div>
          </div>}
        </div>
      </div>
    </div>
  )
}
