import { useEffect } from 'react'

export const useListener = (type, callback) => {
  useEffect(() => {
    window.addEventListener(type, callback)

    return () => {
      window.removeEventListener(type, callback)
    }
  }, [])
}
