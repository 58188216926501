import { useSelector } from 'react-redux'

const THEMES = {
  LIGHT: 'light',
  DARK:  'dark',
}

export const useTheme = () => {
  const theme = useSelector(state => state.data.theme)

  return {
    theme,
    isDark:  theme === THEMES.DARK,
    isLight: theme === THEMES.LIGHT,
  }
}
