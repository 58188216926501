import { ReactComponent as AndroidIcon } from 'assets/images/left-menu/android.svg'
import { ReactComponent as IOSIcon } from 'assets/images/left-menu/ios.svg'
import { ExternalLink } from 'components/ExternalLink'
import {
  ANDROID_APP_URL,
  IOS_APP_URL,
} from 'constants/links'
import { isIos } from 'constants/platform'
import styles from './styles.module.scss'

export const AppLink = () => (
  <ExternalLink
    to={isIos ? IOS_APP_URL : ANDROID_APP_URL}
    className={styles.link}
  >
    {isIos ? <IOSIcon /> : <AndroidIcon />}
    {isIos ? 'App Store' : 'Скачать'}
  </ExternalLink>
)
