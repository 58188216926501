import {
  useEffect,
  useState,
} from 'react'

export const useDelayedState = delay => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (isActive) {
      const timeout = setTimeout(() => setIsActive(false), delay)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [isActive])

  const toggle = () => setIsActive(true)

  return [isActive, toggle]
}
