import { Page } from 'components/Page'
import { useTheme } from 'hooks/useTheme'
import { FastPayoutsTitle } from 'pages/Payments/components/FastPayouts'
import { OplatiTitle } from 'pages/Payments/components/Oplati'
import { PaymentItem } from 'pages/Payments/components/PaymentItem'
import BankForm from 'pages/Takemoney/Forms/Bank'
import QuickPayout from 'pages/Takemoney/Forms/Card'
import OplatiForm from 'pages/Takemoney/Forms/Oplati'
import PpsForm from 'pages/Takemoney/Forms/Pps'
import TEXT from 'store/constants/text'
import { importImage } from 'utils'

export const TakeMoneyPage = () => {
  const { theme } = useTheme()

  return (
    <Page
      title={TEXT.account.takeMoney.title}
      bg="gray"
    >
      <div className="p-8">
        <PaymentItem
          title={<FastPayoutsTitle />}
          icon={importImage(`banks/${theme}/card.svg`)}
          open
        >
          <QuickPayout />
        </PaymentItem>
        <PaymentItem
          title={<OplatiTitle />}
          icon={importImage(`banks/${theme}/oplati.svg`)}
        >
          <OplatiForm />
        </PaymentItem>
        <PaymentItem
          title="Снятие наличных в ППС"
          icon={importImage(`banks/${theme}/maxline.svg`)}
        >
          <PpsForm />
        </PaymentItem>
        <PaymentItem
          title="По номеру счёта IBAN"
          icon={importImage(`banks/${theme}/banks.svg`)}
        >
          <BankForm />
        </PaymentItem>
      </div>
    </Page>
  )
}
