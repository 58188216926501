import otherCategoryImg from 'assets/images/line/other-category.png'
import { ReactComponent as ArrowIcon } from 'assets/images/live/arrow-new.svg'
import { getFlagUrl } from 'pages/Home/components/LineLeagues/utils'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import { replaceSrcOnError } from 'utils/replace-src-on-error'
import styles from './styles.module.scss'

export const LeagueHeader = ({ countryCode, name, opened, onClick, count, to, fetching, className, icon }) => {
  const Tag = to ? Link : 'div'

  return (
    <Tag
      className={cn(styles.leagueHeader, opened && styles.opened, to && styles.link, className)}
      {...(to && { to })}
      {...(onClick && { onClick })}
    >
      <img
        className={styles.flag}
        src={icon || getFlagUrl(countryCode)}
        onError={replaceSrcOnError(otherCategoryImg)}
        alt={name}
      />
      <div className={styles.name}>{name}</div>
      {(count && !opened) && <span className={styles.count}>{count}</span>}
      {fetching ? <div className={styles.spinner} /> : <ArrowIcon className={styles.arrow} />}
    </Tag>
  )
}
