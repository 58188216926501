import ACTIONS from 'store/constants/actions'

const initialState = {
  allMessages: [],
  unread:      0,
  categories:  [],
}

export default function messages(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.MESSAGES.GET_LIST:
      return { ...state, allMessages: payload }

    case ACTIONS.MESSAGES.GET_CATEGORIES:
      return {
        ...state, categories: [
          {
            'id':   '0',
            'name': 'Все',
          }, ...payload,
        ],
      }

    case ACTIONS.MESSAGES.GET_UNREAD:
      return { ...state, unread: payload }

    default:
      return state
  }
}
