import { Filters } from 'components/Casino/Filters/Filters'
import { Page } from 'components/Page'
import { paths } from 'constants/paths'
import {
  themesToFilters,
  useClearCasinoState,
} from 'pages/Casino/utils'
import { useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getGames } from 'store/casino/actions'
import { setGamesList } from 'store/casino/creators'
import {
  selectThemes,
  selectThemesInfo,
} from 'store/casino/selectors'

export const CasinoThemesPage = () => {
  const dispatch   = useDispatch()
  const navigate   = useNavigate()
  const rawThemes  = useSelector(selectThemes)
  const themesInfo = useSelector(selectThemesInfo)

  useClearCasinoState()

  useEffect(() => {
    dispatch(getGames())

    return () => {
      dispatch(setGamesList([]))
    }
  }, [])

  const handleClick = theme => {
    navigate({
      pathname: paths.CASINO_SLOTS_GAMES,
      search:   `theme=${theme}`,
    })
  }

  const themes = themesToFilters(rawThemes, themesInfo)

  return (
    <Page
      title="Тематика"
      className="casino"
      bg="black"
      scrollTopBtn
    >
      <Filters
        onClick={handleClick}
        items={themes}
      />
    </Page>)
}
