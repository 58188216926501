import { format } from 'date-fns'
import { openPromoCodeInfo } from 'pages/PromoCodes/Available/utils'
import { getUTCDate } from 'utils/time'
import styles from './styles.module.scss'
import {
  colors,
  getAmountText,
} from './utils'

export const PromoCodesHistory = ({ list }) => (
  <div className={styles.promoCodesHistory}>
    {list.map(promoCode => {
      const { created_at, code, value, status, status_str, is_freespin, is_freebet } = promoCode

      return (
        <div
          key={code}
          className={styles.promoCode}
        >
          <div className={styles.code}>{code}</div>
          <div className={styles.amount}>
            <div>
              {getAmountText(is_freespin, is_freebet, value)}
            </div>
            <button
              className={styles.infoBtn}
              onClick={() => openPromoCodeInfo(promoCode)}
            />
          </div>
          <div className={styles.date}>{format(getUTCDate(created_at), 'dd.MM.yy HH:mm:ss')}</div>
          <div
            className={styles.status}
            style={{ color: colors[status] }}
          >
            {status_str}
          </div>
        </div>
      )
    })}
  </div>
)
