import { ReactComponent as A1Icon } from 'assets/images/forbidden/a1.svg'
import { ReactComponent as MtsIcon } from 'assets/images/forbidden/mts.svg'
import { ReactComponent as Logo } from 'assets/images/splash-screen/logo.svg'
import { ExternalLink } from 'components/ExternalLink'
import { forbiddenTypes } from 'components/Forbidden/utils'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

export const Forbidden = ({ type }) => {
  const { title, description, Icon } = forbiddenTypes[type] || forbiddenTypes.unknown

  return (
    <main className={styles.forbidden}>
      <Logo className={styles.logo} />
      <div className={styles.content}>
        <Icon />
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.description}>
          {description}
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.description}>В случае возникновения вопросов обратитесь в службу поддержки:</p>
        <div className={styles.contacts}>
          <ExternalLink
            title="+375 (29) 520-77-77"
            to="tel:+375(29)520-77-77"
            className={styles.mts}
          >
            <MtsIcon />
          </ExternalLink>
          <ExternalLink
            title="+375 (44) 520-77-77"
            to="tel:+375(44)520-77-77"
            className={styles.a1}
          >
            <A1Icon />
          </ExternalLink>
        </div>
        <a
          className={styles.mailLink}
          href="mailto:support@maxline.by"
        >
          support@maxline.by
        </a>
      </div>
    </main>
  )
}

Forbidden.propTypes = {
  type: PropTypes.oneOf(['noConnection', 'serviceWork', 'unknown']),
}

Forbidden.defaultProps = {
  type: 'unknown',
}
