import CashOut from 'components/CashOut'
import {
  BetStatus,
  getBetStyles,
  getBoosterValue,
  toBYN,
} from 'pages/UserHistoryPage/utils'
import {
  cn,
  round,
} from 'utils'
import { MaxlinePaysReturns } from './MaxlinePaysReturns'
import styles from './styles.module.scss'

export const BetItemFooter = ({
  bet: { bet_id, payReturn, value, info, status_vip, count_bets, bonus_money, amount, win, status } = {},
  onRefresh,
}) => {
  const { color } = getBetStyles(status)

  const isCashOut    = info?.isCashOut
  const isFreeBet    = info?.freebet
  const vipStatus    = status_vip
  const isOpen       = status === BetStatus.NOT_CALC
  const isMultiple   = count_bets > 1
  const hasBonus     = +bonus_money > 0
  const resultTitle  = (isCashOut && 'CashOut:') || (isOpen && 'Возм. выигрыш:') || 'Выигрыш:'
  const bonus        = toBYN(bonus_money)
  const betAmount    = toBYN(amount)
  const resultAmount = toBYN(isOpen ? (+value * +amount) : win)
  const boosterValue = getBoosterValue(info, status)

  return (
    <div className={styles.betFooter}>
      <div className={styles.main}>
        <div className={styles.left}>
          <MaxlinePaysReturns
            betId={bet_id}
            payReturn={payReturn}
            onSuccess={onRefresh}
          />
          <CashOut
            betId={bet_id}
            onSuccess={onRefresh}
          />
        </div>
        <div className={styles.right}>
          <div className={styles.titles}>
            <div className={cn(isFreeBet && styles.freeBet)}>
              {isFreeBet ? 'Фрибет' : 'Ставка:'}
            </div>
            {isMultiple && <div>{isOpen ? 'Коэфф.:' : 'Общий коэфф.:'}</div>}
            <div>{resultTitle}</div>
            {hasBonus && <div>Использованы бонусные средства:</div>}
            {boosterValue && <div>{`${isOpen ? 'Возм. бонус' : 'Бонус'}:`}</div>}
          </div>
          <div>
            <div className={cn(!isOpen && styles.accent)}>{betAmount}</div>
            {isMultiple && <div>{round(value)}</div>}
            <div
              className={styles.accent}
              data-color={color}
            >
              {resultAmount}
            </div>
            {hasBonus && <div>{bonus}</div>}
            {boosterValue && <div className="green bold">{boosterValue}</div>}
          </div>
        </div>
      </div>
      {vipStatus && <div className={cn(styles.vipStatus, color)}>{vipStatus}</div>}
    </div>
  )
}
