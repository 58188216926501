import { CurrencyInput } from 'components/CurrencyInput'
import { MaskedInput } from 'components/MaskedInput'
import { Option } from 'components/Option'
import { SubmitBtn } from 'components/SubmitBtn'
import SwipeModal from 'components/SwipeModal'
import { SwipeModalTrigger } from 'components/SwipeModalTrigger'
import React, { Component } from 'react'

const getPpsTitle = ({ city, address }) => (
  <div>
    <div className="text font-14 lh-18">
      {city}
    </div>
    <div className="gray font-12 lh-16">
      {address}
    </div>
  </div>
)

const phoneMaskRegex = /^(\+375)|\D/gi

class BaseForm extends Component {
  state = {
    isCardModalOpen: false,
    isPpsModalOpen:  false,
    activeCard:      '',
  }

  PAY_FACTOR = 0.96
  TAX_FACTOR = 0.04

  getError() {
    const { amount }               = this.state
    const { minAmount, maxAmount } = this.props

    const isMore = amount > maxAmount

    if (isMore || amount < minAmount) {
      return ` ${isMore ? 'максимальная' : 'минимальная'} сумма заказа ${isMore ? maxAmount : minAmount} BYN`
    }
  }

  setAmount = (amount = 0) => this.setState({
    amount,
    toPay: amount * this.PAY_FACTOR,
    tax:   amount * this.TAX_FACTOR,
  })

  onBlurAmount = () => {
    const { minAmount, maxAmount } = this.props
    const { amount }               = this.state

    if (amount < minAmount) {
      this.setAmount(minAmount)
    }

    if (amount > maxAmount) {
      this.setAmount(maxAmount)
    }
  }

  inputChange = ({ target: { name, value } }, transform) => {
    this.setState({ [name]: transform ? transform(value) : value })
  }

  onPhoneChange = ({ target: { value: phone } }) => this.setState({ phone })

  isPhoneEntered = () => this.state.phone.replace(phoneMaskRegex, '').length >= 9

  getAmountRow() {
    const { amount } = this.state

    const error = this.getError()

    return (
      <>
        <CurrencyInput
          invalid={!!error}
          onBlur={this.onBlurAmount}
          onChange={this.setAmount}
          value={amount}
          placeholder="Сумма"
        />
        <div className="amount-row__error">
          {error}
        </div>
      </>
    )
  }

  getTaxRow(disabled) {
    const isVisible = !this.getError() && !disabled

    if (!isVisible) {
      return null
    }

    const { tax, amount } = this.state

    return (
      <div className="payment-info">
        <div
          data-title="Налог:"
          data-value={`${+tax.toFixed(2)} BYN`}
        />
        <div
          data-title="Зачисление:"
          data-value={`${(+amount - +tax).toFixed(2)} BYN`}
          data-profit={true}
        />
      </div>
    )
  }

  getPhoneRow(id, toPhone = false) {
    const { phone } = this.state

    return (
      <MaskedInput
        id={id}
        name="phone"
        label="Номер телефона:"
        mask="+375(99)999-99-99"
        onChange={this.onPhoneChange}
        value={toPhone ? `+${phone}` : phone}
        disabled={toPhone}
        placeholder="+375"
      />
    )
  }

  getPpsRow() {
    const { isPpsModalOpen, selectedCash, cashes } = this.state

    const currentCash = cashes.find(cash => cash.id === selectedCash)

    return (
      <>
        <SwipeModalTrigger
          onOpen={() => this.setState({ isPpsModalOpen: true })}
          value={currentCash && `${currentCash.city}, ${currentCash.address}`}
          placeholder="Адрес ППС"
        />
        <SwipeModal
          isOpen={isPpsModalOpen}
          title="Выберите ППС"
          onClose={() => this.setState({ isPpsModalOpen: false })}
        >
          {cashes.map(cash => (
            <Option
              key={cash.id}
              title={cash.id && getPpsTitle(cash)}
              action={() => this.setState({ isPpsModalOpen: false }, () => this.changeCash(cash.id))}
              checked={selectedCash === cash.id}
            />
          ))}
        </SwipeModal>
      </>
    )
  }

  getOrderButton(disabled) {
    return (
      <SubmitBtn
        className="ml-button mt-14"
        onClick={this.onSubmit.bind(this)}
        disabled={disabled || !!this.getError()}
      >
        Получить выигрыш
      </SubmitBtn>
    )
  }

  onSubmit() {
  }
}

export default BaseForm
