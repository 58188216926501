import ACTIONS from "store/constants/actions";

const initialState = {
  leagues    : [],
  events     : [],
  sports     : [],
  fetching   : true
};

export default function result(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.RESULT.GET_LIVE:
      return {...state, ...action.data, fetching: false};

    case ACTIONS.RESULT.FETCHING:
      return {...state, fetching: true};
    default:
      return state;
  }
}
