export const BonusType = {
  REJECTED: 0,
  CASINO:   1,
  SPORT:    2,
}

export const getBonuses = (isCasinoBlocked, isSportBlocked) => [
  { type: BonusType.CASINO, title: '+22%', subtitle: 'В КАЗИНО', blocked: isCasinoBlocked },
  { type: BonusType.SPORT, title: '+22%', subtitle: 'НА СПОРТ', blocked: isSportBlocked },
]
