import {
  useCallback,
  useState,
} from 'react'

export const useTransition = (timeout = 200) => {
  const [isPending, setIsPending] = useState(false)

  const start = useCallback(callback => {
    setIsPending(true)

    setTimeout(() => {
      setIsPending(false)
      callback()
    }, timeout)
  }, [])

  return [isPending, start]
}
