export const getDeclension = (words) => (count) => {
  const num1 = Math.abs(count) % 100

  if (num1 > 10 && num1 < 20) {
    return words[2]
  }

  const num2 = num1 % 10

  return (num2 > 1 && num2 < 5 && words[1]) || (num2 === 1 && words[0]) || words[2]
}

export const getDayDeclension     = getDeclension(['день', 'дня', 'дней'])
export const getHoursDeclension   = getDeclension(['час', 'часа', 'часов'])
export const getMinutesDeclension = getDeclension(['минута', 'минуты', 'минут'])
