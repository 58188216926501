import { HighlightPlayer } from 'components/Highlights/Player'
import SportIcon from 'components/SportIcon'
import { useModal } from 'hooks/useModal'
import {
  getBetDetails,
  getBetStyles,
} from 'pages/UserHistoryPage/utils'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import { formatTime } from 'utils/time'
import styles from './styles.module.scss'

const HIGHLIGHTS_MODAL_ID = 'highlights-modal'
const PAY_RETURN_REGEXP   = /(платит|вернет)/

export const Details = ({ count_bets, betType, details, expanded }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { openModal } = useModal(HIGHLIGHTS_MODAL_ID)

  const onOpenHighlights = (event, src) => {
    event.stopPropagation()
    openModal(<HighlightPlayer src={src} />)
  }

  const isSingle = count_bets === 1
  const isLive   = +betType === 1

  const onOpenChange = event => {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  return (
    <>
      {details?.map((detail, idx) => {
        const isVisible = expanded || isOpen || idx < 3

        const { status, bet_name: outcome, result, value: coefficient, event, league, score, event_time } = detail

        if (!isVisible || !event || !league) {
          return null
        }

        const { teams, link, highlights } = getBetDetails(event, detail)
        const { color }                   = getBetStyles(status)

        const minutes     = Math.floor(+detail.timer / 60)
        const isPayReturn = PAY_RETURN_REGEXP.test(result?.toLowerCase())

        const hasScore = score || result
        const Tag      = link ? Link : 'div'

        return (
          <div
            key={`${detail.league_id}${detail.event_id}`}
            className={styles.detail}
          >
            <div className={styles.league}>
              <div className={styles.left}>
                <SportIcon
                  id={event.sport_id}
                  size={14}
                  className={styles.sportIcon}
                />
                <span>{league.name}</span>
              </div>
              {!!minutes && <div className="ml-5">{`${minutes}’`}</div>}
            </div>
            <div className={styles.startTime}>{formatTime(event_time)}</div>
            <div className={styles.teamsWrapper}>
              {isLive && <span className={styles.liveLabel}>Live.</span>}
              <Tag
                className={styles.teams}
                {...(link && { to: link })}
              >
                {teams}
              </Tag>
            </div>
            <div className={cn('bet-detail__outcome', !isSingle && color && `dot-${color}`)}>
              <div>{outcome}</div>
              <div className="detail__outcome-coeff">{coefficient}</div>
            </div>
            {hasScore && (
              <div className="bet-detail__score">
                {result && <span className="mr-5">Результат:</span>}
                <span className={cn(isPayReturn && 'green bold')}>{result || score}</span>
              </div>
            )}
            {highlights && (
              <button
                className={styles.highlightsBtn}
                onClick={e => onOpenHighlights(e, highlights)}
              >
                Обзор матча
              </button>
            )}
          </div>
        )
      })}
      {details?.length > 3 && !expanded && (
        <div
          className="bet-detail__more-btn"
          onClick={onOpenChange}
        >
          {isOpen ? 'Скрыть' : 'Показать все'}
        </div>
      )}
    </>
  )
}
