import ACTIONS from 'store/constants/actions'

const initialState = {
  sports:     [],
  events:     [],
  factors:    {},
  results:    {},
  clubs:      [],
  leagues:    [],
  isFetching: false,
}

export const liveVideo = (state = initialState, { type, payload }) => {
  switch (type) {

    case ACTIONS.LIVE.VIDEO.GET:
      return { ...state, ...payload }

    case ACTIONS.LIVE.VIDEO.CLEAR:
      return initialState

    case ACTIONS.LIVE.VIDEO.SET_FETCHING:
      return { ...state, isFetching: payload }

    default:
      return state

  }
}
