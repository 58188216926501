import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ACTIONS from 'store/constants/actions'

const initialState = {
  id:        0,
  isAuth:    false,
  isOnline:  true,
  money:     0,
  inGame:    0,
  bonus:     0,
  freebet:   0,
  freespin:  0,
  ttl:       0,
  is_casino: 0,
  hash:      true,
  wager:     {
    wager:         0,
    current_value: 0,
    value:         0,
    bet_amount:    0,
    status:        0,
  },
  step:      0,
}

const persistConfig = {
  key: 'auth',
  storage,
}

export const authReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.AUTH.UPDATE: {
      const balance = action.payload.balance
      return { ...state, ...action.payload, ...(balance && { money: balance }) }
    }

    case ACTIONS.AUTH.IS_ONLINE: {
      return { ...state, isOnline: action.payload }
    }

    case ACTIONS.AUTH.LOGOUT: {
      return initialState
    }

    default:
      return state
  }
})
