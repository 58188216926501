import ACTIONS from 'store/constants/actions'

export const setSliders            = payload => ({ type: ACTIONS.CASINO.SET_SLIDES, payload })
export const setProviders          = payload => ({ type: ACTIONS.CASINO.SET_PROVIDERS, payload })
export const setCategories         = payload => ({ type: ACTIONS.CASINO.SET_CATEGORIES, payload })
export const setPopulars           = payload => ({ type: ACTIONS.CASINO.SET_POPULARS, payload })
export const setThemes             = payload => ({ type: ACTIONS.CASINO.SET_THEMES, payload })
export const setGames              = payload => ({ type: ACTIONS.CASINO.SET_GAMES, payload })
export const setRecentGames        = payload => ({ type: ACTIONS.CASINO.SET_RECENT_GAMES, payload })
export const setGamesList          = payload => ({ type: ACTIONS.CASINO.SET_GAMES_LIST, payload })
export const setIsFavoritePage     = payload => ({ type: ACTIONS.CASINO.SET_IS_FAVORITE_PAGE, payload })
export const setFavorites          = payload => ({ type: ACTIONS.CASINO.SET_FAVORITES, payload })
export const setCounters           = payload => ({ type: ACTIONS.CASINO.SET_COUNTERS, payload })
export const changeSearch          = payload => ({ type: ACTIONS.CASINO.CHANGE_GAMES_SEARCH, payload })
export const setPageId             = payload => ({ type: ACTIONS.CASINO.SET_PAGE_ID, payload })
export const setLoading            = payload => ({ type: ACTIONS.CASINO.CHANGE_LOADING, payload })
export const setActiveID           = payload => ({ type: ACTIONS.CASINO.CHANGE_ACTIVE_IDS, payload })
export const resetCasinoData       = () => ({ type: ACTIONS.CASINO.CLEAR_DATA })
export const resetCasinoState      = () => ({ type: ACTIONS.CASINO.CLEAR_STATE })
export const setMaxlinePay         = payload => ({ type: ACTIONS.CASINO.SET_MAXLINE_PAY, payload })
export const setFreeSpinAppGameIDs = payload => ({ type: ACTIONS.CASINO.APP_ONLY_GAMES_IDS, payload })
