import { FilterItem } from 'components/Filter'
import { FilterGroup } from 'components/FilterGroup'
import { CasinoHistory } from 'pages/UserHistoryPage/Casino/History'
import { casinoPeriods } from 'pages/UserHistoryPage/Casino/History/utils'
import {
  INITIAL_FILTER,
  INITIAL_PERIOD,
} from 'pages/UserHistoryPage/utils'
import { useState } from 'react'

export const CasinoHistoryPage = () => {
  const [period, setPeriod] = useState(INITIAL_PERIOD)
  const [filter, setFilter] = useState(INITIAL_FILTER)

  return (
    <>
      <FilterGroup>
        <FilterItem onClick={() => setFilter('period')}>
          {casinoPeriods[period]}
        </FilterItem>
      </FilterGroup>
      <CasinoHistory
        period={period}
        filter={filter}
        setPeriod={setPeriod}
        setFilter={setFilter}
      />
    </>
  )
}