import {
  useEffect,
  useState,
} from 'react'

export const useIsLandscape = () => {
  const [isLandscape, setIsLandscape] = useState(window.matchMedia('(orientation: landscape)').matches)

  useEffect(() => {
    const onChange = ({ matches }) => setIsLandscape(matches)

    const mql = window.matchMedia('(orientation: landscape)')

    setIsLandscape(mql.matches)

    mql.addEventListener?.('change', onChange)

    return () => {
      mql.removeEventListener?.('change', onChange)
    }
  }, [])

  return isLandscape
}
