import { Api } from 'api/Api'
import styles from 'components/SignUp/PromoCodeInput/styles.module.scss'
import { UNKNOWN_ERROR } from 'constants/errors'
import { STATUSES } from 'constants/statuses'
import { useIsFetching } from 'hooks/useIsFetching'
import { useQuery } from 'hooks/useQuery'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { cn } from 'utils'

const PROMO_CODE_QUERY_KEY = 'pc'

export const PromoCodeInput = ({ onChange, className, activate, label = 'Есть промокод?' }) => {
  const [value, setValue]         = useState('')
  const [isApplied, setIsApplied] = useState(false)
  const [error, setError]         = useState('')

  const [fetch, isFetching] = useIsFetching()

  const codeFromQuery = useQuery(PROMO_CODE_QUERY_KEY)

  useEffect(() => {
    if (codeFromQuery) {
      setValue(codeFromQuery.toUpperCase())
    }
  }, [codeFromQuery])

  const onSubmit = useCallback(async promocode => {
    try {
      const action = activate ? Api.activatePromoCode : Api.validatePromoCode

      const { messages, status } = await fetch(action, promocode)

      if (status === STATUSES.SUCCESS) {
        setIsApplied(true)

        return onChange(promocode)
      }

      setError(messages.toString() || UNKNOWN_ERROR)
    } catch (e) {
      setError(UNKNOWN_ERROR)
    }
  }, [])

  const onPromoCodeChange = useCallback(({ target: { value } }) => {
    if (error) {
      setError('')
    }

    setValue(value.toUpperCase())
  }, [error])

  const onReset = () => {
    setValue('')
    setIsApplied(false)
  }

  const isSubmitDisabled = !value.length || isFetching || !!error || isApplied
  const isResetAvailable = isApplied && activate

  return (
    <div className={cn(styles.promoCode, className)}>
      <label
        className={styles.promoCodeLabel}
        htmlFor="promo-conde-input"
      >
        {label}
      </label>
      <div className={cn(styles.promoCodeForm, error && styles.invalid, isApplied && styles.activated)}>
        <input
          id="promo-conde-input"
          type="text"
          value={value}
          onChange={onPromoCodeChange}
          className={styles.promoCodeInput}
        />
        <button
          className={styles.promoCodeBtn}
          onClick={() => onSubmit(value)}
          disabled={isSubmitDisabled}
        >
          {(isFetching && <div className={styles.spinner} />) || (isApplied && 'Активирован') || 'Применить'}
        </button>
      </div>
      {isResetAvailable && (
        <button
          className={styles.resetBtn}
          onClick={onReset}
        >
          У меня есть ещё промокод
        </button>
      )}
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  )
}
