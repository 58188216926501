import { useScoreUpdating } from 'hooks/useScoreUpdating'
import { cn } from 'utils'
import styles from './styles.module.scss'

const Value = ({ children }) => {
  const isUpdating = useScoreUpdating(children)

  return (
    <div className={cn(styles.value, isUpdating && styles.updating)}>
      {children}
    </div>
  )
}

export const ScoreCol = ({ values, className, label }) => (
  values ?
    <div className={styles.scoreCol}>
      <div className={styles.label}>{label}</div>
      <div className={className}>
        <Value>{values['1']}</Value>
        <Value>{values['2']}</Value>
      </div>
    </div> : null
)
