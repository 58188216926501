import { ReactComponent as ClearIcon } from 'assets/images/svg/clear.svg'
import { ReactComponent as SearchIcon } from 'assets/images/svg/search-black.svg'
import styles from 'components/Highlights/Search/styles.module.scss'
import { debounce } from 'lodash'
import {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

export const Search = ({ value, onChange }) => {
  const [tempValue, setTempValue] = useState('')

  const ref = useRef()

  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [])

  useEffect(() => {
    setTempValue(value)
  }, [value])

  const onValueChange = (value, debounced = true) => {
    setTempValue(value);
    (debounced ? debouncedOnChange : onChange)(value)
  }

  const onClear = () => {
    onValueChange('', false)
    ref.current.focus()
  }

  return (
    <div className={styles.searchInput}>
      <input
        ref={ref}
        type="text"
        placeholder="Поиск"
        value={tempValue}
        onChange={event => onValueChange(event.target.value)}
        className={styles.input}
      />
      <SearchIcon className={styles.searchIcon} />
      <ClearIcon
        className={styles.clearIcon}
        onClick={onClear}
      />
    </div>
  )
}