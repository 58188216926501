import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'
import { getRandomBonusStatus } from 'store/data/utils'

const KEYS = {
  theme:                'theme',
  isShowBalance:        'isShowBalance',
  isShowCashOut:        'isShowCashOut',
  autoConfirmCashOut:   'autoConfirmCashOut',
  session_lifetime:     'session_lifetime',
  calculatedBetTime:    'showCalculatedBetTime',
  indicateFactorChange: 'indicateFactorChange',
  hasRandomBonus:       'hasRandomBonus',
}

const initialState = {
  data:                 {},
  betOnOur:             {
    live: false,
    line: false,
  },
  theme:                window.theme.get(),
  device:               {
    id:       '',
    platform: '',
  },
  initialized:          false,
  isShowBalance:        true,
  indicateFactorChange: false,
  isShowCashOut:        true,
  hasRandomBonus:       false,
  wagerBonus:           {},
  wagerConditions:      {},
}

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SYSTEM.CHANGE_THEME: {
      window.theme.update(action.payload)
      return { ...state, theme: action.payload }
    }

    case ACTIONS.SYSTEM.CHANGE_BALANCE: {
      return { ...state, isShowBalance: !!+action.data.isShowBalance }
    }

    case ACTIONS.SYSTEM.CHANGE_DISPLAY_STYLE_KOEFF: {
      return { ...state, indicateFactorChange: !!+action.payload.indicateFactorChange }
    }

    case ACTIONS.SYSTEM.CHANGE_CASH_OUT: {
      const { data: { isShowCashOut } } = action

      return { ...state, isShowCashOut }
    }

    case ACTIONS.SYSTEM.CHANGE_AUTO_CONFIRM_CASHOUT: {
      const { data: { autoConfirmCashOut } } = action
      return { ...state, autoConfirmCashOut }
    }

    case ACTIONS.SYSTEM.CHANGE_SESSION_LIFE_TIME: {
      const { data: { session_lifetime } } = action
      return { ...state, session_lifetime }
    }

    case ACTIONS.SYSTEM.CHANGE_CALCULATED_BET_TIME: {
      const { data: { showCalculatedBetTime } } = action
      return { ...state, showCalculatedBetTime }
    }

    case ACTIONS.SYSTEM.GET_CARDS: {
      const { data: { cards = [], hidden = [] } } = action

      return { ...state, activeCards: cards, hiddenCards: hidden }
    }

    case ACTIONS.SYSTEM.HIDE_CARD: {
      const { data: ids }                = action
      const { activeCards, hiddenCards } = state

      const nextHidden = []
      const nextActive = []

      activeCards.forEach(card => (ids.includes(card.id) ? nextHidden : nextActive).push(card))

      return {
        ...state,
        hiddenCards: [...hiddenCards, ...nextHidden],
        activeCards: nextActive,
      }
    }

    case ACTIONS.SYSTEM.SHOW_CARD: {
      const { data: ids }                = action
      const { activeCards, hiddenCards } = state

      const nextHidden = []
      const nextActive = []

      hiddenCards.forEach(card => (ids.includes(card.id) ? nextActive : nextHidden).push(card))

      return {
        ...state,
        hiddenCards: nextHidden,
        activeCards: [...activeCards, ...nextActive],
      }
    }

    case ACTIONS.SYSTEM.GET_OUTCOMES: {
      const {
              payload: {
                         isShowCashOut,
                         indicateFactorChange,
                         isShowBalance,
                         autoConfirmCashOut,
                         session_lifetime,
                         registration_bonus_id,
                       },
            } = action

      return {
        ...state,
        isShowCashOut:        !!+isShowCashOut,
        isShowBalance:        !!+isShowBalance,
        indicateFactorChange: !!+indicateFactorChange,
        autoConfirmCashOut,
        session_lifetime,
        registration_bonus_id,
      }
    }

    case ACTIONS.SYSTEM.RESET_CHANGE_KOEFF: {
      return { ...state, indicateFactorChange: false }
    }

    case ACTIONS.SYSTEM.INITIALIZED: {
      return { ...state, initialized: action.payload }
    }

    case ACTIONS.SYSTEM.SET_RANDOM_BONUS: {
      return { ...state, hasRandomBonus: action.payload }
    }

    case ACTIONS.SYSTEM.SET_WAGER_BONUS: {
      return { ...state, wagerBonus: action.payload || {} }
    }

    case ACTIONS.SYSTEM.SET_WAGER_CONDITIONS: {
      return { ...state, wagerConditions: action.payload }
    }

    case ACTIONS.SYSTEM.SET_BET_ON_OUR: {
      return { ...state, betOnOur: { ...state.betOnOur, ...action.payload } }
    }

    case ACTIONS.SYSTEM.SET_DEVICE: {
      return { ...state, device: action.payload }
    }
  }

  return state
}

export const getOutcomes              = payload => ({ type: ACTIONS.SYSTEM.GET_OUTCOMES, payload })
export const updateCoeffAnimation     = payload => ({ type: ACTIONS.SYSTEM.CHANGE_DISPLAY_STYLE_KOEFF, payload })
export const setRandomBonus           = payload => ({ type: ACTIONS.SYSTEM.SET_RANDOM_BONUS, payload })
export const setWagerBonus            = payload => ({ type: ACTIONS.SYSTEM.SET_WAGER_BONUS, payload })
export const setWagerConditions       = payload => ({ type: ACTIONS.SYSTEM.SET_WAGER_CONDITIONS, payload })
export const setDevice                = payload => ({ type: ACTIONS.SYSTEM.SET_DEVICE, payload })
export const toggleIsShowCashOut      = data => ({ type: ACTIONS.SYSTEM.CHANGE_CASH_OUT, data })
export const toggleAutoConfirmCashOut = data => ({ type: ACTIONS.SYSTEM.CHANGE_AUTO_CONFIRM_CASHOUT, data })
export const toggleSessionLifeTime    = data => ({ type: ACTIONS.SYSTEM.CHANGE_SESSION_LIFE_TIME, data })
export const updateBalanceVisibility  = data => ({ type: ACTIONS.SYSTEM.CHANGE_BALANCE, data })
export const getCards                 = data => ({ type: ACTIONS.SYSTEM.GET_CARDS, data })
export const onHideCard               = data => ({ type: ACTIONS.SYSTEM.HIDE_CARD, data })
export const onShowCard               = data => ({ type: ACTIONS.SYSTEM.SHOW_CARD, data })

export const accountSettings = () => async dispatch => {
  try {
    const { data } = await Api.getSettings()
    dispatch(getOutcomes(data))
  } catch (error) {
    console.error(error)
  }
}

export const hideCard = ids => dispatch => Api.hideCard(ids).then(() => dispatch(onHideCard(ids)))
export const showCard = ids => dispatch => Api.showCard(ids).then(() => dispatch(onShowCard(ids)))

export const getCardsList = () => async dispatch => {
  try {
    const { data } = await Api.getPaymentCards()
    dispatch(getCards(data))
  } catch (e) {
    console.error(e)
  }
}

export const checkRandomBonus = () => async (dispatch, getState) => {
  const { auth: { isAuth } } = getState()

  const status = await getRandomBonusStatus(isAuth)

  dispatch(setRandomBonus(status))
}

export const checkWagerBonus = () => async dispatch => {
  try {
    const { data: { wager = {}, conditions = {} } } = await Api.getWagerBonus()

    dispatch(setWagerBonus(wager))
    dispatch(setWagerConditions(conditions))
  } catch (error) {
    console.error(error)
  }
}

const setSettings = (name, callback, value) => async (dispatch, getState) => {
  const { data: { [name]: prevValue } } = getState()

  const { data } = await Api.setSettings({ [name]: value !== undefined ? value : +prevValue ? 0 : 1 })

  dispatch(callback(data))
}

export const changeBalance         = () => setSettings(KEYS.isShowBalance, updateBalanceVisibility)
export const changeCashOut         = () => setSettings(KEYS.isShowCashOut, toggleIsShowCashOut)
export const changeConfirmCashOut  = value => setSettings(KEYS.autoConfirmCashOut, toggleAutoConfirmCashOut, value)
export const changeSessionLifeTime = value => setSettings(KEYS.session_lifetime, toggleSessionLifeTime, value)
export const setCoeffAnimation     = value => setSettings(KEYS.indicateFactorChange, updateCoeffAnimation, value)
