import defaultFlag from 'assets/images/flags/default-flag.svg'

export const defaultMask        = '+XXX XX XXX - XXX - XX - XX - XX'
export const DEFAULT_MIN_LENGTH = 10
export const DEFAULT_MAX_LENGTH = defaultMask.length

/**
 *
 * @param {string | number} value
 * @param {[{id: number, name: string, flag: string, phonecode: number, masks: string[]}]} countries
 *
 * @returns {{country?: {}, flag: string, isValid: boolean, maxLength: number, mask: (*|string)}}
 */
export const getInputData = (value, countries) => {
  const country = countries.find(({ phonecode }) => {
    const phoneCode   = String(phonecode)
    const valueString = String(value)

    return phoneCode.length >= valueString.length ? phoneCode.startsWith(valueString) : valueString.startsWith(phoneCode)
  })

  if (!country) {
    return {
      mask:      defaultMask,
      isValid:   value.length >= DEFAULT_MIN_LENGTH,
      flag:      defaultFlag,
      maxLength: DEFAULT_MAX_LENGTH,
    }
  }

  const { masks, maxLength } = country
  const rawMask              = masks?.find(mask => value.length <= mask.replace(/\+|\(|\)|-|\s/g, '').length)
  const mask                 = rawMask?.replace(/\d/g, 'X') || defaultMask
  const validLength          = rawMask ? rawMask.replace(/\+|\(|\)|-|\s/g, '').length : DEFAULT_MIN_LENGTH

  return {
    country,
    mask,
    maxLength,
    flag:    country?.flag || defaultFlag,
    isValid: value.length >= validLength,
  }
}

export const handleCountries = rawCountries => rawCountries.map(({ masks, ...other }) => {
  const nextMasks = masks?.sort((left, right) => left.length - right.length) ?? []

  return {
    ...other,
    masks:     nextMasks,
    maxLength: nextMasks?.[nextMasks?.length - 1]?.length ?? DEFAULT_MAX_LENGTH,
  }
})
