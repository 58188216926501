import styles from 'components/Highlights/LeaguePicker/styles.module.scss'
import { cn } from 'utils'

const Option = ({ selected, name, onChange, countryCode, withIcon = true }) => (
  <button
    className={cn(styles.option, selected && styles.selected)}
    onClick={onChange}
  >
    {withIcon && (
      <img
        className={styles.icon}
        src={`https://cdn-01.maxline.by/images/flags/${countryCode || 'def'}.png`}
        alt={name}
      />
    )}
    <div className={styles.name}>{name}</div>
  </button>
)

export const LeaguePicker = ({ options, value, onChange }) => (
  <>
    <Option
      name="Все чемпионаты"
      selected={!value}
      onChange={() => onChange(null)}
      withIcon={false}
    />
    {options.map(league => (
      <Option
        key={league.id}
        selected={league.id === value?.id}
        onChange={() => onChange(league)}
        {...league}
      />
    ))}
  </>
)
