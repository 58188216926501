export const copyToClipboard = async text => {
  if (!navigator.userAgent.match(/ipad|iphone/i)) {
    return await navigator.clipboard?.writeText(text)
  }

  const textArea = document.createElement('textArea')
  textArea.value = text

  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}
