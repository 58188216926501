import { CardItem } from 'components/CardItem'
import { Option } from 'components/Option'
import SwipeModal from 'components/SwipeModal'
import { SwipeModalTrigger } from 'components/SwipeModalTrigger'
import { useState } from 'react'

export const CardsDropdown = ({ list, hasHidden, onChange, selected }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onCardChange = card => {
    onChange(card)
    setIsOpen(false)
  }

  return (
    <>
      <SwipeModalTrigger
        onOpen={() => setIsOpen(true)}
        value={selected ? `${selected.number} ${selected.exp}` : ''}
        placeholder="Карта для выплаты"
      />
      <SwipeModal
        isOpen={isOpen}
        title="Привязанные карты"
        onClose={() => setIsOpen(false)}
      >
        {list.map(card => (
          <Option
            key={card.id}
            className="py-8"
            title={<CardItem card={card} />}
            action={() => onCardChange(card)}
            checked={selected?.id === card.id}
          />
        ))}
        {!list.length &&
          <div className="fastpay__empty-card-list">
            {hasHidden ? 'Все Ваши карты скрыты в настройках аккаунта' : 'У Вас пока нет привязанных карт'}
          </div>
        }
      </SwipeModal>
    </>
  )
}
