import ACTIONS from 'store/constants/actions'

const initialState = {
  list: [],
}

export default function (state = initialState, action) {
  const { payload, type } = action

  switch (type) {
    case ACTIONS.MODAL.ADD:
      return { ...state, list: [...state.list, payload] }

    case ACTIONS.MODAL.CLOSE:
      const list = payload ? state.list.filter(modal => modal.id !== payload) : []

      return { ...state, list }

    default:
      break
  }

  return state
}
