import gridIcon from 'assets/images/league-grid.svg'
import statisticIcon from 'assets/images/statistic.svg'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

export const LeagueInfo = ({ description }) => {
  const { stat_link, grid } = JSON.parse(description || '{}')

  if (!stat_link && !grid) {
    return null
  }

  return (
    <div className={styles.leagueInfo}>
      {!!grid && (
        <Link
          className={styles.grid}
          to={`/league-grid/${grid}`}
        >
          <img
            src={gridIcon}
            alt="grid"
          />
          Турнирная сетка
        </Link>
      )}
      {!!stat_link && (
        <Link
          to={`/statistics/league/${stat_link}`}
          className={styles.grid}
        >
          <img
            src={statisticIcon}
            alt="statistics"
          />
          Статистика на турнир
        </Link>
      )}
    </div>
  )
}
