import SportIcon from 'components/SportIcon'
import styles from 'pages/MaxinePayReturn/MatchList/MatchItem/styles.module.scss'
import {
  getFullDate,
  getUTCDate,
} from 'utils/time'

export const MatchItem = ({ leagueName, team1, team2, outcome, date, type, sportId }) => (
  <div className={styles.matchItem}>
    <SportIcon
      id={sportId}
      size={18}
    />
    <div className={styles.main}>
      <div
        className={styles.header}
        data-live={!!+type}
      >
        <div className={styles.startedAt}>
          {getFullDate(getUTCDate(date))}
        </div>
        <div className={styles.leagueName}>
          {leagueName}
        </div>
      </div>
      <div className="font-14 lh-16 bold mt-4">
        {`${team1}${team2 ? ` - ${team2}` : ''}`}
      </div>
      <div className="font-12 lh-12 mt-4">
        {outcome}
      </div>
    </div>
  </div>
)
