import emailLogo from 'assets/images/email.svg'
import TextInput from 'components/Field/TextInput'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import { useAuthModal } from 'hooks/useAuthModal'
import {
  ErrorAlert,
  SuccessAlert,
} from 'pages/Contacts/SendMail/utils'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import TEXT from 'store/constants/text'
import { onPersonalData } from 'store/personal-data/actions'

const EMAIL_REGEX = /^\S+@\S+\.\S+$/

export const SendMail = ({ isAuth, mailTo, action, user, onPersonalData }) => {
  const [isOpen, setIsOpen]       = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [message, setMessage]     = useState('')
  const [email, setEmail]         = useState('')

  const openAuthModal = useAuthModal()

  const userEmail = user?.email?.match(EMAIL_REGEX)?.[0]

  useEffect(() => {
    onPersonalData()
  }, [isAuth])

  useEffect(() => {
    userEmail && setEmail(userEmail)
  }, [userEmail])

  const onSend = useCallback(async () => {
    setIsSending(true)

    const { code } = await action({ message, email })

    const successful = code === 200

    setIsSending(false)

    if (successful) {
      setMessage('')
      setEmail(userEmail)
      setIsOpen(false)
    }

    successful ? showSuccessModal(SuccessAlert) : showErrorModal(ErrorAlert)
  }, [message, email])

  const canSendMail = !isSending && !!message.length && message.length <= 485 && email.match(EMAIL_REGEX)

  const toggle = () => {
    if (action) {
      return setIsOpen(!isOpen)
    }
  }

  const onChangeMessage = useCallback(value => {
    if (value.length <= 485) {
      setMessage(value)
    }
  }, [])

  return (
    <div className="send-mail-wrapper">
      <div
        role="button"
        onClick={toggle}
        className={`send-mail ${isOpen && 'open'} ${!action && 'static'}`}
      >
        <img
          className="send-mail__icon"
          alt="e-mail"
          src={emailLogo}
        />
        <div className="send-mail__description">
          <div className="bold lh-16 font-14">{TEXT.contacts.email}</div>
          <div className="font-12 lh-14">
            {mailTo}
          </div>
        </div>
      </div>
      {isOpen &&
        <div className="send-mail__container">
          {isAuth &&
            <>
              <TextInput
                label="Текст сообщения:"
                onChange={onChangeMessage}
                value={message}
              />
              <TextInput
                label="Email для ответа:"
                onChange={setEmail}
                value={email}
              />
              <button
                className="ml-button mt-14"
                onClick={onSend}
                disabled={!canSendMail}
              >
                Отправить
              </button>
            </> ||
            <span className="font-14 lh-16">
                Для отправки сообщения необходимо&nbsp;
              <button
                className="send-mail__btn"
                onClick={() => openAuthModal()}
              >
                авторизоваться
              </button>
              </span>}
        </div>}
    </div>
  )
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  user:   state.user_account.user,
})

const mapDispatchToProps = {
  onPersonalData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMail)
