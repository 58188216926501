import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const BetLink = ({ id, disabled, onClose, value, live }) => {
  const isLink        = !disabled && id && onClose
  const disabledClass = disabled && 'removed'

  return (
    <div className={cn(styles.betLink, live && styles.live)}>
      {isLink ? (
        <Link
          className={cn(styles.link, disabledClass)}
          to={`/${live ? 'live' : 'line'}-event/${id}`}
          onClick={onClose}
        >
          {value}
        </Link>
      ) : <div className={cn(disabledClass)}>{value}</div>}
    </div>
  )
}
