class Emitter {
  entity          = null
  eventCollection = new Map()

  constructor(task) {
    this.task = task
  }

  setEntity(entity) {
    this.entity = entity
  }

  addListener(eventType, onSuccess, onError) {
    this.eventCollection.set(eventType, { eventType, onSuccess, onError })
  }

  emit(eventType, data, isError) {
    this.eventCollection.get(eventType)?.[isError ? 'onError' : 'onSuccess']?.(data)
  }

  push(action, data = {}) {
    this.waitForConnection(() => this.entity.send(JSON.stringify({ data: { task: this.task, action, data } })))
  }

  waitForConnection(callback) {
    if (this.entity?.readyState === WebSocket.OPEN) {
      callback()
    } else {
      setTimeout(() => this.waitForConnection(callback), 100)
    }
  }
}

export default new Emitter('Casino')
