import getPayInWaysData from 'pages/Payments/components/PayInWays/data'
import { PaymentItem } from 'pages/Payments/components/PaymentItem'

export const PayInWays = ({ theme, full = true }) => (
  getPayInWaysData(theme, full).map(({ iconSrc, title, content }) => (
    <PaymentItem
      key={iconSrc}
      title={title}
      icon={iconSrc}
    >
      {content}
    </PaymentItem>
  ))
)
