import { TournamentsActionTypes } from 'store/tournaments/types'

const initialState = {
  tabs: [],
  list: [],
}

export const tournamentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TournamentsActionTypes.SET: {
      return { ...state, ...action.payload }
    }

    default: {
      return state
    }
  }
}
