import ACTIONS from 'store/constants/actions'

const initialState = {
  count:   0,
  sports:  [],
  events:  [],
  factors: [],
  leagues: [],
  clubs:   [],
}

export default function margin0(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.MARGIN_0.SET:
      return { ...state, ...payload }

    default:
      return state
  }
}
