import {
  buildGameURI,
  onError,
} from 'components/Casino/Slots/Game/utils'
import { CasinoSpinner } from 'components/Casino/Spinner'
import { Placeholder } from 'components/Placeholder'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

const ProviderID = {
  1: 'BetGames',
  2: 'TVBet',
}

export const TVGamesList = ({ list, loading }) => (
  <div className={styles.tvGamesList}>
    {(loading && <CasinoSpinner height={350} />) ||
      (!list.length && <Placeholder name="gamesSearch" />) || (
        <div className={styles.games}>
          {list.map(({ id, name, image, remote_id, provider_id }) => (
            <Link
              key={id}
              to={buildGameURI(ProviderID[provider_id].toLowerCase(), name, remote_id)}
              className={styles.game}
            >
              <div className={styles.imgWrapper}>
                <img
                  className={styles.img}
                  src={image || '#'}
                  alt={name}
                  onError={onError}
                />
              </div>
              {/*<div className={styles.footer}>*/}
              {/*  <div className={styles.name}>{name}</div>*/}
              {/*  <div className={styles.provider}>{ProviderID[provider_id]}</div>*/}
              {/*</div>*/}
            </Link>
          ))}
        </div>
      )}
  </div>
)
