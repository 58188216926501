import { EventItem } from 'pages/Results/live/event'
import { LeagueListItem } from 'pages/Results/live/leagues-list/leagueItem'

export const LeaguesList = ({ leagues, events = [] }) => (
  <div className="p-8">
    {leagues.map((league, idx) => (
      <LeagueListItem
        key={league.id}
        opened={idx < 5}
        live
        {...league}
      >
        {events
        .filter(event => league.id === event.leagueId)
        .map(event => (
          <EventItem
            key={event.id}
            event={event}
          />
        ))}
      </LeagueListItem>
    ))}
  </div>
)
