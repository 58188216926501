import { ExternalLink } from 'components/ExternalLink'
import { HideBtn } from 'components/SignUpModals/HideBtn'
import { SignUpModalWrapper } from 'components/SignUpModals/Wrapper'
import { APP_URL } from 'constants/links'
import { useActiveBonus } from 'hooks/useActiveBonus'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const GetBonus = ({ onClose, onDoNotShow }) => {
  const { id, name } = useActiveBonus()

  return id ? (
    <SignUpModalWrapper
      onClose={onClose}
      closeBtn={false}
      fullscreen={false}
    >
      <div className={styles.header}><b>{`Бонус ${name}`}</b> зачислен в приложении</div>
      <div className={styles.main}>
        <img
          src={`https://cdn-01.maxline.by/images/bonuses/mobile/app/${id}.png`}
          className={styles.img}
          alt={name}
        />
        <div>
          <div className={styles.step}>Скачай приложение</div>
          <div className={styles.step}>Авторизируйся</div>
          <div className={styles.step}>Играй!</div>
        </div>
      </div>
      <ExternalLink
        to={APP_URL}
        className={cn('ml-button', styles.installAppBtn)}
        onClick={onClose}
      >
        Установить приложение
      </ExternalLink>
      <HideBtn onClick={() => onDoNotShow(600)} />
    </SignUpModalWrapper>
  ) : null
}
