import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'

export const showSuccessNewsModal = title => showSuccessModal({
  title,
  submitBtn: {
    title: 'ОК',
  },
})

export const showFailureNewsModal = message => showErrorModal({
  title:     'Ошибка',
  message:   message?.toString() || 'Попробуйте повторить запрос позже',
  submitBtn: {
    title: 'Назад',
  },
})

export const getHasRules = (isRules, rules) => !!+isRules && !!rules && !!rules?.length && rules !== 'null'
