import styles from 'components/SignUpModals/SignUpForm/Bonuses/styles.module.scss'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export const SignUpBonuses = ({ list, onChange, selected }) => {
  useEffect(() => {
    if (!selected.id) {
      onChange(list[0])
    }
  }, [])

  return (
    <div className={styles.signUpBonuses}>
      {list.map(bonus => {
        const { id, name, description, icon } = bonus

        return (
          <button
            key={id}
            className={styles.bonusBtn}
            onClick={() => onChange(bonus)}
            data-selected={id === selected?.id}
          >
            <img
              src={icon}
              className={styles.img}
              alt={name}
            />
            <div className={styles.text}>
              <div className={styles.title}>{name}</div>
              <div>{description}</div>
            </div>
            <div className={styles.indicator} />
          </button>
        )
      })}
      <Link
        className={styles.link}
        to={selected?.conditions}
      >
        Условия бонуса
      </Link>
    </div>
  )
}
