import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { ConfigActionTypes } from 'store/config/types'
import {
  DEFAULT_PERIOD,
  PeriodsOrderBy,
} from 'store/config/utils'

const initialState = {
  addons:           { register: [] },
  countries:        [],
  line:             {
    default_period: DEFAULT_PERIOD,
    default_sort:   PeriodsOrderBy.DESC,
  },
  modules:          {
    prize_tournament: {
      count: 0,
    },
  },
  identity:         { '1': {}, '2': {} },
  new_registration: {
    enabled: false,
    bonuses: [],
  },
}

const persistConfig = {
  key: 'config',
  storage,
}

export const configReducer = persistReducer(persistConfig, (state = initialState, action) => {
  switch (action.type) {
    case ConfigActionTypes.SET: {
      return { ...state, ...action.payload }
    }

    default: {
      return state
    }
  }
})
