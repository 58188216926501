import { ReactComponent as InfoIcon } from 'assets/images/svg/info.svg'
import { SubmitBtn } from 'components/SubmitBtn'
import { differenceInSeconds } from 'date-fns'
import { useTimer } from 'hooks/useTimer'
import {
  activatePromoCode,
  getAmountText,
  showPromoCodeInfo,
} from 'pages/PromoCodes/Available/utils'
import styles from './styles.module.scss'

const EndDate = ({ value, onEnd }) => {
  const diffInSeconds = differenceInSeconds(new Date(value), new Date())

  const { fullHours, minutes, seconds } = useTimer(diffInSeconds, { onEnd })

  return (
    <div className={styles.endDate}>
      {`Осталось: ${fullHours}:${minutes}:${seconds}`}
    </div>
  )
}

export const PromoCode = ({ onUpdate, onExpired, ...promoCode }) => {
  const { code, date_end, is_freebet, is_freespin, value } = promoCode

  return (
    <div
      className={styles.promoCode}
      onClick={() => showPromoCodeInfo(promoCode, onUpdate)}
    >
      <div className={styles.left}>
        <div className={styles.code}>{code}</div>
        <div className={styles.value}>
          {getAmountText(value, is_freespin, is_freebet).join(' + ')}
        </div>
        <EndDate
          value={date_end}
          onEnd={() => onExpired(code)}
        />
      </div>
      <SubmitBtn
        className={styles.activateBtn}
        onClick={() => activatePromoCode(promoCode, onUpdate)}
        stopPropagation
      >
        Активировать
      </SubmitBtn>
      <div className={styles.right}>
        <div className={styles.divider}>
          <div className={styles.line} />
        </div>
        <InfoIcon className={styles.infoIcon} />
      </div>
    </div>
  )
}
