import SportIcon from 'components/SportIcon'
import { Link } from 'react-router-dom'
import { cn } from 'utils'

export const SportTab = ({
  id,
  name,
  count,
  active,
  className,
  to = '#',
  onClick,
  championship,
}) => (
  <Link
    to={to}
    onClick={onClick}
    className={cn('live-sports__tab-link', active && 'active', className)}
  >
    {!!count &&
      <div className="live-sports__sport-count">
        {count}
      </div>}
    <SportIcon
      id={id}
      championship={championship}
    />
    <div className="live-sports__sportname">
      {name}
    </div>
  </Link>
)
