import { useLocation } from 'react-router-dom'

export const useDefineLocation = () => {
  const { pathname } = useLocation()

  return {
    isTop:          pathname === '/',
    isLive:         pathname.startsWith('/live'),
    isLine:         pathname.startsWith('/line'),
    isEvent:        !!pathname.match(/^\/(live|line)-event/),
    isAccount:      pathname.startsWith('/account'),
    isChampionship: pathname.startsWith('/line/championship-'),
    isTopExpress:   pathname.startsWith('/top-express'),
  }
}

