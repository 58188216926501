import { debounce } from 'lodash'
import { useEffect } from 'react'
import styles from './styles.module.scss'
import {
  MIN_SCROLL_TOP,
  SCROLL_TO_OPTIONS,
} from './utils'

const html = document.documentElement

const EVENT_TYPE = 'scroll'
const ATTR_NAME  = 'scroll-top-btn'

const onScroll = debounce(() => {
  html.setAttribute(ATTR_NAME, String(html.scrollTop > MIN_SCROLL_TOP))
}, 20)

const onClick = () => window.scrollTo(SCROLL_TO_OPTIONS)

export const ScrollUpBtn = () => {
  useEffect(() => {
    window.addEventListener(EVENT_TYPE, onScroll)

    return () => {
      window.removeEventListener(EVENT_TYPE, onScroll)
    }
  }, [])

  return (
    <button
      id="scroll-up-btn"
      aria-label="scroll-up"
      onClick={onClick}
      className={styles.scrollUpBtn}
    />
  )
}
