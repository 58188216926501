import { cn } from 'utils'
import styles from './styles.module.scss'

export const TVGamesFilter = ({ list, active, onChange }) => (
  <div className={styles.tvGamesFilter}>
    {list.map(({ id, label }) => (
      <button
        key={id}
        className={cn(styles.filterBtn, active === id && styles.active)}
        onClick={() => onChange(id)}
      >
        {label}
      </button>
    ))}
  </div>
)
