import CouponLine from 'components/Coupon/Line'
import CouponLive from 'components/Coupon/Live'
import CouponSettings from 'components/Coupon/Settings'
import { useAuth } from 'hooks/useAuth'
import { useDefineLocation } from 'hooks/useDefineLocation'

export const Coupon = () => {
  const { isTop, isLive, isLine, isTopExpress } = useDefineLocation()

  const isAuth = useAuth()

  const isLiveCoupon = isTop || isLive
  const isLineCoupon = isLine || isTopExpress

  return isAuth && (
    <>
      {((isLiveCoupon && <CouponLive />) || (isLineCoupon && <CouponLine />))}
      <CouponSettings />
    </>
  )
}
