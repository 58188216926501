import React, {
  useEffect,
  useState,
} from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'
import { padTime } from './utils'

export const EventTimer = ({ time, className, direction }) => {
  const [currentTime, setCurrentTime] = useState(0)

  const isRunning = !!time && !!+direction
  const isHidden  = !time && !+direction

  useEffect(() => {
    if (!isHidden) {
      setCurrentTime(time)

      if (isRunning) {
        const interval = setInterval(() => setCurrentTime(prevState => prevState + 1), 1000)

        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [time, direction])

  const min = Math.floor(currentTime / 60)
  const sec = currentTime % 60

  return (
    currentTime ?
      <div className={cn(styles.timer, className)}>
        {`${padTime(min)}:${padTime(sec)}`}
      </div> : null
  )
}
