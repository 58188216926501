import _ from 'lodash'
import ACTIONS from 'store/constants/actions'

const initialState = {
  forecast:            {},
  user_forecast:       {},
  selected:            {},
  is_already_forecast: false,
  winners:             [],
  loading:             false,
}

export const forecastReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FORECAST.GET.SUCCESS: {
      const { forecast, user_forecast } = action.data
      const outcomes                    = user_forecast.outcomes || {}
      return {
        ...state,
        forecast,
        user_forecast,
        selected:            outcomes,
        is_already_forecast: !_.isEmpty(user_forecast),
      }
    }

    case ACTIONS.FORECAST.GET_WINNERS.SUCCESS: {
      return { ...state, winners: action.data }
    }

    case ACTIONS.FORECAST.CHANGE.LOADING: {
      return { ...state, loading: action.payload }
    }

    case ACTIONS.FORECAST.CHANGE.SELECTED: {
      if (state.is_already_forecast) {
        return state
      }

      const { event, outcome } = action.data
      return { ...state, selected: { ...state.selected, [event]: +outcome } }
    }

    case ACTIONS.FORECAST.CHANGE.RANDOM: {
      return { ...state, selected: action.payload }
    }

    case  ACTIONS.HISTORY_FORECAST.GET: {
      return { ...state, history_forecasts: action.history_forecasts }
    }

    case ACTIONS.FORECAST.CLEAR: {
      return initialState
    }

    default:
      return state
  }
}
