import { Events } from 'components/Lottie/utils'
import lottieWeb from 'lottie-web'
import {
  useEffect,
  useRef,
} from 'react'

export const Lottie = ({
  src: animationData,
  loop = true,
  autoplay = true,
  onComplete,
  ...restProps
}) => {
  const ref = useRef(null)

  useEffect(() => {
    const name = Math.random().toString(16).slice(2, 10)

    const animation = lottieWeb.loadAnimation({
      container: ref.current,
      renderer:  'svg',
      loop,
      autoplay,
      animationData,
      name,
    })

    if (onComplete) {
      animation.addEventListener(Events.COMPLETE, onComplete)
    }

    return () => {
      if (onComplete) {
        animation?.removeEventListener(Events.COMPLETE, onComplete)
      }

      lottieWeb.destroy(name)
    }
  }, [animationData])

  return (
    <div ref={ref} {...restProps} />
  )
}
