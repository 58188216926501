import { ROOT } from 'constants/ids'
import { setDefaultOptions } from 'date-fns'
import { ru } from 'date-fns/locale'
import React from 'react'
import { render } from 'react-dom'
import { RouterProvider } from 'react-router-dom'
import { router } from 'router'
import { initSentry } from 'utils/sentry'

initSentry()
setDefaultOptions({ locale: ru })

render(<RouterProvider router={router} />, document.getElementById(ROOT))
