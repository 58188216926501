import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { SafeResizeObserver } from 'utils/resize-observer'

export const patchResizeCallback = (resizeCallback, refreshMode, refreshRate) => {
  switch (refreshMode) {
    case 'debounce':
      return debounce(resizeCallback, refreshRate)
    case 'throttle':
      return throttle(resizeCallback, refreshRate)
    default:
      return resizeCallback
  }
}

export const useResize = ({
  refreshMode,
  refreshRate = 100,
  handleWidth = true,
  handleHeight = true,
  observerOptions,
  onResize,
} = {}) => {
  const [size, setSize] = useState({ width: undefined, height: undefined })

  const [refElement, setRefElement] = useState(null)

  const refProxy = useMemo(
    () =>
      new Proxy(
        node => {
          if (node !== refElement) {
            setRefElement(node)
          }
        },
        {
          get(target, prop) {
            if (prop === 'current') {
              return refElement
            }
            return target[prop]
          },
          set(target, prop, value) {
            if (prop === 'current') {
              setRefElement(value)
            } else {
              target[prop] = value
            }
            return true
          },
        },
      ),
    [refElement],
  )

  const shouldSetSize = useCallback((prev, next) => {
    if (prev.width === next.width && prev.height === next.height) {
      return false
    }

    return !((prev.width === next.width && !handleHeight) || (prev.height === next.height && !handleWidth))
  }, [handleWidth, handleHeight])

  const resizeCallback = useCallback(entries => {
    if (handleWidth || handleHeight) {
      entries.forEach(entry => {
        const { width, height } = entry?.contentRect || {}
        setSize(prevSize => !shouldSetSize(prevSize, { width, height }) ? prevSize : { width, height })
      })
    }
  }, [handleWidth, handleHeight, shouldSetSize])

  const resizeHandler = useCallback(patchResizeCallback(resizeCallback, refreshMode, refreshRate), [
    resizeCallback,
    refreshMode,
    refreshRate,
  ])

  useEffect(() => {
    let resizeObserver

    if (refElement) {
      resizeObserver = new SafeResizeObserver(resizeHandler)
      resizeObserver.observe(refElement, observerOptions)
    } else if (size.width || size.height) {
      setSize({ width: undefined, height: undefined })
    }

    return () => {
      resizeObserver?.disconnect?.()
      resizeHandler.cancel?.()
    }
  }, [resizeHandler, refElement])

  useEffect(() => {
    onResize?.(size)
  }, [size])

  return { ref: refProxy, ...size }
}
