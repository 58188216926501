import { ReactComponent as BetGamesIcon } from 'assets/images/casino/tv-games/betgames.svg'
import { ReactComponent as TvBetIcon } from 'assets/images/casino/tv-games/tv-bet.svg'

export const DEFAULT_FILTER_ID = '-1'

export const providers = [
  { label: 'Все провайдеры', id: DEFAULT_FILTER_ID },
  { label: <BetGamesIcon />, id: '1' },
  { label: <TvBetIcon />, id: '2' },
]

export const defaultCategory = { label: 'Все игры', id: DEFAULT_FILTER_ID }

const checkId = (selected, target) => (selected === DEFAULT_FILTER_ID || selected === target)

export const filterGames = (games, categoryId, providerId) => {
  if (categoryId === DEFAULT_FILTER_ID && providerId === DEFAULT_FILTER_ID) {
    return games
  }

  return games.filter(({
    category_id,
    provider_id,
  }) => checkId(categoryId, category_id) && checkId(providerId, provider_id))
}

export const sortCategories = categories => categories.sort((left, right) => +right.order - +left.order)
