import { ExternalLink } from 'components/ExternalLink'
import styles from './styles.module.scss'

export const RulesLink = ({ to }) => (
  <ExternalLink
    className={styles.rulesLink}
    to={to}
  >
    Условия
  </ExternalLink>
)
