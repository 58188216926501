import { Backdrop } from 'components/Backdrop'
import { Transition } from 'components/Transition'
import { useStopScroll } from 'hooks/useStopScroll'
import { useRef } from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const CouponWrapper = ({ opened, single, onClose, children, status, processing }) => {
  useStopScroll(opened && !single)

  const couponRef = useRef()
  const statusRef = useRef()

  return (
    <div id="coupon">
      {processing && (
        <Backdrop
          zIndex={300}
          className={styles.backdrop}
        />
      )}
      <Transition
        in={opened}
        timeout={300}
        nodeRef={couponRef}
        unmountOnExit
      >
        {state => (
          <div
            ref={couponRef}
            className={cn(styles.coupon, !single && styles.full, styles[state], processing && styles.processing, state)}
          >
            <button
              onTouchStart={onClose}
              className={cn(styles.closeGesture, single && styles.static)}
            >
              <div className={styles.line} />
            </button>
            {children}
          </div>
        )}
      </Transition>
      <Transition
        in={!opened}
        timeout={300}
        nodeRef={statusRef}
        unmountOnExit
      >
        {state => (
          <div
            ref={statusRef}
            className={cn(styles.statusWrapper, styles[state], state)}
          >
            {status}
          </div>
        )}
      </Transition>
    </div>
  )
}
