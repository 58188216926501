import { useEffect } from 'react'

const EVENT_TYPE = 'click'

/**
 * Example:
 *
 * useCheckOutsideClick(containerRef, onClose)
 *
 * {onClose} will be called when the user has clicked outside the containerRef's area.
 */

/**
 * Hook responds to a click outside the passed {ref}.
 *
 * @param {Object} ref
 * @param {Function} callback
 * @param {boolean} condition
 */
export const useCheckOutsideClick = (ref, callback, condition = true) => {
  useEffect(() => {
    if (condition) {
      const onClick = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback(event)
        }
      }

      document.addEventListener(EVENT_TYPE, onClick)
      return () => document.removeEventListener(EVENT_TYPE, onClick)
    }
  }, [condition])
}
