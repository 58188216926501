import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'

const setIsFetchingVideo = payload => ({
  type: ACTIONS.LIVE.VIDEO.SET_FETCHING,
  payload,
})

export const getLiveVideo = ({ isFirst, betOnOur }) => async dispatch => {
  isFirst && dispatch(setIsFetchingVideo(true))

  try {
    const { data } = betOnOur ? await Api.getTvEventsBelarus() : await Api.getTvEvents()

    dispatch({
      type:    ACTIONS.LIVE.VIDEO.GET,
      payload: data,
    })

  } catch (e) {
    console.error(e)
  } finally {
    dispatch(setIsFetchingVideo(false))
  }
}

export const clearLiveVideo = () => ({
  type: ACTIONS.LIVE.VIDEO.CLEAR,
})
