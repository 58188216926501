import { useAuth } from 'hooks/useAuth'
import { useWillUnmount } from 'hooks/useWillUnmount'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { resetCasinoState } from 'store/casino/creators'
import {
  ClosingCode,
  createCasinoSocket,
} from 'utils/socket/socket'

export const CasinoLayout = () => {
  const isAuth   = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    const socket = createCasinoSocket()

    return () => {
      socket.close(ClosingCode.CLEAR)
    }
  }, [isAuth])

  useWillUnmount(() => dispatch(resetCasinoState()))

  return <Outlet />
}
