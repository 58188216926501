import { Api } from 'api/Api'
import { useModal } from 'hooks/useModal'
import { useRequest } from 'hooks/useRequest'
import { CalculationModal } from 'pages/Cashback/History/Modal'
import { CALCULATION_MODAL_ID } from 'pages/Cashback/History/utils'
import { actionFactory } from 'pages/Cashback/utils'
import { formatTime } from 'utils/time'
import styles from './styles.module.scss'

export const VipCashbackHistory = () => {
  const { data } = useRequest(actionFactory(Api.getCashbackHistory))

  const { openModal, closeModal } = useModal(CALCULATION_MODAL_ID)

  const onCalculate = async () => {
    const { data } = await Api.calculateCashback()
    openModal(<CalculationModal {...data} onClose={closeModal} />)
  }

  return (
    <section className={styles.vipCashbackHistory}>
      {data?.map(({ amount, date, future, name, percent }) => (
        <div
          key={date}
          className={styles.historyItem}
        >
          <div className={styles.header}>
            <div className="font-11 gray">{formatTime(date)}</div>
            <div className="font-13">{future ? '' : `CashBack ${percent}%`}</div>
          </div>
          <div className={styles.main}>
            <div>{future ? '-' : `${name} ${percent}%`}</div>
            {future ? (
              <button
                className="red underline"
                onClick={onCalculate}
              >
                Посмотреть расчёт
              </button>
            ) : (
              <div className="green">
                {`${amount} BYN`}
              </div>
            )}
          </div>
        </div>
      ))}
    </section>
  )
}
