import ACTIONS from 'store/constants/actions'

const initialState = {
  is_on:         true,
  paymentLimits: {
    deposit:     2,
    withdraw:    2,
    minWithdraw: 5,
    minDeposit:  false,
  },
}

export const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SERVICE.SET_STATUS: {
      return { ...state, is_on: action.payload.result === 'success' }
    }

    case ACTIONS.SERVICE.SET_PAYMENT_LIMITS: {
      const { min_deposit: minDeposit, min_withdraw, ...restProps } = action.payload

      return {
        ...state,
        paymentLimits: {
          minDeposit,
          minWithdraw: +min_withdraw.value,
          ...restProps,
        },
      }
    }

    default:
      return state
  }
}
