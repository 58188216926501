import age21sign from 'assets/images/svg/age21.svg'
import { Link } from 'react-router-dom'

const links = [
  { year: '2019', to: '/news/site/maxline-pobeditel-konkursa-produkt-goda-2019' },
  { year: '2020', to: '/news/site/maxline-pobeditel-konkursa-produkt-goda-2020' },
  { year: '2021', to: '/news/site/maxline-pobeditel-konkursa-produkt-goda-2021' },
  { year: '2022', to: '/news/site/maxline-pobeditel-konkursa-produkt-goda-2022' },
  { year: '2023', to: '/news/site/Luchsheye-onlayn-kazino-Belarusi-po-versii-Produkt-goda' },
]

export const YearProduct = () => (
  <div className="year-product">
    <div className="year-product__age-limit">
      <img
        src={age21sign}
        alt="21-years-old"
      />
      <div className="year-product__age-limit-text">Только для лиц старше 21 года</div>
    </div>
    <div className="year-product__medals">
      {links.map(({ to, year }) => (
        <Link
          key={to}
          to={to}
          className="year-product__medal-link"
          aria-label={`Product of the year ${year}`}
        />
      ))}
    </div>
  </div>
)

