import { RecoveryPassword } from 'components/PasswordRecovery'
import { VipCashbackPage } from 'pages/Cashback'
import { ChangePasswordPage } from 'pages/ChangePassword'
import { HelpPage } from 'pages/Help'
import { PaymentPageLegacy } from 'pages/Payment'
import { PromoCodesPage } from 'pages/PromoCodes'
import { TakeMoneyPage } from 'pages/Takemoney'
import { UserHistoryPage } from 'pages/UserHistoryPage'
import { BetsHistoryPage } from 'pages/UserHistoryPage/Bets'
import { CasinoHistoryPage } from 'pages/UserHistoryPage/Casino'
import { lazy } from 'react'

const Account             = lazy(() => import('../../components/Account'))
const AccountPage         = lazy(() => import('../../pages/Account'))
const SignUpOld           = lazy(() => import('../../components/SignUp'))
const AuthHistoryPage     = lazy(() => import('../../pages/AuthHistory'))
const BetGamesHistoryPage = lazy(() => import('../../pages/BetGamesHistory'))
const BonusHistoryPage    = lazy(() => import('../../pages/BonusHistory'))
const ForecastHistoryPage = lazy(() => import('../../pages/UserHistoryPage/Forecasts'))
const ForecastHistoryList = lazy(() => import('../../pages/UserHistoryPage/Forecasts/ForecastEventsList'))
const LoadPassportPage    = lazy(() => import('../../pages/LoadPassport'))
const MessagesPage        = lazy(() => import('../../pages/Messages'))
const MessageItemPage     = lazy(() => import('../../pages/Messages/MessageItem'))
const PaymentHistoryPage  = lazy(() => import('../../pages/UserHistoryPage/Payments'))
const PersonalData        = lazy(() => import('../../pages/PersonalData'))
const SettingsPage        = lazy(() => import('../../pages/Settings'))
const TvBetHistoryPage    = lazy(() => import('../../pages/TvbetHistory'))

export const userRoutes = [
  {
    path:     'user',
    element:  <Account />,
    children: [
      {
        index:   true,
        element: <AccountPage />,
      },
      {
        path:    'signup',
        element: <SignUpOld />,
      },
      {
        path:    'forgot-password',
        element: <RecoveryPassword />,
      },
      {
        path:    'pay',
        element: <PaymentPageLegacy />,
      },
      {
        path:    'promocode',
        element: <PromoCodesPage />,
      },
      {
        path:    'messages',
        element: <MessagesPage />,
      },
      {
        path:    'messages/:id',
        element: <MessageItemPage />,
      },
      {
        path:    'settings',
        element: <SettingsPage />,
      },
      {
        path:    'password',
        element: <ChangePasswordPage />,
      },
      {
        path:    'personal-data',
        element: <PersonalData />,
      },
      {
        path:     'history',
        element:  <UserHistoryPage />,
        children: [
          {
            path:    'bets',
            element: <BetsHistoryPage />,
          },
          {
            path:    'casino',
            element: <CasinoHistoryPage />,
          },
          {
            path:    'payments',
            element: <PaymentHistoryPage />,
          },
          {
            path:    'forecasts',
            element: <ForecastHistoryPage />,
          },
          {
            path:    'forecasts/:id',
            element: <ForecastHistoryList />,
          },
        ],
      },
      {
        path:    'history-tvbet',
        element: <TvBetHistoryPage />,
      },
      {
        path:    'history-bet-games',
        element: <BetGamesHistoryPage />,
      },
      {
        path:    'history-bonus',
        element: <BonusHistoryPage />,
      },
      {
        path:    'history-session',
        element: <AuthHistoryPage />,
      },
      {
        path:    'help',
        element: <HelpPage />,
      },
      {
        path:    'take-money',
        element: <TakeMoneyPage />,
      },
      {
        path:    'upload-document',
        element: <LoadPassportPage />,
      },
      {
        path:    'vip-cashback',
        element: <VipCashbackPage />,
      },
    ],
  },
]
