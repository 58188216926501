import paymentSuccessIcon from 'assets/images/payments/duck.png'
import { openPopup } from 'components/Popup'
import {
  ANDROID_APP_URL,
  IOS_APP_URL,
} from 'constants/links'
import {
  isIos,
  isWeb,
} from 'constants/platform'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

const webTitle     = 'Активируй бонус 22% к депозиту в приложении Maxline'
const webviewTitle = 'Следи за статусом бонуса 22% к депозиту в истории промокодов'

const webSubmitBtn = {
  title: 'Перейти в приложение',
  to:    isIos ? IOS_APP_URL : ANDROID_APP_URL,
}

const webviewSubmitBtn = {
  title: 'ОК',
}

const Header = () => (
  <>
    <div className={styles.title}>Счёт был успешно пополнен</div>
    <img
      src={paymentSuccessIcon}
      className={styles.img}
      alt="Payment success"
    />
  </>
)

const Footer = ({ web, callback }) => (
  <div className={styles.footer}>
    {web && <div>Статус отображается в разделе</div>}
    <Link
      to="/user/promocode"
      className={styles.link}
      onClick={callback}
    >
      «История промокодов»
    </Link>
  </div>
)

export const showPaymentHybridModal = callback => {
  openPopup({
    title:     isWeb ? webTitle : webviewTitle,
    icon:      Header,
    submitBtn: {
      ...(isWeb ? webSubmitBtn : webviewSubmitBtn),
      callback,
    },
    footer:    <Footer
                 web={isWeb}
                 callback={callback}
               />,
  })
}
