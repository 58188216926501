import { GooseAnimation } from 'components/GooseAnimation'
import { Placeholder } from 'components/Placeholder'
import { SportDivider } from 'components/SportDivider'
import Event from 'pages/Home/components/Event'
import { getEventProps } from 'pages/Home/components/Event/utils'
import { Interval } from 'pages/Home/utils'
import React, { useEffect } from 'react'
import {
  connect,
  useSelector,
} from 'react-redux'
import { selectLiveBetOnOur } from 'store/data/selectors'
import {
  clearLiveVideo,
  getLiveVideo,
} from 'store/live-video/actions'

const VideoFC = ({ getLiveVideo, data }) => {
  const betOnOur = useSelector(selectLiveBetOnOur)

  useEffect(() => {
    getLiveVideo({ isFirst: true, betOnOur })

    const intervalId = setInterval(() => getLiveVideo({ betOnOur }), Interval.LIVE)

    return () => {
      clearInterval(intervalId)
    }
  }, [betOnOur])

  const { sports = [], events = [], factors, results, clubs, leagues, isFetching } = data

  const hasData = !!sports.length && !!events.length

  return (
    (hasData && sports.map(sport => (
      <SportDivider
        key={sport.id}
        sport={sport}
        label="Live"
        link
      >
        {events
          .filter(({ sport_id }) => sport_id === sport.id)
          .map(event => <Event {...getEventProps({
            event, results, factors, clubs,
            league: leagues.find(({ id }) => id === event.league_id),
          })} />)}
      </SportDivider>
    ))) || (isFetching && <GooseAnimation className="absolute-center" />) || (
      <Placeholder name={betOnOur ? 'betOnOur' : 'video'} />
    )
  )
}

const mapStateToProps = state => ({
  data: state.liveVideo,
})

const mapDispatchToProps = {
  getLiveVideo,
  clearLiveVideo,
}

export const Video = connect(mapStateToProps, mapDispatchToProps)(VideoFC)
