import {
  API_URL,
  appendPartnersIDs,
  buildParams,
  buildUtmQuery,
  get,
  getToken,
  post,
  postOptions,
  request,
  sq,
} from 'api/utils'
import { device } from 'constants/platform'
import { getOneClickBetsAmount } from 'store/one-click-bets/reducer'
import { getAccounts } from 'store/user/actions'
import { isBoolean } from 'utils/guards'

export class Api {
  static login(login, password) {
    const accounts = getAccounts()

    const data = {
      login,
      password,
      ...(accounts && { accounts }),
      ...(device && {
        device_id: device.id,
        trusted:   isBoolean(device.trusted) ? device.trusted : true,
      }),
    }

    return post('/user/login', data, false)
  }

  static register               = data => post('/user/sign-up', data)
  static verification           = data => post('/user/sign-up', data)
  static getSearchEvent         = data => post(`/search`, data, false)
  static activateCashback       = () => post(`/vip-cashback/activate`)
  static deactivateCashback     = () => post(`/vip-cashback/deactivate`)
  static bankOut                = data => post('/payment/bank-out', data)
  static cashOut                = data => post('/payment/cash-out', data)
  static restorePasswordPost    = data => post(`/user/restore-password`, data)
  static changePasswordPost     = data => post('/user/change-password', data)
  static makeTopExpress         = data => post('/top-express', data)
  static search                 = data => post('/search', data)
  static makeLineBet            = data => post('/bet/line', data)
  static takeCashOut            = (bet_id, hash) => post('/cashout/pay', { bet_id, hash })
  static cardOut                = data => post(`/payment/card-out`, data)
  static setSettings            = data => post('/user/set-account-settings', data)
  static makeForecast           = data => post('/forecast/make', data)
  static getMaxlinePayout       = bet_id => post('/user/payout-maxline-pay-return', { bet_id })
  static payoutMaxlinePayReturn = bet_id => post('/user/payout-maxline-pay-return', { bet_id })
  static sendMailToHead         = data => post('/data/send-email-to-head', data)
  static sendMailToSupport      = data => post('/data/send-email-to-support', data)
  static sendMailToMarketing    = data => post('/data/send-email-to-marketing', data)
  static sendSlideStatistics    = id => post(`/sliderpromo/counter`, { id }, false)
  static updateUserBets         = data => post('/user/set-user-bets', data)
  static activatePromoCode      = promocode => post('/promocode/activate', { promocode })
  static getOplatiPaymentLink   = data => post('/oplati/deposit', data)
  static getOplatiWithdraw      = data => post('/oplati/withdraw', data)
  static activateWagerBonus     = id => post('/wager/activate', { id })
  static cancelWagerBonus       = id => post('/wager/deactivate', { id })
  static activateTournament     = id => post('/prize-tournament/activate', { id })
  static getTournament          = game_id => post('/prize-tournament/get', { game_id })
  static changeBonus22          = type => post('/user/change-status-twenty-two', { type })
  static toggleCasinoFavorite   = data => post('/favorite/games', sq(data), true)

  static payIn(amount, { id: card_id, exp: expired, updated } = {}) {
    const data = {
      card_id,
      amount,
      ...(updated && { expired }),
      utm:    buildUtmQuery(),
      atoken: getToken(),
    }

    return post(`/payment/pay-in-by-3d`, data)
  }

  static loadPassport(formData) {
    return request(`${API_URL}/user/passport-upload?${buildParams(true)}`, { method: 'POST', body: formData })
  }

  static getChampionships          = () => get('/championship/all')
  static getSignInSlider           = () => get('/slider-promo/get-active', 'position=5')
  static getLeaguesSlider          = () => get('/league/sliders')
  static getCasinoSlider           = position => get('/slider-promo/get-active', sq({ position }))
  static getCasinoGames            = data => get('/games', sq(data))
  static getCasinoFavorites        = data => get('/favorite/games', sq(data), true)
  static getMargin0                = () => get('/sport/margin-zero')
  static getLiveTop                = () => get('/event-mobile/live-top', 'country_code=0')
  static getServiceWork            = () => get('/data/ping')
  static resultLive                = () => get('/result/live-result')
  static getListWhatsNew           = () => get('/data/whats-new')
  static getLiveData               = ids => get('/event-mobile/live-data', `id=${ids}`)
  static getWinners                = () => get('/forecast/winners')
  static getBank                   = () => get('/data/get-bank')
  static getPpsList                = () => get('/data/pps', '')
  static getCashes                 = () => get(`/data/get-cashes`)
  static getLiveTopBelarus         = () => get('/event-mobile/live-top', 'country_code=BLR')
  static getLineTop                = () => get('/event-mobile/line-top')
  static getTvEvents               = () => get('/event-mobile/live-tv', 'country_code=0')
  static getTvEventsBelarus        = () => get('/event-mobile/live-tv', 'country_code=BLR')
  static getAllFAQ                 = () => get('/faq/get')
  static getCentrifugoJWT          = () => get('/user/get-jwt', '', true)
  static getBonuses                = () => get('/data/bonus')
  static getCasinoProviders        = data => get('/games/providers', sq(data))
  static getCasinoThemes           = data => get('/games/themes', sq(data))
  static getCasinoCategories       = data => get('/games/categories', sq(data))
  static validatePromoCode         = promocode => get('/promocode/check', sq({ promocode }))
  static getPromoCodesCount        = () => get('/promocode/count', '', true)
  static getTVGamesCategories      = () => get('/games/tv-categories')
  static topExpress                = () => get('/top-express')
  static getTVGames                = search => get('/games/tv', sq({ ...(search && { search }) }))
  static getSuccessPay             = hash => get('/user/get-success-pay', sq({ hash }))
  static getLastNews               = (catId, limit = 50) => get('/news/last', sq({ catId, limit }))
  static forgotPassword            = data => get('/user/forgot', sq(data))
  static checkCode                 = data => get('/user/check-code', sq(data))
  static canGenerateCode           = phone => get('/user/can-generate-code', sq({ phone }))
  static getLineResults            = date => get(`/gameresult/get-sports`, `date=${date}`)
  static getChampionshipCategories = id => get(`/championship/get-categories/${id}`)
  static getHighlights             = (params = '') => get('/event/highlight', params)

  static getLiveSports(betOnOur, sport) {
    const params = sq({ v: 2, version: 1, country_code: betOnOur ? 'BLR' : 0, ...(sport && { sport }) })
    return get(`/event-mobile/live-sports`, params)
  }

  static getLine = ({ period = 0, betOnOur }) => {
    return get('/sport/line', sq({ period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getLineSports = (period, betOnOur) => {
    return get('/category/sports', sq({ period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getLineSportCategories = (sport_id, period, betOnOur) => {
    return get('/category/get', sq({ sport_id, period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getLineCategoryLeagues = (sport_id, category_id, period, betOnOur) => {
    return get('/category/leagues', sq({ sport_id, category_id, period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getPopulars = ({ period = 4, betOnOur }) => {
    return get('/popular/line-menu', sq({ period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getLineData(id, express_plus = 0) {
    return get(`/event${id ? '-mobile' : ''}/line-data`, sq({ id, period: 0, express_plus }))
  }

  static getLineSelect(sport, period = 4, betOnOur) {
    return get('/league/line-select', sq({ sport, period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getLineResultsNew(date, sports) {
    return get(`/gameresult/get-new-result`, `date=${date}&sportIds=${sports.join('-')}`)
  }

  static getTopGames(betOnOur) {
    return get('/event/line-top', `country_code=${betOnOur ? 'BLR' : '0'}`)
  }

  static getMaxlinePayMatchList({ period = '0', sport = '0' }) {
    return get('/maxlinepay/get-data', `period=${period}&sport=${sport}`)
  }

  static getMaxlineReturnMatchList({ period = '0', sport = '0' }) {
    return get('/maxlinereturn/get-data', `period=${period}&sport=${sport}`)
  }

  static getLiveCount(betOnOur) {
    return get(`/event-mobile/live-count`, `&country_code=${betOnOur ? 'BLR' : '0'}`)
  }

  static getLiveBySport(id, betOnOur) {
    return get(`/event-mobile/live-main`, `sport=${id}&country_code=${betOnOur ? 'BLR' : '0'}`)
  }

  static getLineByLeague(league, period = '0', betOnOur = false) {
    return get('/event-mobile/line-main', sq({ league, period, country_code: betOnOur ? 'BLR' : '0' }))
  }

  static getChampionshipLeagues(period = 0) {
    return get('/championship/get-leagues-by-sport', sq({ period }))
  }

  static getFavorites = ids => {
    return get('/event-mobile/live-data/', `id=${ids}&favorite=1`)
  }

  static getLineFilters(betOnOur) {
    return get('/sport/line-mobile', `v=2&country_code=${betOnOur ? 'BLR' : '0'}`)
  }

  static isAuth                 = () => get('/user/is-auth', '', true)
  static getOrderedPayment      = () => get('/user/get-ordered-payment', '', true)
  static getInfo                = () => get('/user/get-info', '', true)
  static subscribeToNews        = id => get('/news/subscribe', sq({ id }), true)
  static getMessagesList        = (cat = 0) => get('/message/list', sq({ cat }), true)
  static getMessageText         = id => get(`/message/get/${id}`, '', true)
  static getUnreadMessagesCount = () => get('/message/not-read-count', '', true)
  static readAllMessages        = () => get('/message/read-all', '', true)
  static getAllCategories       = () => get('/message/cat-list', '', true)
  static getCashbackStatus      = () => get(`/vip-cashback/status`, '', true)
  static getCashbackStatuses    = (need_group = false) => get('/vip-cashback', sq({ need_group }), true)
  static getCashbackHistory     = () => get('/vip-cashback/history', '', true)
  static calculateCashback      = () => get('/vip-cashback/calculate', '', true)
  static getCouponLine          = () => get('/coupon/get', 'type=0&mobile=1&system=1', true)
  static bonusHistory           = () => get(`/user/get-bonus-log`, '', true)
  static getPaymentCards        = () => get(`/payment/ipay-card-list`, '', true)
  static changePassword         = () => get('/user/change-password', '', true)
  static getNotCalcBets         = () => get('/bet/notcalc', '', true)
  static getCashOut             = () => get('/cashout/get', '', true)
  static getCardsList           = () => get(`/payment/ipay-card-list`, '', true)
  static getSettings            = () => get(`/user/get-account-settings`, '', true)
  static getBetgamesData        = () => get(`/data/get-betgames-data`, '', true)
  static getTVBetData           = () => get(`/data/get-tvbet-data`, '', true)
  static getMaxlinePayReturn    = () => get('/user/get-maxline-pay-return', '', true)
  static canChangePhone         = () => get(`/user/is-verify-phone`, '', true)
  static getUnreadNews          = () => get('/news/unread-news', '', true)
  static updateUnreadNewsDate   = () => get('/news/update-unread-news-date', '', true)
  static getPromoSlider         = () => get('/slider-promo/get-active', '', true)
  static getUserBets            = () => get('/user/get-user-bets', '', true)
  static getConfig              = () => get('/config/get', '', true)
  static getUserPromoCodes      = period => get(`/promocode/logs`, sq({ period }), true)
  static getActivePaymentMethod = () => get('/settings/get-active-payment-method', '', true)
  static getOplatiPurses        = () => get('/oplati/purse', '', true)
  static initCasinoGame         = (id, mode) => get(`/games/init${mode ? `-${mode}` : ''}`, `game_id=${id}`, true)
  static getWagerBonus          = () => get('/wager/get', '', true)
  static getTournaments         = type => get('/prize-tournament', sq({ ...(type && { type }) }), true)
  static getTournamentResult    = id => get('/prize-tournament/table', `id=${id}`, true)
  static getTournamentsCount    = () => get('/prize-tournament/count', '', true)
  static getResults             = id => get('/result/data', sq({ id }), true)
  static setLiveOpt             = option => get('/user/live-opt', `option=${option}`, true)
  static getBonus22             = () => get('/user/twenty-two', '', true)

  static verifyPhoneCode({ code, isUserExist, promocode }) {
    const utmQuery = buildUtmQuery()

    const search = sq({
      code,
      ...(isUserExist && { isUserExist: 1 }),
      ...(promocode && { promocode }),
      ...appendPartnersIDs(),
    })

    return get('/user/verify-code', `${search}&${utmQuery}`, true)
  }

  static getNewsOne(slug) {
    const link = new URLSearchParams(window.location.search).get('link')
    return get(`/news/one/${slug}`, `link=${link}`, true)
  }

  static getCoupon(type) {
    return get('/coupon/is-fresh', `type=${type === 'live' ? 1 : 0}&mobile=1`, true)
  }

  static couponAdd(eventId, hash, express_plus = 0) {
    return get('/coupon/add', `event_id=${eventId}&hash=${hash}&mobile=1&express_plus=${express_plus}`, true)
  }

  static couponClear(type) {
    return get('/coupon/clear', `type=${type === 'live' ? 1 : 0}`, true)
  }

  static couponDel(type, event_id) {
    return get('/coupon/del', `type=${type === 'live' ? 1 : 0}&event_id=${event_id}&mobile=1`, true)
  }

  static setGoldBet(type, gold, system = 0) {
    return get('/coupon/set-gold-bet', `type=${type === 'live' ? 1 : 0}&gold=${gold}&system=${system}`, true)
  }

  static betHistory(period = 0, notCalc) {
    return get(`/bet/history`, `period=${period}&not_calc=${Number(notCalc)}`, true)
  }

  static casinoHistory(period = 0, page_id = 0, per_page = 50) {
    return get(`/games/transactions`, `period=${period}&page_id=${page_id}&per_page=${per_page}`, true)
  }

  static paymentHistory({ period, type }) {
    return get(`/payment/history`, `period=${period}&type=${type}`, true)
  }

  static forecastHistory(period) {
    return get(`/forecast/get-by-user`, `period=${period}`, true)
  }

  static paymentCancel(payment_id) {
    return get(`/payment/cancel`, `id=${payment_id}`, true)
  }

  static makeBet(data) {
    return get(`/bet/live`, sq({ amount: 0, gold: 1, ...data }), true)
  }

  static makeOneClickBet = () => {
    const amount = getOneClickBetsAmount()
    return get(`/bet/live`, `amount=${amount}&clear=1&gold=1`, true)
  }

  static getOpenedBets(type = 1) {
    return get('/bet/open', `type=${type}`, true)
  }

  static personalData(userId) {
    return get(`/user/info`, `userId=${userId}`, true)
  }

  static getAuthHistory(dateFrom, dateTo) {
    return get(`/user/get-auth-history`, `type=1&dateStart=${dateFrom}&dateEnd=${dateTo}`, true)
  }

  static hideCard(ids) {
    return Promise.all(ids.map(id => get('/user/hide-card', `card_id=${id}`, true)))
  }

  static showCard(ids) {
    return Promise.all(ids.map(id => get('/user/remove-from-hidden-card', `card_id=${id}`, true)))
  }

  static getForecast(type) {
    return get('/forecast/get', `type=${type}`, true)
  }

  static tvBetHistory(period = '') {
    return get(`/tvbet/get-history`, `period=${period}`, true)
  }

  static betGamesHistory(period = '') {
    return get(`/betgames/get-history`, `period=${period}`, true)
  }

  static async checkOplatiPaymentStatus(id) {
    const url = `${API_URL}/oplati/check-payment?${buildParams(true)}`

    try {
      const response = await fetch(url, { ...postOptions, body: `id=${id}` })
      return await response.json()
    } catch (error) {
      console.error(error)
    }
  }

  static checkRandomBonus = async () => {
    try {
      const { data: { status_code } } = await get('/randombonus/check', '', true)
      return status_code === null
    } catch {
      return false
    }
  }
}
