import {
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

const options = {
  threshold:  0,
  root:       null,
  rootMargin: '0%',
}

export const useIntersect = () => {
  const [isVisible, setIsVisible] = useState(false)

  const observerRef = useRef(null)

  const lastNodeRef = useCallback(node => {
    if (observerRef.current) {
      observerRef.current.disconnect()
    }

    if (node) {
      observerRef.current = new IntersectionObserver(([entry]) => setIsVisible(entry.isIntersecting), options)
      observerRef.current.observe(node)
    }
  }, [])

  const lastNode = useMemo(() => (
    <div
      style={{ height: '1px' }}
      ref={lastNodeRef}
    />
  ), [])

  return [lastNode, isVisible]
}
