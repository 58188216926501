import { getStartedAt } from 'pages/Home/components/Event/StartDate/utils'

export const StartedAt = ({ dirtyTime }) => {
  const { date, time } = getStartedAt(dirtyTime)

  return (
    (date && time) ? (
      <div className="coupon__started-at">
        {`${date} в ${time}`}
      </div>
    ) : null
  )
}
