import { Api } from 'api/Api'
import { MaskedInput } from 'components/MaskedInput'
import { Option } from 'components/Option'
import { showErrorModal } from 'components/Popup'
import SwipeModal from 'components/SwipeModal'
import { SwipeModalTrigger } from 'components/SwipeModalTrigger'
import { STATUSES } from 'constants/statuses'
import BaseForm from 'pages/Takemoney/Forms/Base'
import { PAYOUT_EXTRA_LOCK_MS } from 'pages/Takemoney/Forms/Card/utils'
import { onSuccessPayout } from 'pages/Takemoney/Forms/utils'
import React from 'react'
import { connect } from 'react-redux'
import { getDetails } from 'store/payments/actions'
import { getInfo } from 'store/user/actions'
import {
  cn,
  importImage,
} from 'utils'
import { sleep } from 'utils/time'
import styles from './styles.module.scss'

const IBAN_LENGTH = 28

const BankOption = ({ id, name }) => (
  <div className={styles.bankOption}>
    <img
      className={styles.bankIcon}
      src={importImage(`banks/${id}.svg`)}
      alt={name}
    />
    <div>{name}</div>
  </div>
)

class BankForm extends BaseForm {
  static defaultProps = {
    minAmount: 10,
    maxAmount: 10000,
  }

  state = {
    amount:                 10,
    toPay:                  9.60,
    tax:                    0.40,
    phone:                  '',
    iban:                   '',
    messages:               '',
    isOfficeModalOpen:      false,
    isAccountTypeModalOpen: false,
    isBankModalOpen:        false,
    selectedOffice:         {},
    selectedBank:           {},
    type:                   {},
  }

  componentDidMount() {
    this.initialState = { ...this.state }
  }

  onBankChange = bank => {
    const { selectedBank: { id: prevBankId } } = this.state

    const hasBankChanged = prevBankId && prevBankId !== bank?.id

    this.setState({ ...(hasBankChanged && this.initialState), isBankModalOpen: false, selectedBank: bank })
  }

  onSubmit = async () => {
    const { amount, iban, selectedBank: bank, selectedOffice: office, type, phone } = this.state
    const { getInfo, getDetails }                                                   = this.props

    const data = {
      amount,
      bank:       office.id,
      mfo:        office.mfo,
      unn:        office.unn,
      account:    iban,
      type:       type.id,
      numberMail: phone,
      bankId:     bank.id,
    }

    const { messages, status } = await Api.bankOut(data)

    if (status === STATUSES.SUCCESS) {
      onSuccessPayout()
      getDetails()
      getInfo()
      this.setState(this.initialState)
      return await sleep(PAYOUT_EXTRA_LOCK_MS)
    }

    showErrorModal({
      message:   messages.toString(),
      submitBtn: { title: 'Продолжить' },
    })
  }

  render() {
    const { banks } = this.props

    const { type, iban, selectedOffice, selectedBank, isOfficeModalOpen, isBankModalOpen } = this.state

    const { name: officeName, mfo, unn } = selectedOffice
    const {
            name:    bankName,
            id:      bankId,
            mask:    bankMask,
            account: bankAccount,
            office:  bankOffice,
            commission,
          }                              = selectedBank

    const isIbanValid = iban.replace(/(_|\s)/gi, '').length === IBAN_LENGTH
    const isDisabled  = !this.isPhoneEntered() || !officeName || !type.name || !isIbanValid

    return (
      <div className="description__payment-item">
        <div className="description__content">
          {this.getAmountRow()}
          <SwipeModalTrigger
            onOpen={() => this.setState({ isBankModalOpen: true })}
            value={bankName &&
              <BankOption
                id={bankId}
                name={bankName}
              />
            }
            placeholder="Выберите банк"
          />
          <SwipeModal
            isOpen={isBankModalOpen}
            title="Выберите банк"
            onClose={() => this.setState({ isBankModalOpen: false })}
          >
            {banks?.map(bank => (
              <Option
                key={bank.id}
                title={
                  <BankOption
                    id={bank.id}
                    name={bank.name}
                  />
                }
                action={() => this.onBankChange(bank)}
                checked={bankId === bank.id}
              />
            ))}
          </SwipeModal>
          {bankName && (
            <>
              <SwipeModalTrigger
                onOpen={() => this.setState({ isOfficeModalOpen: true })}
                value={officeName}
                placeholder="Номер филиала"
              />
              <SwipeModal
                isOpen={isOfficeModalOpen}
                title="Выберите филиал"
                onClose={() => this.setState({ isOfficeModalOpen: false })}
              >
                {bankOffice?.map(office => (
                  <Option
                    key={office.id}
                    title={office.name}
                    action={() => this.setState({ isOfficeModalOpen: false, selectedOffice: office })}
                    checked={officeName === office.name}
                  />
                ))}
              </SwipeModal>
              <div
                className={styles.bankInfo}
                data-title="МФО банка:"
                data-value={mfo}
              />
              <div
                className={styles.bankInfo}
                data-title="УНН банка:"
                data-value={unn}
              />
              <div className={cn(styles.commission, !+commission && styles.free)}>
                {+commission ? 'Банк взимает комиссию согласно своих тарифов' : 'Maxline оплачивает комиссию за Вас'}
              </div>
              <MaskedInput
                id="iban"
                name="iban"
                label="Номер счёта (IBAN) в банке:"
                alwaysShowMask
                mask={`BY__${bankMask} ____ #### #### #### ####`}
                formatChars={{ '_': '[0-9]', '#': '[A-Za-z0-9]' }}
                onChange={event => this.inputChange(event, value => value.toUpperCase())}
                value={iban}
              />
              <div className={styles.attention}>
                <div className="bold">
                  Внимание!
                </div>
                <div className="mt-14">
                  Выплата выигрыша возможна только на карт-счёт
                  открытый на имя
                  (паспортные данные) владельца игрового счёта.
                </div>
              </div>
              <SwipeModalTrigger
                onOpen={() => this.setState({ isAccountTypeModalOpen: true })}
                value={type.name}
                placeholder="Тип счёта"
              />
              <SwipeModal
                isOpen={this.state.isAccountTypeModalOpen}
                title="Выберите счёт"
                onClose={() => this.setState({ isAccountTypeModalOpen: false })}
              >
                {bankAccount?.map(item => (
                  <Option
                    key={item.id}
                    title={item.name}
                    action={() => this.setState({ isAccountTypeModalOpen: false, type: item })}
                    checked={type.name === item.name}
                  />
                ))}
              </SwipeModal>
              {this.getPhoneRow('bank-form-input')}
            </>
          )}
          {this.getTaxRow(isDisabled)}
          {this.getOrderButton(isDisabled)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  banks: state.banks,
})

const mapDispatchToProps = {
  getInfo,
  getDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(BankForm)
