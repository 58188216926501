import { useAuth } from 'hooks/useAuth'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getMaxlinePays } from 'store/maxline-pays/actions'

export const useMaxlinePays = () => {
  const isAuth   = useAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isAuth) {
      dispatch(getMaxlinePays())
    }
  }, [isAuth])
}
