import { DatePicker } from 'components/DatePicker'
import { DateSwitcher } from 'components/DateSwitcher'
import { HighlightsList } from 'components/Highlights/HighlightsList'
import { LeaguePicker } from 'components/Highlights/LeaguePicker'
import { Search } from 'components/Highlights/Search'
import Loading from 'components/Loading'
import { Page } from 'components/Page'
import SwipeModal from 'components/SwipeModal'
import {
  addDays,
  subDays,
} from 'date-fns'
import {
  allSports,
  filterEvents,
  filterLeagues,
  getCounters,
  getHighlights,
  getSportFilters,
  initialCounter,
  initialHighlights,
  SwipeModalFilter,
} from 'pages/Highlights/utils'
import { SportPicker } from 'pages/Results/line/SportPicker'
import {
  useEffect,
  useState,
} from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const HighlightsPage = () => {
  const [date, setDate]                 = useState(null)
  const [isLoading, setIsLoading]       = useState(false)
  const [highlights, setHighlights]     = useState(initialHighlights)
  const [swipeModal, setSwipeModal]     = useState(SwipeModalFilter.NONE)
  const [sportId, setSportId]           = useState(allSports.id)
  const [counter, setCounter]           = useState(initialCounter)
  const [league, setLeague]             = useState(null)
  const [searchString, setSearchString] = useState('')

  const isModalOpen   = swipeModal !== SwipeModalFilter.NONE
  const isLeaguesOpen = swipeModal === SwipeModalFilter.LEAGUES

  const { sports, events, leagues } = highlights

  useEffect(() => {
    getHighlights(date, sportId, setIsLoading)
      .then(highlights => {
        setCounter(getCounters(highlights))
        setHighlights(highlights)
      })
  }, [date])

  const onLeagueChange = nextLeague => {
    setLeague(nextLeague)
    setSwipeModal(SwipeModalFilter.NONE)
  }

  const onDateChange = nextDate => {
    setSportId(allSports.id)
    setDate(nextDate)

    if (swipeModal) {
      setSwipeModal(SwipeModalFilter.NONE)
    }
  }

  const onSportChange = nextSportId => {
    if (nextSportId !== sportId && !isLoading) {
      setSportId(nextSportId)
      setLeague(null)
      setSearchString('')
      getHighlights(date, nextSportId, setIsLoading)
        .then(({ events, leagues }) => setHighlights(prevHighlights => ({ ...prevHighlights, events, leagues })))
    }
  }

  const hasEvents = events.length > 0

  const filteredLeagues = filterLeagues(leagues, league)
  const filteredEvents  = filterEvents(events, searchString)
  const sportFilters    = getSportFilters(sports, counter)

  return (
    <Page title="Обзоры">
      <DateSwitcher
        activeDate={date}
        goNext={() => onDateChange(addDays(date, 1))}
        goPrevious={() => onDateChange(subDays(date, 1))}
        openPicker={() => setSwipeModal(SwipeModalFilter.DATE)}
        pastOnly
      />
      <div className={styles.sportPicker}>
        {hasEvents && (
          <SportPicker
            list={sportFilters}
            active={sportId}
            onChange={onSportChange}
          />)}
      </div>
      <div className={cn(styles.filters, !hasEvents && styles.disabled)}>
        <button
          className={styles.leagueBtn}
          onClick={() => setSwipeModal(SwipeModalFilter.LEAGUES)}
        >
          {league?.name || 'Все чемпионаты'}
        </button>
        <Search
          value={searchString}
          onChange={setSearchString}
        />
      </div>
      {isLoading ? <Loading /> : (
        <HighlightsList
          leagues={filteredLeagues}
          events={filteredEvents}
          searchString={searchString}
        />
      )}
      <SwipeModal
        isOpen={isModalOpen}
        onClose={() => setSwipeModal(SwipeModalFilter.NONE)}
        {...isLeaguesOpen && { title: 'Чемпионат' }}
      >
        {isLeaguesOpen ? (
          <LeaguePicker
            value={league}
            onChange={onLeagueChange}
            options={leagues}
          />
        ) : (
          <DatePicker
            value={date}
            onChange={onDateChange}
          />
        )}
      </SwipeModal>
    </Page>
  )
}
