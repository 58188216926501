import { Redirect } from 'components/Redirect'
import { paths } from 'constants/paths'
import { ContactsPage } from 'pages/Contacts'
import { ExpressBoostPagePage } from 'pages/ExpressBoost'
import { HighlightsPage } from 'pages/Highlights'
import { NewsItem } from 'pages/News/Item'
import { PaymentsPage } from 'pages/Payments'
import { PpsPage } from 'pages/Pps'
import { SecurityPage } from 'pages/Security'
import { SuccessPayPage } from 'pages/SuccessPay'
import { lazy } from 'react'

const AboutPage            = lazy(() => import('pages/About'))
const Bonus22Page          = lazy(() => import('pages/Bonus22'))
const BonusesPage          = lazy(() => import('pages/Bonuses'))
const FAQPage              = lazy(() => import('pages/FAQ'))
// const MaxlinePayReturnPage = lazy(() => import('pages/MaxinePayReturn'))
const MobileAppsPage       = lazy(() => import('pages/MobileApps'))
const NewsPage             = lazy(() => import('pages/News'))
const RandomBonusPage      = lazy(() => import('pages/RandomBonus'))
const TournamentPage       = lazy(() => import('pages/Tournament'))
const TournamentsPage      = lazy(() => import('pages/Tournaments'))
const VipCashbackPage      = lazy(() => import('pages/VipCashback'))

export const staticRoutes = [
  {
    path:    'page/about_company',
    element: <AboutPage />,
  },
  {
    path:    'page/mobile-app',
    element: <MobileAppsPage />,
  },
  {
    path:    'page/alfabank',
    element: <SecurityPage />,
  },
  {
    path:    'page/payments',
    element: <PaymentsPage />,
  },
  {
    path:    'success-pay',
    element: <SuccessPayPage />,
  },
  {
    path:    'page/pps',
    element: <PpsPage />,
  },
  {
    path:    'news/site',
    element: <NewsPage />,
  },
  {
    path:    'news/site/:slug',
    element: <NewsItem />,
  },
  {
    path:    'pages/bonuses',
    element: <BonusesPage />,
  },
  {
    path:    'faq',
    element: <FAQPage />,
  },
  {
    path:    'contacts',
    element: <ContactsPage />,
  },
  // TODO Delete as needed
  // {
  //   path:    'maxline-pay-return',
  //   element: <MaxlinePayReturnPage />,
  // },
  {
    path:    'highlights',
    element: <HighlightsPage />,
  },
  {
    path:    'random-bonus',
    element: <RandomBonusPage />,
  },
  {
    path:    'prize-tournament',
    element: <TournamentsPage />,
  },
  {
    path:    'prize-tournament/:slug',
    element: <TournamentPage />,
  },
  {
    path:    'vip-cashback',
    element: <VipCashbackPage />,
  },
  {
    path:    'express-boost',
    element: <ExpressBoostPagePage />,
  },
  {
    path:    'deposit-bonus',
    element: <Bonus22Page />,
  },
  {
    path:    '*',
    element: <Redirect to={paths.HOME} />,
  },
]
