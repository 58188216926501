import { CountriesList } from 'components/PhoneInput/CountriesList'
import styles from 'components/PhoneInput/styles.module.scss'
import {
  getInputData,
  handleCountries,
} from 'components/PhoneInput/utils'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { cn } from 'utils'

export const PhoneInput = ({ value, onChange, onValidChange, label, errorMessage, submitted }) => {
  const [countries, setCountries]   = useState([])
  const [isListOpen, setIsListOpen] = useState(false)
  const [isInvalid, setIsInvalid]   = useState(false)

  const rawCountries = useSelector(state => state.config.countries)

  const inputRef = useRef()

  const hasCountries = !!rawCountries?.length

  const { mask, isValid, flag, maxLength, country = {} } = getInputData(value, countries)

  useEffect(() => {
    if (submitted) {
      setIsInvalid(!isValid)
    }

    onValidChange(isValid)
  }, [isValid, submitted])

  useEffect(() => {
    if (hasCountries) {
      const nextCountries = handleCountries(rawCountries)
      setCountries(nextCountries)
      onChange(String(nextCountries[0].phonecode))
    }
  }, [hasCountries])

  useEffect(() => {
    if (value.length > maxLength) {
      onChange(value.slice(0, maxLength - 1))
    }
  }, [value.length, maxLength])

  const onCountryChange = useCallback(country => {
    setIsListOpen(false)
    onChange(String(country.phonecode))
    inputRef.current.focus()
  }, [])

  const valueHandler = useCallback(value => {
    let currentIndex  = 0
    let replacedIndex = -1

    return mask?.replace(/X/g, (_, idx) => {
      if (currentIndex >= value.length) {
        return 'X'
      }

      replacedIndex = idx

      return value[currentIndex++]
    })
      .substring(0, replacedIndex + 1) || '+'
  }, [mask])

  const onInputChange = useCallback(({ target: { value } }) => {
    if (isInvalid) {
      setIsInvalid(false)
    }

    onChange(value.replace(/\D/g, ''))
  }, [isInvalid])

  return (
    <div className={styles.phoneInput}>
      <label
        htmlFor="phone-input"
        className={styles.label}
      >
        {label}
      </label>
      <div className={cn(styles.inputWrapper, isInvalid && styles.invalid)}>
        <button
          className={cn(styles.activeCountryFlag, isListOpen && styles.open)}
          onClick={() => setIsListOpen(!isListOpen)}
        >
          <img
            src={flag}
            alt="flag"
          />
        </button>
        <input
          id="phone-input"
          type="tel"
          ref={inputRef}
          className={styles.input}
          maxLength={maxLength}
          value={valueHandler(value)}
          onChange={onInputChange}
          onBlur={() => setIsInvalid(!isValid)}
        />
      </div>
      {isInvalid && <small className={styles.errorMessage}>{errorMessage}</small>}
      <CountriesList
        isOpen={isListOpen}
        onClose={() => setIsListOpen(false)}
        onChange={onCountryChange}
        list={countries}
        activeId={country.id}
      />
    </div>
  )
}

PhoneInput.propTypes = {
  errorMessage:  PropTypes.string,
  label:         PropTypes.string,
  onValidChange: PropTypes.func,
  onChange:      PropTypes.func.isRequired,
  value:         PropTypes.string.isRequired,
}

PhoneInput.defaultProps = {
  errorMessage:  'введите корректный номер телефона',
  label:         'Номер телефона',
  onValidChange: () => null,
}
