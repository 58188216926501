import { Api } from 'api/Api'
import { showSuccessPaymentMessage } from 'components/PaymentModal/ByCard/utils'
import { showPaymentHybridModal } from 'components/PaymentModal/HybridModal'
import { buildPaymentError } from 'components/PaymentModal/utils'
import { showErrorModal } from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { STATUSES } from 'constants/statuses'
import { usePaymentsState } from 'hooks/usePaymentsState'
import {
  useCallback,
  useState,
} from 'react'

export const OBSERVE_TIMEOUT = 3000
export const SUCCESS_MESSAGE = 'Платеж прошел успешно!'
export const STATUS_ERROR    = 'error'

export const useOplatiPayment = () => {
  const [paymentLink, setPaymentLink]     = useState('')
  const [statusMessage, setStatusMessage] = useState('')

  const { closeModal, getDetails, isHybridModal } = usePaymentsState()

  const onSuccess = () => {
    closeModal()
    getDetails()
  }

  const checkPaymentStatus = useCallback(async (id, intervalId = 0) => {
    const { messages, status } = await Api.checkOplatiPaymentStatus(id)

    const isError       = status === STATUS_ERROR
    const statusMessage = isError ? messages[0] : SUCCESS_MESSAGE

    if (isError) {
      return setStatusMessage(statusMessage)
    }

    setStatusMessage('')
    setPaymentLink('')
    clearInterval(intervalId)

    return (isHybridModal ? showPaymentHybridModal : showSuccessPaymentMessage)(onSuccess)
  }, [])

  const observePaymentStatus = useCallback(async id => {
    await checkPaymentStatus(id)
    const intervalId = setInterval(() => checkPaymentStatus(id, intervalId), OBSERVE_TIMEOUT)
  }, [])

  const onSubmit = useCallback(async amount => {
    showPreloader()

    try {
      const { data: { qr, id }, messages, status } = await Api.getOplatiPaymentLink({ amount })

      if (status === STATUSES.ERROR) {
        return showErrorModal(buildPaymentError(messages, closeModal))
      }

      setPaymentLink(qr)
      await observePaymentStatus(id)
    } catch {
      showErrorModal(buildPaymentError('Что-то пошло не так...', closeModal))
    } finally {
      hidePreloader()
    }
  }, [])

  return { paymentLink, statusMessage, onSubmit }
}
