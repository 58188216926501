import { ReactComponent as SettingsIcon } from 'assets/images/coupon/settings.svg'
import { useDispatch } from 'react-redux'
import { setIsSettingsOpen } from 'store/coupon/actions'
import styles from './styles.module.scss'

export const CouponSettingsTrigger = () => {
  const dispatch = useDispatch()

  const onOpen = () => dispatch(setIsSettingsOpen(true))

  return (
    <button
      className={styles.settingsBtn}
      onClick={onOpen}
    >
      <SettingsIcon className={styles.icon} />
    </button>
  )
}
