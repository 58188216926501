import { ReactDatePicker } from 'components/DatePicker/lazy'
import {
  useEffect,
  useState,
} from 'react'
import 'react-datepicker/dist/react-datepicker.css'

export const DatePicker = ({ value, onChange }) => {
  const [tempValue, setTempValue] = useState()

  useEffect(() => {
    setTempValue(value)
  }, [value])

  return (
    <section className="date-picker">
      <ReactDatePicker
        inline
        selected={tempValue}
        onChange={setTempValue}
        locale="ru-RU"
        maxDate={new Date()}
      />
      <div className="date-picker__footer">
        <button
          className="ml-button"
          onClick={() => onChange(tempValue)}
        >
          Выбрать
        </button>
        <button
          onClick={() => setTempValue(new Date())}
          className="ml-button date-picker__today-btn"
        >
          Сегодня
        </button>
      </div>
    </section>
  )
}
