import { ByCard } from './ByCard'
import { ByERIP } from './ERIP'
import { ByOplati } from './Oplati'

export const PaymentMethodID = {
  ERIP:   2,
  OPLATI: 18,
  CARD:   17,
}

export const PaymentTab = {
  [PaymentMethodID.ERIP]:   ByERIP,
  [PaymentMethodID.OPLATI]: ByOplati,
  [PaymentMethodID.CARD]:   ByCard,
}

export const buildPaymentError = (message, callback) => ({
  message:   message.toString(),
  submitBtn: { title: 'Продолжить' },
  cancelBtn: { title: 'Служба поддержки', to: '/contacts', callback },
})
