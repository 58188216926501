import { Api } from 'api/Api'
import { ReactComponent as Logo } from 'assets/images/sign-up-modals/logo.svg'
import { Addon } from 'components/Addon'
import { getAddonTitle } from 'components/Addon/utils'
import { Checkbox } from 'components/Checkbox'
import { PasswordInput } from 'components/Field/Password'
import TextInput from 'components/Field/TextInput'
import { PhoneInput } from 'components/PhoneInput'
import { showErrorModal } from 'components/Popup'
import { ModalType } from 'components/SignInModal/utils'
import ConfirmPhoneBlock from 'components/SignUp/ConfirmPhone'
import styles from 'components/SignUp/styles.module.scss'
import {
  getCredentialsFromLS,
  hidePhone,
  initialState,
  removeCredentialFromLS,
  setCredentialsToLS,
  showVerifySuccessModal,
  validatePassword,
} from 'components/SignUp/utils'
import { SignUpBonuses } from 'components/SignUpModals/SignUpForm/Bonuses'
import { isAddonsValid } from 'components/SignUpModals/SignUpForm/utils'
import { SignUpModalWrapper } from 'components/SignUpModals/Wrapper'
import { STATUSES } from 'constants/statuses'
import { useAuthModal } from 'hooks/useAuthModal'
import {
  Component,
  Fragment,
} from 'react'
import {
  connect,
  useDispatch,
} from 'react-redux'
import { setModalKey } from 'store/new-sign-up/actions'
import {
  setSignUpStart,
  setSignUpStartError,
} from 'utils/google-analytics/EcommerceGTM'
import { isBoolean } from 'utils/guards'
import { withNavigate } from 'utils/withNavigate'

const SignInBtn = () => {
  const dispatch      = useDispatch()
  const openAuthModal = useAuthModal()

  const onClick = () => {
    openAuthModal(ModalType.DEFAULT, { onSuccess: () => dispatch(setModalKey('')) })
  }

  return (
    <button
      onClick={onClick}
      className={styles.signInBtn}
    >
      Уже есть аккаунт&nbsp;
      <span>Войти</span>
    </button>
  )
}

class SignUpNew extends Component {
  state = { ...initialState }

  async componentDidMount() {
    const { login, phone, ttl } = getCredentialsFromLS()

    if (phone && ttl && !login) {
      const { data: { ttl = 0 } } = await Api.canGenerateCode(phone)
      this.setState({ ttl, isFirstStep: false, phone })
    } else {
      this.setState({ isFirstStep: true })
    }
  }

  componentDidUpdate() {
    const { addons }       = this.props
    const { addonsValues } = this.state

    if (!addonsValues && addons.length) {
      this.setState({ addonsValues: Addon.configureValues(addons) })
    }
  }

  resetState = () => {
    removeCredentialFromLS()
    this.setState({ ...initialState, isFirstStep: true })
  }

  resendCode = async () => {
    const { phone, password } = getCredentialsFromLS()

    const { status, data: { atoken, ttl, message } } = await Api.register({ password, phone, version: 3 })

    if (status === STATUSES.SUCCESS) {
      localStorage.setItem('token', atoken)
      this.setState({ ttl, isFirstStep: false, isTimeoutActive: true })
    } else {
      showErrorModal({ message })
    }
  }

  validatePromocode = async () => {
    const { bonus: { promo_code } } = this.state

    const { status } = await Api.validatePromoCode(promo_code)

    return status === STATUSES.SUCCESS
  }

  onSubmit = async () => {
    this.setState({ isFetching: true })

    const { phone, isPhoneValid, password, email, addonsValues, bonus: { promo_code: promocode } } = this.state

    const isPromocodeValid = await this.validatePromocode()

    if (isPromocodeValid && isPhoneValid && !validatePassword(password) && isAddonsValid(addonsValues)) {
      const signUpData = Addon.configureParams({ password, phone, promocode, version: 3 }, addonsValues)

      const { status, messages, data: { atoken, ttl } = {} } = await Api.register(signUpData)

      if (status === STATUSES.SUCCESS) {
        setSignUpStart()
        setCredentialsToLS({ phone, password, atoken, ttl, email })
        this.setState({ ttl, isFirstStep: false })
      } else {
        showErrorModal({ message: messages.toString() })
        setSignUpStartError()
      }
    }

    this.setState({ isSubmitted: true, isFetching: false, isPromocodeValid })
  }

  onAddonChange = (key, value) => {
    const { addonsValues } = this.state
    this.setState({ addonsValues: { ...addonsValues, [key]: { ...addonsValues[key], value } } })
  }

  onVerifySuccess = userId => showVerifySuccessModal(userId, { submitBtn: { title: 'Перейти к верификации' } })

  render() {
    const { addons, bonuses, onClose } = this.props

    const {
            isFirstStep,
            phone,
            password,
            ttl,
            isTimeoutActive,
            isSubmitted,
            addonsValues = {},
            bonus,
            isPromocodeValid,
            isFetching,
          } = this.state

    return isBoolean(isFirstStep) && (
      <SignUpModalWrapper
        onClose={onClose}
        className={styles.signUpModal}
      >
        <div className={styles.signUpPage}>
          <Logo className={styles.logo} />
          {isFirstStep && (
            <SignUpBonuses
              list={bonuses}
              selected={bonus}
              onChange={bonus => this.setState({ bonus })}
            />
          )}
          <div>
            {!isFirstStep && (
              <>
                <div className="font-18 bold text">Подтверждение телефона</div>
                <div className="gray font-13 mt-4">
                  {`На номер телефона ${hidePhone(phone)} был отправлен код подтверждения`}
                </div>
              </>
            )}
            <section className="register-phone-form">
              {isFirstStep && (
                <div>
                  <PhoneInput
                    value={phone}
                    onChange={phone => this.setState({ phone })}
                    onValidChange={isPhoneValid => this.setState({ isPhoneValid })}
                    submitted={isSubmitted}
                  />
                  <PasswordInput
                    value={password}
                    onChange={password => this.setState({ password })}
                    label="Придумайте пароль"
                    validateFn={validatePassword}
                    submitted={isSubmitted}
                  />
                  <TextInput
                    value={bonus?.promo_code}
                    label="Промокод"
                    validateFn={() => !isPromocodeValid && isSubmitted && 'Промокод не действителен'}
                    disabled
                  />
                  {addons?.map(({ key, name, required, bind }) => {
                    const { value } = addonsValues[key] || {}

                    return (
                      <Checkbox
                        key={name}
                        checked={value}
                        onChange={() => this.onAddonChange(key, !value)}
                        className={styles.addon}
                        invalid={isSubmitted && required && !value}
                        title={getAddonTitle(name, bind)}
                        left
                      />
                    )
                  })}
                  <button
                    className="ml-button mt-25"
                    onClick={this.onSubmit}
                    disabled={isFetching}
                  >
                    Регистрация
                  </button>
                </div>)}
              {isFirstStep ? <SignInBtn /> : (
                <ConfirmPhoneBlock
                  ttl={ttl}
                  resendCode={this.resendCode}
                  promoCode={bonus?.promo_code}
                  isTimeoutActive={isTimeoutActive}
                  withRedirect={false}
                  onSuccess={this.onVerifySuccess}
                  setIsTimeoutActive={isTimeoutActive => this.setState({ isTimeoutActive })}
                >
                  <button
                    className={styles.prevStepBtn}
                    onClick={this.resetState}
                  >
                    Вернуться на предыдущий шаг
                  </button>
                </ConfirmPhoneBlock>
              )}
            </section>
          </div>
        </div>
      </SignUpModalWrapper>
    )
  }
}

const mapStateToProps = state => ({
  addons:  state.config.addons.register,
  bonuses: state.config.new_registration?.bonuses ?? [],
})

export default withNavigate(connect(mapStateToProps)(SignUpNew))
