import { getBoostedCoeff } from 'components/Coupon/TotalCoeff/utils'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const TotalCoeff = ({ value, ...restProps }) => {
  const boostedCoeff = getBoostedCoeff(value, restProps)

  const symCount = value.toString().length
  const fontSize = symCount > 10 && 10 || symCount > 8 && 12 || symCount > 6 && 14 || 16

  return (
    <div
      className={styles.totalCoeff}
      style={{ fontSize }}
    >
      <div
        id="responsive_headline"
        className={cn(boostedCoeff && styles.outdated)}
      >
        {value}
      </div>
      {boostedCoeff && (
        <div className={styles.boosted}>
          {boostedCoeff}
        </div>
      )}
    </div>
  )
}
