import { Api } from 'api/Api'
import TextInput from 'components/Field/TextInput'
import { Page } from 'components/Page'
import { UpdatePassword } from 'components/PasswordRecovery/UpdatePassword'
import {
  CODE_LENGTH,
  infoContents,
  methodHeaders,
  MIN_LOGIN_LENGTH,
  removeTempFromLS,
  tabsList,
} from 'components/PasswordRecovery/utils'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import {
  hidePhone,
  validateEmail,
} from 'components/SignUp/utils'
import Timeout from 'components/Timeout'
import { paths } from 'constants/paths'
import { usePhoneInput } from 'hooks/usePhoneInput'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

export const RecoveryPassword = () => {
  const [activeTab, setActiveTab]             = useState(0)
  const [isTimeoutActive, setIsTimeoutActive] = useState(true)
  const [ttl, changeTtl]                      = useState()
  const [isPasswordForm, setIsPasswordForm]   = useState(false)
  const [email, setEmail]                     = useState('')
  const [userId, setUserId]                   = useState('')
  const [code, setCode]                       = useState('')

  const navigate = useNavigate()

  const resetForm = useCallback(() => {
    removeTempFromLS()
    navigate(paths.FORGOT_PASSWORD)
  }, [])

  const { PhoneInput, phone, setPhone, isPhoneValid } = usePhoneInput()

  const initialTtl = localStorage.getItem('rec_iTtl')

  const isReqSent          = !!initialTtl
  const isConfirmFormValid = code && code.length === CODE_LENGTH

  const sendConfirmCode = () => {
    if (isConfirmFormValid) {
      Api.checkCode({ user_id: localStorage.getItem('uid'), code })
         .then(({ status, data, messages }) => {
          if (status === 'ok') {
            showSuccessModal({ message: data })
            setIsPasswordForm(true)
          } else {
            showErrorModal({ message: messages.toString() })
          }
        })
    }
  }

  const saveTempToLS = activeTab => {
    switch (activeTab) {
      case 0:
        return localStorage.setItem('rec_pe', phone)
      case 1:
        return localStorage.setItem('rec_el', email)
      case 2:
        return localStorage.setItem('rec_uid', userId)
    }
  }

  const loginValidation = value => value.length < MIN_LOGIN_LENGTH ? `не менее ${MIN_LOGIN_LENGTH} символов` : null

  const onActiveTabChange = index => {
    if (!isReqSent) {
      removeNotRelevant(index)
      setActiveTab(index)
    }
  }

  const onConfirmCodeChange = value => {
    if (value.match(/^[0-9]*$/)) {
      setCode(value)
    }
  }

  const getRecoveryValue = (activeTab) => {
    switch (activeTab) {
      case 0:
        return localStorage.getItem('rec_pe') || phone
      case 1:
        return localStorage.getItem('rec_el') || email
      case 2:
        return localStorage.getItem('rec_uid') || userId
    }
  }

  const checkIsRecoveryFormValid = activeTab => {
    switch (activeTab) {
      case 0:
        return isPhoneValid
      case 1:
        return email.length >= MIN_LOGIN_LENGTH
      case 2:
        return userId.length >= MIN_LOGIN_LENGTH
    }
  }

  const isRecoveryFormValid = checkIsRecoveryFormValid(activeTab)

  const removeNotRelevant = activeTab => {
    switch (activeTab) {
      case 0:
        localStorage.removeItem('rec_el')
        localStorage.removeItem('rec_uid')
        setEmail('')
        setUserId('')
        break
      case 1:
        localStorage.removeItem('rec_pe')
        localStorage.removeItem('rec_uid')
        setPhone('')
        setUserId('')
        break
      case 2:
        localStorage.removeItem('rec_pe')
        localStorage.removeItem('rec_el')
        setPhone('')
        setEmail('')
    }
  }

  const onSubmit = async ({ updateReq, newActiveTab }) => {
    if (!updateReq) {
      saveTempToLS(activeTab)
    }

    const currentTab = updateReq ? newActiveTab : activeTab

    const recoveryData = {
      type:  tabsList.find(tab => tab.id === currentTab).recoveryType,
      value: getRecoveryValue(currentTab),
    }

    const { code, data } = await Api.forgotPassword(recoveryData)

    if (!updateReq) {
      if (code === 200) {
        localStorage.setItem('rec_iTtl', data.ttl)
        localStorage.setItem('uid', data.userId)

        if (activeTab === 0) {
          localStorage.setItem('rec_val', hidePhone(data.phone))
          localStorage.setItem('rec_trg', 'номер телефона')
        }
        if (activeTab === 1) {
          localStorage.setItem('rec_val', data.email)
          localStorage.setItem('rec_trg', 'Ваш e-mail')
        }
        if (activeTab === 2) {
          localStorage.setItem('rec_val', data.phone ? hidePhone(data.phone) : data.email)
          localStorage.setItem('rec_trg', data.phone ? 'номер телефона' : 'Ваш e-mail')
        }

        changeTtl(data.ttl)
        setIsTimeoutActive(true)

        showSuccessModal({ message: data.messages.toString() })
      } else {
        showErrorModal({ message: data.messages?.join('') || data.message || data[0] || 'Повторите запрос позже.' })
      }
    } else {
      changeTtl(data.ttl)
      setIsTimeoutActive(true)
    }
  }

  const tabControls = tabsList.map((tab, index) => (
    <div
      key={index}
      onClick={onActiveTabChange.bind(this, index)}
      className={`tab ${index === activeTab ? 'active' : ''}`}
      style={{ width: `${100 / tabsList.length}%` }}
      data-tab={`#tab-${index}`}
    >
      {tab.title}
    </div>
  ))

  useEffect(() => {
    if (localStorage.getItem('rec_pe')) {
      setPhone(localStorage.getItem('rec_pe'))
      setActiveTab(0)
      onSubmit({ updateReq: true, newActiveTab: 0 })
    } else if (localStorage.getItem('rec_el')) {
      setEmail(localStorage.getItem('rec_el'))
      setActiveTab(1)
      onSubmit({ updateReq: true, newActiveTab: 1 })
    } else if (localStorage.getItem('rec_uid')) {
      setUserId(localStorage.getItem('rec_uid'))
      setActiveTab(2)
      onSubmit({ updateReq: true, newActiveTab: 2 })
    }
  }, [])

  const recValue = localStorage.getItem('rec_val') || ''

  const isResettable = !isPasswordForm && isReqSent

  return (
    <Page
      title={isResettable ? 'Назад' : 'Восстановление пароля'}
      {...(isResettable && { onGoBack: resetForm })}
      className="password-recovery-page"
    >
      {!isReqSent && (
        <div className="form-tabs">
          {tabControls}
        </div>
      )}
      <section className="password-recovery-form">
        <div className="top-block">
          {!isPasswordForm && (
            <>
              {!isReqSent ? (
                <>
                  <div className="font-13 lh-16 gray">
                    Для смены пароля введите свой {infoContents[activeTab]}
                  </div>
                  {activeTab === 0 && PhoneInput}
                  {activeTab === 1 &&
                    <TextInput
                      label="E-mail"
                      value={email}
                      onChange={value => setEmail(value)}
                      validateFn={validateEmail}
                    />}
                  {activeTab === 2 &&
                    <TextInput
                      label="Номер счёта"
                      value={userId}
                      onChange={value => {
                        if (value.match(/^[0-9]*$/)) {
                          setUserId(value)
                        }
                      }}
                      validateFn={loginValidation}
                    />}
                  <button
                    className="ml-button"
                    onClick={onSubmit}
                    disabled={!isRecoveryFormValid}
                  >
                    Далее
                  </button>
                </>
              ) : (
                <>
                  <div>
                    <div className="font-18 bold">{methodHeaders[activeTab]}</div>
                    <div className="font-13 lh-16 gray mt-4">
                      {`На ${localStorage.getItem('rec_trg')} ${recValue} был отправлен код подтверждения`}
                    </div>
                  </div>
                  <div className="confirm-code-container">
                    <TextInput
                      placeholder="Код подтверждения"
                      value={code}
                      type="text"
                      maxLength={CODE_LENGTH}
                      onChange={onConfirmCodeChange}
                      validateFn={value => value.length === CODE_LENGTH ? null :
                        `код должен быть не короче ${CODE_LENGTH} цифр`}
                    />
                  </div>
                  <button
                    className="ml-button"
                    onClick={sendConfirmCode}
                    disabled={!isConfirmFormValid}
                  >
                    Подтвердить
                  </button>
                </>
              )}
            </>
          )}
          {isPasswordForm && <UpdatePassword code={code} />}
          {!isPasswordForm && isReqSent && ttl && (
            <Timeout
              isActive={isTimeoutActive}
              setIsActive={setIsTimeoutActive}
              duration={ttl}
              initialDuration={initialTtl}
              onRefresh={() => onSubmit({ tabOfLastSent: activeTab })}
            />
          )}
        </div>
      </section>
    </Page>
  )
}
