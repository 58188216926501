import considered from 'assets/images/svg/considered.svg'
import loss from 'assets/images/svg/loss.svg'
import not_calculated from 'assets/images/svg/not_calculated.svg'
import returned from 'assets/images/svg/return.svg'
import win from 'assets/images/svg/win.svg'
import { paths } from 'constants/paths'
import { format } from 'date-fns'
import TEXT from 'store/constants/text'

export const BetStatus = {
  NOT_CALC: 0,
  LOSE:     1,
  WIN:      2,
  RETURNED: 3,
  VIP:      4,
  REJECTED: 5,
  ACCEPTED: 6,
}

export const INITIAL_PERIOD      = 0
export const INITIAL_TYPE        = 2
export const INITIAL_FILTER      = null
export const INITIAL_IS_NOT_CALC = false

export const tabs = [
  { title: 'Спорт', link: paths.BETS_HISTORY },
  { title: 'Казино', link: paths.CASINO_HISTORY },
  { title: 'Платежи', link: paths.PAYMENTS_HISTORY },
  { title: 'Прогнозы', link: paths.FORECASTS_HISTORY },
]

export const getBetDetails = ({ team1, team2, description, status, type } = {}, { event_id } = {}) => {
  const isActiveLive = +type === 1 && (+status === 1 || +status === 2)
  const isActiveLine = +type === 0 && +status === 2
  const isLink       = isActiveLive || isActiveLine

  return {
    teams:      `${team1}${team2 ? ` - ${team2}` : ''}`,
    highlights: JSON.parse(description || '{}').highlight,
    link:       isLink ? `/${isActiveLive ? 'live' : 'line'}-event/${event_id}` : '',
  }
}

export const getBetTime = time => format(time, 'dd.MM.yy HH:mm:ss')

export const toBYN = (number = 0) => `${number ? number.toFixed(2) : 0} BYN`

export const getBetStyles = status => {
  let color
  let text
  let img

  switch (status) {
    case 0:
      color = ''
      text  = TEXT.account.betHistory.notCalculated
      img   = not_calculated
      break
    case 1:
      color = 'red'
      text  = TEXT.account.betHistory.lost
      img   = loss
      break
    case 2:
      color = 'green'
      text  = TEXT.account.betHistory.win
      img   = win
      break
    case 3:
      color = 'gray'
      text  = TEXT.account.betHistory.return
      img   = returned
      break
    case 4:
      color = 'amber'
      text  = TEXT.account.betHistory.considered
      img   = considered
      break
    case 5:
      color = 'gray'
      text  = TEXT.account.betHistory.return
      img   = returned
      break
  }

  return {
    color,
    text,
    img,
  }
}

export const getBoosterValue = (info, status) => {
  const value = info?.booster_amount

  if (+value > 0 && (status === BetStatus.WIN || status === BetStatus.NOT_CALC)) {
    return `+${toBYN(value)}`
  }
}
