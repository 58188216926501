import { Api } from 'api/Api'
import { STATUSES } from 'constants/statuses'
import { MaxlinePaysTypes } from 'store/maxline-pays/types'

export const setMaxlinePays = payload => ({
  type: MaxlinePaysTypes.SET,
  payload,
})

export const getMaxlinePays = () => async dispatch => {
  try {
    const { status, data } = await Api.getMaxlinePayReturn()

    if (status === STATUSES.SUCCESS) {
      const { total, ...eventsObj } = data

      const bets = Object.values(eventsObj || {})
      dispatch(setMaxlinePays({ total, bets, count: bets.length }))
    }
  } catch (error) {
    console.error(error)
  }
}

