import { BetItemFooter } from './Footer'
import { BetItemHeader } from './Header'
import { BetItemMain } from './Main'
import styles from './styles.module.scss'

export const BetItem = ({ bet, onRefresh }) => (
  bet.status !== 10 && (
    <div className={styles.betItem}>
      <BetItemHeader {...bet} />
      <BetItemMain
        bet={bet}
        onRefresh={onRefresh}
      />
      <BetItemFooter
        bet={bet}
        onRefresh={onRefresh}
      />
    </div>
  )
)
