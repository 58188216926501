import { Show } from 'components/Show'
import { ModalType } from 'components/SignInModal/utils'
import { useAuthModal } from 'hooks/useAuthModal'
import { useDelayedState } from 'hooks/useDelayedState'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { AdditionalInfo } from 'pages/Home/components/Event/AdditionalInfo'
import { COEFF_WRAPPER_ID } from 'pages/Home/components/Event/Coefficient'
import { CoefficientGroup } from 'pages/Home/components/Event/Coefficient/Group'
import { EventHeader } from 'pages/Home/components/Event/Header'
import { EventLink } from 'pages/Home/components/Event/Link'
import { Score } from 'pages/Home/components/Event/Score'
import styles from 'pages/Home/components/Event/styles.module.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  addToCoupon,
  delCoupon,
  makeOneClickBet,
} from 'store/coupon/actions'
import { cn } from 'utils'

const Event = ({
  addToCoupon,
  removeFromCoupon,
  isFinished,
  coeffs,
  isAuth,
  makeOneClickBet,
  isset,
  isOneClickBets,
  leagueName,
  nested,
  to,
  results: { turn, seconds, points, timer_direction, score: { main, periods } = {} },
  event:   { id, teams, time, sportId, isLive, hasTvLink, isDraw, type, link, needNotification },
}) => {
  const openAuthModal = useAuthModal()

  const [isMainChanged, setIsMainChanged] = useDelayedState(5000)

  useDidUpdate(() => {
    setIsMainChanged()
  }, [JSON.stringify(main)])

  const onChangeCoupon = event => {
    event.preventDefault()

    const { hash, eventId } = event.target.closest(`#${COEFF_WRAPPER_ID}`)?.dataset || {}

    if (!hash || !eventId) {
      return
    }

    if (!isAuth) {
      return openAuthModal(ModalType.UNAUTHORIZED)
    }

    if (isOneClickBets && isLive) {
      return makeOneClickBet({ e: eventId, f: hash })
    }

    if (!!isset[eventId]?.[hash]) {
      return removeFromCoupon(type, eventId)
    }

    return addToCoupon(type, eventId, hash)
  }

  const isGoalAnimation = needNotification && isMainChanged

  return (
    <Link
      to={to || link}
      className={cn(styles.event, nested && styles.nested)}
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <Show when={isLive}>
            <EventHeader
              id={id}
              hasTvLink={hasTvLink}
              leagueName={leagueName}
            />
          </Show>
          <div className={styles.main}>
            <EventLink
              sportId={sportId}
              live={isLive}
              teams={teams}
              turn={turn}
            />
          </div>
        </div>
        <AdditionalInfo
          live={isLive}
          startedAt={time}
          goal={isGoalAnimation}
          timeDirection={timer_direction}
          seconds={seconds}
          finished={isFinished}
        />
        <Show when={isLive}>
          <Score
            main={main}
            periods={periods}
            points={points}
            reverse={+sportId === 3}
          />
        </Show>
      </div>
      <div
        className={styles.coefficients}
        onClick={onChangeCoupon}
      >
        <CoefficientGroup
          coeffs={coeffs}
          eventId={id}
          draw={isDraw}
          isset={isset}
        />
      </div>
    </Link>
  )
}

Event.propTypes = {
  addToCoupon:      PropTypes.func.isRequired,
  removeFromCoupon: PropTypes.func.isRequired,
  event:            PropTypes.object.isRequired,
  results:          PropTypes.object.isRequired,
  isFinished:       PropTypes.bool.isRequired,
  coeffs:           PropTypes.array.isRequired,
  isAuth:           PropTypes.bool.isRequired,
  makeOneClickBet:  PropTypes.func.isRequired,
  isset:            PropTypes.object.isRequired,
  isOneClickBets:   PropTypes.bool.isRequired,
  leagueName:       PropTypes.string,
  nested:           PropTypes.bool,
}

Event.defaultProps = {
  nested:     false,
  leagueName: '',
}

const mapStateToProps = state => ({
  isAuth:         state.auth?.isAuth,
  isset:          state.coupon?.isset || {},
  isOneClickBets: state.oneClickBets.isActive,
})

const mapDispatchToProps = {
  addToCoupon,
  removeFromCoupon: delCoupon,
  makeOneClickBet,
}

export default connect(mapStateToProps, mapDispatchToProps)(Event)
