import { Page } from 'components/Page'
import {
  HASH_KEY,
  PAYMENT_COMPLETED,
  PaymentPending,
} from 'pages/SuccessPay/utils'
import React, { useEffect } from 'react'
import {
  Link,
  useSearchParams,
} from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SuccessPayPage = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const payload = searchParams.get(HASH_KEY)
    window.parent.postMessage(JSON.stringify({ action: PAYMENT_COMPLETED, payload }), '*')
  }, [])

  const { title, subtitle, confirmBtn, secondaryBtn, icon: Icon, className } = PaymentPending

  return (
    <Page
      bg="gray"
      className={cn(styles.successPay, className)}
    >
      <div className={styles.status}>
        <Icon className={styles.statusIcon} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      {confirmBtn && (
        <Link
          to={confirmBtn.to}
          className="ml-button mt-22"
        >
          {confirmBtn.title}
        </Link>
      )}
      {secondaryBtn && (
        <Link
          to={secondaryBtn.to}
          className={styles.secondaryBtn}
        >
          {secondaryBtn.title}
        </Link>
      )}
    </Page>
  )
}
