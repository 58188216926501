import React from 'react'

export const MIN_LOGIN_LENGTH = 5
export const CODE_LENGTH      = 7

export const tabsList = [
  {
    id:           0,
    title:        'по телефону',
    recoveryType: 1,
  },
  {
    id:           1,
    title:        'по e-mail',
    recoveryType: 2,
  },
  {
    id:           2,
    title:        'по № счёта',
    recoveryType: 3,
  },
]

export const removeTempFromLS = () => {
  localStorage.removeItem('rec_pe')
  localStorage.removeItem('rec_el')
  localStorage.removeItem('rec_uid')
  localStorage.removeItem('rec_iTtl')
  localStorage.removeItem('uid')
  localStorage.removeItem('rec_val')
  localStorage.removeItem('rec_trg')
}

export const infoContents = {
  0: 'номер телефона, указанный при регистрации',
  1: 'e-mail, указанный при регистрации',
  2: 'номер счёта',
}

export const methodHeaders = {
  0: 'Подтверждение телефона',
  1: 'Подтверждение e-mail',
  2: 'Подтверждение телефона',
}
