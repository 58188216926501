import { Highlighted } from 'components/Highlighted'
import styles from 'components/Highlights/Card/styles.module.scss'
import { HighlightPlayer } from 'components/Highlights/Player'
import { useModal } from 'hooks/useModal'

export const HighlightCard = ({ description, team1, team2, searchString }) => {
  const { highlight, highlight_thumbnail } = description || {}

  const { openModal } = useModal('highlight')

  const handleClick = () => openModal(<HighlightPlayer src={highlight} />)

  const teams = `${team1}${team2 ? ` - ${team2}` : ''}`
  return (
    <div
      className={styles.highlightsCard}
      onClick={handleClick}
    >
      <img
        className={styles.img}
        src={highlight_thumbnail}
        alt={`${team1} ${team2}`}
      />
      <div className={styles.teams}>
        {searchString ? (
          <Highlighted
            source={teams}
            subStr={searchString.trim()}
          />
        ) : teams}
      </div>
    </div>
  )
}
