import { useDelayedState } from 'hooks/useDelayedState'
import { useDidUpdate } from 'hooks/useDidUpdate'

const DELAY = 1500

export const useScoreUpdating = (value, condition = true) => {
  const [isUpdating, toggle] = useDelayedState(DELAY)

  useDidUpdate(() => {
    if ((+value > 0 || value === 'AVG') && condition) {
      toggle()
    }
  }, [value])

  return isUpdating
}
