import { Api } from 'api/Api'
import { showErrorModal } from 'components/Popup'
import ACTIONS from 'store/constants/actions'
import { getCoupon } from 'store/coupon/actions'
import { getInfo } from 'store/user/actions'

export const getCashOut = () => dispatch => {
  Api.getCashOut()
    .then(({ data }) => dispatch({ type: ACTIONS.CASHOUT.GET, data }))
    .catch(console.error)
}

export const takeCashOut = (bet_id, cashout, onSuccess) => async dispatch => {
  dispatch({ type: ACTIONS.CASHOUT.FETCHING, bet_id })

  const { status, messages = [] } = await Api.takeCashOut(bet_id, cashout)

  if (status === 'ok') {
    dispatch({ type: ACTIONS.CASHOUT.SUCCESS, bet_id })
    dispatch(getInfo())
    onSuccess?.()
  } else {
    showErrorModal({
      title:     messages.toString() || 'Кэшаут взять не удалось',
      message:   'Попробуйте повторить',
      submitBtn: {
        title: 'Продолжить',
      },
    })

    dispatch({ type: ACTIONS.CASHOUT.FAILURE, data: messages[0], bet_id })
  }

  dispatch(getCoupon('live'))
}
