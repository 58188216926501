import {
  getBetStyles,
  getBetTime,
} from 'pages/UserHistoryPage/utils'
import React from 'react'
import styles from './styles.module.scss'

export const BetItemHeader = ({ ts, status, count_bets, type, number, isVip } = {}) => {
  const { img } = getBetStyles(status)

  const betType = count_bets === 1 && 'Одиночная' || type

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <img
          src={img}
          className={styles.statusImg}
          alt="Bet status"
        />
        <div>{`${betType} №${number}`}</div>
      </div>
      <div className={styles.right}>
        {isVip && <div className={styles.vip} />}
        <div className={styles.time}>{getBetTime(new Date(ts * 1000))}</div>
      </div>
    </div>
  )
}
