import { BONUS_REGEXP } from 'components/Coupon/Booster/Message/utils'
import styles from './styles.module.scss'

export const Message = ({ value }) => {
  if (!value) {
    return null
  }

  const bonus = value.match(BONUS_REGEXP)?.[1]
  const text  = value.replace(BONUS_REGEXP, '')

  return (
    <div>
      <span className={styles.text}>{text}</span>
      &nbsp;
      {bonus && <span className={styles.bonus}>{bonus}</span>}
    </div>
  )
}
