import { Api } from 'api/Api'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import {
  showForecastErrorModal,
  showForecastSuccessModal,
} from 'pages/Forecast/modals'
import ACTIONS from 'store/constants/actions'
import { setPaymentsState } from 'store/payments/actions'

export const getForecastHistory = (forecasts) => dispatch => {
  dispatch({
    type:              ACTIONS.HISTORY_FORECAST.GET,
    history_forecasts: forecasts,
  })
}

export const changeSelected = (data) => dispatch => {
  dispatch({
    type: ACTIONS.FORECAST.CHANGE.SELECTED,
    data: data,
  })
}

export const setRandom = payload => ({ type: ACTIONS.FORECAST.CHANGE.RANDOM, payload })

export const clear = () => dispatch => dispatch({ type: ACTIONS.FORECAST.CLEAR })

export const getForecast = (type) => dispatch => {
  dispatch({ type: ACTIONS.FORECAST.CHANGE.LOADING, payload: true })

  showPreloader()

  Api.getForecast(type).then(({ status, data }) => {
    if (status === 'ok') {
      dispatch({ type: ACTIONS.FORECAST.GET.SUCCESS, data })
    }
  }).finally(() => {
    dispatch({ type: ACTIONS.FORECAST.CHANGE.LOADING, payload: false })
    hidePreloader()
  })
}

export const getWinners = () => dispatch => {
  dispatch({ type: ACTIONS.FORECAST.CHANGE.LOADING, payload: true })

  showPreloader()

  Api.getWinners().then((resp) => {
    if (resp.status === 'error') {
      return
    }

    dispatch({
      type: ACTIONS.FORECAST.GET_WINNERS.SUCCESS,
      data: resp.data && !!resp.data.length ? resp.data : [],
    })
  }).finally(() => {
    dispatch({ type: ACTIONS.FORECAST.CHANGE.LOADING, payload: false })
    hidePreloader()
  })
}

export const makeForecast = type => (dispatch, getState) => {
  const { forecast: { selected, forecast: { id } } } = getState()

  const data = {
    forecast_id: id || 0,
    ...Object.entries(selected).reduce((acc, [id, result]) => ({ ...acc, [`outcomes[${id}]`]: result }), {}),
  }

  showPreloader()

  Api.makeForecast(data).then(({ status, data }) => {
    hidePreloader()

    if (status === 'error') {
      const openPaymentModal = () => dispatch(setPaymentsState({ isVisible: true }))
      return showForecastErrorModal(data?.messages, openPaymentModal)
    }

    dispatch(getForecast(type))

    showForecastSuccessModal()
  })
}
