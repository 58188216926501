import { Filters } from 'components/Casino/Filters/Filters'
import { appendStaticFilters } from 'components/Casino/SelectBlock/Categories/utils'
import { Select } from 'components/Casino/SelectBlock/Select'
import {
  CasinoFilter,
  useGamesFilter,
  useSelectContext,
} from 'components/Casino/SelectBlock/utils'
import SwipeModal from 'components/SwipeModal'
import { categoriesToFilters } from 'pages/Casino/utils'
import { useSelector } from 'react-redux'
import {
  selectCategories,
  selectCounters,
} from 'store/casino/selectors'

export const CategoriesSelect = () => {
  const [activeID, setFilter]         = useGamesFilter(CasinoFilter.CATEGORY)
  const { isOpen, onChange, onClose } = useSelectContext(CasinoFilter.CATEGORY)

  const categories = useSelector(selectCategories)
  const counters   = useSelector(selectCounters)

  const filters  = appendStaticFilters(categoriesToFilters(categories), counters)
  const category = filters.find(({ id }) => String(id) === String(activeID))

  const onClick = category => {
    setFilter(category)
    onClose()
  }

  return (
    <Select
      title="Категория"
      subTitle={category?.name}
      onClick={() => onChange(CasinoFilter.CATEGORY)}
    >
      <SwipeModal
        title="Категория"
        isOpen={isOpen}
        onClose={onClose}
        className="casino-swipe-modal"
      >
        <Filters
          onClick={onClick}
          items={filters}
          activeId={activeID}
        />
      </SwipeModal>
    </Select>
  )
}
