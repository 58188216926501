import { Progress } from 'components/Progress'
import { useWagerBonus } from 'hooks/useWagerBonus'
import styles from './styles.module.scss'

export const WagerProgress = () => {
  const { isActive, bynLeft, percent } = useWagerBonus()

  return isActive && (
    <div className={styles.wagerProgress}>
      <div className={styles.header}>
        <div>{`Осталось отыграть: ${bynLeft} BYN`}</div>
        <div>{`${percent}%`}</div>
      </div>
      <Progress
        value={percent}
        className={styles.progress}
      />
    </div>
  )
}
