import { SearchSports } from 'components/SearchSports'
import { Interval } from 'pages/Home/utils'
import { LeaguesList } from 'pages/Results/live/leagues-list'
import { prepareSportsList } from 'pages/Results/utils'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  connect,
  useSelector,
} from 'react-redux'
import { getResultLive } from 'store/result-live/actions'
import { selectResultsLive } from 'store/result-live/selectors'
import { SportPicker } from '../line/SportPicker'

const ResultsLive = ({ getResultLive }) => {
  const [sportId, setSportId]             = useState('')
  const [isModalOpened, setIsModalOpened] = useState(false)

  const { events, leagues, sports } = useSelector(selectResultsLive)

  const popSports = prepareSportsList(sports)

  useEffect(() => {
    if (!sportId && popSports.length) {
      setSportId(popSports[0].id)
    }
  }, [popSports.length])

  useEffect(() => {
    getResultLive()

    const interval = setInterval(getResultLive, Interval.LIVE)

    return () => clearInterval(interval)
  }, [])

  const leaguesData = leagues
    .filter(league => +sportId === +league.sportId)
    .sort((left, right) => left.name.localeCompare(right.name))

  const onChangeSport = useCallback(id => {
    setSportId(id)
    setIsModalOpened(false)
  }, [])

  return (
    <>
      <SportPicker
        list={popSports}
        active={sportId}
        onChange={onChangeSport}
        onShowAll={() => setIsModalOpened(true)}
      />
      {!!leaguesData.length && (
        <LeaguesList
          leagues={leaguesData}
          events={events}
        />
      )}
      <SearchSports
        open={isModalOpened}
        sports={sports}
        activeSportId={sportId}
        onCancel={() => setIsModalOpened(false)}
        onSelect={onChangeSport}
      />
    </>
  )
}

const mapDispatchToProps = {
  getResultLive,
}

export default connect(null, mapDispatchToProps)(ResultsLive)
