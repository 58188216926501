import { ReactComponent as Spinner } from 'assets/images/svg/spinner.svg'
import { useState } from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SubmitBtn = ({ children, onClick, stopPropagation, className, ...restProps }) => {
  const [isFetching, setIsFetching] = useState(false)

  const onSubmitHandler = async event => {
    if (stopPropagation) {
      event.stopPropagation()
    }

    setIsFetching(true)

    try {
      return await onClick()
    } catch {
      return null
    } finally {
      setIsFetching(false)
    }
  }

  return (
    <button
      onClick={onSubmitHandler}
      className={cn(styles.submitBtn, className)}
      data-fetching={isFetching}
      {...restProps}
    >
      <div className={styles.content}>{children}</div>
      {isFetching && <Spinner className={styles.spinner} />}
    </button>
  )
}