import top from 'assets/images/sports-new/0.svg'
import video from 'assets/images/sports-new/110.svg'
import favorites from 'assets/images/sports/01.svg'
import allSports from 'assets/images/sports/all-sports.svg'
import all from 'assets/images/sports/all.svg'
import betOnYour from 'assets/images/sports/bet-on-your.svg'
import by from 'assets/images/sports/by.svg'
import collections from 'assets/images/sports/collections.svg'
import margin0 from 'assets/images/sports/margin0.svg'
import pop from 'assets/images/sports/pop.svg'
import allTopExpress from 'assets/images/top-express/ticket.svg'
import PropTypes from 'prop-types'
import React from 'react'
import { cn } from 'utils'

const imagesURI = 'https://cdn-01.maxline.by/images/'

const getIconPath = ({ sportId, championship }) => `${imagesURI}${championship
  ? `championship/${sportId}.png`
  : `sport-icons/svg/${sportId}.svg`}`

const icons = {
  by,
  top,
  favorites,
  margin0,
  video,
  pop,
  collections,
  all,
  allTopExpress,
  'bet-on-your': betOnYour,
  'all-sports':  allSports,
}

const getSrc = (sportId, championship) => {
  const staticSrc = icons[`${championship ? 'championship' : ''}${sportId}`]

  return staticSrc || getIconPath({ sportId, championship })
}

const SportIcon = ({ id, size, championship, className }) => (
  <img
    width={size}
    height={size}
    src={getSrc(id, championship)}
    alt={`sport-${id}`}
    className={cn(className)}
  />
)

SportIcon.propTypes = {
  size:   PropTypes.number,
  imgSrc: PropTypes.string,
  id:     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SportIcon.defaultProps = {
  size:   20,
  imgSrc: '',
  id:     1,
}

export default SportIcon
