import { centrifugoContext } from 'components/Centrifugo/utils'
import { useAuth } from 'hooks/useAuth'
import {
  useContext,
  useEffect,
  useState,
} from 'react'
import { Centrifugo } from 'utils/centrifugo'
import { fillTemplate } from 'utils/strings'

export const useCentrifugoSub = (rawChannel, params, onChange) => {
  const [data, setData] = useState({})

  const centrifugo = useContext(centrifugoContext)

  const channel = fillTemplate(rawChannel, params, true)

  useEffect(() => {
    if (centrifugo && channel) {
      centrifugo.subscribe(channel, ({ data }) => {
        setData(data)
        onChange?.(data)
      })

      return () => {
        centrifugo.unsubscribe(channel)
      }
    }
  }, [centrifugo, channel])

  return data
}

export const CentrifugoProvider = ({ children }) => {
  const [value, setValue] = useState(null)

  const isAuth = useAuth()

  useEffect(() => {
    const centrifugo = new Centrifugo()
    centrifugo.connect()
    setValue(centrifugo)

    return () => {
      centrifugo?.disconnect()
    }
  }, [isAuth])

  return (
    <centrifugoContext.Provider value={value}>
      {children}
    </centrifugoContext.Provider>
  )
}
