import recentIcon from 'assets/images/casino/recent.svg'
import { paths } from 'constants/paths'

export const DEFAULT_ICON_URL = 'https://cdn-01.maxline.by/images/casino/categories/m/default.png'

export const PopCategory       = { id: 'popular', name: 'Популярные', icon: DEFAULT_ICON_URL }
export const FreeSpinsCategory = { id: 'free-spins', name: 'Фриспины', icon: DEFAULT_ICON_URL }
export const RecentCategory    = { id: 'recent', name: 'Недавние', image: recentIcon, link: paths.CASINO_MY_GAMES }
export const AllFilter         = { id: '-1', name: 'Все' }

export const CasinoActions = {
  GAMES:              'Games',
  SLIDERS:            'Sliders',
  PROVIDERS:          'Providers',
  CATEGORIES:         'Categories',
  THEMES:             'Themes',
  FAVORITES_GET:      'Favorites',
  FAVORITES_SET:      'SetFavorites',
  FAVORITES_CLEAR:    'ClearFavorites',
  CONFIG:             'Config',
  POPULAR:            'Popular',
  MAXLINE_PAY:        'MaxlinePay',
  MAXLINE_PAY_ENROLL: 'MaxlinePayEnroll',
  FREE_SPIN_APP:      'FreespinApp',
  RECENT_GAMES:       'Recent',
}

export const GameTypes = {
  HOME:      1,
  FAVORITES: 2,
  SEARCH:    3,
  LIVE:      4,
  VIRTUAL:   5,
  FREE_SPIN: 6,
  RECENT:    7,
}

export const CasinoEvents = {
  CATEGORIES:      1,
  GAMES:           2,
  LOBBY:           3,
  THEMES:          4,
  FAVORITES_GET:   5,
  FAVORITES_SET:   6,
  FAVORITES_CLEAR: 8,
  PROVIDERS:       7,
  CONFIG:          9,
  SLIDERS:         10,
  POPULAR:         12,
  MAXLINE_PAY:     13,
  FREE_SPIN_APP:   14,
  RECENT_GAMES:    15,
}

export const GameMode = {
  DEMO:      'demo',
  FREE_SPIN: 'freespin',
}
