import styles from 'components/PhoneInput/CountriesList/styles.module.scss'
import { useCheckOutsideClick } from 'hooks/useCheckOutsideClick'
import React, { useRef } from 'react'
import { cn } from 'utils'

export const CountriesList = ({ isOpen, onClose, list, onChange, activeId }) => {
  const countryListRef = useRef()

  useCheckOutsideClick(countryListRef, onClose, isOpen)

  return (
    isOpen &&
    <ul
      className={styles.countryList}
      ref={countryListRef}
    >
      {list.map(country => (
        <li
          role="button"
          onClick={() => onChange(country)}
          className={cn(styles.countryItem, activeId === country.id && styles.active)}
          data-code={`+ ${country.phonecode}`}
        >
          <img
            src={country.flag}
            alt={country.name}
          />
          {country.name}
        </li>
      ))}
    </ul>
  )
}
