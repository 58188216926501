import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'

export const getResultLive = () => dispatch => {
  Api.resultLive().then(resp => {
    dispatch({
      type: ACTIONS.RESULT.GET_LIVE,
      data: resp ? { ...resp.data, sports: resp.data.sports.filter(sport => sport.id !== -1) } : [],
    })
  })
}
