import { Api } from 'api/Api'
import moment from 'moment'
import Event from 'pages/Home/components/Event'
import { getEventProps } from 'pages/Home/components/Event/utils'

export const handleLineByLeague = ({ data: { events = [], factors = {}, clubs } = {} } = {}) => ({
  events: events.sort(({ time: time1 }, { time: time2 }) => moment(time1) - moment(time2)),
  factors,
  clubs,
  isOpen: true,
})

export const renderEvents = ({ events = [], factors, clubs } = {}) => (
  events.map(event => <Event {...getEventProps({ event, factors, clubs })} nested />)
)

export const getLeagueData = async (id, period, betOnOur) => {
  try {
    const leagueData = await Api.getLineByLeague(id, period, betOnOur)

    return handleLineByLeague(leagueData)
  } catch (e) {
    console.error(e)
  }
}
