import ACTIONS from 'store/constants/actions'

const initialState = {
  banks: [],
}

export default function banks(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.BANKS.SET: {
      return [
        ...action.payload,
      ]
    }

    default:
      return state
  }
}
