import ACTIONS from 'store/constants/actions'

const initialState = {
  sports:       [],
  leagues:      [],
  top:          { events: [], factors: {}, leagues: [], results: [], sports: [] },
  express_plus: 0,
  leaguesById:  {},
  slider: [],
}

export const lineReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LINE.GET_LINE:
      return Object.assign({}, state, { ...action.data })

    case ACTIONS.LINE.GET_TOP:
      return { ...state, top: action.payload }

    case ACTIONS.LINE.TOGGLE_EXPRESS_PLUS:
      return Object.assign({}, state, { express_plus: state.express_plus === 0 ? 1 : 0 })

    case ACTIONS.LINE.GET_SPORT_LEAGUES:
      return { ...state, ...action.payload }

    case ACTIONS.LINE.GET_LEAGUES:
      return Object.assign({}, state, { ...action.data })

    case ACTIONS.LINE.LEAGUES_BY_ID:
      return { ...state, leaguesById: { ...state.leaguesById, ...action.payload } }

    case ACTIONS.LINE.LEAGUES_FETCHING:
      return { ...state, isFetching: action.payload }

    case ACTIONS.LINE.SET_SLIDES:
      return { ...state, slider: action.payload }

  }
  return state
}
