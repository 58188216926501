import Loading from 'components/Loading'
import { Page } from 'components/Page'
import styles from 'pages/Statistics/styles.module.scss'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

export const StatisticsPage = () => {
  const [isLoading, setIsLoading] = useState(true)

  const { id, type } = useParams()

  const isLeague = type === 'league'

  return (
    <Page
      title={`Статистика ${isLeague ? 'турнира' : ''}`}
      bg="gray"
    >
      {isLoading && <Loading />}
      <div className={styles.statisticPage}>
        <iframe
          src={`https://maxline.by/${isLeague ? 'stat?a=tt&p=' : 'mstat.php?p='}${id}`}
          className={styles.iframe}
          onLoad={() => setIsLoading(false)}
          data-stat={type}
        />
      </div>
    </Page>
  )
}
