import { ScoreCol } from 'pages/Home/components/Event/Score/Col'
import {
  mainProps,
  pointsProps,
  swapValues,
} from 'pages/Home/components/Event/Score/utils'
import styles from './styles.module.scss'

export const Score = ({ main, periods, points, reverse }) => {
  const [firstValues, lastValues] = swapValues(reverse, main, points)
  const [firstProps, lastProps]   = swapValues(reverse, mainProps, pointsProps)

  return (
    <div className={styles.score}>
      <ScoreCol values={firstValues} {...firstProps} />
      {periods && <div className={styles.divider} />}
      {periods?.map((period, idx) => (
        <ScoreCol
          key={idx}
          className={styles.period}
          values={period}
          label={idx + 1}
        />
      ))}
      {periods && lastValues && <div className={styles.divider} />}
      <ScoreCol values={lastValues} {...lastProps} />
    </div>
  )
}
