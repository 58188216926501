const NaNRegexp = /^(NaN|null|undefined|)$/

const isNumeric = (rawValue) => {
  const value = /^string/.test(typeof rawValue) ? rawValue.replace(/[,]/g, '').trim() : rawValue
  return !NaNRegexp.test(value)
}

const getNumbers = values => values.filter(isNumeric)

export const add = (...values) => getNumbers(values).reduce((acc, cv) => (+acc + +cv).toFixed(2) * 100 / 100, 0)

export const sub = (...values) => {
  const first = values.shift()
  return getNumbers(values).reduce((acc, cv) => (+acc - +cv).toFixed(2) * 100 / 100, first)
}
