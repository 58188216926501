import { popSportIds } from 'constants/popular-sports'
import React from 'react'
import ResultLine from './line'
import ResultsLive from './live'

export const resultPageTabs = [
  { title: 'Live', value: 'live' },
  { title: 'Спорт', value: 'line' },
]

export const ResultScreen = {
  live: <ResultsLive />,
  line: <ResultLine />,
}

export const prepareSportsList = (sports = []) => sports
  .filter(sport => popSportIds.indexOf(+sport.id) !== -1)
  .sort((a, b) => a.id - b.id)

export const handleLineResults = data => {
  const eventsByLeagues = {}

  data.forEach(event => {
    if (eventsByLeagues[event.fullName]) {
      return eventsByLeagues[event.fullName].push(event)
    }

    eventsByLeagues[event.fullName] = [event]
  })

  return eventsByLeagues
}
