import { sq } from 'api/utils'
import { ReactComponent as AppOnlyIcon } from 'assets/images/casino/app-only.svg'
import {
  buildGameURI,
  onError,
} from 'components/Casino/Slots/Game/utils'
import { Tags } from 'components/Casino/Tags'
import { IOS_APP_URL } from 'constants/links'
import { paths } from 'constants/paths'
import {
  isIos,
  isWebView,
} from 'constants/platform'
import { GameMode } from 'pages/Casino/constants'
import { GameParam } from 'pages/Casino/Game/utils'
import { Link } from 'react-router-dom'
import styles from '../styles.module.scss'

export const Game = ({ id, image, name, provider, tags, freeSpin, hasDemo, appOnly }) => {
  const search = sq({
    ...(freeSpin && { [GameParam.MODE]: GameMode.FREE_SPIN }),
    ...(!hasDemo && { [GameParam.NO_DEMO]: 'true' }),
  })

  const isAvailable     = !appOnly || isWebView
  const hasAppOnlyLabel = appOnly && !isWebView

  const uri  = buildGameURI(provider, name, id, search)
  const path = `${paths.CASINO_SLOTS_GAMES}/${uri}`
  const to   = (isAvailable && path) || (isIos && IOS_APP_URL) || null
  const Tag  = to ? Link : 'div'

  return (
    <div className={styles.game}>
      <Tags
        list={tags}
        className={styles.tags}
      />
      <Tag
        className={styles.link}
        title={name}
        {...(to && { to })}
      >
        <img
          className={styles.image}
          src={image}
          alt={name}
          onError={onError}
        />
        {hasAppOnlyLabel && <AppOnlyIcon className={styles.appOnlyIcon} />}
      </Tag>
    </div>
  )
}
