import React from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SwipeModalTrigger = ({ onOpen, value, placeholder }) => (
  <div
    className={styles.swipeModalTrigger}
    onClick={onOpen}
  >
    <div className={cn(!value && 'gray')}>{value || placeholder}</div>
  </div>
)
