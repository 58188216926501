export const settings = {
  dots:           false,
  arrows:         false,
  speed:          500,
  slidesToShow:   3,
  slidesToScroll: 1,
  autoplay:       true,
  autoplaySpeed:  4000,
  pauseOnHover:   true,
  centerMode:     true,
  centerPadding:  10,
  responsive:     [
    {
      breakpoint: 1024,
      settings:   {
        slidesToShow:   2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings:   {
        slidesToShow:   1,
        slidesToScroll: 1,
      },
    },
  ],
}
