import * as Sentry from '@sentry/react'
import pkg from '../../../package.json'
import {
  beforeSend,
  dsn,
  ignoreErrors,
  isProd,
} from './utils'

export const initSentry = () => {
  if (dsn && isProd) {
    try {
      Sentry.init({
        dsn,
        release:                  pkg.version,
        integrations:             [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        beforeSend,
        ignoreErrors,
        sampleRate:               0.01,
        tracesSampleRate:         0.01,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.01,
      })
    } catch (e) {
      console.error(e)
    }
  }
}
