import { Api } from 'api/Api'
import { statIcons } from 'pages/Event/EventById/StatTabs/utils'
import { transliterate } from 'utils'

String.prototype.has = function (searchString = '') {
  return this.toLowerCase().includes(String(searchString).toLowerCase())
}

export const initialState = {
  isTrackerOpened:   false,
  isVideoOpened:     false,
  isMomentOpened:    false,
  isWeatherOpened:   false,
  activeEventIdx:    0,
  activeTab:         0,
  data:              {
    events:          [],
    results:         {},
    factors:         {},
    leagues:         [],
    sports:          [],
    tennisStatistic: {},
  },
  isLoaded:          false,
  favorites:         [],
  allAdditionalBets: [],
  isSearching:       false,
  searchString:      '',
}

export const EXTRA_TIME = 'дополнительное время'

export const statItems = [
  EXTRA_TIME, 'желтые карты', 'жёлтые карты', 'серия пенальти', 'фолы', 'угловые',
  'вброс аутов', 'кол-во 2-мин удалений', 'броски в створ', 'удары в створ',
  'офсайды', 'голы в большинстве', 'овертайм', 'серия буллитов', 'удары от ворот', 'штанги или перекладины',
  'видеопросмотры', 'выход мед. бригады на поле', 'компенсированное время',
  'касания мяча вратарем за пределами штрафной площади',
]

export const linkRegExp           = /http(s?):\/\/[^\s,]+/i
export const statRegExp           = new RegExp(statItems.join('|'), 'g')
export const team2RegExp          = new RegExp(`\\((${statItems.join('|')})\\)`, 'g')
export const textInBracketsRegExp = /\(([^)]+)\)/g

export const getStatIcon = name => statIcons[transliterate(name.match(statRegExp)?.[0])]

const extractName = team2 => team2.match(statRegExp)?.[0]
  || team2.match(textInBracketsRegExp)?.at(-1).replace(/([()])/g, '')

export const getStatName = (team2, idx) => idx ? extractName(team2) : 'Матч'

export const getStatScore = ({ team2 }, result, activeIdx) => {
  const score = activeIdx > 0 && result?.score?.main

  if (score) {
    const icon = getStatIcon(team2)

    return { ...score, ...(icon && { icon }) }
  }
}

export const fetchLiveData = async eventId => {
  const { data: { events, results, factors, ...otherEventData } } = await Api.getLiveData(eventId)

  const children = events[0]?.childs?.join('-')

  const { data: stat } = children ? await Api.getLiveData(children) : initialState

  return {
    data: {
      events:  [...events, ...stat.events],
      results: { ...results, ...stat.results },
      factors: { ...factors, ...stat.factors },
      ...otherEventData,
    },
  }
}

export const fetchLineData = (eventId, expressPlus) => Api.getLineData(eventId, expressPlus)

export const eventFetcher = {
  live: fetchLiveData,
  line: fetchLineData,
}

export const getIsMargin = event => !!(JSON.parse(event || '{}')?.marginZero)

export const highlightQuery = (ref, text, query) => {
  text = String(text)

  if (ref) {
    let nextText = text
    const idx    = text?.toLowerCase().indexOf(query.toLowerCase())

    if (idx >= 0) {
      const originalPart = text.slice(idx, idx + query.length)

      nextText = text.replace(originalPart, `<span class="search-string">${originalPart}</span>`)
    }

    ref.innerHTML = nextText
  }
}

export const getExtraTimeId = events => events.findIndex(({ team2 }) => team2.includes(EXTRA_TIME))

export const getSportColor = ({ color }) => color || '#2A2A2A'

export const MAX_SEARCH_LENGTH = 25

export const getLinkForIframe = (link) => {
  let type = 'default'

  if (link && link.indexOf('youtube') !== -1) {
    type = 'youtube'
    link = link.replace('watch?v=', 'embed/') + '?playsinline=1'
  }

  if (link && link.indexOf('youtu.be') !== -1) {
    type = 'youtube'
    link = link.replace(/youtu.be\//, 'youtube.com/embed/') + '?playsinline=1'
  }

  if (link && link.indexOf('twitch') !== -1) {
    if (link[link.length - 1] === '/') {
      link = link.slice(0, -1)
    }
    type = 'twitch'
    link = link.replace(/www.twitch.tv\//, 'player.twitch.tv/?channel=') + '&parent=mobile.maxline.by'
  }

  if (link?.includes('365livesport')) {
    type = 'live365'
  }

  return [link, type]
}

export const getPlayedMatchesInfo = (playedGames, team1, team2) => ({
  firstTeam:         team1,
  secondTeam:        team2,
  textPlayedMatches: playedGames,
})

export const filterFactors = (eventFactors, activeTab, searchQuery) => {
  let filteredFactors = JSON.parse(JSON.stringify(eventFactors))

  if (!!activeTab) {
    filteredFactors = filteredFactors.filter(({ ft }) => ft.includes(activeTab))
  }

  if (searchQuery.length) {
    filteredFactors = filteredFactors.map(factor => {
      if (factor.name.has(searchQuery)) {
        return factor
      }

      const nextRows = factor.rows.map(row => {
        if (row.name.has(searchQuery)) {
          return row
        }

        const nextValues = row.values.filter(({ name, v, label }) => {
          return `${name}${label ? `(${label})` : ''}`.has(searchQuery) || String(v).has(searchQuery)
        })

        if (nextValues.length) {
          row.values = nextValues

          return row
        }
      }).filter(Boolean)

      if (nextRows.length) {
        factor.rows = nextRows

        return factor
      }

      return null
    }).filter(Boolean)
  }

  return filteredFactors.sort((a, b) => b.sort - a.sort)
}
