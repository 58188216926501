import CashOut from 'components/CashOut'
import {
  BetStatus,
  getBetStyles,
  getBoosterValue,
  toBYN,
} from 'pages/UserHistoryPage/utils'
import { Link } from 'react-router-dom'
import {
  cn,
  round,
} from 'utils'
import { MaxlinePaysReturns } from '../../Footer/MaxlinePaysReturns'
import styles from './styles.module.scss'

export const BetItemModal = ({
  status,
  count_bets,
  value: coeff,
  amount,
  info,
  win,
  bet_id,
  payReturn,
  onRefresh,
  children,
}) => {
  const { img, text, color } = getBetStyles(status)

  const isOpen       = status === BetStatus.NOT_CALC
  const boosterValue = getBoosterValue(info, status)

  return (
    <>
      <div className={styles.infoRow}>
        <div
          className={styles.infoCell}
          data-title="Ставка:"
          data-value={toBYN(amount)}
        />
        <div
          className={styles.infoCell}
          data-title="Коэффициент:"
          data-value={round(coeff)}
        />
        <div
          className={cn(styles.infoCell, color)}
          data-title={(info?.isCashOut && 'CashOut:') || (isOpen && 'Возм. выигрыш:') || 'Выигрыш:'}
        >
          <div className={`bold ${color} lh-16 mt-4`}>{toBYN(isOpen ? (+coeff * +amount) : win)}</div>
          {boosterValue && (
            <div className={styles.booster}>
              <div>{boosterValue}</div>
              <Link
                to="/express-boost"
                className={styles.boosterLink}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.status}>
          <img
            src={img}
            alt="Bet status"
          />
          <div className={color}>{text}</div>
        </div>
        <div className="font-12 lh-14 gray">{`Возможные исходы: ${count_bets}`}</div>
      </div>
      {children}
      <div className={styles.footer}>
        <CashOut
          betId={bet_id}
          onSuccess={onRefresh}
        />
        <MaxlinePaysReturns
          betId={bet_id}
          payReturn={payReturn}
          onSuccess={onRefresh}
        />
      </div>
    </>
  )
}
