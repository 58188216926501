import { ReactComponent as ClosedIcon } from 'assets/images/empty-history/bets-closed.svg'
import { ReactComponent as DefaultDarkIcon } from 'assets/images/empty-history/default-dark.svg'
import { ReactComponent as DefaultIcon } from 'assets/images/empty-history/default.svg'
import { ReactComponent as DiceIcon } from 'assets/images/empty-history/dice.svg'
import { ReactComponent as EventIcon } from 'assets/images/empty-history/empty-events.svg'
import { ReactComponent as FinishIcon } from 'assets/images/empty-history/finish.svg'
import { ReactComponent as ForecastIcon } from 'assets/images/empty-history/forecast.svg'
import { ReactComponent as MapIcon } from 'assets/images/empty-history/map.svg'
import { ReactComponent as MessageIcon } from 'assets/images/empty-history/message.svg'
import { ReactComponent as TopLiveIcon } from 'assets/images/empty-history/top-live.svg'
import { ReactComponent as TopExpressIcon } from 'assets/images/empty-history/topExpress.svg'
import { ReactComponent as TournamentsIcon } from 'assets/images/empty-history/tournaments.svg'
import { ReactComponent as TranslationsIcon } from 'assets/images/svg/video-empty.svg'
import { paths } from 'constants/paths'
import { Link } from 'react-router-dom'

export const placeholders = {
  default:               {
    title:    'Записи не найдены',
    subTitle: 'Попробуйте выбрать другой период',
    Icon:     DefaultIcon,
  },
  bets:                  {
    title:     'Ставки не найдены',
    subTitle:  <span>Измените критерии поиска<br />или сделайте новую ставку</span>,
    link:      {
      to:    '/',
      title: 'Сделать ставку',
    },
    Icon:      DefaultIcon,
    className: 'centered',
  },
  topLive:               {
    title:    'Нет активных ТОП-событий',
    subTitle: 'Попробуйте посмотреть позже',
    Icon:     TopLiveIcon,
  },
  liveBySport:           {
    title:    'События не найдены',
    subTitle: 'Выберите другой вид спорта или попробуйте посмотреть позже',
    Icon:     DefaultIcon,
  },
  casino:                {
    title:     'Ставки не найдены',
    subTitle:  <span>Измените критерии поиска<br />или сделайте новую ставку</span>,
    link:      {
      to:    '/casino',
      title: 'Сделать ставку',
    },
    Icon:      DefaultIcon,
    className: 'centered',
  },
  forecast:              {
    title:     'Прогнозы не найдены',
    subTitle:  <span>Делайте прогнозы каждый день<br />и выигрывайте до 1 000 000 BYN</span>,
    link:      {
      to:    '/forecast/create',
      title: 'Сделать прогноз',
    },
    Icon:      DefaultIcon,
    className: 'centered',
  },
  bonus:                 {
    title:    'Вы еще не получали бонусы',
    subTitle: <span>Посмотрите актуальные предложения в разделе «Бонусы»</span>,
    link:     {
      to:    '/pages/bonuses',
      title: 'Бонусы',
    },
    Icon:     DefaultIcon,
    top:      0,
  },
  paymentHistory:        {
    title:     <span>Отсутствует история операций<br />
                 за указанный период времени</span>,
    subTitle:  'Пополните счёт и начните делать ставки',
    link:      {
      to:    paths.PAYMENTS_PAGE,
      title: 'Пополнить счёт',
    },
    Icon:      DefaultIcon,
    className: 'centered',
  },
  search:                {
    title:    'Поиск не дал результатов',
    subTitle: 'Рекомендуем изменить критерии поиска',
    Icon:     DefaultIcon,
    top:      80,
  },
  league:                {
    title:    'Поиск турнира',
    subTitle: 'Укажите название интересующего турнира',
    top:      148,
  },
  event:                 {
    title:    'Поиск события',
    subTitle: 'Укажите название интересующего события',
    top:      148,
  },
  oddsSearch:            {
    title:     'Ничего не найдено',
    subTitle:  'Рекомендуем изменить критерии поиска',
    Icon:      DefaultIcon,
    className: 'stable',
  },
  resultLineSportFilter: {
    title:     'Поиск не дал результатов',
    subTitle:  'Рекомендуем изменить критерии поиска',
    Icon:      DefaultIcon,
    className: 'stable',
  },
  coupon:                {
    title:    'Купон пуст',
    subTitle: 'Начните делать ставки',
    Icon:     EventIcon,
    top:      -100,
  },
  openBets:              {
    title: 'Нет открытых ставок',
    subTitle:
           <span>
             Ваши предыдущие ставки отображаются
             <br />
             в разделе
             <Link
               className="green ml-5"
               to={paths.BETS_HISTORY}
             >
               История ставок
             </Link>
           </span>,
    Icon:  DefaultIcon,
    top:   -100,
  },
  promoCodes:            {
    title:     'Отсутствует история промокодов',
    subTitle:  'Измените период или посмотрите раздел с бонусами',
    Icon:      DefaultIcon,
    link:      {
      to:    '/pages/bonuses',
      title: 'Перейти к бонусам',
    },
    className: 'stable',
  },
  liveSoonDefault:       {
    title: 'Нет актуальных событий',
    subTitle:
           <span>
             Попробуйте изменить период или выберите<br />
             другой вид спорта
           </span>,
    Icon:  DefaultIcon,
    top:   -100,
  },
  liveSoonTv:            {
    title: <span>На данный момент нет информации<br />
             по каналам воспроизведения</span>,
    subTitle:
           <span>
            Пожалуйста, попробуйте снова воспользоваться<br />
            фильтром в ближайшее время
           </span>,
    Icon:  DefaultIcon,
    top:   -100,
  },
  liveSoonTranslation:   {
    title: <span>На данный момент нет информации<br />
                 по событиям с прямой трансляцией</span>,
    subTitle:
           <span>
            Пожалуйста, попробуйте снова воспользоваться<br />
            фильтром в ближайшее время
           </span>,
    Icon:  DefaultIcon,
    top:   -100,
  },
  liveSoonTvTranslation: {
    title: <span>Нет активных событий по фильтрам:<br />
                 прямые трансляции и TV</span>,
    subTitle:
           <span>
            Пожалуйста, попробуйте снова воспользоваться<br />
            фильтром в ближайшее время
           </span>,
    Icon:  DefaultIcon,
    top:   -100,
  },
  lineBySport:           {
    title:    <span>
                На данный момент нет информации
                <br />
                для просмотра
              </span>,
    subTitle: <span>
                Пожалуйста, попробуйте снова воспользоваться
                <br />
                фильтром в ближайшее время
              </span>,
    Icon:     DefaultIcon,
    top:      25,
  },
  chooseSport:           {
    title:    'Поиск не дал результатов',
    subTitle: 'Рекомендуем изменить критерии поиска',
    Icon:     DefaultIcon,
    top:      72,
  },
  eventFinish:           {
    title:     'Событие завершено',
    subTitle:  'Выберите другое событие',
    link:      {
      to:    '/',
      title: 'Перейти на главную',
    },
    extraLink: {
      to:    '/live/top',
      title: 'Перейти в Live',
    },
    Icon:      FinishIcon,
    top:       -40,
  },
  betsPaused:            {
    title:     'Прием ставок приостановлен',
    subTitle:  'Попробуйте вернуться позже или делайте ставки на другие матчи',
    Icon:      ClosedIcon,
    top:       -54,
    className: 'stable',
  },
  maxlinePayReturn:      {
    title:     'События не найдены',
    subTitle:  'Попробуйте посмотреть позже',
    Icon:      DefaultIcon,
    className: 'stable',
  },
  betOnOur:              {
    title:     'На данный момент нет активных событий белорусских команд',
    subTitle:  'Пожалуйста, попробуйте снова воспользоваться фильтром в ближайшее время',
    Icon:      MapIcon,
    className: 'stable',
  },
  video:                 {
    title:    'Нет активных видеотрансляций',
    subTitle: 'Попробуйте посмотреть позже',
    Icon:     TranslationsIcon,
  },
  messages:              {
    title:    'Сообщения не найдены',
    subTitle: 'Выберите другую категорию или дождитесь новостей от Maxline',
    Icon:     MessageIcon,
    top:      44,
  },
  highlights:            {
    title:     'Топ моменты не найдены',
    subTitle:  'Попробуйте выбрать другую дату или посмотреть позже',
    Icon:      DefaultIcon,
    className: 'stable',
  },
  forecastDone:          {
    title:     'Сегодня Вы уже оставляли прогноз',
    subTitle:  'Информация о Ваших прогнозах отображается в личном кабинете',
    Icon:      ForecastIcon,
    link:      {
      to:    paths.FORECASTS_HISTORY,
      title: 'История прогнозов',
    },
    className: 'stable',
  },
  forecastEmpty:         {
    title:     'На данный момент матчи, участвующие в предложении, отсутствуют',
    subTitle:  'Попробуйте заглянуть позже',
    Icon:      ForecastIcon,
    className: 'stable',
  },
  topExpress:            {
    title:    'На данный момент нет предложений по топ-экспрессам',
    subTitle: 'Попробуйте заглянуть позже',
    Icon:     TopExpressIcon,
  },
  gameNotFound:          {
    title:     'Игра недоступна',
    subTitle:  'Попробуйте перезапустить или выберите другую игру',
    Icon:      DiceIcon,
    link:      {
      to:    '/casino',
      title: 'Вернуться в лобби',
    },
    className: 'dark',
    top:       -36,
  },
  gameNotAuth:           {
    title:     'Игра недоступна',
    subTitle:  'Вам необходимо авторизоваться',
    Icon:      DiceIcon,
    link:      {
      title: 'Регистрация',
      type:  'toSignUp',
    },
    extraLink: {
      title: 'Войти',
      type:  'openAuthModal',
    },
    className: 'dark',
    top:       -36,
  },
  gameUnverified:        {
    title:     'Игра недоступна',
    subTitle:  'Вам необходимо верифицировать аккаунт',
    Icon:      DiceIcon,
    link:      {
      to:    '/user/upload-document',
      title: 'Верификация',
    },
    className: 'dark',
    top:       -36,
  },
  casinoFavorites:       {
    title:     'Список избранного пуст',
    subTitle:  'Добавьте любимые игры для быстрого доступа',
    Icon:      DiceIcon,
    className: 'dark',
  },
  casinoRecent:          {
    title:    'Отсутствует история игр',
    subTitle: 'Начните играть прямо сейчас',
    Icon:     DiceIcon,
    dark:     true,
    link:     {
      to:    paths.CASINO_SLOTS,
      title: 'Играть',
    },
  },
  gamesSearch:           {
    title:     'Поиск не дал результатов',
    subTitle:  'Рекомендуем изменить критерии поиска',
    Icon:      DefaultDarkIcon,
    className: 'dark',
    top:       -46,
  },
  tvBet:                 {
    title:    'Воспользуйтесь фильтром',
    subTitle: 'Для истории операций необходимо сделать ставку',
    Icon:     DefaultIcon,
    link:     {
      title: 'Перейти к TVBet',
      to:    '/casino/tv-games',
    },
  },
  betGames:              {
    title:    'Воспользуйтесь фильтром',
    subTitle: 'Для истории операций необходимо сделать ставку',
    Icon:     DefaultIcon,
    link:     {
      title: 'Перейти к BetGames',
      to:    '/casino/tv-games',
    },
  },
  news:                  {
    title:    'Запрашиваемая новость не найдена',
    subTitle: 'Произошла ошибка при запросе, или ссылка недействительна',
    Icon:     DefaultIcon,
    link:     {
      title: 'К списку новостей',
      to:    '/news/site',
    },
  },
  tournaments:           {
    title:     'Ожидание первых результатов',
    Icon:      TournamentsIcon,
    className: 'stable',
  },
}

