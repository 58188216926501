import { CategoriesSelect } from 'components/Casino/SelectBlock/Categories'
import { ProvidersSelect } from 'components/Casino/SelectBlock/Providers'
import { ThemesSelect } from 'components/Casino/SelectBlock/Themes'
import {
  CasinoFilter,
  SelectContextProvider,
} from 'components/Casino/SelectBlock/utils'
import { useState } from 'react'

export const SelectBlock = () => {
  const contextValue = useState(CasinoFilter.NONE)

  return (
    <div className="select-block">
      <SelectContextProvider value={contextValue}>
        <CategoriesSelect />
        <ThemesSelect />
        <ProvidersSelect />
      </SelectContextProvider>
    </div>
  )
}
