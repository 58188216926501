import ACTIONS from 'store/constants/actions'

const initialState = {}

export default function liveBySport(state = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.LIVE.GET_BY_SPORT:
      return { ...state, [payload.sportId]: { ...(state?.[payload.sportId] || {}), ...payload.data } }

    default:
      return state
  }
}
