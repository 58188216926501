import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = (param) => {
  const { search } = useLocation()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  return param ? searchParams.get(param) : searchParams
}
