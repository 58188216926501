import ResizeObserver from 'resize-observer-polyfill'

export class SafeResizeObserver extends ResizeObserver {
  constructor(cb) {
    const callback = entries => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return
        }

        cb(entries)
      })
    }

    super(callback)
  }
}
