import { PeriodsOrderBy } from 'store/config/utils'

const filters = [
  { title: '24ч', value: 5 },
  { title: '12ч', value: 4 },
  { title: '6ч', value: 3 },
  { title: '3ч', value: 2 },
  { title: '1ч', value: 1 },
  { title: '30м', value: 6 },
  { title: 'Все', value: 0 },
]

export const getFilters = orderBy => orderBy === PeriodsOrderBy.DESC ? filters : filters.reverse()
