import styles from 'pages/Payments/components/PaymentItem/styles.module.scss'
import React, { useState } from 'react'
import { cn } from 'utils'

export const PaymentItem = ({ children, title, icon, open = false }) => {
  const [isOpen, setIsOpen] = useState(open)

  return (
    <div className={styles.paymentItem}>
      <div
        className={cn(styles.listItem, isOpen && styles.opened)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          className={styles.icon}
          src={icon}
          alt={title}
        />
        <div className={styles.title}>
          {title}
        </div>
      </div>
      {isOpen && (
        <div className={styles.content}>
          {children}
        </div>
      )}
    </div>
  )
}
