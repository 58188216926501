import { ExternalLink } from 'components/ExternalLink'
import { Page } from 'components/Page'
import { faqLinks } from 'pages/Help/data'
import TEXT from 'store/constants/text'

export const HelpPage = () => (
  <Page
    title={TEXT.account.help.title}
    className="help-page"
    bg="gray"
  >
    <div className="help-page__container">
      {faqLinks.map(({ title, link }) => (
        <ExternalLink
          key={link}
          to={link}
          className="help-page__link"
        >
          {title}
        </ExternalLink>
      ))}
    </div>
  </Page>
)
