import { ReactComponent as InfoIcon } from 'assets/images/info.svg'
import { Message } from 'components/Coupon/Booster/Message'
import styles from 'components/Coupon/Booster/styles.module.scss'
import { getIndicatorWidth } from 'components/Coupon/Booster/utils'
import { Link } from 'react-router-dom'
import { cn } from 'utils'

export const Booster = ({ value, full }) => {
  const { enabled, percent, message } = value || {}

  return (
    value?.active ?
      <div
        className={cn(styles.booster, full && styles.full)}
        data-enabled={enabled}
      >
        {full && (
          <div className={styles.header}>
            <Message value={message} />
            <Link
              to="/express-boost"
              className={styles.infoLink}
            >
              <InfoIcon />
            </Link>
          </div>
        )}
        <div className={styles.progressBar}>
          <div
            className={styles.indicator}
            style={{ width: getIndicatorWidth(percent) }}
          >
            <div className={styles.label}>
              {`${percent}%`}
            </div>
          </div>
        </div>
      </div> : null
  )
}
