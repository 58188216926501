import { PayInWays } from 'pages/Payments/components/PayInWays'
import { PayOutWays } from 'pages/Payments/components/PayOutWays'

const PaymentType = {
  PAY_IN:  'PAY_IN',
  PAY_OUT: 'PAY_OUT',
}

export const PaymentScreen = {
  [PaymentType.PAY_IN]:  PayInWays,
  [PaymentType.PAY_OUT]: PayOutWays,
}

export const paymentTabs = [
  { value: [PaymentType.PAY_IN], title: 'Пополнение' },
  { value: [PaymentType.PAY_OUT], title: 'Вывод' },
]
