import { DocumentReadyState } from 'constants/event'
import { useCentrifugoInit } from 'hooks/useCentrifugoInit'
import { useDimensionsObserver } from 'hooks/useDimensionsObserver'
import { useMaxlinePays } from 'hooks/useMaxlinePays'
import { useSearchParamsHandler } from 'hooks/useSearchParamsHandler'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkIsAuth } from 'store/auth/actions'
import { getBanks } from 'store/banks/actions'
import { getCashback } from 'store/cashback/actions'
import { handleChampionships } from 'store/championship/actions'
import { getConfig } from 'store/config/actions'
import { onInitChange } from 'store/data/actions'
import {
  accountSettings,
  checkRandomBonus,
} from 'store/data/reducers'
import { checkFavorites } from 'store/favorites/actions'
import { getLeaguesSlider } from 'store/line/actions'
import { getServiceWork } from 'store/service/actions'
import { postMessageToRN } from 'utils/react-native'

export const useAppInit = () => {
  const dispatch = useDispatch()

  useDimensionsObserver()
  useSearchParamsHandler()
  useMaxlinePays()
  useCentrifugoInit()
  // TODO Temporary disabled
  // useOnlineListeners()

  useEffect(() => {
    setTimeout(() => {
      dispatch(onInitChange(true))
      postMessageToRN({ mlReadyState: DocumentReadyState.COMPLETE })
    }, 1000)

    dispatch(checkIsAuth())
    dispatch(getConfig())
    dispatch(accountSettings())
    dispatch(checkFavorites())
    dispatch(getCashback())
    dispatch(checkRandomBonus())
    dispatch(handleChampionships())
    dispatch(getServiceWork())
    dispatch(getBanks())
    dispatch(getLeaguesSlider())
  }, [])
}
