import PropTypes from 'prop-types'
import React from 'react'

export const Contact = {
  item:    ({ title, subtitle, icon, postfix }) => (
    <div className="contacts-item">
      <div className="contacts-item__content">
        <img
          className="contacts-item__icon"
          src={icon}
          alt={title}
        />
        <div className="contacts-item__description">
          <div className="contacts-item__title">
            {title}
          </div>
          <div className="contacts-item__subtitle">
            {subtitle}
          </div>
        </div>
      </div>
      {postfix &&
      <div className="contacts-item__postfix">
        {postfix}
      </div>}
    </div>
  ),
  divider: ({ title }) => (
    <div className="contacts-divider">
      {title}
    </div>
  ),
}

Contact.item.propTypes = {
  icon:     PropTypes.string,
  postfix:  PropTypes.object,
  subtitle: PropTypes.object,
  title:    PropTypes.string,
}

Contact.item.defaultProps = {
  icon:     '',
  postfix:  null,
  subtitle: null,
  title:    '',
}

Contact.divider.propTypes = {
  title: PropTypes.string,
}

Contact.divider.defaultProps = {
  title: '',
}

