import {
  format,
  isToday,
  isTomorrow,
} from 'date-fns'
import { capitalize } from 'utils/strings'
import { getUTCDate } from 'utils/time'

export const getEventDate = value => {
  const shortDate = (isToday(value) && 'Сегодня') || (isTomorrow(value) && 'Завтра')

  if (shortDate) {
    return shortDate
  }

  const date  = value.getDate()
  const month = capitalize(format(value, 'MMMM')).slice(0, 3)

  return `${date} ${month}`
}


export const getStartedAt = dirtyTime => {
  try {
    const date = getUTCDate(dirtyTime)

    return {
      time: format(date, 'HH:mm'),
      date: getEventDate(date),
    }
  } catch {
    console.error(`Invalid time format. Received: ${dirtyTime}`)
    return { time: '', date: '' }
  }
}
