import styles from 'components/SwipeToDelete/styles.module.scss'
import {
  ACTION_WIDTH,
  addClass,
  DELETE_TIMEOUT,
  MAX_X_SHIFT,
  MIN_SHIFT_TO_CLOSE,
  MIN_SHIFT_TO_OPEN,
  MIN_X_SHIFT,
  removeClass,
  TRANSITION_DURATION,
  translateX,
} from 'components/SwipeToDelete/utils'
import React, {
  useCallback,
  useRef,
} from 'react'
import { cn } from 'utils'

export const SwipeToDelete = ({ className, onDelete, children }) => {
  const containerRef    = useRef()
  const contentRef      = useRef()
  const removeBtnRef    = useRef()
  const touchStart      = useRef(0)
  const isRemoveVisible = useRef(false)
  const willActionCall  = useRef(false)

  const onTouchStart = useCallback(({ changedTouches }) => {
    touchStart.current = changedTouches[0].clientX
  }, [])

  const updatedPosition = useCallback(value => {
    const shift = value - (isRemoveVisible.current ? ACTION_WIDTH : 0)

    const isMinX = shift < MIN_X_SHIFT
    const nextX  = (isMinX && MIN_X_SHIFT) || (shift > MAX_X_SHIFT && MAX_X_SHIFT) || shift

    if (isMinX && !willActionCall.current) {
      willActionCall.current = true
      addClass(removeBtnRef, styles.pulse)
    }

    if (!isMinX && willActionCall.current) {
      willActionCall.current = false
      removeClass(removeBtnRef, styles.pulse)
    }

    translateX(contentRef, nextX)
    translateX(removeBtnRef, nextX)
  }, [])

  const onTouchMove = useCallback(({ changedTouches }) => {
    updatedPosition(changedTouches[0].clientX - touchStart.current)
  }, [])

  const animateAndDelete = useCallback(() => {
    isRemoveVisible.current = false
    willActionCall.current  = false
    touchStart.current      = 0

    updatedPosition(0)

    addClass(containerRef, styles.removing)

    setTimeout(onDelete, DELETE_TIMEOUT)
  }, [])

  const onTouchEnd = useCallback(({ changedTouches }) => {
    if (willActionCall.current) {
      return animateAndDelete()
    }

    const shift = changedTouches[0].clientX - touchStart.current

    touchStart.current      = 0
    isRemoveVisible.current = shift < (isRemoveVisible.current ? MIN_SHIFT_TO_CLOSE : MIN_SHIFT_TO_OPEN)

    addClass(containerRef, styles.transition)

    updatedPosition(0)

    setTimeout(() => removeClass(containerRef, styles.transition), TRANSITION_DURATION)
  }, [])

  return (
    <div
      className={cn(styles.swipeToDelete, className)}
      ref={containerRef}
    >
      <div
        ref={contentRef}
        className={styles.contentWrapper}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {children}
      </div>
      <div className={styles.loading} />
      <div className={styles.actionWrapper}>
        <button
          ref={removeBtnRef}
          className={styles.removeBtn}
          onClick={animateAndDelete}
        />
      </div>
    </div>
  )
}
