import { Backdrop } from 'components/Backdrop'
import { Bonus22 } from 'components/PaymentModal/Bonus22'
import { PaymentTabs } from 'components/PaymentModal/Tabs'
import { PaymentTab } from 'components/PaymentModal/utils'
import { Portal } from 'components/Portal'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { useStopScroll } from 'hooks/useStopScroll'
import { useEffect } from 'react'
import styles from './styles.module.scss'

export const PaymentModal = () => {
  const { state: { isVisible, method }, closeModal, getDetails, reset } = usePaymentsState()

  useStopScroll(isVisible)

  useEffect(() => {
    getDetails()

    return reset
  }, [])

  const SelectedTab = PaymentTab[method]

  return isVisible ? (
    <Portal>
      <Backdrop zIndex={200} />
      <div className={styles.paymentModal}>
        <div className={styles.main}>
          <button
            onClick={closeModal}
            className={styles.closeBtn}
            aria-label="Close"
          />
          <div>
            <h2 className={styles.title}>Пополнить счёт</h2>
            <PaymentTabs />
          </div>
          <Bonus22 />
          <SelectedTab />
        </div>
      </div>
    </Portal>
  ) : null
}