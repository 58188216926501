import { ModalType } from 'components/SignInModal/utils'
import styles from 'components/SignUp/ConfirmPhone/styles.module.scss'
import { useAuthModal } from 'hooks/useAuthModal'
import { useState } from 'react'
import { connect } from 'react-redux'
import { closeModal } from 'store/modal/actions'
import { cn } from 'utils'
import { copyToClipboard } from 'utils/clipbord'

const FailAuthModal = ({ userId, closeModal }) => {
  const [isCopied, setIsCopied] = useState(false)

  const openAuthModal = useAuthModal()

  const onClick = async () => {
    await copyToClipboard(userId)
    setIsCopied(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Регистрация завершена!
      </div>
      <div className={styles.description}>
        Номер Вашего игрового счёта:
        <button
          className={cn(styles.accInfo, isCopied && styles.copied)}
          data-acc-number={userId}
          onClick={onClick}
        />
      </div>
      <button
        className="ml-button"
        onClick={() => {
          closeModal(FailAuthModal.ID)
          openAuthModal(ModalType.DEFAULT)
        }}
      >
        Войти
      </button>
    </div>
  )
}

FailAuthModal.ID = 'FAIL_AUTH_MODAL'

const mapDispatchToProps = {
  closeModal,
}

export default connect(null, mapDispatchToProps)(FailAuthModal)
