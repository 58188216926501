import moment from 'moment'

export const getBetInfo = ({ error, is_single, is_express, is_system, amount, currency, koeff, payout, is_vip, message, time }) => ({
  title:    (error && 'Ошибка') || (is_vip && 'VIP-ставка принята на рассмотрение') || 'Ставка успешно принята',
  subTitle: !error ? moment(time).format('D MMMM YYYY HH:mm:ss') : message,
  error,
  isExist:  !!(message || (amount && koeff)),
  ...(!error && {
    type:   !is_vip && (is_single && 'одиночная ставка') || (is_system && 'система') || (is_express && 'экспресс'),
    amount: `${(+amount).toFixed(2)} ${currency}`,
    payout: `${(+payout).toFixed(2)} ${currency}`,
    koeff,
  }),
})
