import { Checkbox } from 'components/Checkbox'
import { PasswordInput } from 'components/Field/Password'
import TextInput from 'components/Field/TextInput'
import {
  getCredentials,
  headers,
  LOGIN_LABEL,
  MIN_LOGIN_LENGTH,
  MIN_PASSWORD_LENGTH,
  ModalType,
  removeCredentials,
  saveCredentials,
  titles,
  validateLogin,
  validatePassword,
} from 'components/SignInModal/utils'
import { paths } from 'constants/paths'
import { useToSignUp } from 'hooks/useToSignUp'
import {
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import {
  Link,
  useNavigate,
} from 'react-router-dom'
import TEXT from 'store/constants/text'
import { setModalKey } from 'store/new-sign-up/actions'
import { signIn } from 'store/user/actions'
import {
  cn,
  KeyListener,
} from 'utils'
import styles from './styles.module.scss'

export const SignInModal = ({ type = ModalType.DEFAULT, onClose, redirectURL, onSuccess }) => {
  const [login, setLogin]                   = useState('')
  const [password, setPassword]             = useState('')
  const [shouldSaveCred, setShouldSaveCred] = useState(false)
  const [isPending, setIsPending]           = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toSignUp = useToSignUp()

  const goToSignUp = () => {
    toSignUp()
    onClose()
  }

  const goToForgotPassword = () => {
    dispatch(setModalKey(''))
    onClose()
  }

  const isLoginFormValid = login.length >= MIN_LOGIN_LENGTH && password.length >= MIN_PASSWORD_LENGTH

  useEffect(() => {
    const [prevLogin, prevPassword] = getCredentials()

    if (prevLogin && prevPassword) {
      setShouldSaveCred(true)
      setLogin(prevLogin)
      setPassword(prevPassword)
    }
  }, [])

  const onSuccessCallback = () => {
    onClose?.()

    if (redirectURL) {
      navigate(redirectURL)
    }

    onSuccess?.()
  }

  const onSignIn = async event => {
    event.stopPropagation()

    if (isLoginFormValid) {
      setIsPending(true)
      shouldSaveCred ? saveCredentials({ login, password }) : removeCredentials()
      await dispatch(signIn(login, password, { onSuccess: onSuccessCallback }))
      setIsPending(false)
    }
  }

  const title = titles[type]

  return (
    <section className={cn('dialog login', type, isPending && styles.pending)}>
      <button
        className="dialog__close-btn"
        onClick={onClose}
      />
      <div className="dialog__header">
        <h1 className="dialog-header__title">
          {headers[type] || TEXT.login.loginTitle}
        </h1>
        {title && <div className="dialog-title">{title}</div>}
      </div>
      <TextInput
        label={LOGIN_LABEL}
        value={login}
        onChange={value => setLogin(value.trim())}
        validateFn={validateLogin}
        autoFocus
      />
      <PasswordInput
        value={password}
        onChange={setPassword}
        label="Пароль"
        validateFn={validatePassword}
        onKeyDown={KeyListener.Enter(onSignIn)}
      />
      <div className="dialog__group">
        <Checkbox
          className="dialog__remember-me"
          checked={shouldSaveCred}
          onChange={() => setShouldSaveCred(!shouldSaveCred)}
          title="Запомнить меня"
        />
        <Link
          to={paths.FORGOT_PASSWORD}
          className="dialog__recovery-link"
          onClick={goToForgotPassword}
        >
          Забыли пароль?
        </Link>
      </div>
      <button
        className="ml-button"
        onClick={onSignIn}
        disabled={!isLoginFormValid}
      >
        Войти
      </button>
      <button
        onClick={goToSignUp}
        className="dialog__register-link"
      >
        Зарегистрироваться
      </button>
    </section>
  )
}
