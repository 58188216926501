export const MIN_DATE = new Date()

export const expToDate = exp => {
  const [month, year] = exp?.split('/') || []
  return month && year && new Date(`20${year}-${month}-05`) || null
}

export const dateToExp = date => {
  const [year, month] = date.toISOString().split('-')
  return `${month}/${year.slice(2)}`
}
