import { Addon } from 'components/Addon'
import styles from './styles.module.scss'

export const Addons = ({ list, values, onChange, children }) => (
  <div className={styles.addons}>
    {children}
    {list.map(addon => {
      const { value } = values[addon.key] || {}

      return new Addon({
        data:      addon,
        onChange:  value => onChange(prev => ({ ...prev, ...value })),
        value,
        nextValue: !value,
      }).buildComponent()
    })}
  </div>
)
