import styles from 'components/Addon/styles.module.scss'
import { Checkbox } from 'components/Checkbox'
import { ExternalLink } from 'components/ExternalLink'

const variableRegExp = /:(.+):/gi

export const getAddonTitle = (title, bind) => (
  <>
    {title.split(variableRegExp).map(part => {
      const { name, href } = bind?.[part] || {}

      return name ? (
        <ExternalLink
          key={name}
          to={href}
          onClick={event => event.stopPropagation()}
          className={styles.link}
        >
          {name}
        </ExternalLink>
      ) : part
    })}
  </>
)

export const addonTypes = {
  checkbox: {
    Tag:   Checkbox,
    props: {
      left: true,
    },
  },
  get(instance) {
    const element = this[instance.type]

    element.props.checked = instance.value

    return element
  },
}
