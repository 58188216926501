import { getProgressWidth } from 'components/Progress/utils'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const Progress = ({ value, className }) => (
  <div className={cn(styles.progress, className)}>
    <div
      className={styles.indicator}
      style={{ width: getProgressWidth(value) }}
    />
  </div>
)
