import { AppLinks } from 'components/Footer/AppLinks'
import { InternalLinks } from 'components/Footer/InternalLinks'
import { LinksGroup } from 'components/Footer/LinksGroup'
import styles from 'components/Footer/styles.module.scss'
import {
  FOOTER_SEO_CONTAINER,
  year,
} from 'components/Footer/utils'
import { YearProduct } from 'components/Footer/YearProduct'
import { socialNetworks } from 'components/Header/Navigation/utils'
import { isWebView } from 'constants/platform'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="footer">
    <div id={FOOTER_SEO_CONTAINER} />
    <div className={styles.linksContainer}>
      <InternalLinks />
      <div className={styles.externalLinks}>
        {!isWebView && <AppLinks />}
        <LinksGroup links={socialNetworks} />
      </div>
    </div>
    <YearProduct />
    <div className={styles.info}>
      <div>
        ООО "Финансово-инвестиционная компания "ИНХО". УНП: 191318808. 210022, Республика Беларусь, Витебск, пр.
        Строителей, д. 10. Лицензия №33120/586 от 31.07.2012, выдана Министерством
        по налогам и сборам Республики Беларусь. Решение №8.
      </div>
      <div>Режим работы: круглосуточно.</div>
      <Link
        to="/page/alfabank"
        className="payment-security"
      >
        Безопасность платежей и конфиденциальность
      </Link>
    </div>
    <div className="copyright-container">
      {`© ${year} Букмекерская компания Maxline. Все права защищены`}
    </div>
  </footer>
)

export default Footer
