import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import ACTIONS from 'store/constants/actions'
import { PERIOD_NONE } from 'store/sports-filter/utils'

const initialState = {
  period: PERIOD_NONE,
  live:   [],
  line:   [],
}

const sportsFilterPersistConfig = {
  key: 'period',
  storage,
}

export const sportsFilterReducer = persistReducer(
  sportsFilterPersistConfig,
  (state = initialState, { type, payload }) => {
    switch (type) {
      case ACTIONS.SPORTS_FILTER.UPDATE_LIVE:
        return { ...state, live: payload }

      case ACTIONS.SPORTS_FILTER.UPDATE_LINE:
        return { ...state, line: payload }

      case ACTIONS.SPORTS_FILTER.UPDATE_PERIOD:
        return { ...state, period: payload }

      default:
        return state
    }
  },
)
