import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'

export const onPersonalData = () => dispatch => {
	Api.getInfo().then((resp) =>{

		dispatch({
			type : ACTIONS.USER.GET_PERSONAL_DATA,
			data: resp.data
		});
		}
	)
};


