import { Api } from 'api/Api'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'

export const filters = [
  { title: '3 дня', value: 0 },
  { title: 'Неделя', value: 1 },
  { title: 'Месяц', value: 2 },
  { title: '3 месяца', value: 8 },
]

export const initialState  = { history: [], future: [] }
export const initialFilter = filters[0]

export const getUserPromoCodes = async period => {
  showPreloader()

  try {
    const { data: { logs: history, promo_codes: future } } = await Api.getUserPromoCodes(period)

    return { history, future }
  } catch {
    return initialState
  } finally {
    hidePreloader()
  }
}
