import { ReactComponent as NoConnectionIcon } from 'assets/images/forbidden/no-connection.svg'
import { ReactComponent as ServiceWorkIcon } from 'assets/images/forbidden/service-work.svg'
import { ReactComponent as UnknownErrorIcon } from 'assets/images/forbidden/unknown-error.svg'

export const forbiddenTypes = {
  noConnection: {
    title:       'Соединение отсутствует',
    description: 'Вы не подключены к сети Интернет. Проверьте Ваше соединение и повторите попытку.',
    Icon:        NoConnectionIcon,
  },
  serviceWork:  {
    title:       'Ведутся плановые технические работы',
    description: 'Приносим свои извинения за доставленные неудобства',
    Icon:        ServiceWorkIcon,
  },
  unknown:      {
    title:       'Что-то пошло не так',
    description: 'Попробуйте позже',
    Icon:        UnknownErrorIcon,
  },
}
