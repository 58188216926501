import { cn } from 'utils'
import styles from './styles.module.scss'

export const AmountInput = ({
  isOpen,
  onOpen,
  value,
  className,
  placeholder = 'мин. 2',
  invalid
}) => (
  <div
    className={cn(styles.amountInput, invalid && styles.invalid, className)}
    data-editing={isOpen}
  >
    <div
      className={styles.input}
      onClick={onOpen}
    >
      <div className={styles.value}>
        {value || <span className={styles.placeholder}>{placeholder}</span>}
      </div>
      <div className={styles.currency}>BYN</div>
    </div>
  </div>
)
