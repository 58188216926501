import ACTIONS from 'store/constants/actions'

const initialState = {
  bets:        [],
  message_out: '',
  loading:     {},
}

export const cashoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CASHOUT.GET: {
      return { ...state, bets: action.data }
    }

    case ACTIONS.CASHOUT.FETCHING: {
      const bet_id = action.bet_id
      return { ...state, loading: { ...state.loading, [bet_id]: true } }
    }
    case ACTIONS.CASHOUT.SUCCESS: {
      const bet_id = action.bet_id
      return { ...state, loading: { ...state.loading, [bet_id]: false } }
    }
    case ACTIONS.CASHOUT.FAILURE: {
      return { ...state, loading: false }
    }

    default:
      return state
  }
}
