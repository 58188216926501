import { Api } from 'api/Api'
import { ConfigActionTypes } from 'store/config/types'
import { DEFAULT_PERIOD } from 'store/config/utils'
import { updateFilterPeriod } from 'store/sports-filter/actions'
import { PERIOD_NONE } from 'store/sports-filter/utils'

const setConfig = payload => ({
  type: ConfigActionTypes.SET,
  payload,
})

export const getConfig = () => async (dispatch, getState) => {
  try {
    const { data } = await Api.getConfig()

    const { sportsFilter: { period } } = getState()

    if (period === PERIOD_NONE) {
      dispatch(updateFilterPeriod(data?.line?.default_period ?? DEFAULT_PERIOD))
    }

    dispatch(setConfig(data))
  } catch (error) {
    console.error(error)
  }
}
