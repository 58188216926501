import { sortBy } from 'lodash'
import styles from 'pages/Home/Line/BySport/styles.module.scss'

const getDivider = name => (
  <div
    key={name}
    className={styles.divider}
  >
    {name}
  </div>
)

const orderedDivider  = { divider: getDivider('Популярные') }
const ordinaryDivider = { divider: getDivider('Все чемпионаты') }
const longTermDivider = { divider: getDivider('Долгосрочные') }

export const sortByName  = (array = []) => sortBy(array, 'name')
export const sortByOrder = (array = []) => array.sort((left, right) => right.order - left.order)
export const sortLeagues = leagues => sortByOrder(sortByName(leagues))

export const responseHandler = ({ leagues }) => {
  const sortedLeagues = sortLeagues(leagues)

  let ordered  = []
  let ordinary = []
  let longTerm = []

  sortedLeagues.forEach(league => ((+league.type && longTerm) || (+league.order > 0 && ordered) || ordinary).push(league))

  return [
    ...(ordered.length ? [orderedDivider, ...ordered] : []),
    ...(ordinary.length ? [ordinaryDivider, ...ordinary] : []),
    ...(longTerm.length ? [longTermDivider, ...longTerm] : []),
  ]
}
