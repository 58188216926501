import { useState } from 'react'
import { cn } from 'utils'
import { Card } from './Card'
import styles from './styles.module.scss'

export const CardsSelect = ({ options, value, onChange, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const selectedCard = options.find(({ id }) => +id === +value)

  const onCardChange = card => {
    onChange(card)
    setIsOpen(false)
  }

  const onOpen = () => setIsOpen(true)

  return (
    <div className={cn(styles.select, className)}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cn(styles.arrowBtn, isOpen && styles.open)}
        aria-label="Arrow button"
      />
      <Card onClick={onOpen} {...selectedCard} />
      {isOpen && (
        <div className={styles.cards}>
          {options.map(card => (
            <Card
              key={card.id}
              onClick={() => onCardChange(card)}
              className={cn(+card.id === +value && styles.selectedCard)}
              {...card}
            />
          ))}
        </div>
      )}
    </div>
  )
}
