import { Api } from 'api/Api'
import { CasinoSearchInput } from 'components/Casino/Search/Input'
import { Page } from 'components/Page'
import { Slider } from 'components/Slider'
import { paths } from 'constants/paths'
import { useDeferredValue } from 'hooks/useDeferredValue'
import { useRequest } from 'hooks/useRequest'
import { TVGamesList } from 'pages/Casino/TVGames/Games'
import { TVGamesFilter } from 'pages/Casino/TVGames/Providers'
import {
  DEFAULT_FILTER_ID,
  defaultCategory,
  filterGames,
  providers,
  sortCategories,
} from 'pages/Casino/TVGames/utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './styles.module.scss'

export const CasinoTVGamesPage = () => {
  const [search, setSearch]                 = useState('')
  const [activeCategory, setActiveCategory] = useState(DEFAULT_FILTER_ID)
  const [activeProvider, setActiveProvider] = useState(DEFAULT_FILTER_ID)

  const navigate = useNavigate()

  const delayedSearch = useDeferredValue(search)

  const { data: slides = [] }     = useRequest(() => Api.getCasinoSlider(4))
  const { data: categories = [] } = useRequest(Api.getTVGamesCategories)

  const {
          data:      games = [],
          isLoading: isGamesLoading,
        } = useRequest(() => Api.getTVGames(delayedSearch), { deps: [delayedSearch] })

  const filteredGames    = filterGames(games, activeCategory, activeProvider)
  const sortedCategories = sortCategories(categories)

  return (
    <Page
      title="TV-игры"
      className="casino"
      onGoBack={() => navigate(paths.CASINO_HOME)}
      bg="black"
    >
      <Slider slides={slides} />
      <div className={styles.searchPanel}>
        <TVGamesFilter
          list={providers}
          active={activeProvider}
          onChange={setActiveProvider}
        />
        <TVGamesFilter
          list={[defaultCategory, ...sortedCategories]}
          active={activeCategory}
          onChange={setActiveCategory}
        />
        <CasinoSearchInput
          value={search}
          onChange={setSearch}
        />
      </div>
      <TVGamesList
        list={filteredGames}
        loading={isGamesLoading}
      />
    </Page>
  )
}
