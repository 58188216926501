import ACTIONS from 'store/constants/actions'
import { clearCoupon } from 'store/coupon/actions'

/**
 * Change amount of one click bets
 *
 * @param {number} payload
 *
 * @returns {function(*): void}
 */
export const changeAmount = payload => dispatch => dispatch({
  type: ACTIONS.ONE_CLICK_BETS.AMOUNT_UPDATE,
  payload,
})

/**
 * Change one click bets isActive value
 *
 * @param {number} payload - 0 - disable, 1 - enable
 *
 * @returns {function(*): *}
 */
export const changeOneClickBets = payload => dispatch => {
  dispatch({ type: ACTIONS.ONE_CLICK_BETS.IS_ACTIVE_UPDATE, payload })
  if (+payload) {
    dispatch(clearCoupon('live'))
  }
}
