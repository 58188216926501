import { Api } from 'api/Api'
import { FreeSpins } from 'components/Casino/FreeSpins'
import { Page } from 'components/Page'
import { CasinoPageSEOCollapse } from 'components/SEOCollapse/Casino'
import { getBanners } from 'pages/Casino/Banners/utils'
import {
  useEffect,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const CasinoBannersPage = () => {
  const [tournamentsCount, setTournamentsCount] = useState(0)

  useEffect(() => {
    Api.getTournamentsCount()
      .then(({ data: { count_casino } }) => setTournamentsCount(count_casino))
      .catch(console.error)
  }, [])

  return (
    <Page
      className="casino"
      bg="black"
    >
      <FreeSpins className={styles.freeSpins} />
      <div className={cn(styles.banners, tournamentsCount > 0 && styles.withTournament)}>
        {getBanners(tournamentsCount).map(({ name, to, image, count }) => (
          <Link
            key={name}
            to={to}
            className={cn(styles[name])}
          >
            <img
              src={image}
              className={styles.img}
              alt={name}
            />
            {count && <div className={styles.counter}>{count}</div>}
          </Link>
        ))}
      </div>
      <CasinoPageSEOCollapse />
    </Page>
  )
}
