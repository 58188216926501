import { getCardIcon } from 'components/CardItem/utils'
import PropTypes from 'prop-types'

export const CardItem = ({ card: { icon, number, exp } }) => (
  <div className="cards-settings__item-info checked">
    <div className="cards-settings__card_icon">
      <img
        src={getCardIcon(icon)}
        alt="card-type"
      />
    </div>
    <span>{number}</span>
    <span className="cards-settings__card_exp">{exp}</span>
  </div>
)

CardItem.propTypes = {
  card:    PropTypes.object,
  checked: PropTypes.bool,
}

CardItem.defaultProps = {
  card:    {
    icon:   '',
    number: '',
    exp:    '',
  },
  checked: false,
}
