export const Tags = ({ list, className }) => list?.length ? (
  <div className={className}>
    {list.map(({ id, background_color, label, text_color } = {}) => id && (
      <div
        key={id}
        style={{ backgroundColor: background_color, color: text_color }}
      >
        {label}
      </div>
    ))}
  </div>
) : null
