import React from 'react'
import InputMask from 'react-input-mask'
import styles from './styles.module.scss'

export const MaskedInput = ({ label, ...restProps }) => (
  <div className={styles.maskedInput}>
    {label && <div className={styles.label}>{label}</div>}
    <InputMask {...restProps} className={styles.input} />
  </div>
)
