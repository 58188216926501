import { PreloaderActionTypes } from 'store/preloader/types'

const initialState = {
  active: false,
}

export const preloaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case PreloaderActionTypes.SHOW:
      return { active: true }

    case PreloaderActionTypes.HIDE:
      return initialState

    default:
      return state
  }
}
