import { Addon } from 'components/Addon'
import { Booster } from 'components/Coupon/Booster'
import { Addons } from 'components/Coupon/Live/Addons'
import BetLive from 'components/Coupon/Live/Bet'
import { Error } from 'components/Coupon/Live/Error'
import { CouponFooter } from 'components/Coupon/Live/Footer'
import { clearCouponModal } from 'components/Coupon/Live/utils'
import OneClickBets from 'components/Coupon/OneClickBets'
import { CouponStatus } from 'components/Coupon/Status'
import { useCloseOnScroll } from 'components/Coupon/utils'
import { CouponWrapper } from 'components/Coupon/Wrapper'
import { STATUSES } from 'constants/statuses'
import { usePrevious } from 'hooks/usePrevious'
import { Interval } from 'pages/Home/utils'
import {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  clearCoupon,
  getCoupon,
  makeLiveBet,
} from 'store/coupon/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

const initialAmount = ''
const COUPON_TYPE   = 'live'

const CouponLive = ({
  couponStorage: { status, coupon, messages },
  user:          { login: { auth } },
  addons,
  onGetCoupon,
  onClearCoupon,
  onMakeBet,
  isGoldBet,
  isOneClickBets,
  loading,
}) => {
  const [isOpen, setIsOpen]             = useState(false)
  const [amount, setAmount]             = useState(initialAmount)
  const [isFetching, setIsFetching]     = useState(false)
  const [addonsValues, setAddonsValues] = useState({})

  const hasBets   = (coupon || {}).items
  const betsCount = (hasBets || []).length

  const prevBetsCount = usePrevious(betsCount)
  const prevIsAuth    = usePrevious(auth)
  const prevGoldBet   = usePrevious(isGoldBet)

  const closeCoupon = () => setIsOpen(false)
  const openCoupon  = () => setIsOpen(true)

  useCloseOnScroll(isOpen && isOpen && betsCount === 1 && !loading, closeCoupon)

  useEffect(() => {
    if (addons.length) {
      setAddonsValues(Addon.configureValues(addons))
    }
  }, [addons.length])

  useEffect(() => {
    if ((!betsCount && amount !== initialAmount) || (prevGoldBet !== isGoldBet)) {
      setAmount(initialAmount)
    }
  }, [betsCount, isGoldBet])

  useEffect(() => {
    if (!prevBetsCount && betsCount === 1 && !isOpen && prevIsAuth) {
      openCoupon()
    }

    if ((prevBetsCount === 1 && betsCount === 2 && isOpen) || !betsCount) {
      closeCoupon()
    }
  }, [betsCount, prevBetsCount])

  useEffect(() => {
    setIsFetching(true)
    onGetCoupon(() => setIsFetching(false))

    const interval = setInterval(onGetCoupon, Interval.LIVE)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const clearCoupon = useCallback(() => clearCouponModal(() => {
    closeCoupon()
    onClearCoupon(COUPON_TYPE)
  }), [])

  const makeBet = useCallback(() => {
    onMakeBet(Addon.configureParams({ amount, clear: 1, gold: +coupon?.isGoldBet }, addonsValues))
  }, [amount, coupon?.isGoldBet, addonsValues])

  const isSingle      = betsCount <= 1
  const isFreeBet     = addonsValues.freebet?.value
  const isCouponExist = !!betsCount && !isOneClickBets
  const isError       = !loading && status === STATUSES.ERROR && messages.length > 0
  const hasBooster    = !isFreeBet && !isSingle

  const couponStatus = (
    <CouponStatus
      isOpen={isOpen}
      isFetching={isFetching}
      onOpen={openCoupon}
      freeBet={isFreeBet}
      booster={coupon?.bonus}
      totalCoeff={coupon?.totalValue}
      couponsCount={betsCount}
    />
  )

  return (
    isCouponExist ? (
      <CouponWrapper
        opened={isOpen}
        single={isSingle}
        status={couponStatus}
        onClose={closeCoupon}
        processing={loading}
      >
        <div className={cn(styles.couponData, isSingle && styles.single)}>
          <div className={styles.bets}>
            {coupon.items.map(item =>
              <BetLive
                key={`${item.idEvent}${item.f}`}
                type={COUPON_TYPE}
                item={item}
                onClose={closeCoupon}
                single={isSingle}
              />)}
          </div>
          {isError && <Error message={messages} />}
          {hasBooster && (
            <Booster
              value={coupon?.bonus}
              full
            />
          )}
          <CouponFooter
            coupon={coupon}
            amount={amount}
            freeBet={isFreeBet}
            makeBet={makeBet}
            onAmountChange={setAmount}
            onClose={closeCoupon}
            onClear={clearCoupon}
          >
            <Addons
              list={addons}
              values={addonsValues}
              onChange={setAddonsValues}
            />
          </CouponFooter>
        </div>
      </CouponWrapper>
    ) : (isOneClickBets && <OneClickBets />)
  )
}

const mapStateToProps = state => ({
  user:           state.user,
  couponStorage:  state.coupon,
  isGoldBet:      state.coupon.coupon?.isGoldBet,
  addons:         state.coupon.coupon?.addons || [],
  isOneClickBets: state.oneClickBets.isActive,
  loading:        state.bet_data.loading,
})

const mapDispatchToProps = {
  onGetCoupon:   callback => getCoupon(COUPON_TYPE, callback),
  onClearCoupon: clearCoupon,
  onMakeBet:     makeLiveBet,
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponLive)
