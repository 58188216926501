import headerLgImg from 'assets/images/express-boost/header-lg.jpg'
import headerSmImg from 'assets/images/express-boost/header-sm.jpg'
import { Page } from 'components/Page'
import { RulesLink } from 'components/RulesLink'
import {
  RULES_URL,
  steps,
} from 'pages/ExpressBoost/utils'
import styles from './styles.module.scss'

export const ExpressBoostPagePage = () => (
  <Page
    bg="black"
    title="Экспресс-буст"
    headerContent={<RulesLink to={RULES_URL} />}
  >
    <picture>
      <source
        srcSet={headerSmImg}
        media="(max-width: 520px)"
      />
      <source
        srcSet={headerLgImg}
        media="(min-width: 521px)"
      />
      <img
        src={headerSmImg}
        alt="header"
        className={styles.headerImg}
      />
    </picture>
    <section className={styles.expressBoostPage}>
      {steps.map(({ num, text }) => (
        <div className={styles.step}>
          <div className={styles.stepNum}>{num}</div>
          <div className={styles.stepText}>{text}</div>
        </div>
      ))}
    </section>
  </Page>
)
