import { SportTab } from 'pages/Home/components/SportsFilter/SportTab'
import { scrollToCenter } from 'pages/Home/components/SportsFilter/utils'
import {
  useEffect,
  useRef,
} from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SportPicker = ({ list, onChange, active, onShowAll }) => {
  const containerRef = useRef()

  useEffect(() => {
    scrollToCenter(containerRef)
  }, [active])

  return (
    list.length ? (
      <div className={styles.sportPicker}>
        <div
          ref={containerRef}
          className={cn(styles.sportList, onShowAll && styles.short)}
        >
          {list.map(({ id, name, counter }) => (
            <SportTab
              key={id}
              id={id}
              name={name}
              active={id === active}
              onClick={() => onChange(id)}
              count={counter}
            />
          ))}
        </div>
        {onShowAll && (
          <button
            onClick={onShowAll}
            className={styles.showAllBtn}
            aria-label="Show all sports"
          />
        )}
      </div>
    ) : null
  )
}
