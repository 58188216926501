import {
  ComponentByKey,
  handleModal,
  setMaxAge,
} from 'components/SignUpModals/utils'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { setModalKey } from 'store/new-sign-up/actions'

const SignUpModals = ({ step, modalKey, setModalKey }) => {
  useEffect(() => {
    handleModal(step, setModalKey)
  }, [step])

  const onClose = () => setModalKey('')

  const onDoNotShow = minutes => {
    if (modalKey) {
      setMaxAge(modalKey, minutes)
    }

    onClose()
  }

  const Modal = ComponentByKey[modalKey]

  return Modal ? (
    <Modal
      onClose={onClose}
      onDoNotShow={onDoNotShow}
      onChange={setModalKey}
    />
  ) : null
}

const mapStateToProps = state => ({
  step:     state.auth.step,
  modalKey: state.newSignUp.modalKey,
})

const mapDispatchToProps = {
  setModalKey,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpModals)
