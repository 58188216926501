import React from 'react'
import styles from 'components/Loading/styles.module.scss'

const Loading = () => (
  <div className={styles.loading}>
    <div className={styles.spinner} />
  </div>
)

export default Loading
