import { lazy } from 'react'

const VerificationPage = lazy(() => import('../../pages/Verification'))

export const authRoutes = [
  {
    path:    'verification',
    element: <VerificationPage />,
  },
  {
    path:    'change-phone',
    element: <VerificationPage changePhone />,
  },
]
