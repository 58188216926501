import { Api } from 'api/Api'
import { isBoolean } from 'utils/guards'

const RANDOM_BONUS_KEY = 'has-random-bonus'

const setRandomBonusStatus = status => {
  localStorage.setItem(RANDOM_BONUS_KEY, JSON.stringify(status))
  return status
}

export const getRandomBonusStatus = async isAuth => {
  if (isAuth) {
    const status = await Api.checkRandomBonus()
    return setRandomBonusStatus(status)
  }

  try {
    const storedStatus = localStorage.getItem(RANDOM_BONUS_KEY)

    if (storedStatus) {
      const parsedStatus = JSON.parse(storedStatus)
      return isBoolean(parsedStatus) ? parsedStatus : setRandomBonusStatus(true)
    }

    return setRandomBonusStatus(true)
  } catch {
    return setRandomBonusStatus(true)
  }
}
