export const GameError = {
  UNVERIFIED: 'gameUnverified',
  NOT_FOUND:  'gameNotFound',
  NOT_AUTH:   'gameNotAuth',
}

export const GameResponseCode = {
  UNAUTHORIZED: 1,
  UNVERIFIED:   101,
}

export const GameParam = {
  MODE:    'mode',
  NO_DEMO: 'no_demo',
}

export const CASINO_NAVIGATE_OPTS = {
  replace: true,
}

export const getGameError = code => code === GameResponseCode.UNVERIFIED ? GameError.UNVERIFIED :
  GameError.NOT_FOUND
