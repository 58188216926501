export class EventEmitter {
  events = {}

  emmit(event, data) {
    if (this.events[event]) {
      this.events[event].forEach(callback => callback(data))
    }
  }

  on(event, callback) {
    if (!this.events[event]) {
      this.events[event] = []
    }

    this.events[event].push(callback)
  }

  off(event) {
    if (this.events[event]) {
      const { [event]: removedEvent, ...events } = this.events

      this.events = events
    }
  }
}
