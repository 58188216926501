import styles from 'components/Casino/FreeSpins/styles.module.scss'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { Link } from 'react-router-dom'
import { resetCasinoData } from 'store/casino/creators'
import { cn } from 'utils'

export const FreeSpins = ({ className }) => {
  const dispatch  = useDispatch()
  const freeSpins = useSelector(state => state.auth.freespin)

  return (
    freeSpins > 0 ?
      <Link
        to="/casino/free-spins-games"
        className={cn(styles.freeSpins, className)}
        onClick={() => dispatch(resetCasinoData())}>
          <div className={styles.content}>Фриспины</div>
          <div className={styles.value}>{freeSpins}</div>
          {/*<Link*/}
          {/*  onClick={() => dispatch(resetCasinoData())}*/}
          {/*  to="/casino/free-spins-games"*/}
          {/*  className={styles.useBtn}*/}
          {/*>*/}
          {/*  Использовать*/}
          {/*</Link>*/}
      </Link> : null
  )
}
