import styles from './styles.module.scss'

export const MaxAmount = ({ value, onChange }) => (
  <div
    className={styles.maxAmount}
    {...(value && { onClick: () => onChange(value) })}
  >
    Max:&nbsp;<span>{`${value.toFixed(2)} BYN`}</span>
  </div>
)
