const IFRAME_CONTAINER_ID = 'betgames_div_iframe'

export const initBetGames = ({ token, partner, server, lang }, id) => {
  const BetGames        = window.BetGames
  const iframeContainer = document.getElementById(IFRAME_CONTAINER_ID)

  iframeContainer.innerHTML = ''

  BetGames?.frame([
    ['token', token],
    ['language', lang],
    ['timezone', id],
    ['partner', partner],
    ['server', server],
    ['is_mobile', 'true'],
  ])
}
