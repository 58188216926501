import React, { useState } from 'react'
import { cn } from 'utils'
import { omitTags } from 'utils/strings'
import { getFullDate } from 'utils/time'
import styles from './styles.module.scss'

export const LineResultEvent = ({ name, date, score, additional }) => {
  const [isOpen, setIsOpen] = useState(false)

  const separatedScores = score.split(' (')
  const mainScores      = separatedScores[0] ? separatedScores[0].trim() : null
  const detailedScores  = separatedScores[1] ? `(${separatedScores[1]}` : null

  const [team1, team2] = omitTags(name).split(' - ')

  const hasScores = mainScores || detailedScores

  return (
    <div className={styles.lineResultEvent}>
      <div className={styles.header}>{getFullDate(date * 1000)}</div>
      <div
        className={cn(styles.main, !additional && styles.noHistory, isOpen && styles.opened)}
        {...(!!additional && { onClick: () => setIsOpen(!isOpen) })}
      >
        <div className={styles.teams}>
          {team1 && <div className={styles.teamName}>{team1}</div>}
          {team2 && <div className={styles.teamName}>{team2}</div>}
        </div>
        {hasScores && (
          <div className={styles.score}>
            <div className="bold">{mainScores}</div>
            <div className="gray font-11">{detailedScores}</div>
          </div>
        )}
      </div>
      <div className="add-info-container">
        {!!additional && isOpen && (
          <div
            className={styles.history}
            dangerouslySetInnerHTML={{ __html: additional }}
          />
        )}
      </div>
    </div>
  )
}
