import { paths } from 'constants/paths'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

export const OtherPaymentsLink = () => {
  const { closeModal } = usePaymentsState()

  return (
    <Link
      className={styles.link}
      to={paths.PAYMENTS_PAGE}
      onClick={closeModal}
    >
      Другие способы пополнения
    </Link>
  )
}
