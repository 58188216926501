import { ExternalLink } from 'components/ExternalLink'
import { Link } from 'react-router-dom'
import TEXT from 'store/constants/text'
import styles from './styles.module.scss'

export const InternalLinks = () => (
  <div className={styles.internalLinks}>
    <Link
      className={styles.link}
      to="/page/about_company"
    >
      {TEXT.about.about_us}
    </Link>
    <ExternalLink
      className={styles.link}
      to="https://maxline.by?no-redirect=1"
      title={TEXT.desktop.title}
    />
    <Link
      className={styles.link}
      to="/contacts"
    >
      {TEXT.contacts.title}
    </Link>
  </div>
)
