import styles from './styles.module.scss'

export const StepTree = ({ steps }) => (
  <div>
    {steps.map((step, idx) => (
      <div
        key={step}
        className={styles.step}
        style={{ marginLeft: `${idx * 20}px` }}
      >
        {step}
      </div>
    ))}
  </div>
)