import { CouponSettingsTrigger } from 'components/Coupon/Settings/Trigger'
import styles from './styles.module.scss'

export const CouponControls = ({ single, onClear, onClose }) => (
  <div className={styles.controls}>
    {!single && (
      <div className={styles.clearBtnWrapper}>
        <button
          onClick={onClear}
          className={styles.clearBtn}
          aria-label="Clear coupon"
        />
      </div>
    )}
    <CouponSettingsTrigger />
    <button
      className={styles.closeBtn}
      onClick={onClose}
      aria-label="Close coupon"
    />
  </div>
)
