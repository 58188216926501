import { Api } from 'api/Api'
import { PasswordInput } from 'components/Field/Password'
import { Page } from 'components/Page'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import React, {
  useEffect,
  useState,
} from 'react'
import TEXT from 'store/constants/text'
import styles from './styles.module.scss'

export const ChangePasswordPage = () => {
  const [csrf, changeCsrf]                         = useState('')
  const [password_current, changePassword_current] = useState('')
  const [password, changePassword]                 = useState('')
  const [password_confirm, changePassword_confirm] = useState('')

  useEffect(() => {
    Api.changePassword().then(({ data: { form: { csrf } } }) => changeCsrf(csrf))
  }, [])

  const onSubmit = event => {
    event.preventDefault()

    showPreloader()

    Api.changePasswordPost({
      csrf,
      password,
      password_confirm,
      password_current,
    }).then((resp) => {
      hidePreloader()

      const title   = resp.code !== 200 ? 'Ошибка' : TEXT.account.changePassword.title
      const message = typeof resp.data.messages === 'string' ? resp.data.messages : resp.data.messages[0]

      resp.code !== 200 ? showErrorModal({ title, message }) : showSuccessModal({ message })
    })
  }

  const validatePassword = value => {
    return value
    && value.length
    && value.length > 0
    && value.length < 6
      ? 'пароль должен быть не короче 6 символов'
      : null
  }

  const validatePasswordConfirm = (password, confirmation) => (!!password && password !== confirmation) ?
    'пароли должны совпадать' : null

  const passwordCurrentError = validatePassword(password_current)
  const passwordError        = validatePassword(password)
  const passwordConfirmError = validatePasswordConfirm(password, password_confirm)

  const isFormValid =
          password_current && password_current.length && !passwordCurrentError
          && password && password.length && !passwordError
          && password_confirm && password_confirm.length && !passwordConfirmError

  return (
    <Page title={TEXT.account.changePassword.title}>
      <div className={styles.changePasswordPage}>
        <h2 className={styles.title}>Смена пароля</h2>
        <h4 className={styles.subtitle}>Придумайте новый пароль для входа в аккаунт</h4>
        <PasswordInput
          value={password_current}
          label="Текущий пароль"
          onChange={value => changePassword_current(value)}
          validateFn={validatePassword}
        />
        <PasswordInput
          value={password}
          label="Новый пароль"
          onChange={value => changePassword(value)}
          validateFn={validatePassword}
        />
        <PasswordInput
          value={password_confirm}
          label="Повторите пароль"
          onChange={value => changePassword_confirm(value)}
          validateFn={value => validatePasswordConfirm(password, value)}
        />
        <button
          className="ml-button mt-25"
          disabled={!isFormValid}
          onClick={onSubmit}
        >
          Сохранить
        </button>
      </div>
    </Page>
  )
}
