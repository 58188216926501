import styles from 'components/Coupon/BetInput/styles.module.scss'
import {
  onDecrease,
  onIncrease,
} from 'components/Coupon/BetInput/utils'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { cn } from 'utils'

export const BetInput = ({
  isOpen,
  onOpen,
  value,
  onChange,
  isVip,
  uncontrolled,
  innerRef,
  max: rawMax,
  className,
  placeholder = 'мин. 0.1',
}) => {
  const step = +useSelector(state => state.userBets.plusMinus) || 10
  const max  = +rawMax

  const isValid = !!String(value)

  const valueNum = +value || 0
  const isMax    = valueNum >= max && !isVip
  const isMin    = valueNum === 0

  return (
    <div
      ref={innerRef}
      className={cn(styles.moneyInput, uncontrolled && styles.uncontrolled, className)}
      data-editing={isOpen}
    >
      {!uncontrolled && (
        <button
          className={styles.btn}
          onClick={() => onChange(onDecrease(valueNum, step))}
          disabled={isMin}
        >
          –
        </button>
      )}
      <div
        className={styles.input}
        onClick={onOpen}
      >
        <div className={cn(styles.value, !isValid && styles.invalid)}>
          {isValid ? value : placeholder}
        </div>
        <div className={styles.currency}>BYN</div>
      </div>
      {!uncontrolled && (
        <button
          className={styles.btn}
          onClick={() => onChange(onIncrease(valueNum, step, isVip ? Infinity : max))}
          disabled={isMax}
        >
          +
        </button>
      )}
    </div>
  )
}

BetInput.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  value:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

BetInput.defaultProps = {
  isOpen: false,
  onOpen: () => null,
  value:  '0',
}
