import { Placeholder } from 'components/Placeholder'
import { ModalType } from 'components/SignInModal/utils'
import { useAuthModal } from 'hooks/useAuthModal'
import { getRandomOutcomes } from 'pages/Forecast/Create/utils'
import ForecastItem from 'pages/Forecast/Item'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  changeSelected,
  clear,
  getForecast,
  makeForecast,
  setRandom,
} from 'store/forecast/actions'

const ForecastCreate = ({
  is_already_forecast,
  selected,
  forecast,
  getForecast,
  makeForecast,
  isAuth,
  changeSelected,
  isVerified,
  clear,
  loading,
  user_forecast,
  setRandom,
}) => {
  const openAuthModal = useAuthModal()

  const type         = new Date().getDay() === 5 ? 3 : 2
  const matchesCount = type === 3 ? 16 : 15
  const amount       = type === 3 ? '1 000 000' : '100 000'

  useEffect(() => {
    getForecast(type)

    return () => clear()
  }, [])

  const eventsCount   = forecast?.events?.length || 0
  const selectedCount = Object.keys(selected).length

  const withAuth = callback => (...args) => isAuth ? callback(...args) : openAuthModal(ModalType.UNAUTHORIZED)

  const onChangeForecast  = withAuth(changeSelected)
  const setRandomForecast = withAuth(() => setRandom(getRandomOutcomes(forecast)))

  const isSubmitDisabled = !isVerified || (eventsCount > selectedCount) || is_already_forecast ||
    (!!user_forecast && !!user_forecast.forecast_id)

  const isForecastAvailable = !!eventsCount && !loading && ((!!user_forecast && !user_forecast.forecast_id) || !user_forecast)
  const isForecastDone      = !loading && !!user_forecast && !!user_forecast.forecast_id
  const isForecastEmpty     = !eventsCount && !loading

  const forbiddenType = isForecastDone && 'forecastDone' || isForecastEmpty && 'forecastEmpty'

  return (
    <>
      {isForecastAvailable && (
        <>
          <div className="make-forecast-title">
            {`Сделай верный прогноз на ${matchesCount} матчей`}
            <br />
            и забери <span className="gold">{`${amount} BYN!`}</span>
          </div>
          <button
            className="make-forecast__random-btn"
            onClick={setRandomForecast}
          >
            Случайный выбор
          </button>
          <div className="make-forecast-events">
            {forecast.events.map(event => (
              <ForecastItem
                key={event.id}
                selected={selected?.[event.id] ?? -1}
                event={event}
                onChange={onChangeForecast}
              />
            ))}
          </div>
          <div className="make-forecast__btn">
            <button
              onClick={() => makeForecast(type)}
              className="ml-button"
              disabled={isSubmitDisabled}
            >
              {isSubmitDisabled ? `Выбрано ${selectedCount} из ${eventsCount} исходов` : 'Сделать прогноз'}
            </button>
          </div>
        </>
      )}
      {forbiddenType && <Placeholder name={forbiddenType} />}
    </>
  )
}

const mapStateToProps = state => ({
  is_already_forecast: state.forecast.is_already_forecast,
  selected:            state.forecast.selected,
  forecast:            state.forecast.forecast,
  user_forecast:       state.forecast.user_forecast,
  loading:             state.forecast.loading,
  isAuth:              state.auth.isAuth,
  isVerified:          !!+state.user.info.has_scan,
})

const mapDispatchToProps = {
  getForecast,
  makeForecast,
  changeSelected,
  setRandom,
  clear,
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastCreate)
