import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'
import { Championship } from 'utils/models/Championship'

export const handleChampionships = () => async () => {
  try {
    const { data } = await Api.getChampionships()
    data.forEach(Championship.handle)
  } catch (error) {
    console.error(error)
  }
}

export const getLeagues = id => async dispatch => {
  dispatch({ type: ACTIONS.CHAMPIONSHIP.FETCHING, payload: true })

  try {
    const { data: categories = [] } = await Api.getChampionshipCategories(id)

    dispatch({ type: ACTIONS.CHAMPIONSHIP.GROUPS, payload: categories.filter(category => !+category.parent_id) })
  } catch (e) {
    console.error(e)
  } finally {
    dispatch({ type: ACTIONS.CHAMPIONSHIP.FETCHING, payload: false })
  }
}

export const getCategories = (ids, champId) => async dispatch => {
  dispatch({ type: ACTIONS.CHAMPIONSHIP.FETCHING, payload: true })

  const { data: { leagues } }   = await Api.getChampionshipLeagues()
  const { data: championships } = await Api.getChampionships()

  const arrayFromIds     = ids.split('-')
  const categories       = leagues.filter(league => arrayFromIds.includes(league.id))
  const result           = categories.sort((a, b) => +b.order - +a.order)
  const currentChampName = championships.find(({ id }) => id === champId)?.name

  dispatch({ type: ACTIONS.CHAMPIONSHIP.CATEGORIES, payload: result })
  dispatch({ type: ACTIONS.CHAMPIONSHIP.CURRENT, payload: currentChampName })
  dispatch({ type: ACTIONS.CHAMPIONSHIP.FETCHING, payload: false })
}
