import { ReactComponent as ClearAllIcon } from 'assets/images/coupon/clear-modal.svg'
import { openPopup } from 'components/Popup'

export const clearCouponModal = callback => {
  openPopup({
    title:     'Хотите очистить купон?',
    message:   'Данная операция необратима',
    icon:      ClearAllIcon,
    submitBtn: { title: 'Очистить', callback },
    cancelBtn: { title: 'Отмена' },
  })
}
