import gooseImg from 'assets/images/splash-screen/goose.png'
import { ReactComponent as MxlIcon } from 'assets/images/splash-screen/mxl.svg'
import { ReactComponent as PromotionIcon } from 'assets/images/splash-screen/promotion.svg'
import styles from './styles.module.scss'

export const SplashScreen = ({ visible }) => visible ? (
  <div className={styles.splashScreen}>
    <MxlIcon className={styles.mottoIcon} />
    <div className={styles.promotion}>
      <PromotionIcon className={styles.promotionImg} />
    </div>
    <img
      src={gooseImg}
      className={styles.gooseImg}
      alt="Goose"
    />
  </div>
) : null
