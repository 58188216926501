import ACTIONS from 'store/constants/actions'

export const clearBetData = () => dispatch => {
  dispatch({
    type: ACTIONS.BET_DATA.CLEAR,
  })
}

export const setIsBetFinishedOpen = payload => dispatch => {
  dispatch({
    type: ACTIONS.BET_DATA.SET_IS_OPEN,
    payload,
  })
}
