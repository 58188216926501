import { Redirect } from 'components/Redirect'
import { paths } from 'constants/paths'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { useEffect } from 'react'

export const PaymentPageLegacy = () => {
  const { showModal } = usePaymentsState()

  useEffect(() => {
    showModal()
  }, [])

  return <Redirect to={paths.HOME} />
}
