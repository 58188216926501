import {
  useCallback,
  useState,
} from 'react'

export const useIsFetching = () => {
  const [isFetching, setIsFetching] = useState(false)

  const fetch = useCallback(async (action, ...arg) => {
    setIsFetching(true)

    const response = await action(...arg)

    setIsFetching(false)

    return response
  }, [])

  return [fetch, isFetching]
}