import { dispatch } from 'components/AppProvider'
import { SubmitBtn } from 'components/SubmitBtn'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  addModal,
  closeModal,
} from 'store/modal/actions'
import {
  cn,
  generateId,
} from 'utils'
import styles from './styles.module.scss'
import {
  ErrorAlert,
  mergeProps,
  SuccessAlert,
} from './utils'
import './styles.scss'

const ModalBtn = ({ title, to, callback, className, onClose }) => {
  const Tag = to ? Link : SubmitBtn

  const onClick = async event => {
    const response = await callback?.(event)
    onClose()
    return response
  }

  return title ? (
    <Tag
      className={className}
      onClick={onClick}
      to={to}
    >
      {title}
    </Tag>
  ) : null
}

export const ModalContent = ({
  id,
  icon: Icon,
  title,
  message,
  submitBtn,
  cancelBtn,
  children,
  footer,
}) => {
  const dispatch = useDispatch()

  const onClose = useCallback(() => dispatch(closeModal(id)), [])

  return (
    <div className={styles.popup}>
      <button
        className={styles.closeBtn}
        onClick={onClose}
        aria-label="Close window"
      />
      {Icon && <Icon className={styles.icon} />}
      <div className={styles.text}>
        {title && <div className={styles.title}>{title}</div>}
        {message && <div className={styles.message}>{message}</div>}
      </div>
      {children}
      <ModalBtn
        className={cn('ml-button', 'mt-22')}
        onClose={onClose}
        {...submitBtn}
      />
      {cancelBtn && (
        <ModalBtn
          className="gray underline mt-22"
          onClose={onClose}
          {...cancelBtn}
        />
      )}
      {footer}
    </div>
  )
}

/**
 * @typedef {Object} ModalOptions
 * @property {string} [title]
 * @property {string} [message]
 * @property {ReactComponent | string | Function} [icon]
 * @property {{title: string|false=, callback: Function=, to: string=, className: string=}} [submitBtn]
 * @property {{title: string|false=, callback: Function=, to: string=, className: string=}} [cancelBtn]
 * @property {ReactNode} [footer]
 */

/**
 * @param {ModalOptions} options
 */
export const openPopup = options => {
  const id = generateId()

  dispatch(addModal(id, <ModalContent id={id} {...options} />))
}

/**
 * @param {ModalOptions} options
 */
export const showErrorModal = options => openPopup(mergeProps(ErrorAlert, options))

/**
 * @param {ModalOptions} options
 */
export const showSuccessModal = options => openPopup(mergeProps(SuccessAlert, options))
