import { Checkbox } from 'components/Checkbox'
import OneClickBetsSettings from 'components/Coupon/OneClickBets/OneClickBetsSettings'
import UserBets from 'components/Coupon/Settings/UserBets'
import { PageHeader } from 'components/PageHeader'
import { Portal } from 'components/Portal'
import { useDefineLocation } from 'hooks/useDefineLocation'
import { useTransition } from 'hooks/useTransition'
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  setGoldBet,
  setIsSettingsOpen,
  setLiveOpt,
} from 'store/coupon/actions'
import { changeOneClickBets } from 'store/one-click-bets/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

const CouponSettings = ({
  onSetGoldBet,
  onSetLiveOpt,
  liveOpt,
  isOneClickBets,
  changeOneClickBets,
  isGoldBet,
  isOpen,
  setIsSettingsOpen,
}) => {
  const [acceptSelected, setAcceptSelected] = useState(1)
  const [isClosing, startClosing]           = useTransition(400)

  const { isLive, isTop } = useDefineLocation()

  const isLiveCoupon = isLive || isTop

  const onGoBack = useCallback(() => startClosing(() => setIsSettingsOpen(false)), [])

  const type = useMemo(() => isLiveCoupon ? 'live' : 'line', [isLiveCoupon])

  useEffect(() => {
    setAcceptSelected(liveOpt)
  }, [liveOpt])

  const changeAccept = value => {
    setAcceptSelected(value)
    onSetLiveOpt(value)
  }

  const onChangeOneClickBets = () => changeOneClickBets(isOneClickBets ? 0 : 1)

  return (
    <Portal>
      {isOpen && (
        <div className={cn(styles.couponSettings, isClosing && styles.closing)}>
          <PageHeader
            title="Настройки купона"
            onGoBack={onGoBack}
          />
          <Checkbox
            title="GoldBet"
            checked={(isOneClickBets && isLiveCoupon) || isGoldBet}
            onChange={() => onSetGoldBet(type, +!isGoldBet)}
            disabled={isOneClickBets && isLiveCoupon}
            left
          />
          {isLiveCoupon && (
            <>
              <Checkbox
                title="Ставка в 1 клик"
                checked={isOneClickBets}
                onChange={onChangeOneClickBets}
                className="one-click-bets__checkbox"
                left
              />
              <OneClickBetsSettings isActive={isOneClickBets} />
            </>
          )}
          <div className="coupon__setting-divider">
            Соглашаться с изменением
          </div>
          <Checkbox
            title="При повышении"
            checked={acceptSelected === 2}
            onChange={() => changeAccept(2)}
            radio
            left
          />
          <Checkbox
            title="Никогда"
            checked={acceptSelected === 1}
            onChange={() => changeAccept(1)}
            radio
            left
          />
          <Checkbox
            title="Всегда"
            checked={acceptSelected === 3}
            onChange={() => changeAccept(3)}
            radio
            left
          />
          <UserBets />
        </div>
      )}
    </Portal>
  )
}

const mapStateToProps = state => ({
  liveOpt:        state.coupon.liveOpt,
  isGoldBet:      state.coupon?.coupon?.isGoldBet,
  isOpen:         state.coupon?.isSettingsOpen,
  isOneClickBets: state.oneClickBets.isActive,
})

const mapDispatchToProps = {
  onSetGoldBet: setGoldBet,
  onSetLiveOpt: setLiveOpt,
  changeOneClickBets,
  setIsSettingsOpen,
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponSettings)
