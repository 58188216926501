import styles from 'components/PaymentModal/Oplati/ErrorMessage/styles.module.scss'
import { SUCCESS_MESSAGE } from 'components/PaymentModal/Oplati/utils'
import { cn } from 'utils'

export const OplatiErrorMessage = ({ statusMessage, link }) => statusMessage ? (
  <div
    className={cn(
      styles.statusMessage,
      !link && (statusMessage === SUCCESS_MESSAGE ? styles.success : styles.failed),
    )}
  >
    {statusMessage}
  </div>
) : null
