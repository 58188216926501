import { ReactComponent as HideIcon } from 'assets/images/svg/eye-white-hide.svg'
import { ReactComponent as ShowIcon } from 'assets/images/svg/eye-white.svg'
import { useWagerBonus } from 'hooks/useWagerBonus'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeBalance } from 'store/data/reducers'
import { cn } from 'utils'
import styles from './styles.module.scss'

const HeaderBalance = ({ money, currency, inGame, isVisible, changeBalance }) => {
  const { isActive, balance, percent } = useWagerBonus()

  return (
    <>
      <div
        onClick={changeBalance}
        className={styles.showHide}
      >
        {isVisible ? <ShowIcon /> : <HideIcon />}
      </div>
      <Link
        to="/user"
        className={cn(styles.balance, !isVisible && styles.hidden)}
      >
        <div className={styles.amount}>
          {`${money} ${currency}`}
        </div>
        <div className={styles.inGame}>
          {`В игре: ${inGame || 0} ${currency}`}
        </div>
        {isActive && (
          <div className={styles.wager}>
            {`${balance} ${currency}`}
            <span className={styles.wagerPercent}>{`(${percent}%)`}</span>
          </div>
        )}
      </Link>
    </>
  )
}

const mapStateToProps = state => ({
  currency:  state.user.info.currency,
  money:     state.auth.money,
  inGame:    state.auth.inGame,
  isVisible: state.data.isShowBalance,
})

const mapDispatchToProps = {
  changeBalance,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBalance)