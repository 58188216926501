import { Env } from 'constants/environment'

const ignoredFilenames = ['gtm.js']

export const ignoreErrors = ['DataLayer']
export const dsn          = process.env.REACT_APP_SENTRY_DNS
export const isProd       = process.env.NODE_ENV === Env.PROD

export const beforeSend = event => {
  if (event.exception?.values?.[0]?.stacktrace?.frames?.some(frame => ignoredFilenames.some(f => frame?.filename?.includes(f)))) {
    return null
  }

  return event
}
