import { showErrorModal } from 'components/Popup'
import { getDeclension } from 'utils/declension'

export const TakenStage = {
  SUCCESS: 'success',
  PENDING: 'pending',
  INITIAL: 'initial',
}

export const getTitleString = (status, count) =>
  `${status === TakenStage.SUCCESS ? 'Вернули' : 'Возвращаем'} ${getDeclension([
    `Вашу ${count} проигрышную ставку`,
    `Ваши ${count} проигрышные ставки`,
    `Ваши ${count} проигрышные ставки`,
  ])(count)}`

export const showErrorReturnModal = (message = 'Пожалуйста, попробуйте позже') => {
  showErrorModal({
    title: 'Не удалось вернуть ставки',
    message,
  })
}
