import { useEffect } from 'react'

export const useEventListener = (element, eventType, handler, condition = true) => {
  useEffect(() => {
    if (condition) {
      element.addEventListener(eventType, handler)

      return () => {
        element.removeEventListener(eventType, handler)
      }
    }
  }, [condition])
}