import { AllFilter } from 'pages/Casino/constants'
import {
  createContext,
  useContext,
} from 'react'
import { useSearchParams } from 'react-router-dom'

export const CasinoFilter = {
  CATEGORY: 'category',
  THEME:    'theme',
  PROVIDER: 'provider',
  NONE:     'none',
}

const selectsContext = createContext([CasinoFilter.NONE, v => null])

export const { Provider: SelectContextProvider } = selectsContext

export const useSelectContext = toCompare => {
  const [value, setValue] = useContext(selectsContext)

  const isOpen  = toCompare === value
  const onClose = () => setValue(CasinoFilter.NONE)

  return {
    isOpen,
    onClose,
    onChange: setValue,
  }
}

export const useGamesFilter = key => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activeID = searchParams.get(key) || AllFilter.id

  const setFilter = value => {
    searchParams.set(key, value)
    setSearchParams(searchParams, { replace: true })
  }

  return [activeID, setFilter]
}