import belarusBankImg from 'assets/images/banks/1.svg'
import reshenieBankImg from 'assets/images/banks/10.svg'
import paritetBankImg from 'assets/images/banks/11.svg'
import dobrabitBankImg from 'assets/images/banks/12.svg'
import alfaBankImg from 'assets/images/banks/13.svg'
import belAgroPromBankImg from 'assets/images/banks/2.svg'
import priorBankImg from 'assets/images/banks/3.svg'
import sberBankImg from 'assets/images/banks/4.svg'
import tehnoBankImg from 'assets/images/banks/5.svg'
import belInvestBankImg from 'assets/images/banks/6.svg'
import belWebBankImg from 'assets/images/banks/7.svg'
import mTBankImg from 'assets/images/banks/8.svg'
import belGazBankImg from 'assets/images/banks/9.svg'
import { FastPayoutsTitle } from 'pages/Payments/components/FastPayouts'
import { OplatiTitle } from 'pages/Payments/components/Oplati'
import { OplatiAppLinks } from 'pages/Payments/components/Oplati/AppLinks'
import styles from 'pages/Payments/components/styles.module.scss'
import React from 'react'
import {
  cn,
  importImage,
} from 'utils'

const bankTaxes = [
  { name: 'Беларусбанк', icon: belarusBankImg, tax: false },
  { name: 'БелАгроПромБанк', icon: belAgroPromBankImg, tax: true },
  { name: 'Приорбанк', icon: priorBankImg, tax: false },
  { name: 'СберБанк', icon: sberBankImg, tax: false },
  { name: 'Технобанк', icon: tehnoBankImg, tax: true },
  { name: 'Белинвестбанк', icon: belInvestBankImg, tax: false },
  { name: 'БелВЭБ банк', icon: belWebBankImg, tax: true },
  { name: 'МТБанк', icon: mTBankImg, tax: true },
  { name: 'БелГазпромБанк', icon: belGazBankImg, tax: true },
  { name: 'Банк Решение', icon: reshenieBankImg, tax: true },
  { name: 'Паритетбанк', icon: paritetBankImg, tax: false },
  { name: 'Банк Дабрабыт', icon: dobrabitBankImg, tax: true },
  { name: 'Альфа-Банк', icon: alfaBankImg, tax: true },
]

export const getPayInWaysData = (theme = 'light') => [
  {
    iconSrc: importImage(`banks/${theme}/card.svg`),
    title: <FastPayoutsTitle />,
    content:
             <>
               <p>Выплаты осуществляются круглосуточно.</p>
               <p>Комиссию банка за перевод оплачивает Maxline.</p>
               <p>
                 Для получения выигрыша клиент должен сделать заказ на получение денег на сайте букмекерской конторы.
                 Выплаты производятся только на карты, которые были привязаны к игровому счёту.
               </p>
               <p>
                 Выводить денежные средства возможно только на карту, которая открыта на владельца игрового счёта.
               </p>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Мин. сумма:"
                   data-value="5 BYN"
                 />
                 <div
                   data-title="Выплата:"
                   data-value="от 5 мин до 24 ч"
                 />
               </div>
               <p className={styles.description}>
                 * В связи с тем, что срок зачисления денежных средств на карту устанавливает банк, выплаты на карту
                 МТБанка могут производиться в течение 3-х банковских дней.
               </p>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/oplati.svg`),
    title:   <OplatiTitle />,
    content: <>
               <p>
                 Для получения выигрыша клиент должен зайти в раздел «Заказать на выплату» в личном кабинете и
                 оформить заказ на электронный кошелек «Оплати».
               </p>
               <OplatiAppLinks />
               <p>
                 Вывод средств осуществляется на кошелек «Оплати», с которого было произведено пополнение игрового
                 счета.
               </p>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Выплата:"
                   data-value="от 5 мин до 24 ч"
                 />
               </div>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/banks.svg`),
    title:   'По номеру счёта IBAN',
    content: <>
               <p>
                 Выплата выигрыша осуществляется на карт-счёт, открытый на имя (паспортные данные) владельца игрового
                 счёта.
               </p>
               <div className="payment-info border-top">
                 <div
                   data-title="Мин. сумма:"
                   data-value="10 BYN"
                 />
                 <div
                   data-title="Выплата:"
                   data-value="1 - 2 дня"
                 />
               </div>
               <div>
                 <div className={styles.banksHeader}>
                   <div>Банк</div>
                   <div>Комиссия</div>
                 </div>
                 {bankTaxes.map(({ icon, name, tax }) => (
                   <div
                     key={name}
                     className={styles.bankTax}
                   >
                     <div className={styles.left}>
                       <img
                         src={icon}
                         className={styles.bankImg}
                         alt={name}
                       />
                       <div>{name}</div>
                     </div>
                     <div className={cn(styles.right, tax && styles.needPay)}>
                       {tax ? 'Банк взимает комиссию согласно своих тарифов' : 'Maxline оплачивает комиссию за Вас'}
                     </div>
                   </div>
                 ))}
               </div>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/maxline.svg`),
    title:   'Пункты приёма ставок Maxline',
    content: <>
               <p>
                 Для получения выигрыша клиент должен сделать заказ на получение денег на сайте букмекерской конторы.
                 Получить заказанную сумму можно в пунктах приема ставок, указанных на сайте, сразу после
                 подтверждения заказа. При получении денег необходимо предъявить паспорт.
               </p>
               <p className="bold">Список ППС:</p>
               <ul>
                 <li>Брест, ул. ДЗЕРЖИНСКОГО, д. 50, часть изолированного помещения (комната №15)</li>
                 <li>Витебск, ул. Замковая, 5-11</li>
                 <li>Гомель, ул. Красноармейская 7, помещение 7</li>
                 <li>Гродно, пр. Клецкова, д. 21б-2, часть № 2, помещение № 23</li>
                 <li>Минск, ул. Веры Хоружей, 6А-1Б</li>
                 <li>Могилёв, пер. Пожарный 11, 2-й этаж</li>
                 <li>Орша, ул. Советская, 2В-24, часть изолированного помещения</li>
               </ul>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Мин. сумма:"
                   data-value="1 BYN"
                   data-profit={true}
                 />
                 <div
                   data-title="Выплата:"
                   data-value="от 15 мин до 24 ч"
                 />
               </div>
             </>,
  },
]
