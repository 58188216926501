import { Keyboard } from 'components/Keyboard'
import { AmountInput } from 'components/PaymentModal/AmountController/AmountInput'
import {
  DEFAULT_AMOUNT,
  defaultAmounts,
  getAmountError,
} from 'components/PaymentModal/AmountController/utils'
import { Portal } from 'components/Portal'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { useState } from 'react'
import styles from './styles.module.scss'

export const useAmountController = () => {
  const [value, setValue]             = useState(DEFAULT_AMOUNT)
  const [hasKeyboard, setHasKeyboard] = useState(false)
  const [isTouched, setIsTouched]     = useState(false)

  const { state: { amounts } } = usePaymentsState()

  const onKeyboardClose = () => setHasKeyboard(false)

  const onValidate = () => {
    if (!isTouched) {
      setIsTouched(true)
    }
  }

  const onKeyboardOpen = () => {
    if (!isTouched) {
      setValue(0)
    }

    onValidate()
    setHasKeyboard(true)
  }

  const errorMessage = isTouched && getAmountError(value)

  const Component = (
    <>
      <div>
        <div className={styles.amountController}>
          <AmountInput
            value={value}
            isOpen={hasKeyboard}
            onOpen={onKeyboardOpen}
            invalid={errorMessage}
          />
          <div className={styles.amounts}>
            {(amounts?.length ? amounts : defaultAmounts).map(value => (
              <button
                key={value}
                className={styles.amountBtn}
                onClick={() => setValue(value)}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
        {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      </div>
      {hasKeyboard && (
        <Portal>
          <Keyboard
            value={value}
            onChange={setValue}
            onClose={onKeyboardClose}
            className={styles.keyboard}
            keyBar={false}
            max={false}
          />
        </Portal>
      )}
    </>
  )

  return [Component, { value, onKeyboardClose, onValidate }]
}
