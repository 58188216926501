import { ExternalLink } from 'components/ExternalLink'
import { isWebView } from 'constants/platform'
import styles from 'pages/Payments/components/Oplati/AppLinks/styles.module.scss'
import {
  androidLink,
  iosLink,
} from 'pages/Payments/components/Oplati/AppLinks/utils'
import { cn } from 'utils'

export const OplatiAppLinks = ({ className }) => (
  <div className={cn(styles.links, className)}>
    {!isWebView && (
      <ExternalLink
        to={iosLink}
        className={styles.iosLink}
      />
    )}
    <ExternalLink
      to={androidLink}
      className={styles.androidLink}
    />
  </div>
)
