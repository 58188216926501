import { placeholders } from 'components/Placeholder/utils'
import { useAuthModal } from 'hooks/useAuthModal'
import { useToSignUp } from 'hooks/useToSignUp'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const HEADER_HEIGHT = 96
export const FILTER_HEIGHT = 45

const ExtraLink = ({ to, title, type, className }) => {
  const openAuthModal = useAuthModal()
  const toSignUp      = useToSignUp()

  const handlers = {
    openAuthModal,
    toSignUp,
  }

  const Tag     = to ? Link : 'button'
  const onClick = handlers[type]

  return (
    <Tag
      {...(to && { to })}
      {...(onClick && { onClick })}
      className={className}
    >
      {title}
    </Tag>
  )
}

// TODO need refactoring
export const Placeholder = ({ name, marginTop }) => {
  const {
          title,
          subTitle,
          link,
          Icon,
          top = FILTER_HEIGHT,
          extraLink,
          className,
        } = placeholders[name] || placeholders.default

  return (
    <div
      className={cn(styles.placeholder, styles[className])}
      style={{ top: `${marginTop || HEADER_HEIGHT + top}px` }}
    >
      <div className={styles.placeholderWrapper}>
        {Icon && <Icon className={styles.icon} />}
        <div className={styles.text}>
          <div className={styles.title}>
            {title}
          </div>
          <div className={styles.subtitle}>
            {subTitle}
          </div>
        </div>
        {link && <ExtraLink className={cn('ml-button', styles.link)} {...link} />}
        {extraLink && <ExtraLink className={styles.extraLink} {...extraLink} />}
      </div>
    </div>
  )
}
