import { Api } from 'api/Api'
import { STATUSES } from 'constants/statuses'
import { CashbackActionTypes } from 'store/cashback/types'
import { getProgress } from 'store/cashback/utils'

export const setCashback = payload => ({ type: CashbackActionTypes.SET, payload })
export const setStatuses = payload => ({ type: CashbackActionTypes.SET_STATUSES, payload })

export const getCashback = () => async (dispatch, getState) => {
  const { auth: { isAuth }, cashback: { statuses } } = getState()

  try {
    if (!statuses.length) {
      const { data, status } = await Api.getCashbackStatuses()
      dispatch(setStatuses(status === STATUSES.SUCCESS ? data : []))
    }

    if (isAuth) {
      const { data, status } = await Api.getCashbackStatus()
      dispatch(setCashback(status === STATUSES.SUCCESS && { ...data, progress: getProgress(data) }))
    }
  } catch (e) {
    console.error(e?.message)
  }
}
