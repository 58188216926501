import { createSelector } from 'reselect'

export const all = state => state.casino

export const selectProviders       = state => all(state).providers
export const selectCategories      = state => all(state).categories
export const selectThemes          = state => all(state).themes
export const selectThemesInfo      = state => all(state).themesInfo
export const selectGames           = state => all(state).games
export const selectRecentGames     = state => all(state).recentGames.games
export const selectGamesSearch     = state => all(state).gamesSearch
export const selectFavorites       = state => all(state).favorites
export const selectCounters        = state => all(state).counters
export const selectMaxlinePay      = state => all(state).game_for_pay
export const selectAppOnlyGamesIDs = state => all(state).appOnlyGamesIDs

export const selectFreeSpinGames = createSelector(
  [selectGames, selectAppOnlyGamesIDs],
  (games, appOnlyIds) => {
    const appOnlyIdsObj = appOnlyIds.reduce((acc, id) => ({ ...acc, [id]: true }), {})
    return games.map(game => ({ ...game, appOnly: Boolean(appOnlyIdsObj[game.id]) }))
  },
)
