import { DatePicker } from 'components/DatePicker'
import { DateSwitcher } from 'components/DateSwitcher'
import { Placeholder } from 'components/Placeholder'
import { SearchSports } from 'components/SearchSports'
import SwipeModal from 'components/SwipeModal'
import { popSportIds } from 'constants/popular-sports'
import {
  addDays,
  format,
  subDays,
} from 'date-fns'
import { LineResultEvent } from 'pages/Results/line/Event'
import { SportPicker } from 'pages/Results/line/SportPicker'
import { ModalType } from 'pages/Results/line/utils'
import { handleLineResults } from 'pages/Results/utils'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  getLineResults,
  getLineResultsSports,
} from 'store/results/actions'
import { LeagueListItem } from '../live/leagues-list/leagueItem'

const ResultLine = ({ sports, getLineResultsSports, getLineResults, data }) => {
  const [activeSport, setActiveSport]   = useState()
  const [activeDate, setActiveDate]     = useState(new Date())
  const [openedFilter, setOpenedFilter] = useState(ModalType.NONE)

  const onModalClose = () => setOpenedFilter(ModalType.NONE)

  const onChangeFilter = useCallback(nextFilter => {
    setActiveSport(nextFilter)
    onModalClose()
  }, [])

  const dateStepForward  = () => setActiveDate(addDays(activeDate, 1))
  const dateStepPrevious = () => setActiveDate(subDays(activeDate, 1))

  useEffect(() => {
    const date = format(activeDate, 'yyyy-MM-dd')

    getLineResultsSports(date)
    activeSport && getLineResults(date, [activeSport])
  }, [activeSport, activeDate])

  const popSports = useMemo(() => sports
    .filter(({ id }) => popSportIds.includes(+id))
    .sort((left, right) => left.id - right.id), [sports.length])

  useEffect(() => {
    if (popSports.length && !activeSport) {
      setActiveSport(popSports[0].id)
    }
  }, [popSports.length, activeSport])

  const eventsByLeagues = handleLineResults(data)

  const leagues = Object.keys(eventsByLeagues)

  return (
    <div className="result-soon-line-content">
      <SportPicker
        list={popSports}
        active={activeSport}
        onChange={setActiveSport}
        onShowAll={() => setOpenedFilter(ModalType.SPORTS)}
      />
      <DateSwitcher
        goPrevious={dateStepPrevious}
        goNext={dateStepForward}
        openPicker={() => setOpenedFilter(ModalType.CALENDAR)}
        activeDate={activeDate}
        pastOnly
      />
      <div className="p-8">
        {leagues.length ? leagues.map(name => (
          <LeagueListItem
            key={name}
            name={name}
          >
            {eventsByLeagues[name].map(event => <LineResultEvent key={event.id} {...event} />)}
          </LeagueListItem>
        )) : <Placeholder name="search" />}
      </div>
      <SearchSports
        open={openedFilter === ModalType.SPORTS}
        sports={sports}
        activeSportId={activeSport}
        onCancel={onModalClose}
        onSelect={onChangeFilter}
      />
      <SwipeModal
        isOpen={openedFilter === ModalType.CALENDAR}
        onClose={onModalClose}
      >
        <DatePicker
          value={activeDate}
          onChange={date => {
            setActiveDate(date)
            setTimeout(onModalClose, 250)
          }}
        />
      </SwipeModal>
    </div>
  )
}

const mapStateToProps = state => ({
  sports: state.lineResults.result_sports,
  data:   state.lineResults.data,
})

const mapDispatchToProps = {
  getLineResults,
  getLineResultsSports,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultLine)
