import { STATUSES } from 'constants/statuses'
import ACTIONS from 'store/constants/actions'
import {
  emptyState,
  getCouponIsset,
  initialState,
  setCoupon,
} from 'store/coupon/utils'

export const couponReducer = (state = initialState, { type, data, status, code } = {}) => {
  switch (type) {
    case ACTIONS.COUPON.UPDATE: {
      const hasData = Object.keys(data).length

      const nextState = {
        ...(hasData ? data : { bet_data: state.bet_data }),
        status:         hasData ? status : STATUSES.SUCCESS,
        code:           hasData ? code : 200,
        isSettingsOpen: state.isSettingsOpen,
        isset:          getCouponIsset(data?.coupon?.items),
      }

      return setCoupon(nextState)
    }

    case ACTIONS.COUPON.IS_SETTINGS_OPEN: {
      return { ...state, isSettingsOpen: data }
    }

    case ACTIONS.COUPON.ERROR: {
      return { ...state, ...data }
    }

    case ACTIONS.COUPON.CHANGE_LIVE_OPT: {
      return { ...state, liveOpt: data }
    }

    case ACTIONS.COUPON.RESET: {
      return emptyState
    }

    default:
      return state
  }
}
