import { ReactComponent as GooseIcon } from 'assets/images/header/goose.svg'
import { ReactComponent as WalletIcon } from 'assets/images/header/pay.svg'
import { PaymentModal } from 'components/PaymentModal'
import { usePaymentsState } from 'hooks/usePaymentsState'
import styles from './styles.module.scss'

export const HeaderPay = () => {
  const { showModal } = usePaymentsState()

  return (
    <>
      <button
        className={styles.paymentLink}
        onClick={showModal}
        aria-label="Payments"
      >
        <div className={styles.face}>
          <div className={styles.imgsWrapper}>
            <div className={styles.imgs}>
              <WalletIcon />
              <GooseIcon />
            </div>
          </div>
        </div>
        <div className={styles.back} />
      </button>
      <PaymentModal />
    </>
  )
}
