import { parseJSON } from 'utils/parse-json'

export const convertScores = scores => scores.reduce((acc, { event, name, content: json }) => {
  const { general, periods, first, second } = parseJSON(json)

  if (name === 'score') {
    if (general) {
      acc[event] = `${general.first}:${general.second}`
    }

    if (periods) {
      acc[event] = `${acc[event]} (${periods.map(({ first, second }) => `${first}:${second}`).join(', ')})`
    }
  }

  if (name === 'points') {
    acc[event] = `${acc[event] ? `${acc[event]} ` : ''}${first}:${second}`
  }

  return acc
}, {})

export const findNotCalcBets = (bets = []) => {
  const timeNow = Date.now()

  return bets.reduce((acc, { status, details }) => status === 0
    ? [...acc, ...details.filter(({ event_ts }) => (event_ts * 1000) <= timeNow).map(({ event_id }) => event_id)]
    : acc, [])
}
