import { Page } from 'components/Page'
import { useTabs } from 'hooks/useTabs'
import { useTheme } from 'hooks/useTheme'
import {
  PaymentScreen,
  paymentTabs,
} from 'pages/Payments/utils'
import React from 'react'
import TEXT from 'store/constants/text'

export const PaymentsPage = () => {
  const { theme } = useTheme()

  const [Tabs, activeTab] = useTabs(paymentTabs)

  const Screen = PaymentScreen[activeTab]

  return (
    <Page
      title={TEXT.payments.title}
      bg="gray"
    >
      <Tabs className="p-8" />
      <section className="p-8">
        <Screen theme={theme} />
      </section>
    </Page>
  )
}
