import TextInput from 'components/Field/TextInput'
import {
  CODE_LENGTH,
  validate,
} from 'components/SignUp/ConfirmPhone/utils'
import Timeout from 'components/Timeout'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { confirmPhoneCode } from 'store/user/actions'
import { Statistics } from 'utils/models/Statistics'

export const ConfirmPhoneBlock = ({
  ttl,
  resendCode,
  isTimeoutActive,
  setIsTimeoutActive,
  onSubmit,
  promoCode,
  children,
  onSuccess,
}) => {
  const [code, setCode] = useState('')

  const navigate = useNavigate()

  const onChange = dirtyValue => {
    const value = dirtyValue.replace(/\D/g, '')

    if (value.length <= CODE_LENGTH) {
      setCode(value)
    }
  }

  return (
    <section className="register-confirm-code-form">
      <div className="confirm-code-container">
        <TextInput
          value={code}
          label="Код из смс"
          maxLength={CODE_LENGTH}
          onChange={onChange}
          validateFn={validate}
          className="mt-22"
        />
      </div>
      <button
        className="ml-button mt-22"
        onClick={() => onSubmit(code, navigate, Statistics.getDuration(Statistics.keys.signUp), promoCode, onSuccess)}
        disabled={validate(code)}
      >
        Завершить регистрацию
      </button>
      <Timeout
        isActive={isTimeoutActive}
        setIsActive={setIsTimeoutActive}
        duration={ttl}
        onRefresh={resendCode}
      />
      {children}
    </section>
  )
}

ConfirmPhoneBlock.propTypes = {
  isTimeoutActive:    PropTypes.bool,
  onSubmit:           PropTypes.func,
  resendCode:         PropTypes.func,
  setIsTimeoutActive: PropTypes.func,
  ttl:                PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ConfirmPhoneBlock.defaultProps = {
  isTimeoutActive:    false,
  onSubmit:           () => null,
  resendCode:         () => null,
  setIsTimeoutActive: () => null,
  ttl:                0,
}

const mapDispatchToProps = {
  onSubmit: confirmPhoneCode,
}

export default connect(null, mapDispatchToProps)(ConfirmPhoneBlock)
