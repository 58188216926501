import { paths } from 'constants/paths'
import { isWebView } from 'constants/platform'
import { useCallback } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectIsNewSignUp } from 'store/config/selectors'
import { setModalKey } from 'store/new-sign-up/actions'

const MODAL_KEY = 'SIGN_UP_FORM'

export const useToSignUp = () => {
  const dispatch    = useDispatch()
  const isNewSignUp = useSelector(selectIsNewSignUp)

  const navigate = useNavigate()

  const isModal = isNewSignUp && !isWebView

  return useCallback(
    () => isModal ? dispatch(setModalKey(MODAL_KEY)) : navigate(paths.SIGN_UP),
    [isModal],
  )
}
