import { ExternalLink } from 'components/ExternalLink'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const LinksGroup = ({ links, dark, className }) => (
  <div className={cn(styles.linksGroup, dark && styles.dark, className)}>
    {links.map(({ link, Icon, title }) => (
      <ExternalLink
        className={styles.link}
        key={link}
        to={link}
        aria-label={title}
      >
        <Icon className={styles.icon} />
        {title && <span className={styles.title}>{title}</span>}
      </ExternalLink>
    ))}
  </div>
)
