import { Api } from 'api/Api'
import { STATUSES } from 'constants/statuses'
import {
  convertScores,
  findNotCalcBets,
} from 'pages/UserHistoryPage/Bets/History/converter'
import {
  periods,
  types,
} from 'pages/UserHistoryPage/Casino/History/utils'

export const initialState = {
  bets:      [],
  events:    [],
  leagues:   [],
  scores:    {},
  payReturn: {},
  isFetched: false,
}

export const INITIAL_PAGE   = 1
export const BETS_PER_PAGE  = 20
export const FETCH_INTERVAL = 10000

export const getTypeFilters = (action, value, callback) => [
  { title: types[2], action: () => action(2), checked: +value === 2, callback },
  { title: types[1], action: () => action(1), checked: +value === 1, callback },
  { title: types[0], action: () => action(0), checked: +value === 0, callback },
]

export const getPeriodFilters = (action, value, callback) => [
  { title: periods[0], action: () => action(0), checked: +value === 0, callback },
  { title: periods[1], action: () => action(1), checked: +value === 1, callback },
  { title: periods[2], action: () => action(2), checked: +value === 2, callback },
]

const arrayToIdObj = array => array.reduce((acc, element) => ({ ...acc, [element.id]: element }), {})
const filterBets   = (type, bets) => type === 2 ? bets : bets.filter(bet => bet.betType === type) || []

export const transformBets = (type, { bets, events, leagues, scores, payReturn } = {}) => {
  if (!events.length || !leagues.length || !bets.length) {
    return []
  }

  const payReturnByEventId = Object
    .entries(payReturn)
    .reduce((acc, [key, value]) => ({ ...acc, [key.replace(/\..+/g, '')]: value }), {})

  const eventsObj    = arrayToIdObj(events)
  const leaguesObj   = arrayToIdObj(leagues)
  const filteredBets = filterBets(type, bets)

  return filteredBets.map(bet => ({
    ...bet,
    payReturn: payReturnByEventId[bet.bet_id],
    details:   bet.details.map(detail => ({
      ...detail,
      event:  eventsObj[detail.event_id],
      league: leaguesObj[detail.league_id],
      score:  scores?.[detail.event_id],
    })),
  })).reverse()
}

const getBetHistory = async (period, notCalc) => {
  try {
    const { data: { bets, leagues, events } } = await Api.betHistory(period, notCalc)
    return { bets, leagues, events }
  } catch {
    return { bets: [], leagues: [], events: [] }
  }
}

const getScores = async bets => {
  const notCalcBets = findNotCalcBets(bets)

  if (notCalcBets.length) {
    try {
      const { data } = await Api.getResults(notCalcBets.join('-'))
      return convertScores(data?.results || [])
    } catch {
      return {}
    }
  }

  return {}
}

const getPayReturn = async () => {
  const { data, status } = await Api.getMaxlinePayReturn()
  return status === STATUSES.SUCCESS ? data : {}
}

export const getHistory = async (period, notCalc) => {
  try {
    const betHistory = await getBetHistory(period, notCalc)
    const scores     = await getScores(betHistory.bets)
    const payReturn  = await getPayReturn()

    return { ...betHistory, scores, payReturn, isFetched: true }
  } catch {
    return { ...initialState, isFetched: true }
  }
}
