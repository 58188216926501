import { team2RegExp } from 'pages/Event/EventById/utils'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const EventLink = ({ live, teams, turn, sportId }) => (
  <div className={styles.link}>
    {teams.map(({ name, img, index }) => (
      <div
        key={name}
        className={cn(styles.team, turn === index && styles.turn, styles[`sport${sportId}`])}
      >
        {img && (
          <img
            className={styles.teamImg}
            src={img}
            alt={name}
          />
        )}
        <div className={cn(styles.teamName, !teams[1]?.name && styles.multiLine)}>
          {live ? name.replace(team2RegExp, '') : name}
        </div>
      </div>
    ))}
  </div>
)
