import { BetInput } from 'components/Coupon/BetInput'
import { CouponControls } from 'components/Coupon/Live/Footer/Controls'
import { MakeBetButton } from 'components/Coupon/MakeBetBtn'
import { MaxAmount } from 'components/Coupon/MaxAmount'
import { TotalCoeff } from 'components/Coupon/TotalCoeff'
import { useCouponKeyboard } from 'components/Coupon/utils'
import Keyboard from 'components/Keyboard'
import { useEffect } from 'react'
import styles from './styles.module.scss'

export const CouponFooter = ({
  coupon,
  amount,
  onAmountChange,
  makeBet,
  onClose,
  children,
  onClear,
  freeBet,
}) => {
  const { inputRef, keyboardRef, setHasKeyboard, hasKeyboard } = useCouponKeyboard()

  const totalWin    = +((coupon.totalValue * amount).toFixed(2))
  const betsCount   = coupon.items.length
  const booster     = coupon.bonus
  const isSingleBet = betsCount === 1

  useEffect(() => {
    if (betsCount <= 3) {
      setHasKeyboard(true)
    }
  }, [])

  return (
    <>
      <div
        className={styles.couponFooter}
        data-single={isSingleBet}
      >
        <div className={styles.left}>
          <div className={styles.inputWrapper}>
            <BetInput
              innerRef={inputRef}
              max={coupon.max}
              value={amount}
              isOpen={hasKeyboard}
              onOpen={() => setHasKeyboard(true)}
              onChange={onAmountChange}
            />
            <div className={styles.numbers}>
              {!isSingleBet && (
                <TotalCoeff
                  value={coupon.totalValue}
                  booster={booster}
                  freeBet={freeBet}
                />
              )}
              <MaxAmount
                onChange={onAmountChange}
                value={coupon.max}
              />
            </div>
          </div>
          {children}
          <MakeBetButton
            onSubmit={makeBet}
            possibleWin={totalWin}
            booster={coupon.bonus}
            freeBet={freeBet}
            disabled={!+coupon?.max}
          />
        </div>
        <CouponControls
          single={isSingleBet}
          onClose={onClose}
          onClear={onClear}
        />
      </div>
      {hasKeyboard && (
        <Keyboard
          innerRef={keyboardRef}
          min={0.1}
          max={coupon.max}
          onClose={() => setHasKeyboard(false)}
          onChange={onAmountChange}
          value={amount}
        />
      )}
    </>
  )
}
