export const DocumentReadyState = {
  LOADING:     'loading',
  INTERACTIVE: 'interactive',
  COMPLETE:    'complete',
}

export const EventType = {
  LIVE: '1',
  LINE: '0',
}
