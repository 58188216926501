export const CHAMP_PREFIX = 'championship-'
const FAVORITES           = 'favorites'

export const getFavoritesIfExist = count => ({
  live: count ? [{ id: FAVORITES, name: 'Избранное', countEvents: count, className: FAVORITES }] : [],
  line: [],
})

export const scrollToCenter = containerRef => {
  const container = containerRef.current

  if (container) {
    const activeLink = container.querySelector('.live-sports__tab-link.active')

    if (activeLink) {
      const position       = activeLink.offsetLeft
      const containerWidth = container.offsetWidth
      const left           = position - (containerWidth / 2) + (activeLink.offsetWidth / 2)

      container.scrollTo({ left, behavior: 'smooth' })
    }
  }
}
