import ACTIONS from 'store/constants/actions'

const initialState = {
  selected_sports: {},
  result_sports:   [],
  data:            [],
  selected_date:   '',
}

export const lineResultsReduces = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.RESULT_FROM_MARAPHON.INIT_STATE:
      return { ...initialState }

    case ACTIONS.RESULT_FROM_MARAPHON.GET_SPORTS_LINE:
      return {
        ...state, result_sports: action.data,
      }

    case ACTIONS.RESULT_FROM_MARAPHON.CHANGE_SELECTED_SPORT: {
      const { id } = action.sport

      const selected_sports = { ...state.selected_sports }
      if (!selected_sports.hasOwnProperty(id)) {
        selected_sports[id] = true
      } else {
        delete selected_sports[id]
      }

      return { ...state, selected_sports }
    }

    case ACTIONS.RESULT_FROM_MARAPHON.RESET_ALL_SELECTED_FILTERS:
      return { ...state, selected_sports: {} }

    case ACTIONS.RESULT_FROM_MARAPHON.GET_LINE: {
      return {
        ...state, data: action.data,
      }
    }

    case ACTIONS.RESULT_FROM_MARAPHON.CHANGE_NO_ONE_SPORT: {
      return {
        ...state, data: [],
      }
    }

    case ACTIONS.RESULT_FROM_MARAPHON.CHANGE_SELECTED_DATE: {
      return {
        ...state, selected_date: action.date,
      }
    }

    case ACTIONS.RESULT_FROM_MARAPHON.FETCHING:
      return { ...state, selected_date: { ...state.selected_date, fetching: !state.selected_date.fetching } }

    default:
      return state
  }
}
