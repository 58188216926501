import Loading from 'components/Loading'
import { Page } from 'components/Page'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from './styles.module.scss'

const url = 'https://maxline.by/event/grid/'

export const LeagueGridPage = () => {
  const [isLoading, setIsLoading] = useState(true)

  const { id } = useParams()

  return (
    <Page title="Турнирная сетка">
      {isLoading && <Loading />}
      <iframe
        className={styles.leagueGrid}
        src={`${url}${id}`}
        onLoad={() => setIsLoading(false)}
      />
    </Page>
  )
}
