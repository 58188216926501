import { GooseAnimation } from 'components/GooseAnimation'
import { Placeholder } from 'components/Placeholder'
import GroupContainer from 'pages/Home/Line/Championship/GroupContainer'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { getLeagues } from 'store/championship/actions'

const Championship = ({ getLeagues, groups, isFetching, id }) => {
  useEffect(() => {
    if (!groups.length) {
      getLeagues(id)
    }
  }, [id])

  return (
    <>
      {(isFetching && <GooseAnimation className="absolute-center" />) || (!!groups.length && (
          groups.map(({ id: groupId, name, leagues }) =>
            <GroupContainer
              key={groupId}
              name={name}
              leagueIds={leagues}
              champId={id}
            />))
      ) || <Placeholder name="lineBySport" />}
    </>
  )
}

const mapStateToProps = state => ({
  groups:     state.championship.groups,
  isFetching: state.championship.isFetching,
})

const mapDispatchToProps = {
  getLeagues,
}

export default connect(mapStateToProps, mapDispatchToProps)(Championship)
