import { Bet } from 'components/Coupon/Line/Bet'
import { LINE_COUPON_TYPE } from 'components/Coupon/Line/utils'
import { getIsBetOutdated } from 'components/Coupon/utils'
import { useDispatch } from 'react-redux'
import { delCoupon } from 'store/coupon/actions'

export const BetExpress = ({
  onClose,
  item: { nameEvent, idEvent, nameValue, value: koeff, time, status },
}) => {
  const dispatch = useDispatch()
  const onDelete = () => dispatch(delCoupon(LINE_COUPON_TYPE, idEvent))

  return (
    <Bet
      id={idEvent}
      started={getIsBetOutdated(status)}
      title={nameValue}
      onClose={onClose}
      onDelete={onDelete}
      coefficient={koeff}
      description={nameEvent}
      time={time}
    />
  )
}