import { GooseAnimation } from 'components/GooseAnimation'
import { Placeholder } from 'components/Placeholder'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { LineLeaguesBySport } from 'pages/Home/components/Event/LineLeagueBySport'
import {
  getLeagueData,
  renderEvents,
} from 'pages/Home/Line/BySport/utils'
import {
  useLayoutEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { getLeagueById } from 'store/line/actions'
import styles from './styles.module.scss'

let prevLeagueData = {}

const LineBySport = ({ id, period, getLeagueById, leaguesById, isFetching, betOnOur }) => {
  const [leaguesData, setLeaguesData] = useState(prevLeagueData)
  const [fetchingId, setFetchingId]   = useState(0)

  const leagues = leaguesById[id] || []

  const onChangeLeagues = value => {
    const nextState = prevLeagueData = value ? { ...leaguesData, ...value } : {}
    setLeaguesData(nextState)
  }

  useDidUpdate(() => {
    onChangeLeagues()
  }, [period, id])

  useLayoutEffect(() => {
    getLeagueById(id, period, betOnOur)
  }, [id, period, betOnOur])

  const onLeagueClick = async id => {
    const league = leaguesData[id]

    if (!league) {
      setFetchingId(id)
    }

    const nextLeagueData = league
      ? { ...league, isOpen: !league.isOpen }
      : await getLeagueData(id, period, betOnOur)

    onChangeLeagues({ [id]: nextLeagueData })
    setFetchingId(0)
  }

  return (
    (!!leagues.length && (
      <div className={styles.leagues}>
        {leagues.map(({ id, name, countEvents, countryCode, description, divider }) => (
          divider || (
            <LineLeaguesBySport
              key={id}
              name={name}
              countEvents={countEvents}
              countryCode={countryCode}
              description={description}
              opened={leaguesData[id]?.isOpen}
              onClick={() => onLeagueClick(id)}
              fetching={fetchingId === id}
            >
              {renderEvents(leaguesData[id])}
            </LineLeaguesBySport>
          )
        ))}
      </div>
    )) || (isFetching && <GooseAnimation className="absolute-center" />) || (
      <Placeholder
        name={betOnOur ? 'betOnOur' : 'lineBySport'}
        marginTop={20}
      />
    )
  )
}

const mapStateToProps = state => ({
  period:      state.sportsFilter.period,
  leaguesById: state.line.leaguesById,
  isFetching:  state.line.isFetching,
  betOnOur:    state.data.betOnOur.line,
})

const mapDispatchToProps = {
  getLeagueById,
}

export default connect(mapStateToProps, mapDispatchToProps)(LineBySport)
