import ACTIONS from 'store/constants/actions'

export const ONE_CLICK_BETS_KEYS = {
  AMOUNT:    'one-click-bets_amount',
  IS_ACTIVE: 'one-click-bets_is-active',
}

export const isOneClickBetsActive  = () => !!+(localStorage.getItem(ONE_CLICK_BETS_KEYS.IS_ACTIVE) || '0')
export const getOneClickBetsAmount = () => +(localStorage.getItem(ONE_CLICK_BETS_KEYS.AMOUNT) || '0')

const initialAmount   = getOneClickBetsAmount()
const initialIsActive = isOneClickBetsActive()

const initialState = {
  amount:   initialAmount,
  isActive: initialIsActive,
}

export default function oneClickBets(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.ONE_CLICK_BETS.AMOUNT_UPDATE:
      const nextAmount = String(payload || 0)

      localStorage.setItem(ONE_CLICK_BETS_KEYS.AMOUNT, nextAmount)

      return { ...state, amount: nextAmount }

    case ACTIONS.ONE_CLICK_BETS.IS_ACTIVE_UPDATE:
      localStorage.setItem(ONE_CLICK_BETS_KEYS.IS_ACTIVE, String(payload))

      return { ...state, isActive: !!(+payload) }

    default:
      return state
  }
}
