import { format } from 'date-fns'
import styles from './styles.module.scss'

export const CalculationModal = ({ name, sum, percent, onClose }) => {
  const date  = new Date()
  const month = format(date, 'LLLL')
  const time  = format(date, 'dd.MM.yy HH:mm')

  return (
    <div className={styles.calculationModal}>
      <button
        className={styles.closeBtn}
        onClick={onClose}
        aria-label="Close"
      />
      <h3 className={styles.title}>{`Предварительный расчет кэшбэка за ${month}`}</h3>
      <div className={styles.main}>
        <div>{name}</div>
        <div className={styles.right}>
          {`${percent}% (${sum} BYN)`}
        </div>
      </div>
      <div className={styles.bottom}>
        {`Расчёт актуален на ${time}`}
      </div>
    </div>
  )
}
