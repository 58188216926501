export const getProgressWidth = value => {
  if (value < 0 || !value || typeof value !== 'number') {
    return '0%'
  }

  if (value > 100) {
    return '100%'
  }

  return `${value}%`
}
