import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'
import { logOut } from 'store/user/actions'
import { setWagerBonus } from '../data/reducers'

export const updateAuth = payload => ({ type: ACTIONS.AUTH.UPDATE, payload })

export const setIsOnline = payload => ({ type: ACTIONS.AUTH.IS_ONLINE, payload })

export const checkIsAuth = () => async (dispatch, getState) => {
  try {
    const { auth: { isAuth } }      = getState()
    const { data, data: { wager } } = await Api.isAuth()

    dispatch(updateAuth(data))
    dispatch(setWagerBonus(wager))

    if (!data.isAuth && isAuth) {
      dispatch(logOut())
    }
  } catch (e) {
    console.error(e)
  }
}
