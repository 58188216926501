import ACTIONS from 'store/constants/actions'

export const addModal = (id, content) => dispatch => dispatch({
  type:    ACTIONS.MODAL.ADD,
  payload: { id, content },
})

export const closeModal = payload => dispatch => dispatch({
  type: ACTIONS.MODAL.CLOSE,
  payload,
})
