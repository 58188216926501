import { showErrorModal } from 'components/Popup'
import { add } from 'date-fns'
import { formatTime } from 'utils/time'

export const WagerStatus = {
  NONE:        0,
  ACTIVE:      1,
  USED:        2,
  DEACTIVATED: 3,
}

export const getEndTime = (total, target) => {
  const targetDate = new Date(target)
  const endDate    = add(targetDate, { days: total })

  return formatTime(endDate)
}

export const showWagerError = (message = 'При отправке запроса возникла ошибка. Пожалуйста, попробуйте позже.') => {
  showErrorModal({
    message,
    submitBtn: {
      title: 'Назад',
    },
  })
}
