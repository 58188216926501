import { Api } from 'api/Api'
import { showErrorModal } from 'components/Popup'
import { STATUSES } from 'constants/statuses'
import BaseForm from 'pages/Takemoney/Forms/Base'
import { PAYOUT_EXTRA_LOCK_MS } from 'pages/Takemoney/Forms/Card/utils'
import { onSuccessPayout } from 'pages/Takemoney/Forms/utils'
import React from 'react'
import { connect } from 'react-redux'
import { getDetails } from 'store/payments/actions'
import { getInfo } from 'store/user/actions'
import { sleep } from 'utils/time'

class PpsForm extends BaseForm {
  static defaultProps = {
    minAmount: 1,
    maxAmount: 10000,
  }

  constructor(props) {
    super(props)
    this.initialState = {
      selectedCash: null,
      amount:       this.props.minAmount,
      toPay:        this.props.minAmount * this.PAY_FACTOR,
      tax:          this.props.minAmount * this.TAX_FACTOR,
      messages:     '',
    }
    this.state        = { ...this.initialState, cashes: [] }
  }

  async componentDidMount() {
    const { data: { cashes } = {} } = await Api.getCashes()

    if (cashes) {
      this.setState({ cashes })
    }
  }

  changeCash = selectedCash => this.setState({ selectedCash })

  onSubmit = async () => {
    const { amount, selectedCash: cash } = this.state
    const { getInfo, getDetails }        = this.props

    const { status, data: { messages } } = await Api.cashOut({ amount, cash })

    if (status === STATUSES.SUCCESS) {
      onSuccessPayout()
      getDetails()
      getInfo()
      this.setState(this.initialState)
      return await sleep(PAYOUT_EXTRA_LOCK_MS)
    }

    showErrorModal({
      message:   messages.toString(),
      submitBtn: { title: 'Продолжить' },
    })
  }

  render() {
    const { selectedCash } = this.state

    return (
      <div className="description__payment-item">
        {this.getAmountRow()}
        {this.getPpsRow()}
        {this.getTaxRow(!selectedCash)}
        {this.getOrderButton(!selectedCash)}
      </div>
    )
  }
}

const mapDispatchToProps = {
  getInfo,
  getDetails,
}

export default connect(null, mapDispatchToProps)(PpsForm)
