import { ReactComponent as ErrorIcon } from 'assets/images/popup/error.svg'
import { ReactComponent as SuccessIcon } from 'assets/images/popup/success.svg'

export const ErrorAlert = {
  title:     'Ошибка',
  icon:      ErrorIcon,
  submitBtn: {
    title: 'Повторить',
  },
}

export const SuccessAlert = {
  title:     'Успешно',
  icon:      SuccessIcon,
  submitBtn: {
    title: 'Продолжить',
  },
}

/**
 * @param {ModalOptions} left
 * @param {ModalOptions} right
 *
 * @returns ModalOptions
 */
export const mergeProps = (left, right) => {
  const { submitBtn: aSubmitBtn, cancelBtn: aCancelBtn, ...aProps } = left
  const { submitBtn: bSubmitBtn, cancelBtn: bCancelBtn, ...bProps } = right

  return {
    submitBtn: { ...aSubmitBtn, ...bSubmitBtn },
    cancelBtn: { ...aCancelBtn, ...bCancelBtn },
    ...aProps,
    ...bProps,
  }
}
