import { Api } from 'api/Api'
import { useCentrifugoSub } from 'components/Centrifugo'
import {
  AnimationStage,
  AnimationType,
  getAnimation,
} from 'components/Halloween2024/utils'
import { Lottie } from 'components/Lottie'
import {
  useEffect,
  useState,
} from 'react'
import { CentrifugoChannel } from 'utils/centrifugo/utils'
import styles from './styles.module.scss'

export const Halloween2024 = ({ user }) => {
  const [type, setType]   = useState(AnimationType.DEFAULT)
  const [stage, setStage] = useState(AnimationStage.DEFAULT)

  const { promo_code, type: updatedType } = useCentrifugoSub(CentrifugoChannel.PROMO_CODE, { user })

  useEffect(() => {
    if (+updatedType && type !== updatedType) {
      setType(updatedType)
    }
  }, [updatedType])

  const onClick = async ({ currentTarget: node }) => {
    node.style.pointerEvents = 'none'
    await Api.activatePromoCode(promo_code)
    setStage(AnimationStage.CLICK)
    node.style.removeProperty('pointer-events')
  }

  const onComplete = () => {
    setType(AnimationType.DEFAULT)
    setStage(AnimationStage.DEFAULT)
  }

  const animation = getAnimation(type, stage, { onClick, onComplete })

  return animation ? <Lottie className={styles.halloween2024} {...animation} /> : null
}
