import { Carousel } from 'components/Carousel'
import React, { useState } from 'react'
import styles from './styles.module.scss'

export const PpsItem = ({ city, address, break_time, working_time, images, map_image, map, map_app }) => {
  const [isOpened, setIsOpened] = useState(false)

  const openMap = () => {
    setTimeout(() => {
      window.location = map
    }, 0)

    window.location = map_app
  }

  return (
    <div className={styles.ppsItem}>
      <button
        className={styles.ppsToggle}
        data-opened={isOpened}
        onClick={() => setIsOpened(!isOpened)}
      >
        <div className={styles.city}>{city}</div>
        <div className={styles.address}>{address}</div>
      </button>
      {isOpened && (
        <div className={styles.about}>
          <div className={styles.schedule}>
            <div
              className={styles.scheduleItem}
              data-title="Время работы:"
              data-value={working_time}
            />
            <div
              className={styles.scheduleItem}
              data-title="Перерыв:"
              data-value={break_time}
            />
            <div
              className={styles.scheduleItem}
              data-title="Выходные:"
              data-value="Без выходных"
            />
          </div>
          <div className={styles.goods}>
            <div className={styles.wifi} />
            <div className={styles.tv} />
            <div className={styles.beverage} />
          </div>
          {!!images?.length && (
            <Carousel
              images={images}
              className="pps-item__carousel"
            />
          )}
          <img
            role="button"
            onClick={openMap}
            className={styles.mapImg}
            src={map_image}
            alt={address}
          />
        </div>
      )}
    </div>
  )
}
