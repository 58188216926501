import { ForecastEventHeader } from 'pages/Forecast/Item/Header'
import React from 'react'
import { connect } from 'react-redux'
import { changeSelected } from 'store/forecast/actions'
import { cn } from 'utils'
import { sportsSetting } from 'utils/settings'
import styles from './styles.module.scss'

const ForecastItem = ({ onChange, event, selected }) => {
  const sportId    = event.sport_id
  const { isDraw } = sportsSetting[sportId] || {}

  return (
    <div className={styles.event}>
      <ForecastEventHeader
        sportId={sportId}
        time={event.time}
        leagueName={event.league_name}
      />
      <div className={styles.main}>
        <div className={styles.teams}>
          <div>{event.team1}</div>
          <div>{event.team2}</div>
        </div>
        <div className={styles.coefficients}>
          <div
            className={cn(styles.coeffBtn, selected === 1 && styles.selected)}
            onClick={() => onChange({ event: event.id, outcome: 1 })}
          >
            П1
          </div>
          {isDraw &&
            <div
              className={cn(styles.coeffBtn, selected === 0 && styles.selected)}
              onClick={() => onChange({ event: event.id, outcome: 0 })}
            >
              X
            </div>}
          <div
            className={cn(styles.coeffBtn, selected === 2 && styles.selected)}
            onClick={() => onChange({ event: event.id, outcome: 2 })}
          >
            П2
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  changeSelected,
}

export default connect(null, mapDispatchToProps)(ForecastItem)
