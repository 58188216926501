import { Api } from 'api/Api'
import { useIsFetching } from 'hooks/useIsFetching'
import { getFlagUrl } from 'pages/Home/components/LineLeagues/utils'
import styles from 'pages/Home/Line/Championship/styles.module.scss'
import { responseHandler } from 'pages/Home/Line/Championship/utils'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useState,
} from 'react'
import {
  Link,
  useLocation,
} from 'react-router-dom'
import { cn } from 'utils'

const GroupContainer = ({ name, leagueIds, champId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [groups, setGroups] = useState([])
  const [fetch, isFetching] = useIsFetching()
  const { pathname }        = useLocation()

  const onOpen = useCallback(async () => {
    if (!groups.length) {
      try {
        const response = await fetch(Api.getChampionshipLeagues)

        setGroups(responseHandler(response, leagueIds))
      } catch (error) {
        console.log(error)
      }
    }

    setIsOpen(prevState => !prevState)
  }, [groups.length])

  return (
    <>
      <div
        className={cn(styles.container, isOpen && styles.open, isFetching && styles.fetching)}
        onClick={onOpen}
      >
        <img
          className={styles.icon}
          src={`https://cdn-01.maxline.by/images/championship/${champId}.png`}
          alt="championship"
        />
        {name}
      </div>
      {isOpen &&
        <>
          <div>
            {groups.map(({ id, name, countEvents, countryCode }) => (
                <Link
                  key={id}
                  to={`/line-league/${id}/0`}
                  className={styles.groupLink}
                  data-count={countEvents}
                >
                  <img
                    className={styles.groupImg}
                    src={getFlagUrl(countryCode)}
                    alt={name}
                  />
                  <div className={styles.groupName}>
                    {name}
                  </div>
                </Link>
              ),
            )}
          </div>
          <Link
            className={styles.showAll}
            to={`${pathname}/${leagueIds.join('-')}`}
          >
            Показать все
          </Link>
        </>}
    </>
  )
}

GroupContainer.propTypes = {
  champId:   PropTypes.string,
  leagueIds: PropTypes.array,
  name:      PropTypes.string,
}

GroupContainer.defaultProps = {
  champId:   '',
  leagueIds: [],
  name:      '',
}

export default GroupContainer
