import { ReactComponent as CheckMarkIcon } from 'assets/images/svg/checkmark-black.svg'
import { Progress } from 'components/Progress'
import { DEFAULT_CASHBACK_ID } from 'pages/Cashback/utils'
import { connect } from 'react-redux'
import { cn } from 'utils'
import styles from './styles.module.scss'

const toBYN = value => `${value} BYN`

export const VipCashbackStatistics = ({ current, next, total_amount, statuses, progress }) => {
  const currentId = current.id
  const nextId         = next.id
  const isLast         = !!current.last
  const isConfirmation = !isLast && nextId === currentId
  const diffBYN        = toBYN((next.min_amount - total_amount).toFixed(2))
  const totalBYN       = toBYN(total_amount)

  return (
    <section className={styles.vipCashbackStatistics}>
      <p className={styles.description}>
        Выполните условия для получения кэшбэка по выбранному статусу
      </p>
      {statuses.map(({ id, name, percent, total, group }) => {
        const isCurrent = currentId === id
        const isNext    = nextId === id
        const title     = id === DEFAULT_CASHBACK_ID ? 'Вступление в клуб' : name
        const minBYN    = toBYN(total[0])
        const isFuture  = id > nextId

        return (
          <div
            key={name}
            data-name={group?.name.toLowerCase()}
            className={cn(styles.cashback, isCurrent && styles.active, (isNext || isFuture) && styles.next)}
          >
            <div className={styles.header}>
              <div className={styles.name}>
                {isCurrent && <CheckMarkIcon className={styles.checkmark} />}
                {title}
              </div>
              <div>{`${percent}%`}</div>
            </div>
            <div className={styles.about}>
              Минимальная сумма ставок: <span className={styles.total}>{`${minBYN} в месяц`}</span>
              {!isLast && (
                (isCurrent && (
                  <>
                    <div
                      className={styles.conditions}
                      data-byn={diffBYN}
                    >
                      Для {isConfirmation ? 'подтверждения' : 'получения следующего'} статуса осталось сделать ставок
                      на общую сумму:
                    </div>
                    <div className={styles.progressLabel}>
                      <div className="font-12 gray">Сумма рассчитанных ставок:</div>
                      <div className="font-13 bolder">{totalBYN}</div>
                    </div>
                    <Progress value={progress} />
                  </>
                )) ||
                (isNext && (
                  <div
                    className={styles.conditions}
                    data-byn={diffBYN}
                  >
                    Для получения статуса осталось сделать ставок на общую сумму:
                  </div>
                )))}
            </div>
          </div>
        )
      })}
    </section>
  )
}

const mapStateToProps = state => ({
  current:      state.cashback.current,
  next:         state.cashback.next,
  total_amount: state.cashback.total_amount,
  statuses: state.cashback.statuses,
  progress: state.cashback.progress,

})

export default connect(mapStateToProps)(VipCashbackStatistics)
