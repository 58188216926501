const initialRender = (text, key) => <mark key={key}>{text}</mark>

export const Highlighted = ({
  source = '',
  pattern,
  render = initialRender,
  subStr,
}) => {
  if ((!pattern && !subStr) || !source) {
    return source
  }

  const regexp = new RegExp(`(${subStr || pattern.source})`, 'gi')
  const parts  = source.split(regexp)

  return (
    <>
      {parts.map((part, idx) => regexp.test(part) ? render(part, idx) : <span key={idx}>{part}</span>)}
    </>
  )
}
