import Hls from 'hls.js'
import { useIsLandscape } from 'hooks/useIsLandscape'
import React, {
  useEffect,
  useRef,
} from 'react'
import { requestFullscreen } from 'utils/request-full-screen'
import styles from './styles.module.scss'

export const Player = ({ src }) => {
  const isLandscape = useIsLandscape()

  const videoRef = useRef()

  useEffect(() => {
    if (isLandscape && videoRef.current) {
      requestFullscreen(videoRef.current)
    }
  }, [isLandscape])

  useEffect(() => {
    const video = videoRef.current
    const hls   = new Hls()

    if (src && video) {
      const playVideo = () => {
        const playPromise = video.play()

        if (playPromise) {
          playPromise.catch(error => {
            console.error('Playback failed:', error)
          })
        }
      }

      if (Hls.isSupported()) {
        hls.loadSource(src)
        hls.attachMedia(video)
        hls.on(Hls.Events.MANIFEST_PARSED, playVideo)
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src
        video.addEventListener('loadedmetadata', playVideo)
      }

      return () => {
        hls.destroy()
        video.removeEventListener('loadedmetadata', playVideo)
      }
    }
  }, [src])

  return (
    <video
      ref={videoRef}
      className={styles.video}
      autoPlay
      controls
      playsInline
    />
  )
}
