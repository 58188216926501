import { useState } from 'react'
import styles from './styles.module.scss'
import { onPayReturn } from './utils'

export const MaxlinePaysReturns = ({ payReturn, betId, onSuccess }) => {
  const [isFetching, setIsFetching] = useState(false)

  const onSubmit = async () => {
    setIsFetching(true)
    await onPayReturn(betId, onSuccess)
    setIsFetching(false)
  }

  return (
    payReturn ?
      <button
        className={styles.returnPaymentBtn}
        disabled={isFetching}
        onClick={onSubmit}
      >
        ML Платит
      </button> : null
  )
}
