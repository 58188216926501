import { ReactComponent as SettingsIcon } from 'assets/images/coupon/settings.svg'
import { toBYN } from 'pages/UserHistoryPage/utils'
import { connect } from 'react-redux'
import { setIsSettingsOpen } from 'store/coupon/actions'
import styles from './styles.module.scss'

const OneClickBets = ({ amount, setIsSettingsOpen }) => (
  <button
    id="one-click-bets"
    className={styles.oneClickBets}
    onClick={() => setIsSettingsOpen(true)}
  >
    <div className={styles.name}>Ставка в 1 клик</div>
    <div className={styles.amount}>
      <SettingsIcon className={styles.triggerIcon} />
      <div className="ml-5">{toBYN(+amount)}</div>
    </div>
  </button>
)

const mapStateToProps = state => ({
  amount: state.oneClickBets.amount,
})

const mapDispatchToProps = {
  setIsSettingsOpen,
}

export default connect(mapStateToProps, mapDispatchToProps)(OneClickBets)
