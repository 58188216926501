import styles from './styles.module.scss'

export const Team = ({ name, img }) => (
  <div className={styles.resultEventTeam}>
    {img && (
      <img
        className={styles.img}
        src={`https://maxline.by/assets/club-images/${img}`}
        alt={name}
      />
    )}
    <div className={styles.name}>
      {name}
    </div>
  </div>
)
