import { Player } from 'components/VideoBox/Player'
import PropTypes from 'prop-types'
import styles from './styles.module.scss'

export const VideoBox = ({ isPlayer, isOpen, src }) => (
  isOpen &&
  <div className="video-box">
    <div className="video-box__frame">
      {isPlayer ? <Player src={src} /> :
        <iframe
          src={src}
          className={styles.iframe}
          allowFullScreen="true"
        />}
    </div>
  </div>
)

VideoBox.propTypes = {
  isOpen:   PropTypes.bool,
  url:      PropTypes.string,
  isPlayer: PropTypes.bool,
}

VideoBox.defaultProps = {
  isOpen:   false,
  url:      '',
  isPlayer: true,
}
