const url = 'https://cdn-01.maxline.by/files/'

export const faqLinks = [
  {
    title: 'МНС',
    link:  `${url}mns.pdf`,
  },
  {
    title: 'Виды игр',
    link:  `${url}games.pdf`,
  },
  {
    title: 'Выписка из положения',
    link:  `${url}statement_of_position.pdf`,
  },
  {
    title: 'Ответственное отношение к азартным играм',
    link:  `${url}responsible_attitude.pdf`,
  },
  {
    title: 'Правила организации и проведения азартных игр',
    link:  `${url}rules.pdf`,
  },
  {
    title: 'Правила посещения виртуального игорного заведения',
    link:  `${url}rules_new.pdf`,
  },
  {
    title: 'Лицензия',
    link: 'https://license.gov.by/onelicense/91654',
  },
  {
    title: 'Реквизиты',
    link:  `${url}contacts.pdf`,
  },
  {
    title: 'Политика в отношении обработки персональных данных',
    link:  `${url}privacy_policy_v1.pdf?v=1`,
  },
]
