import { BetGamesPage } from 'pages/Casino/TVGame/BetGames'
import { TVBetPage } from 'pages/Casino/TVGame/TvBet'
import { useParams } from 'react-router-dom'

const Screens = {
  tvbet:    TVBetPage,
  betgames: BetGamesPage,
}

export const CasinoTVGamePage = () => {
  const { slug } = useParams()

  const [type, ...restParams] = slug.split('-')

  const Screen = Screens[type]

  return <Screen id={restParams.pop()} />
}
