import { SubmitBtn } from 'components/SubmitBtn'
import { AmountInput } from 'pages/Takemoney/Forms/Card/AmountInput'
import { CardsDropdown } from 'pages/Takemoney/Forms/Card/Dropdown'
import { Tax } from 'pages/Takemoney/Forms/Card/Tax'
import React, {
  useEffect,
  useState,
} from 'react'
import {
  connect,
  useDispatch,
} from 'react-redux'
import { getDetails } from 'store/payments/actions'
import { getInfo } from 'store/user/actions'
import { sleep } from 'utils/time'
import {
  getCardsList,
  getIsError,
  onPayout,
  PAYOUT_EXTRA_LOCK_MS,
} from './utils'

export const ByCardPayout = ({ minAmount, getDetails }) => {
  const [amount, setAmount]             = useState(minAmount)
  const [cards, setCards]               = useState([])
  const [hasHidden, setHasHidden]       = useState(false)
  const [selectedCard, setSelectedCard] = useState()

  const dispatch = useDispatch()

  const { id: card, description: cardNumber } = selectedCard || {}

  const isDisabled = getIsError(amount) || !selectedCard

  useEffect(() => {
    setAmount(minAmount)
  }, [minAmount])

  useEffect(() => {
    getCardsList()
      .then(({ cards, hasHidden }) => {
        setCards(cards)
        setHasHidden(hasHidden)
      })
      .catch(console.error)
  }, [])

  const onSubmit = async () => {
    const isOk = await onPayout({ amount, card, cardNumber })

    if (isOk) {
      getDetails()
      getInfo()
      setAmount(minAmount)
      setSelectedCard(undefined)
      await sleep(PAYOUT_EXTRA_LOCK_MS)
    }
  }

  return (
    <div className="description__order fastpay">
      <AmountInput
        value={amount}
        onChange={setAmount}
      />
      <CardsDropdown
        list={cards}
        selected={selectedCard}
        onChange={setSelectedCard}
        hasHidden={hasHidden}
      />
      {!isDisabled && <Tax amount={amount} />}
      <div className="text-center font-13 mt-14 gray lh-16">
        Для привязки карты совершите пополнение на любую сумму <span className="green-800">с помощью карты</span>
      </div>
      <SubmitBtn
        className="ml-button mt-14"
        onClick={onSubmit}
        disabled={isDisabled}
      >
        Получить выигрыш
      </SubmitBtn>
    </div>
  )
}

const mapStateToProps = state => ({
  minAmount: state.service.paymentLimits.minWithdraw,
})

const mapDispatchToProps = {
  getDetails,
  getInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(ByCardPayout)
