import { HideBtn } from 'components/SignUpModals/HideBtn'
import { SignUpModalWrapper } from 'components/SignUpModals/Wrapper'
import { useActiveBonus } from 'hooks/useActiveBonus'
import { usePaymentsState } from 'hooks/usePaymentsState'
import styles from './styles.module.scss'

export const TopUp = ({ onClose, onDoNotShow }) => {
  const { id, name } = useActiveBonus()

  const { showModal } = usePaymentsState()

  const onTopUp = () => {
    onClose()
    showModal()
  }

  return id ? (
    <SignUpModalWrapper
      onClose={onClose}
      fullscreen={false}
    >
      <img
        src={`https://cdn-01.maxline.by/images/bonuses/mobile/register/${id}.png`}
        className={styles.img}
        alt={name}
      />
      <div>
        <div className={styles.title}>
          Верификация успешно пройдена
        </div>
        <div className={styles.bonusText}>
          Активируй приветственный
          <br />
          бонус: <b>{name}</b>
        </div>
        <div className={styles.description}>
          Сделайте свой первый депозит на сумму от 20 BYN и заберите бонус в мобильном приложении.
        </div>
      </div>
      <button
        className="ml-button"
        onClick={onTopUp}
      >
        Пополнить счёт
      </button>
      <HideBtn onClick={onDoNotShow} />
    </SignUpModalWrapper>
  ) : null
}
