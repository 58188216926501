import styles from 'pages/Settings/SwitchToggle/styles.module.scss'
import React from 'react'
import { cn } from 'utils'

export const SwitchToggle = ({ title, checked, onChange, className }) => (
  <div
    role="button"
    className={cn(styles.label, className)}
    onClick={onChange}
  >
    {title}
    <div className={cn(styles.switch, checked && styles.checked)} />
  </div>
)
