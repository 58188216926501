import { Page } from 'components/Page'
import { useTabs } from 'hooks/useTabs'
import TEXT from 'store/constants/text'
import {
  resultPageTabs,
  ResultScreen,
} from './utils'

export const ResultsPage = () => {
  const [Tabs, activeTab] = useTabs(resultPageTabs)

  return (
    <Page
      title={TEXT.results.title}
      bg="gray"
    >
      <Tabs className="p-8" />
      {ResultScreen[activeTab]}
    </Page>
  )
}
