import { BetLink } from 'components/Coupon/BetLink'
import { getGrowth } from 'components/Coupon/Live/Bet/utils'
import { getIsBetOutdated } from 'components/Coupon/utils'
import { SwipeToDelete } from 'components/SwipeToDelete'
import {
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { delCoupon } from 'store/coupon/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

const BetLive = ({
  delCoupon,
  type,
  onClose,
  single,
  item: { change, status, nameEvent, value, nameValue, idEvent },
}) => {
  const [growthClass, setGrowthClass] = useState('')

  const onDelete = () => delCoupon(type, idEvent)

  const isRemoved = getIsBetOutdated(status)

  const textClass = cn(isRemoved && 'removed')

  const { diff, growth } = getGrowth(+change)

  useEffect(() => {
    if (growth) {
      setGrowthClass(growth === 'up' ? 'green' : 'red')
    }
  }, [growth])

  return (
    <SwipeToDelete onDelete={onDelete}>
      <div className={cn(styles.betLive, single && styles.single)}>
        <div className={styles.header}>
          <div className={cn(styles.title, textClass)}>
            {nameValue}
          </div>
          <div className={styles.coeff}>
            <div className={cn(styles.value, textClass)}>
              {!!+diff &&
                <div className={`coupon__data-bets__bet-change ${growth}`}>
                  {diff}
                </div>}
              <div className={`coupon__event-coeff ${growthClass} ${textClass}`}>
                {value}
              </div>
            </div>
            <div
              className={styles.deleteBtn}
              onClick={onDelete}
            />
          </div>
        </div>
        <BetLink
          id={idEvent}
          value={nameEvent}
          onClose={onClose}
          disabled={isRemoved}
          live
        />
      </div>
    </SwipeToDelete>
  )
}

const mapDispatchToProps = {
  delCoupon,
}

export default connect(null, mapDispatchToProps)(BetLive)
