import failIcon from 'assets/images/success-pay/error.svg'
import successIcon from 'assets/images/success-pay/success.svg'
import { getBetInfo } from 'components/BetFinished/utils'
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  clearBetData,
  setIsBetFinishedOpen,
} from 'store/bet_data/actions'

const DURATION_SEC = 3

const BetFinished = ({
  error,
  title,
  subTitle,
  type,
  amount,
  payout,
  koeff,
  isExist,
  isOpen,
  setIsBetFinishedOpen,
}) => {
  const [secToClose, setSecToClose] = useState(DURATION_SEC)

  const interval = useRef(0)
  const timeout  = useRef(0)

  const clearTimers = () => {
    clearTimeout(timeout.current)
    clearInterval(interval.current)
  }

  const onClose = () => setIsBetFinishedOpen(false)

  const needToShow = isExist && isOpen

  useEffect(() => {
    if (needToShow) {
      interval.current = setInterval(() => setSecToClose(prev => prev - 1), 1000)
      timeout.current  = setTimeout(onClose, DURATION_SEC * 1000)
    }

    if (!needToShow) {
      clearTimers()
      setSecToClose(DURATION_SEC)
    }

    return clearTimers
  }, [needToShow])


  return (
    needToShow && (
      <>
        <div className="bet-finished">
          <div className="bet-finished__content">
            <div className="bet-finished__status-img">
              <img
                src={error ? failIcon : successIcon}
                alt={error ? 'failed' : 'success'}
              />
            </div>
            <div className="bet-finished__title">
              {title}
            </div>
            <div className="font-12 gray text-center">
              {subTitle}
            </div>
            {type &&
              <div className="bet-finished__type">
                {type}
              </div>}
            {!error &&
              <div className="bet-finished__info">
                <div
                  className="bet-finished__info-item"
                  data-name="Сумма:"
                >
                  {amount}
                </div>
                <div
                  className="bet-finished__info-item"
                  data-name="Коэффициент:"
                >
                  {koeff}
                </div>
                <div
                  className="bet-finished__info-item"
                  data-name="Возможный выигрыш:"
                >
                  {payout}
                </div>
              </div>}
            <button
              className="ml-button bet-finished__btn"
              onClick={onClose}
            >
              {error ? 'Повторить' : 'Продолжить'}
            </button>
            <div className="bet-finished__close-in">
              {`Будет закрыто через: ${secToClose} сек.`}
            </div>
          </div>
        </div>
      </>
    )
  )
}

const mapStateToProps = state => ({
  isOpen: state.bet_data.isOpen,
  ...getBetInfo(state.bet_data.data),
})

const mapDispatchToProps = {
  clearBetData,
  setIsBetFinishedOpen,
}

export default connect(mapStateToProps, mapDispatchToProps)(BetFinished)
