import {
  getEndTime,
  WagerStatus,
} from 'pages/Account/WagerBonus/utils'
import { useSelector } from 'react-redux'
import { selectWagerBonus } from 'store/data/selectors'
import { isNumber } from 'utils/guards'
import { formatNumber } from 'utils/numbers'

export const useWagerBonus = () => {
  const {
          id = 0,
          valid_after_activate,
          activated_at,
          bet_amount,
          current_value,
          status,
          value,
          wager,
        } = useSelector(selectWagerBonus)

  const total = +value * +wager

  return {
    id,
    total,
    balance: isNumber(+current_value) ? current_value : 0,
    isActive: +status === WagerStatus.ACTIVE,
    value: formatNumber(value),
    bynLeft: formatNumber(total - bet_amount),
    percent: formatNumber(bet_amount * 100 / total) || 0,
    endTime: getEndTime(valid_after_activate, activated_at),
  }
}
