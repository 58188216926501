import logo from 'assets/images/header/logo.png'
import styles from 'components/Logo/styles.module.scss'
import { paths } from 'constants/paths'
import { Link } from 'react-router-dom'

export const Logo = () => (
  <Link
    className={styles.logo}
    to={paths.HOME}
  >
    <img
      src={logo}
      className={styles.icon}
      alt="Maxline"
    />
  </Link>
)
