import { PASSPORT_LENGTH } from 'components/App/utils'
import BetFinished from 'components/BetFinished'
import { CookieModal } from 'components/Cookie/CookieModal'
import { Coupon } from 'components/Coupon'
import { Forbidden } from 'components/Forbidden'
import { Halloween2024 } from 'components/Halloween2024'
import Header from 'components/Header'
import ModalWrapper from 'components/ModalWrapper'
import Preloader from 'components/Preloader'
import { Redirect } from 'components/Redirect'
import { SEO } from 'components/SEO'
import SignUpModals from 'components/SignUpModals'
import SmartBanner from 'components/SmartBanner'
import { SplashScreen } from 'components/SplashScreen'
import { TabBar } from 'components/TabBar'
import { paths } from 'constants/paths'
import { isWebView } from 'constants/platform'
import { useAppInit } from 'hooks/useAppInit'
import { usePrevious } from 'hooks/usePrevious'
import { useStopScroll } from 'hooks/useStopScroll'
import { connect } from 'react-redux'
import {
  Outlet,
  ScrollRestoration,
  useLocation,
} from 'react-router-dom'

const App = ({ isAuth, isVerifyNeeded, isLoading, forbiddenType, isNewSignUp, userID }) => {
  const { pathname } = useLocation()
  const prevIsAuth   = usePrevious(isAuth)

  useAppInit()
  useStopScroll(isLoading)

  if (forbiddenType) {
    return <Forbidden type={forbiddenType} />
  }

  if (!prevIsAuth && isVerifyNeeded && pathname !== paths.VERIFICATION && !isNewSignUp) {
    return <Redirect to={paths.VERIFICATION} />
  }

  return (
    <SEO>
      {isAuth && <Coupon />}
      <SplashScreen visible={isLoading} />
      {!isWebView && <CookieModal loading={isLoading} />}
      {!isWebView && <SmartBanner />}
      {isNewSignUp && !isWebView && <SignUpModals />}
      <Header />
      <Outlet />
      <BetFinished />
      <ModalWrapper />
      <Preloader />
      <TabBar />
      {isAuth && <Halloween2024 user={userID} />}
      <ScrollRestoration getKey={location => location.key} />
    </SEO>
  )
}

const mapStateToProps = state => {
  const {
          user:    { info: { login, passport = '', has_scan } },
          auth:    { isAuth, isOnline, id },
          service: { is_on: isOn },
        } = state

  return {
    isAuth,
    userID:         id,
    forbiddenType:  (!isOnline && 'noConnection') || (!isOn && 'serviceWork') || '',
    isLoading:      !state.data.initialized,
    theme:          state.data.theme,
    isVerifyNeeded: login && (passport.length !== PASSPORT_LENGTH || !+has_scan),
    isNewSignUp:    state.config.new_registration.enabled,
  }
}

export default connect(mapStateToProps)(App)
