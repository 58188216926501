import {
  AllFilter,
  CasinoActions,
  CasinoEvents,
  PopCategory,
} from 'pages/Casino/constants'
import {
  setCategories,
  setCounters,
  setFavorites,
  setFreeSpinAppGameIDs,
  setGames,
  setGamesList,
  setLoading,
  setMaxlinePay,
  setPopulars,
  setProviders,
  setRecentGames,
  setSliders,
  setThemes,
} from 'store/casino/creators'
import emitter from 'utils/socket/emitter'

export const getSlides = (params = {}) => (dispatch) => {
  dispatch(setLoading({ slides: true }))
  emitter.addListener(CasinoEvents.SLIDERS, data => dispatch(setSliders(data)))
  emitter.push(CasinoActions.SLIDERS, params)
}

export const getCategories = () => dispatch => {
  dispatch(setLoading({ categories: true }))

  emitter.addListener(CasinoEvents.CATEGORIES, data => {
    dispatch(setCategories(data))
    dispatch(setLoading({ categories: false }))
  }, () => dispatch(setLoading({ categories: false })))

  emitter.push(CasinoActions.CATEGORIES)
}

const buildParams = (state, { isHome, isFavorite, data }) => {
  const { casino: { pageId, gamesSearch, activeIds: { category, provider, theme } } } = state

  const params = {
    page_id: pageId,
    ...(!isHome && { per_page: 36 }),
    ...data,
  }

  return isFavorite ? { ...params, is_favorite: 1 } : {
    ...params,
    ...(isHome && { is_home: 1 }),
    ...(gamesSearch && { search: gamesSearch }),
    ...(category === PopCategory.id && { is_popular: 1 }) || category !== AllFilter.id && { category_id: category },
    ...(provider !== AllFilter.id && { provider_id: provider }),
    ...(theme !== AllFilter.id && { theme_id: theme }),
  }
}

export const getGames = ({
  isHome = false,
  isAddGames = false,
  isFavorite = false,
  data = {},
} = {}) => (dispatch, getState) => {
  dispatch(setLoading({ games: true }))

  emitter.addListener(CasinoEvents.GAMES, data => {
    dispatch(setGames({ data, isAddGames }))
    dispatch(setLoading({ games: false }))
  }, () => dispatch(setLoading({ games: false })))

  emitter.addListener(CasinoEvents.THEMES, data => dispatch(setThemes(data)))
  emitter.addListener(CasinoEvents.PROVIDERS, data => dispatch(setProviders(data)))
  emitter.addListener(CasinoEvents.POPULAR, data => dispatch(setPopulars(data)))

  const params = buildParams(getState(), { isHome, isFavorite, data })

  if (isHome) {
    emitter.push(CasinoActions.POPULAR)
  }

  emitter.push(CasinoActions.GAMES, params)
}

export const getRecentGames = params => dispatch => {
  dispatch(setLoading({ recentGames: true }))

  emitter.addListener(CasinoEvents.RECENT_GAMES, data => {
    dispatch(setRecentGames({ data }))
    dispatch(setLoading({ recentGames: false }))
  }, () => dispatch(setLoading({ recentGames: false })))

  emitter.push(CasinoActions.RECENT_GAMES, params)
}

export const getFavorites = () => (dispatch) => {
  emitter.addListener(CasinoEvents.FAVORITES_GET, ({ favorites } = {}) => dispatch(setFavorites(favorites || [])))
  emitter.push(CasinoActions.FAVORITES_GET)
}

export const getMaxlinePay = (main = true) => dispatch => {
  dispatch(setLoading({ game_for_pay: true }))

  emitter.addListener(CasinoEvents.MAXLINE_PAY, (data) => {
    dispatch(setMaxlinePay(data || []))
    dispatch(setLoading({ game_for_pay: false }))
  })
  emitter.push(CasinoActions.MAXLINE_PAY, { main })
}

export const enrollMaxlinePay = id => () => {
  emitter.push(CasinoActions.MAXLINE_PAY_ENROLL, { id })
}

export const toggleFavorites = (id) => (dispatch, getState) => {
  const { casino }                = getState()
  const { isFavoritePage, games } = casino

  emitter.addListener(CasinoEvents.FAVORITES_SET, (data) => {
    dispatch(setFavorites(data?.favorites || []))

    if (isFavoritePage) {
      dispatch(setGamesList(games.filter(g => data?.favorites?.includes(+g.id))))
    }
  })

  emitter.push(CasinoActions.FAVORITES_SET, { id })
}

export const clearFavorites = () => (dispatch) => {
  emitter.addListener(CasinoEvents.FAVORITES_CLEAR, () => {
    dispatch(setGamesList([]))
    dispatch(setFavorites([]))
  })

  emitter.push(CasinoActions.FAVORITES_CLEAR)
}

export const getCounters = () => (dispatch) => {
  emitter.addListener(CasinoEvents.CONFIG, ({ counters = {} }) => dispatch(setCounters(counters)))
  emitter.push(CasinoActions.CONFIG)
}

export const getFreeSpinsGames = goHome => dispatch => {
  dispatch(setLoading({ games: true }))

  emitter.push(CasinoActions.GAMES, { page_id: 0, is_freespin: 1 })
  emitter.push(CasinoActions.FREE_SPIN_APP)

  emitter.addListener(CasinoEvents.GAMES, data => {
    if (!data.games?.length) {
      return goHome()
    }

    dispatch(setGames({ data }))
    dispatch(setLoading({ games: false }))
  }, goHome)

  emitter.addListener(CasinoEvents.FREE_SPIN_APP, ({ freespin_app }) => {
    dispatch(setFreeSpinAppGameIDs(freespin_app))
  })
}
