import React from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const Option = ({ title, action, checked }) => (
  <div
    className={cn(styles.option, checked && styles.checked)}
    onClick={action}
  >
    {title}
  </div>
)
