import { Loader } from 'components/Loader'
import { usePrevious } from 'hooks/usePrevious'
import React, {
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { takeCashOut } from 'store/cashout/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

const CashOut = ({ cashout, betId, takeCashOut, loading, className, onSuccess }) => {
  const [shift, setShift] = useState('')

  const isLoading = loading[betId]

  const { cashout: currentCashout, hash } = cashout?.available_cashouts?.[betId] || {}

  const prevCashout = usePrevious(currentCashout)

  useEffect(() => {
    if (prevCashout && currentCashout && prevCashout !== currentCashout) {
      setShift(+currentCashout > +prevCashout ? 'up' : 'down')
      setTimeout(() => setShift(''), 7000)
    }
  }, [currentCashout, prevCashout])

  return (
    currentCashout ?
      <div
        onClick={() => takeCashOut(betId, hash, onSuccess)}
        className={cn(styles.cashout, isLoading && styles.loading, className)}
      >
        <div className={styles.title}>
          {isLoading ? <Loader /> : <span>Cashout <b>{`${currentCashout} BYN`}</b></span>}
        </div>
        {shift && !isLoading && <div className={styles[shift]} />}
      </div> : null
  )
}

const mapStateToProps = state => ({
  cashout: state.cashout.bets,
  loading: state.cashout.loading,
})

const mapDispatchToProps = {
  takeCashOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(CashOut)
