import { Bet } from 'components/Coupon/Line/Bet'
import { LINE_COUPON_TYPE } from 'components/Coupon/Line/utils'
import { getIsBetOutdated } from 'components/Coupon/utils'
import { useDispatch } from 'react-redux'
import { delCoupon } from 'store/coupon/actions'
import { cn } from 'utils'
import styles from '../Bet/styles.module.scss'

export const BetSingle = ({
  item: { nameEvent, nameValue, idEvent, value: coeff, max, time, status },
  value,
  onChange,
  isVip,
  count,
  onClose,
}) => {
  const dispatch = useDispatch()

  const onDelete = () => dispatch(delCoupon(LINE_COUPON_TYPE, idEvent))
  const isSingle = count === 1

  return (
    <Bet
      id={idEvent}
      max={max}
      value={value}
      isVip={isVip}
      onChange={nextValue => onChange(idEvent, nextValue)}
      time={time}
      onDelete={onDelete}
      description={nameEvent}
      coefficient={coeff}
      title={nameValue}
      started={getIsBetOutdated(status)}
      single={isSingle}
      onClose={onClose}
      className={cn(isSingle && styles.single)}
    />
  )
}
