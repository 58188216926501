import ACTIONS from 'store/constants/actions'

const initialState = {
  bet1:      0,
  bet2:      0,
  bet3:      0,
  plusMinus: 0,
  fetching:  false,
  error:     '',
}

export default function userBets(state = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.USER_BETS.GET: {
      return { ...state, ...payload }
    }

    case ACTIONS.USER_BETS.ERROR: {
      return { ...state, error: payload }
    }

    case ACTIONS.USER_BETS.FETCHING: {
      return { ...state, fetching: payload }
    }
    default:
      return state
  }
}
