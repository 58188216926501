import ACTIONS from 'store/constants/actions'

const initialState = {
  liveCount: 0,
}

export default function leftPanel(state = initialState, action) {
  const { type, data } = action

  switch (type) {
    case ACTIONS.LEFT_PANEL.GET_LIVE_COUNT: {
      return { ...state, liveCount: data }
    }
    default:
      return state
  }
}
