import { showSuccessModal } from 'components/Popup'
import { paths } from 'constants/paths'

export const MIN_PASSWORD_LENGTH = 6

export const initialState = {
  email:           '',
  phone:           '',
  password:        '',
  isTimeoutActive: true,
  isPhoneValid:    false,
  isSubmitted:     false,
  promocode:       '',
  bonus:           {},
}

const setToLocalStorage = (key, value) => value && localStorage.setItem(key, value)

export const setCredentialsToLS = ({ phone, password, login, atoken, ttl, email }) => {
  setToLocalStorage('pe', phone.replace(/\s/gi, ''))
  setToLocalStorage('pd', password)
  setToLocalStorage('ln', login)
  setToLocalStorage('token', atoken)
  setToLocalStorage('reg_iTtl', ttl)
  setToLocalStorage('reg_el', email)
}

export const getCredentialsFromLS = () => ({
  phone:    localStorage.getItem('pe'),
  password: localStorage.getItem('pd'),
  login:    localStorage.getItem('ln'),
  ttl:      localStorage.getItem('reg_iTtl'),
  email:    localStorage.getItem('reg_el'),
})

export const removeCredentialFromLS = () => {
  localStorage.removeItem('pe')
  localStorage.removeItem('pd')
  localStorage.removeItem('ln')
  localStorage.removeItem('reg_iTtl')
  localStorage.removeItem('reg_el')
}

export const validateEmail = value => (value.length < 5 || !value.match(/\S+@\S+\.\S+/)) ? 'введите корректный e-mail' :
  null

export const validatePassword = value => value.length < MIN_PASSWORD_LENGTH ?
  `пароль должен быть не короче ${MIN_PASSWORD_LENGTH} символов` : null

export const hidePhone = phone => {
  if (phone) {
    const rawPhone = String(phone).replace(/\+|\s/g, '')
    return rawPhone.slice(0, rawPhone.length - 6).concat('****', rawPhone.slice(-2))
  }
}

export const showVerifySuccessModal = (userId, options = {}) => {
  showSuccessModal({
    title:     'Регистрация завершена!',
    message:   `Номер Вашего игрового счета: ${userId}`,
    submitBtn: {
      to:    paths.VERIFICATION,
      title: 'Перейти к верификации',
    },
    ...options,
  })
}
