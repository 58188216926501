import styles from 'components/Coupon/Settings/UserBets/styles.module.scss'
import { useState } from 'react'

export const AddBetModal = ({ onClose, onSubmit }) => {
  const [nextBet, setNextBet] = useState('')

  const onChange = ({ target: { value: v } }) => {
    const [nextValue] =
          v.replace(',', '.').replace(/[^.\d]+/g, '').match(/^(([1-9]\d{0,8}|0)(\.\d{0,2})?)/) || []

    setNextBet(nextValue || '')
  }

  return (
    <div className={styles.modal}>
      <div className="text-center bold font-18">
        Добавление любимой ставки
      </div>
      <button
        className={styles.close}
        aria-label="Close"
        onClick={() => onClose(AddBetModal.id)}
      />
      <div className={styles.inputWrapper}>
        <input
          type="text"
          value={nextBet}
          pattern="\d*"
          inputMode="decimal"
          onChange={onChange}
          placeholder="мин. 0.1"
          className={styles.betInput}
        />
      </div>
      <button
        className="ml-button uppercase"
        disabled={+nextBet < 0.1}
        onClick={() => {
          onSubmit(nextBet)
          onClose()
        }}
      >
        Добавить
      </button>
    </div>
  )
}

AddBetModal.id = 'ADD-FAVORITE-BET_MODAL'
