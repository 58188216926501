import bgLg from 'assets/images/forecast/bg-lg.jpg'
import bgSm from 'assets/images/forecast/bg-sm.jpg'
import { Page } from 'components/Page'
import { RulesLink } from 'components/RulesLink'
import ForecastCreate from 'pages/Forecast/Create'
import { RULES_LINK } from 'pages/Forecast/utils'

export const ForecastPage = () => (
  <Page
    title="Прогнозы"
    className="forecast-page"
    bg="gray"
    headerContent={<RulesLink to={RULES_LINK} />}
  >
    <div>
      <picture>
        <source
          srcSet={bgLg}
          media="(min-width: 456px)"
        />
        <img
          className="forecast-page__image"
          src={bgSm}
          alt="forecast"
        />
      </picture>
    </div>
    <ForecastCreate />
  </Page>
)
