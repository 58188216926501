export const colors = {
  1: '#9E650A',
  2: '#BA1616',
  3: '#166E52',
  4: '#BA1616',
  5: '#BA1616',
  6: '#BA1616',
}

export const getAmountText = (freespin, freebet, value) => [
  freebet && `${(+value || 0).toFixed(2)} BYN`,
  freespin && `${value} ФС`,
].filter(Boolean).join(', ') || `${value} BYN`
