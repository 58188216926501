import SportIcon from 'components/SportIcon'
import React from 'react'
import { getFullDate } from 'utils/time'
import styles from './styles.module.scss'

export const ForecastEventHeader = ({ sportId, time, leagueName }) => {
  const fullDate = getFullDate(time)

  return (
    <div className={styles.header}>
      <SportIcon
        id={sportId}
        size={14}
      />
      {fullDate && <div className={styles.startedAt}>{fullDate}</div>}
      <div className={styles.leagueName}>{leagueName}</div>
    </div>
  )
}
