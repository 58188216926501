import { usePrevious } from 'hooks/usePrevious'
import { EmptyCoefficients } from 'pages/Home/components/Event/Coefficient/Empty'
import Coefficient from 'pages/Home/components/Event/Coefficient/index'
import { getCoeffName } from 'pages/Home/components/Event/utils'
import {
  useEffect,
  useState,
} from 'react'
import styles from './styles.module.scss'

export const CoefficientGroup = ({ coeffs = [], eventId, draw, isset }) => {
  const [lastCoeffs, setLastCoeffs] = useState([])

  const prevCoeffs = usePrevious(coeffs)

  useEffect(() => {
    if (!coeffs.length && prevCoeffs?.length) {
      setLastCoeffs(prevCoeffs)
    }
  }, [coeffs.length])

  const coefficients = coeffs.length ? coeffs : lastCoeffs

  return (
    <div
      className={styles.coeffGroup}
      data-scroll-sensitive="true"
    >
      {coefficients.length ? (
        coefficients.map(coeff => (
          <Coefficient
            key={`${coeff?.e}${coeff?.id}`}
            label={getCoeffName(coeff)}
            data={coeff}
            selected={!!isset[eventId]?.[coeff?.id]}
            disabled={!coeffs.length && !!lastCoeffs.length}
          />
        ))
      ) : <EmptyCoefficients draw={draw} />}
    </div>
  )
}
