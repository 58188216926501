import otherCategoryImg from 'assets/images/line/other-category.png'
import moment from 'moment'

export const getFlagUrl = code => code ? `https://cdn-01.maxline.by/images/flags/${code}.png` : otherCategoryImg

export const handleLineLeagues = (response = {}) => {
  const { events = [], factors = {}, clubs, leagues } = response.data || {}

  return {
    events:      events.sort(({ time: time1 }, { time: time2 }) => moment(time1) - moment(time2)),
    factors:     factors,
    eventsCount: events.length,
    description: leagues[0]?.description,
    clubs,
    fetched:     true,
  }
}

export const initialState = { fetched: false }
