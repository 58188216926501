import { ModalType } from 'components/SignInModal/utils'
import { useAuth } from 'hooks/useAuth'
import { useAuthModal } from 'hooks/useAuthModal'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  getBonus22,
  getDetails,
  reset,
  setPaymentsState,
} from 'store/payments/actions'
import { selectPayments } from 'store/payments/selectors'

export const usePaymentsState = () => {
  const dispatch      = useDispatch()
  const state         = useSelector(selectPayments)
  const isAuth        = useAuth()
  const showAuthModal = useAuthModal()

  const { defaultMethod, defaultCard, isSubscribed, isBlocked } = state

  const setState      = nextState => dispatch(setPaymentsState(nextState))
  const openModal     = () => setState({ isVisible: true })
  const showModal     = () => isAuth ? openModal() : showAuthModal(ModalType.DEFAULT, { onSuccess: openModal })
  const closeModal    = () => setState({ isVisible: false, value: '', method: defaultMethod, card: defaultCard })
  const isHybridModal = isSubscribed && !isBlocked

  return {
    state,
    setState,
    isHybridModal,
    showModal,
    closeModal,
    getDetails: () => dispatch(getDetails()),
    getBonus22: () => dispatch(getBonus22()),
    reset:      () => dispatch(reset()),
  }
}
