const getInstance = key => JSON.parse(localStorage.getItem(key))

export class Statistics {
  static keys = {
    signUp: 'SIGN-UP-STATISTICS',
    verify: 'VERIFY-STATISTICS',
  }

  instance = {}

  constructor(key) {
    const prevInstance = getInstance(key)

    this.instance.key       = key
    this.instance.startTime = prevInstance?.startTime || Date.now()

    if (!prevInstance) {
      localStorage.setItem(key, JSON.stringify(this.instance))
    }
  }

  getInstance = () => JSON.parse(localStorage.getItem(key))

  destroy = () => {
    localStorage.removeItem(this.instance.key)
    this.instance = {}
  }

  static getDuration = key => {
    const { startTime } = getInstance(key) || {}

    if (startTime) {
      const now = Date.now()

      return (now - startTime) / 1000
    }
  }
}
