import styles from './styles.module.scss'
import { handleResult } from './utils'

const Result = ({ values, className }) => (
  !!values.length ? (
    <div className={className}>
      {values.map((value, idx) => <div key={idx}>{value}</div>)}
    </div>
  ) : null
)

export const ScoreRow = ({ result }) => {
  const { general, periods, points } = handleResult(result)

  return (
    <div className={styles.result}>
      <Result
        values={general}
        className={styles.general}
      />
      {!!periods.length && (
        <div className={styles.periods}>
          {periods.map((period, idx) => (
            <Result
              key={idx}
              values={period}
              className={styles.period}
            />
          ))}
        </div>
      )}
      <Result
        values={points}
        className={styles.points}
      />
    </div>
  )
}
