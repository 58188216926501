import { Api } from 'api/Api'
import { STATUSES } from 'constants/statuses'
import ACTIONS from 'store/constants/actions'

export const getServiceWork = () => async dispatch => {
  try {
    const paymentLimits = await Api.getActivePaymentMethod()
    const serviceWork   = await Api.getServiceWork()

    dispatch({
      type:    ACTIONS.SERVICE.SET_STATUS,
      payload: serviceWork.data,
    })

    if (paymentLimits.status === STATUSES.SUCCESS) {
      dispatch({
        type:    ACTIONS.SERVICE.SET_PAYMENT_LIMITS,
        payload: paymentLimits.data,
      })
    }
  } catch (err) {
    console.error(err)
  }
}
