import ACTIONS from 'store/constants/actions'

const initialState = {
  live: [],
  line: [],
}

export default function coupon(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.COUPON.GET_OPENED_BETS_LIVE: {
      return { ...state, live: action.data }
    }

    case ACTIONS.COUPON.GET_OPENED_BETS_LINE: {
      return { ...state, line: action.data }
    }

    default:
      return state
  }
}
