import { dispatch } from 'components/AppProvider'
import {
  isWebView,
  platform,
} from 'constants/platform'
import { QueryKey } from 'hooks/useSearchParamsHandler'
import { logOut } from 'store/user/actions'
import { getCookie } from 'utils'

export const ResponseCode = {
  UNAUTHORIZED: 1,
}

const cors = '1'

export const ORIGIN  = isWebView && document.location.origin || process.env.REACT_APP_API_URL || 'https://maxline.by'
export const API_URL = `${ORIGIN}/api`

export const postOptions = {
  method:  'post',
  headers: {
    'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
}

const utmMap = {
  utmcsr:   'utm_source',
  utmcmd:   'utm_medium',
  utmccn:   'utm_campaign',
  utmcct:   'utm_content',
  utmctr:   'utm_term',
  utmgclid: 'gclid',
  utmdclid: 'dclid',
}

const utmKeysRegex = /(utmcsr|utmcmd|utmccn|utmcct|utmctr|utmgclid|utmdclid)/g

const replaceUtmKeys = cookie => cookie.replace(utmKeysRegex, (match) => utmMap[match] || match)

const toQuery = string => string.replace(/\|/g, '&')

export const buildUtmQuery = () => toQuery(replaceUtmKeys(getCookie('__utmzz')))

const extractID = key => getCookie(key) || localStorage.getItem(key)

export const appendPartnersIDs = () => {
  const click_id     = extractID(QueryKey.CLICK_ID)
  const ml_sys_id    = extractID(QueryKey.ML_SYS_ID)
  const affiliate_id = extractID(QueryKey.AFFILIATE_ID)

  return {
    ...(click_id && { click_id }),
    ...(ml_sys_id && { ml_sys_id }),
    ...(affiliate_id && { affiliate_id }),
  }
}

export const sq = obj => new URLSearchParams(obj).toString()

export const getToken = () => localStorage.getItem('token')

const buildURL = (pathname, params) => `${API_URL}${pathname}?${params}`

export const buildParams = (withAuth, params) => {
  const atoken = withAuth && getToken()
  const search = sq({ cors, platform, ...(atoken && { atoken }) })
  return `${search}${params ? `&${params}` : ''}`
}

export const request = (...args) => fetch(...args)
  .then(res => res.status >= 200 && res.status < 300 ? Promise.resolve(res) : Promise.reject(new Error(res.statusText)))
  .then(res => res.json())
  .then(json => {
    if (json.code === ResponseCode.UNAUTHORIZED) {
      dispatch(logOut())
    }

    return json
  })
  .catch(error => {
    console.error('Request failed: ', error)
  })

export const get = (pathname, params = '', withAuth = false) => request(buildURL(pathname, buildParams(withAuth, params)))

export const post = (pathname, data = {}, withAuth = true) => {
  return request(buildURL(pathname, buildParams(withAuth)), { ...postOptions, body: sq(data) })
}
