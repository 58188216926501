import TEXT from 'store/constants/text'

export const LOGIN_MODAL_ID = 'LOGIN_MODAL'

export const MIN_PASSWORD_LENGTH = 6
export const MIN_LOGIN_LENGTH    = 5
export const LOGIN_LABEL         = '№ счёта / телефон / e-mail'

export const ModalType = {
  UNAUTHORIZED: 'notAuth',
  DEFAULT:      'default',
}

export const headers = {
  notAuth: 'Вы не вошли в систему', default: TEXT.login.loginTitle,
}

export const titles = {
  notAuth: 'Пожалуйста, введите Ваши данные или зарегистрируйтесь', default: '',
}

export const saveCredentials = ({ login, password }) => {
  localStorage.setItem('login', login)
  localStorage.setItem('password', password)
}

export const getCredentials = () => {
  const login    = localStorage.getItem('login')
  const password = localStorage.getItem('password')

  return [login, password]
}

export const removeCredentials = () => {
  localStorage.removeItem('login')
  localStorage.removeItem('password')
}

export const validatePassword = value => value.length < MIN_PASSWORD_LENGTH ?
  `пароль должен быть не короче ${MIN_PASSWORD_LENGTH} символов` : null

export const validateLogin = value => value.length < MIN_LOGIN_LENGTH ? `не менее ${MIN_LOGIN_LENGTH} символов` : null
