import animation11 from 'components/Halloween2024/lottie/1-1.json'
import animation12 from 'components/Halloween2024/lottie/1-2.json'
import animation21 from 'components/Halloween2024/lottie/2-1.json'
import animation22 from 'components/Halloween2024/lottie/2-2.json'

export const AnimationType = {
  DEFAULT: '0',
  FIRST:   '1',
  SECOND:  '2',
}

export const AnimationStage = {
  DEFAULT: 'default',
  CLICK:   'click',
}

export const AnimationData = {
  [AnimationType.FIRST]:  {
    [AnimationStage.DEFAULT]: {
      src:  animation11,
      loop: true,
    },
    [AnimationStage.CLICK]:   {
      src:  animation12,
      loop: false,
    },
  },
  [AnimationType.SECOND]: {
    [AnimationStage.DEFAULT]: {
      src:  animation21,
      loop: true,
    },
    [AnimationStage.CLICK]:   {
      src:  animation22,
      loop: false,
    },
  },
}

export const getAnimation = (type, stage, { onClick, onComplete }) => {
  const animation = AnimationData[type]?.[stage]

  if (animation) {
    const handler = stage === AnimationStage.DEFAULT ? { onClick } : { onComplete }
    return { ...animation, ...handler }
  }
}
