import noImage from 'assets/images/pps/no-image.svg'
import { settings } from 'components/Carousel/settings'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

export const Carousel = ({ images, className }) => (
  <Slider {...settings} className={className}>
    {images.map(image => (
      <div
        key={image}
        className="carousel-item"
      >
        <img
          src={image}
          alt="carousel-item"
          onError={event => event.target.src = noImage}
        />
      </div>
    ))}
  </Slider>
)

Carousel.propTypes = {
  images: PropTypes.array,
}

Carousel.defaultProps = {
  images: [],
}
