import { converter } from 'pages/TopExpress/converter'
import ACTIONS from 'store/constants/actions'

const initialState = {
  data: {},
}

export const topExpressReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOP_EXPRESS.GET_LIST: {
      return { ...state, data: converter(action.payload) }
    }

    default: {
      return state
    }
  }
}
