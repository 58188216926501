import { getStartedAt } from 'pages/Home/components/Event/StartDate/utils'
import styles from './styles.module.scss'

export const StartDate = ({ value }) => {
  if (!value) {
    return null
  }

  const { time, date } = getStartedAt(value)

  return (
    <div className={styles.startDate}>
      <div className={styles.date}>{date}</div>
      <div className={styles.time}>{time}</div>
    </div>
  )
}
