import ACTIONS from 'store/constants/actions'

export const onInitChange = payload => ({
  type: ACTIONS.SYSTEM.INITIALIZED,
  payload,
})

export const changeTheme = payload => dispatch => {
  dispatch({
    type: ACTIONS.SYSTEM.CHANGE_THEME,
    payload,
  })
}

export const setBetOnOur = payload => dispatch => dispatch({
  type: ACTIONS.SYSTEM.SET_BET_ON_OUR,
  payload,
})
