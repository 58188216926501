import { ReactComponent as WarningIcon } from 'assets/images/popup/warning.svg'
import {
  openPopup,
  showErrorModal,
} from 'components/Popup'

export const RULES_URL = 'https://pub-f80a7e9af5284ae9874affa26c3e7eec.r2.dev/cashback_20_new.pdf'

export const showCashbackError = (message = 'Что-то пошло не так, попробуйте позже.') => showErrorModal({
  title:     'Ошибка',
  message,
  submitBtn: {
    title: 'Ok',
  },
})

export const showUnsubscribeModal = callback => {
  openPopup({
    title:     'Вы действительно хотите отказаться?',
    submitBtn: {
      title:     'Отказаться',
      callback,
      className: 'popup__btn popup__btn_secondary',
    },
    cancelBtn: {
      title:     'Отмена',
      className: 'popup__btn popup__btn_primary',
    },
    icon:      WarningIcon,
  })
}
