import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { VipCashbackHistory } from 'pages/Cashback/History'
import VipCashbackStatistics from 'pages/Cashback/Statistics'

export const DEFAULT_CASHBACK_ID = -1

const Screen = {
  STATISTICS: 'STATISTICS',
  HISTORY:    'HISTORY',
}

export const CashbackScreen = {
  [Screen.STATISTICS]: VipCashbackStatistics,
  [Screen.HISTORY]:    VipCashbackHistory,
}

export const cashbackTabs = [
  { value: [Screen.STATISTICS], title: 'Статистика' },
  { value: [Screen.HISTORY], title: 'История' },
]

export const actionFactory = action => async () => {
  showPreloader()

  const response = await action()

  hidePreloader()

  return response
}
