import { ReactComponent as BroadcastIcon } from 'assets/images/event/tv-link.svg'
import { ScoreRow } from 'pages/Results/live/event/ScoreRow'
import styles from 'pages/Results/live/event/styles.module.scss'
import { Team } from 'pages/Results/live/event/Team'
import { Link } from 'react-router-dom'

export const EventItem = ({
  event: {
           id,
           team1,
           team2,
           team1Img,
           team2Img,
           result,
           description,
           result: { period_str } = {},
         },
}) => {
  const hasBroadcast = !!JSON.parse(description || '{}')['tv-link']

  return (
    <div className={styles.resultEvent}>
      <Link
        to={`/live-event/${id}`}
        className={styles.main}
      >
        <div className={styles.teams}>
          <Team
            name={team1}
            img={team1Img}
          />
          <Team
            name={team2}
            img={team2Img}
          />
        </div>
        <ScoreRow result={result} />
        {hasBroadcast && <BroadcastIcon className={styles.broadcastIcon} />}
      </Link>
      {period_str && <div className={styles.periodString}>{period_str}</div>}
    </div>
  )
}

export default EventItem
