import { Api } from 'api/Api'
import stage1Icon from 'assets/images/upload-passport/stage-1.svg'
import stage2Icon from 'assets/images/upload-passport/stage-2.svg'
import stage3Icon from 'assets/images/upload-passport/stage-3.svg'
import stage4Icon from 'assets/images/upload-passport/stage-4.png'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import styles from 'components/Upload/styles.module.scss'
import { STATUSES } from 'constants/statuses'
import {
  setVerifyError,
  setVerifySuccess,
} from 'utils/google-analytics/EcommerceGTM'
import { Statistics } from 'utils/models/Statistics'

export const MAX_FILES_SIZE = 3e+7
export const IDX_DATA_KEY   = 'data-idx'

const selfieInput = {
  icon:        stage4Icon,
  description: 'СЕЛФИ + СТР. 33',
  className:   styles.selfieLabel,
}

export const getInputs = single => single ? [selfieInput] : [
  {
    icon:        stage1Icon,
    description: 'СТР. 31',
  },
  {
    icon:        stage2Icon,
    description: 'СТР. 33',
  },
  {
    icon:        stage3Icon,
    description: 'СТР. 25',
  },
  selfieInput,
]

export const OtherErrorAlert = {
  title: 'Ошибка отправки файлов',
}

export const SizeErrorAlert = {
  title:   'Превышен максимальный размер файлов',
  message: 'Общий размер фотографий не должен превышать 30mb',
}

export const SuccessAlert = {
  title:   'Фото успешно загружены!',
  message: 'Данные будут обработаны в течение 15 минут. Ожидайте ответ в виде SMS-сообщения.',
}

export const uploadDocs = async (files, onSuccess, onError) => {
  const totalSize = files.reduce((acc, { size }) => acc + size, 0)

  if (totalSize > MAX_FILES_SIZE) {
    return showErrorModal(SizeErrorAlert)
  }

  const formData = new FormData()

  files.forEach((file, i) => formData.append(`photo${i}`, file))
  showPreloader()

  try {
    const { status, data } = await Api.loadPassport(formData)

    hidePreloader()

    if (status === STATUSES.SUCCESS) {
      setVerifySuccess(Statistics.getDuration(Statistics.keys.verify))
      return onSuccess ? onSuccess() : showSuccessModal(SuccessAlert)
    }

    showErrorModal({ ...OtherErrorAlert, message: data.messages.join('. ') })
    setVerifyError()
    onError?.()
  } catch ({ message }) {
    showErrorModal({ ...OtherErrorAlert, message })
    setVerifyError()
    onError?.()
  }
}
