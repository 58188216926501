import { Api } from 'api/Api'
import { PasswordInput } from 'components/Field/Password'
import { validatePassword } from 'components/PasswordRecovery/UpdatePassword/utils'
import { removeTempFromLS } from 'components/PasswordRecovery/utils'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import { MIN_PASSWORD_LENGTH } from 'components/SignInModal/utils'
import { paths } from 'constants/paths'
import React, {
  useCallback,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

export const UpdatePassword = ({ code }) => {
  const [password, setPassword]               = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const navigate = useNavigate()

  const isFormValid = password.length >= MIN_PASSWORD_LENGTH && password === passwordConfirm

  const onSubmit = useCallback(() => {
    if (isFormValid) {
      const data = {
        code,
        userId:           localStorage.getItem('uid'),
        password,
        password_confirm: passwordConfirm,
      }

      Api.restorePasswordPost(data)
         .then(({ status, data }) => {
          if (status === 'ok') {
            removeTempFromLS()
            navigate(paths.HOME)

            showSuccessModal({
              title:   'Пароль успешно изменен',
              message: 'Используйте новый пароль для входа в аккаунт',
            })
          } else {
            showErrorModal({ title: 'Что-то пошло не так', message: data.messages })
          }
        })
    }
  }, [isFormValid])

  return (
    <div className="password-set-form">
      <div className="font-18 bold">
        Смена пароля
      </div>
      <div className="font-13 lh-16 gray mt-4">
        Придумайте новый пароль для входа в аккаунт
      </div>
      <div className="mt-22">
        <PasswordInput
          value={password}
          label="Пароль"
          onChange={setPassword}
          validateFn={validatePassword}
        />
        <PasswordInput
          value={passwordConfirm}
          label="Повторите пароль"
          onChange={setPasswordConfirm}
          validateFn={validatePassword}
        />
      </div>
      <button
        className="ml-button mt-22"
        disabled={!isFormValid}
        onClick={onSubmit}
      >
        Сохранить
      </button>
    </div>
  )
}
