import { CurrencyInput } from 'components/CurrencyInput'
import {
  getIsError,
  MAX_AMOUNT,
  MIN_AMOUNT,
} from 'pages/Takemoney/Forms/Card/utils'

export const AmountInput = ({ value, onChange }) => {
  const isError = getIsError(value)
  const isMore  = value > MAX_AMOUNT

  const onBlurAmount = () => {
    if (isError) {
      onChange(value > MAX_AMOUNT ? MAX_AMOUNT : MIN_AMOUNT)
    }
  }

  return (
    <>
      <CurrencyInput
        value={value}
        onChange={onChange}
        onBlur={onBlurAmount}
        placeholder="Сумма"
        invalid={isError}
      />
      {isError && (
        <div className="amount-row__error">
          {`${isMore ? 'максимальная' : 'минимальная'} сумма заказа ${isMore ? MAX_AMOUNT : MIN_AMOUNT} BYN`}
        </div>
      )}
    </>
  )
}
