import ACTIONS from "store/constants/actions";

const initialState = {
	data : []
};

export default function line(state = initialState, action) {
	switch ( action.type ) {
		case ACTIONS.WHATS_NEW.GET_WHATS_NEW:
			return {...state, data : action.data};
		default:
			break;
	}
	return state;
}
