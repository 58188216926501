import { CashbackActionTypes } from 'store/cashback/types'

const initialCashback = {
  current:      {},
  next:         {},
  total_amount: 0,
  progress:     0,
}

export const initialState = {
  ...initialCashback,
  statuses: [],
}

export const cashbackReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CashbackActionTypes.SET: {
      return { ...state, ...(payload || initialCashback) }
    }

    case CashbackActionTypes.SET_STATUSES: {
      return { ...state, statuses: payload }
    }

    default:
      return state
  }
}
