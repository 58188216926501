import { Tags } from 'components/Casino/Tags'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const Filters = ({ items, onClick, activeId }) => (
  <div className="casino-filters">
    {items.map(({ id, icon, name, count, tags }) => (
      <div
        key={id}
        onClick={() => onClick(id)}
        data-id={id}
        className={cn('casino-filters__item', String(activeId) === String(id) && 'casino-filters__item--active')}
      >
        {icon && (
          <div className="casino-filters__icon-wrapper">
            <img
              className="casino-filters__icon"
              src={icon}
              alt={name}
            />
          </div>
        )}
        <Tags
          list={tags}
          className={styles.tags}
        />
        <span className="casino-filters__name">{name}</span>
        <span className="casino-filters__count">{count}</span>
      </div>
    ))}
  </div>
)
