import payoutImg from 'assets/images/oplati/payout.jpg'
import React from 'react'
import { Link } from 'react-router-dom'

export const Payout = () => (
  <div>
    <p>Зайдите в раздел <Link to="/user/take-money">«Заказать на выплату»</Link> и выберите способ «Оплати».</p>
    <p>
      Укажите сумму, выберите из выпадающего списка номер электронного кошелька и нажмите на кнопку
      «Заказать на выплату».
    </p>
    <img
      src={payoutImg}
      alt="payout"
    />
    <p>После обработки выплаты средства будут зачислены на электронный кошелек «Оплати».</p>
    <p className="italic">
      *Для оформления выплаты выигрыша на кошелек «Оплати» необходимо пополнить игровой счет посредством кошелька
      «Оплати».Вывод средств осуществляется на тот электронный кошелек, с которого было произведено пополнение
      игрового счета.
    </p>
    <p className="bold">
      ! Для вывода денежных средств с электронного кошелька «Оплати» необходимо:
    </p>
    <p>
      <div>1. Нажать "Платежи"</div>
      <div>2. Нажать "Сделать новый платёж"</div>
      <div>3. Выбрать "Банковские,финансовые услуги"</div>
      <div>4. Выбрать "Банки, НКФО"</div>
      <div>5. Выбрать Ваш банк</div>
      <div>6. Нажать "Пополнение счёта".</div>
    </p>
  </div>
)
