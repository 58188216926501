import { Api } from 'api/Api'
import { DEFAULT_CARD } from 'components/PaymentModal/ByCard/utils'
import { PaymentMethodID } from 'components/PaymentModal/utils'
import { PaymentActionType } from 'store/payments/types'
import { getCardsInfo } from 'utils'

export const setPaymentsState = payload => ({
  type: PaymentActionType.SET,
  payload,
})

export const reset = () => ({
  type: PaymentActionType.RESET,
})

export const getBonus22 = () => async dispatch => {
  try {
    const { data, data: { type, ...restBonus22Props } } = await Api.getBonus22()
    dispatch(setPaymentsState({ bonusType: type, ...restBonus22Props }))

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getDetails = () => async dispatch => {
  dispatch(getBonus22())

  try {
    const { data: payments } = await Api.getPaymentCards()

    const { cards: rawCards, default: rawDefaultCardID, method: rawMethod, ...restPaymentsProps } = payments

    const method = Object.values(PaymentMethodID).includes(rawMethod) ? rawMethod : PaymentMethodID.CARD
    const cards  = [...getCardsInfo(rawCards), DEFAULT_CARD]
    const card   = cards.find(({ id }) => +id === +rawDefaultCardID)

    dispatch(setPaymentsState({
      cards,
      card,
      method,
      defaultCard:   card,
      defaultMethod: method,
      ...restPaymentsProps,
    }))
  } catch (error) {
    console.error(error)
  }
}
