import { cn } from 'utils'
import styles from './styles.module.scss'

const TextInput = ({
  label,
  type = 'text',
  value = '',
  onChange,
  className,
  placeholder,
  validateFn,
  children,
  submitted,
  ...restProps
}) => {
  const error = validateFn && ((!!value.length || submitted) && validateFn(value))

  return (
    <div className={cn(styles.textInput, className)}>
      {label && (
        <label
          htmlFor={label}
          className={styles.label}
        >
          {label}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <input
          id={label}
          className={cn(styles.input, error && styles.invalid)}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={({ target: { value } }) => onChange(value)}
          {...restProps}
        />
        {children}
      </div>

      {error && <small className={styles.errorMessage}>{error}</small>}
    </div>
  )
}

export default TextInput
