import { Api } from 'api/Api'
import { STATUSES } from 'constants/statuses'
import { MatchResult } from 'pages/MaxinePayReturn/MatchResult'
import { useState } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { getMaxlinePays } from 'store/maxline-pays/actions'
import { selectMaxlinePays } from 'store/maxline-pays/selectors'
import { cn } from 'utils'
import styles from './styles.module.scss'
import {
  getTitleString,
  showErrorReturnModal,
  TakenStage,
} from './utils'

export const MLReturn = ({ className }) => {
  const [data, setData]     = useState()
  const [stage, setStage]   = useState(TakenStage.INITIAL)
  const [isOpen, setIsOpen] = useState(false)

  const dispatch = useDispatch()

  const maxlinePays = useSelector(selectMaxlinePays)

  const { total = 0, bets = [], count = 0 } = data || maxlinePays

  const betIds  = bets.map(({ bet_id }) => bet_id).join('-')
  const isReady = stage === TakenStage.INITIAL

  const onSubmit = async bet_ids => {
    if (isReady) {
      setStage(TakenStage.PENDING)

      try {
        const { status, messages } = await Api.getMaxlinePayout(bet_ids)

        const isOk = status === STATUSES.SUCCESS

        if (!isOk) {
          showErrorReturnModal(messages.join('. '))
          return setStage(TakenStage.INITIAL)
        }

        setData(maxlinePays)
        setStage(TakenStage.SUCCESS)
        dispatch(getMaxlinePays())
      } catch (error) {
        setStage(TakenStage.INITIAL)
      }
    }
  }

  return (
    +total ?
      <div className={cn(styles.mlReturn, className)}>
        <div className={styles.title}>
          {getTitleString(stage, count)}
        </div>
        <div className={styles.controls}>
          <button
            className={styles.submitBtn}
            onClick={() => onSubmit(betIds)}
            data-total={` ${total} BYN`}
            data-status={stage}
          >
            {stage === TakenStage.SUCCESS ? 'Получено' : 'Забрать'}
          </button>
          <button
            className={styles.expandBtn}
            data-open={isOpen}
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        {isOpen && (
          <div className={styles.bets}>
            {bets.map(event => <MatchResult key={event.bet_id} {...event} />)}
          </div>
        )}
      </div> : null
  )
}
