import { usePrevious } from 'hooks/usePrevious'
import { useWillUnmount } from 'hooks/useWillUnmount'
import styles from 'pages/Home/components/Event/Coefficient/styles.module.scss'
import PropTypes from 'prop-types'
import {
  useEffect,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { cn } from 'utils'

const ANIMATION_DURATION      = 4000
export const COEFF_WRAPPER_ID = 'coeff-wrapper'

const Coefficient = ({
  label,
  selected,
  coeffAnimation,
  disabled,
  data: { id: hash, e: eventId, v: value } = {},
}) => {
  const [activeClass, setActiveClass] = useState('')

  const prevValue = usePrevious(value)

  const timeout = useRef(null)

  useEffect(() => {
    if (value && prevValue && value !== prevValue) {
      setActiveClass((!coeffAnimation && styles.scale) || (value < prevValue && styles.down) ||
        (value > prevValue && styles.up))

      timeout.current = setTimeout(() => setActiveClass(''), ANIMATION_DURATION)
    }
  }, [value, prevValue])

  useWillUnmount(() => clearTimeout(timeout.current))

  return (
    <div
      id={COEFF_WRAPPER_ID}
      data-event-id={eventId}
      data-hash={hash}
      className={cn(styles.coefficient, selected && styles.selected, disabled ? styles.disabled : activeClass)}
    >
      {value && (
        <>
          <div className={styles.label}>{label}</div>
          <div className={styles.value}>{value}</div>
        </>
      )}
    </div>
  )
}

Coefficient.propTypes = {
  data:        PropTypes.object,
  selected:    PropTypes.bool,
  label:       PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  changeCoeff: PropTypes.string,
}


const mapStateToProps = state => ({
  coeffAnimation: state.data.indicateFactorChange,
})

export default connect(mapStateToProps, null)(Coefficient)
