import { ReactComponent as SearchIcon } from 'assets/images/casino/search.svg'
import { useRef } from 'react'
import styles from './styles.module.scss'

export const CasinoSearchInput = ({ value, onChange, className, ...restProps }) => {
  const ref = useRef(null)

  const onClear = () => {
    onChange('', false)
    ref.current.focus()
  }

  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <SearchIcon className={styles.magnifierIcon} />
        <input
          ref={ref}
          value={value}
          type="text"
          onChange={({ target: { value } }) => onChange(value)}
          className={styles.input}
          placeholder="Поиск..."
          autoComplete="off"
          autoCorrect="off"
          {...restProps}
        />
        {value && (
          <button
            onClick={onClear}
            className={styles.crossIcon}
            aria-label="Clear input"
          />
        )}
      </div>
    </div>
  )
}
