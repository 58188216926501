import defaultImg from 'assets/images/casino/default-slot.png'
import { toKebabCase } from 'utils/strings'

export const onError = ({ target }) => {
  target.src = defaultImg
}

export const buildGameURI = (providerRaw, name, id, searchRaw) => {
  const provider = providerRaw ? `${providerRaw} ` : ''
  const search   = searchRaw ? `?${searchRaw}` : ''
  return `${toKebabCase(`${provider}${name} ${id}`)}${search}`
}