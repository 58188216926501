export const ACTION_WIDTH        = 56
export const DELETE_TIMEOUT      = 300
export const MIN_X_SHIFT         = -120
export const MAX_X_SHIFT         = 0.001
export const MIN_SHIFT_TO_CLOSE  = 10
export const MIN_SHIFT_TO_OPEN   = -20
export const TRANSITION_DURATION = 200

export const addClass    = (ref, value) => ref.current?.classList.add(value)
export const removeClass = (ref, value) => ref.current?.classList.remove(value)
export const translateX  = (ref, value) => ref.current && (ref.current.style.transform = `translate3d(${value}px, 0, 0)`)
