export const numbers = {
  top:    [1, 2, 3, 4, 5],
  bottom: [6, 7, 8, 9, 0],
}

export const INITIAL_VALUE = ''
export const MAX_LENGTH    = 9
export const MAX_VALUE     = 999999999.99

export const toCorrectValue = value => value.length > 1 && value[1] !== '0' && value[1] !== '.'
  ? value.replace(/^0+/gi, '')
  : value
