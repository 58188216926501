import { getFlagUrl } from 'pages/Home/components/LineLeagues/utils'
import styles from 'pages/Results/live/leagues-list/leagueItem/styles.module.scss'
import { useState } from 'react'
import { cn } from 'utils'

export const LeagueListItem = ({ children, countryCode, name, live, opened = false }) => {
  const [isOpen, setIsOpen] = useState(opened)

  const count = children?.length

  return (
    <div className={styles.leagueList}>
      <div
        className={cn(styles.header, isOpen && styles.opened)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {live && (
          <img
            src={getFlagUrl(countryCode)}
            className={styles.icon}
            alt="League icon"
          />
        )}
        <div className={styles.leagueTitle}>
          {name}
        </div>
        {!!count && (
          <div className={styles.counter}>
            {count}
          </div>
        )}
      </div>
      {isOpen && children}
    </div>
  )
}
