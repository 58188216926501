import styles from 'components/Spinner/styles.module.scss'
import { cn } from 'utils'

const DEFAULT_TEXT = 'Обновляем список событий'

export const Spinner = ({ active = true, text = DEFAULT_TEXT, className }) => (
  active ?
    <>
      <div
        className={cn(styles.container, className)}
      >
        <div className={styles.loader} />
        <div>{text}</div>
      </div>
      <div className={styles.backdrop} />
    </>
    : null
)
