export const omitTags = (value = '') => value.replace(/<[^>]*>/g, '')

export const toCamelCase = str => str
  .toLowerCase()
  .replace(/-{1+}/gi, '-')
  .split('-')
  .reduce((acc, cv) => acc + `${cv[0].toUpperCase()}${cv.slice(1)}`)

export const toKebabCase = (str = '') => str
  ?.replace(/(\W|_)+/g, '-')
  .replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() ?? ''

export const toSnakeCase = (str = '') => str
  ?.replace(/(\W|-)+/g, '_')
  .replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase() ?? ''

export const capitalize = (target = '') => {
  const string = target.toString().trim()
  const words  = string ? string.split(' ') : []

  if (!words.length) {
    return ''
  }

  return words.map(word => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`).join(' ')
}

export const fillTemplate = (str, values, exact = false) => {
  try {
    return values ? str.replace(/{{(.*?)}}/g, (_, key) => {
      if (!values[key] && exact) {
        throw new Error()
      }

      return values[key]
    }) : str
  } catch {
    return null
  }
}
