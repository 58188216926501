import { BetInput } from 'components/Coupon/BetInput'
import { AddBetModal } from 'components/Coupon/Settings/UserBets/AddBetModal'
import styles from 'components/Coupon/Settings/UserBets/styles.module.scss'
import Keyboard from 'components/Keyboard'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  addModal,
  closeModal,
} from 'store/modal/actions'
import { updateUserBets } from 'store/user-bets/actions'
import { cn } from 'utils'

const PLUS_MINUS_KEY = 'plusMinus'

export const UserBets = ({ addModal, closeModal, bets, updateBets }) => {
  const [isNumpad, setIsNumpad]   = useState(false)
  const [stepValue, setStepValue] = useState('')

  const { plusMinus, bet1, bet2, bet3, fetching, error } = bets

  useEffect(() => {
    setStepValue(String(+plusMinus || ''))
  }, [plusMinus])

  const addBet = useCallback((key, value) => {
    if (!value) {
      return addModal(AddBetModal.id, <AddBetModal
        onClose={closeModal}
        onSubmit={nextBet => updateBets({ key, value: nextBet })}
      />)
    }

    return updateBets({ key, value: 0 })
  }, [])

  const renderBet = useCallback(([key, value]) => (
    <div
      key={key}
      className={cn(styles.favoriteBet, !value && styles.empty, fetching === key && styles.fetching)}
      data-value={value}
      onClick={() => addBet(key, value)}
    />
  ), [fetching])

  const onNumpadClose = useCallback(value => {
    const isValidValue = +value >= 0.1

    setIsNumpad(false)
    updateBets({ key: PLUS_MINUS_KEY, value: isValidValue ? value : 0 })

    if (!isValidValue) {
      setStepValue('')
    }
  }, [])

  const betInputValue = useMemo(() => fetching === PLUS_MINUS_KEY ?
    <div className={styles.stepFetching} /> : stepValue, [fetching, stepValue])

  return (
    <>
      <div className="coupon__setting-divider">
        Любимые ставки
      </div>
      <div className={styles.userBets}>
        <div className={styles.favoriteBets}>
          {Object.entries({ bet1, bet2, bet3 }).map(renderBet)}
        </div>
        {error &&
          <div className={styles.invalid}>
            {error}
          </div>}
      </div>
      <div className={styles.betStep}>
        <div>Шаг ставки</div>
        <BetInput
          isOpen={isNumpad}
          value={betInputValue}
          onOpen={() => setIsNumpad(true)}
          uncontrolled
        />
      </div>
      {isNumpad &&
        <Keyboard
          value={stepValue}
          onChange={setStepValue}
          onClose={onNumpadClose}
          max={false}
          keyBar={false}
        />}
    </>
  )
}

const mapStateToProps = state => ({
  bets: state.userBets,
})

const mapDispatchToProps = {
  addModal,
  closeModal,
  updateBets: updateUserBets,
}

UserBets.propTypes = {
  addModal:   PropTypes.func,
  bets:       PropTypes.object,
  closeModal: PropTypes.func,
  removeBet:  PropTypes.func,
  updateBets: PropTypes.any,
}

UserBets.defaultProps = {
  addModal:   () => null,
  bets:       {},
  closeModal: () => null,
  removeBet:  () => null,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBets)
