import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import {
  getInputs,
  IDX_DATA_KEY,
  uploadDocs,
} from 'components/Upload/utils'
import { useState } from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

const Upload = ({ buttonTitle, single, onSuccess, onError }) => {
  const [files, setFiles]   = useState({})
  const [images, setImages] = useState({})

  const filesArr = Object.values(files)
  const inputs   = getInputs(single)

  const onSubmit = () => uploadDocs(filesArr, onSuccess, onError)

  const onChange = event => {
    const input = event.target
    const file  = input.files[0]
    const idx   = input.getAttribute(IDX_DATA_KEY)
    input.value = ''

    if (file) {
      showPreloader()

      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onloadend = (({ target: { result } }) => {
        setFiles(prev => ({ ...prev, [idx]: file }))
        setImages(prev => ({ ...prev, [idx]: result }))
        hidePreloader()
      })
    }
  }

  const onFileRemove = event => {
    event.stopPropagation()

    const idx = event.target.getAttribute(IDX_DATA_KEY)

    const { [idx]: _file, ...nextFiles } = files
    const { [idx]: _img, ...nextImages } = images

    setFiles(nextFiles)
    setImages(nextImages)
  }

  return (
    <section className={styles.upload}>
      <div className={cn(styles.uploadInputs, single && styles.single)}>
        {inputs.map(({ icon, description, className }, idx) => {
          const file     = files[idx]
          const image    = images[idx]
          const fileSize = file ? (file.size / (1000 ** 2)).toFixed(2) : 0

          return (
            <div
              key={description}
              className={styles.inputWrapper}
            >
              {image && (
                <button
                  className={styles.removeBtn}
                  data-idx={idx}
                  onClick={onFileRemove}
                >
                  {`${fileSize} МБ`}
                </button>
              )}
              <label className={cn(styles.label, className, image && styles.loaded)}>
                <img
                  className={cn(styles.img, image && styles.uploadedImg)}
                  src={image || icon}
                  alt={description}
                />
                <input
                  type="file"
                  data-idx={idx}
                  className={styles.fileInput}
                  onChange={onChange}
                />
              </label>
              <div className={styles.description}>{description}</div>
            </div>
          )
        })}
      </div>
      <button
        onClick={onSubmit}
        className="mt-25 ml-button"
        disabled={!filesArr.length}
      >
        {buttonTitle}
      </button>
    </section>
  )
}

export default Upload
