import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'

export const getBanks = () => dispatch => {
  Api.getBank().then(resp => {
    const banks = resp && resp.data && resp.data.bank

    banks && banks.length && dispatch({
      type:    ACTIONS.BANKS.SET,
      payload: banks,
    })
  })
}
