import {
  add,
  sub,
} from 'utils/math'

export const onIncrease = (value, step, max) => {
  const nextValue = add(value, step)
  return String(nextValue > max ? max : nextValue)
}

export const onDecrease = (value, step) => {
  const nextValue = sub(value, step)
  return String(nextValue < 0 ? 0 : nextValue)
}