import { useCentrifugoSub } from 'components/Centrifugo'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { updateAuth } from 'store/auth/actions'
import { selectAuth } from 'store/auth/selectors'
import {
  setWagerBonus,
  setWagerConditions,
} from 'store/data/reducers'
import { CentrifugoChannel } from 'utils/centrifugo/utils'

export const useCentrifugoInit = () => {
  const { id: user } = useSelector(selectAuth)
  const dispatch     = useDispatch()

  const onBalanceChange = data => dispatch(updateAuth(data))
  const onStepChange    = step => dispatch(updateAuth({ step }))
  const onWagerChange   = ({ wager: { wager, conditions } }) => {
    dispatch(setWagerBonus(wager))
    dispatch(setWagerConditions(conditions))
  }

  useCentrifugoSub(CentrifugoChannel.ONLINE)
  useCentrifugoSub(CentrifugoChannel.BALANCE, { user }, onBalanceChange)
  useCentrifugoSub(CentrifugoChannel.FREE_SPINS, { user }, onBalanceChange)
  useCentrifugoSub(CentrifugoChannel.WAGER, { user }, onWagerChange)
  useCentrifugoSub(CentrifugoChannel.SIGN_UP_STEP, { user }, onStepChange)
}
