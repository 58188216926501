import { DEFAULT_CARD } from 'components/PaymentModal/ByCard/utils'
import { PaymentActionType } from 'store/payments/types'

const DEFAULT_METHOD = 17

const initialState = {
  defaultMethod: DEFAULT_METHOD,
  method:        DEFAULT_METHOD,
  card:          DEFAULT_CARD,
  defaultCard:   DEFAULT_CARD,
  cards:         [],
  amounts:       [],
  hidden:        [],
  bonusType:     1,
  dateBlock:     1,
  isVisible:     false,
}

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PaymentActionType.SET: {
      return { ...state, ...action.payload }
    }

    case PaymentActionType.RESET: {
      return {
        ...state,
        defaultMethod: DEFAULT_METHOD,
        method:        DEFAULT_METHOD,
        defaultCardID: 0,
        isVisible:     false,
      }
    }

    default: {
      return state
    }
  }
}
