export const sportsSetting = {
  1:   {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  2:   {
    isDraw:     false,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'quarter',
    periods:    4,
  },
  3:   {
    isDraw:       false,
    is2Chance:    false,
    isHandicap:   true,
    isTotal:      true,
    periodName:   'set',
    isTotalScore: true,
    periods:      5,
  },
  4:   {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    3,
  },
  5:   {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  6:   {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  7:   {
    isDraw:       false,
    is2Chance:    false,
    isHandicap:   true,
    isTotal:      true,
    periodName:   'party',
    isTotalScore: true,
    periods:      6,
  },
  8:   {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'frame',
  },
  9:   {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  10:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
  },
  11:  {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  12:  {
    isDraw:       false,
    is2Chance:    false,
    isHandicap:   true,
    isTotal:      true,
    periodName:   'set',
    isTotalScore: true,
  },
  13:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  14:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
  },
  15:  {
    isDraw:       false,
    is2Chance:    false,
    isHandicap:   true,
    isTotal:      true,
    periodName:   'set',
    isTotalScore: true,
    periods:      7,
  },
  16:  {
    isDraw:       true,
    is2Chance:    true,
    isHandicap:   true,
    isTotal:      true,
    periodName:   'set',
    isTotalScore: true,
  },
  17:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    4,
  },
  18:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  19:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  20:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  21:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  22:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  23:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  24:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  25:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  26:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  27:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  28:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  29:  {
    isDraw:       true,
    is2Chance:    true,
    isHandicap:   true,
    isTotal:      true,
    periodName:   'period',
    isTotalScore: true,
  },
  30:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    3,
  },
  31:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  32:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  33:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  34:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  35:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  36:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  37:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  38:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  39:  {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  40:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  41:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  42:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  43:  {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'map',
    periods:    6,
  },
  44:  {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  45:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  46:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  47:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  48:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  49:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  50:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  51:  {
    isDraw:     false,
    is2Chance:  false,
    isHandicap: false,
    isTotal:    false,
    periodName: 'period',
  },
  52:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  53:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  54:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  55:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  56:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  57:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  58:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  59:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  60:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  61:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  62:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  63:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  64:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  65:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  66:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  67:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  68:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  69:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  70:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  71:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  72:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  73:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  74:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  75:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  77:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
  },
  80:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  83:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  84:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  85:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  87:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  90:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  89:  {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  91:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  95:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  96:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
  },
  97:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    2,
  },
  98:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    2,
  },
  99:  {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    2,
  },
  100: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    2,
  },
  101: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    2,
  },
  102: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'set',
    periods:    2,
  },
  103: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  104: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  105: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  106: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  107: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  108: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  109: {
    isDraw:     true,
    is2Chance:  false,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  110: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  111: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  112: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'map',
    periods:    2,
  },
  113: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'map',
    periods:    2,
  },
  114: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'map',
    periods:    2,
  },
  115: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'map',
    periods:    2,
  },
  116: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'map',
    periods:    2,
  },
  117: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'half',
    periods:    2,
  },
  118: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'quarter',
    periods:    4,
  },
  119: {
    isDraw:     true,
    is2Chance:  true,
    isHandicap: true,
    isTotal:    true,
    periodName: 'period',
    periods:    3,
  },
}
