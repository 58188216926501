import { Game } from 'components/Casino/Slots/Game'
import styles from 'components/Casino/Slots/styles.module.scss'
import {
  favoritesToObject,
  providersToObject,
  tagsToObject,
} from 'components/Casino/Slots/utils'
import { useAuthModal } from 'hooks/useAuthModal'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { toggleFavorites } from 'store/casino/actions'
import { cn } from 'utils'

export const Slots = ({
  games,
  horizontal,
  freeSpin,
  isAuth,
  children,
  favorites,
  providers,
  tags,
  toggleFavorites,
  className,
  extraTags = [],
  withFavorite = true,
  hasDemo = true,
  live = false,
}) => {
  const openAuthModal = useAuthModal()

  const providersObj = useMemo(() => providersToObject(providers), [providers.length])
  const favoritesObj = useMemo(() => favoritesToObject(favorites), [favorites.length])
  const tagsObj      = useMemo(() => tagsToObject(tags), [tags.length])
  const extraTagsObj = useMemo(() => tagsToObject(extraTags), [extraTags.length])

  const onClick = event => {
    const gameId = event.target.closest('[class*=favoriteBtn]')?.id

    if (gameId) {
      return isAuth ? toggleFavorites(gameId) : openAuthModal()
    }
  }

  return (
    <div
      className={cn(styles.games, horizontal && styles.horizontal, live && styles.live, className)}
      onClick={onClick}
    >
      {games.map(({ id, provider_id, tags: tagIds, ...restProps }) => (
        <Game
          key={id}
          id={id}
          provider={providersObj[provider_id]}
          withFavorite={withFavorite}
          favorite={favoritesObj[id]}
          tags={tagIds.map(id => extraTagsObj[id] || tagsObj[id])}
          hasDemo={hasDemo}
          freeSpin={freeSpin}
          live={live}
          {...restProps}
        />
      ))}
      {children}
    </div>
  )
}

const mapStateToProps = state => ({
  isAuth:    state.auth.isAuth,
  favorites: state.casino.favorites,
  providers: state.casino.providers,
  tags:      state.casino.gamesInfo.tags || [],
})

const mapDispatchToProps = {
  toggleFavorites,
}

export default connect(mapStateToProps, mapDispatchToProps)(Slots)
