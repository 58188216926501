import styles from 'components/DateSwitcher/styles.module.scss'
import { getDayLabel } from 'components/DateSwitcher/utils'
import { isSameDay } from 'date-fns'

export const DateSwitcher = ({ goPrevious, openPicker, goNext, activeDate, pastOnly }) => {
  const isActiveDateToday = activeDate && isSameDay(new Date(), activeDate)

  return (
    <div className={styles.dateSwitcher}>
      <button
        className={styles.nextBtn}
        onClick={goPrevious}
        disabled={!activeDate}
        aria-label="Previous day"
      />
      <button
        className={styles.openCalendarBtn}
        onClick={openPicker}
      >
        {getDayLabel(activeDate)}
      </button>
      <button
        className={styles.prevBtn}
        disabled={!activeDate || (pastOnly && isActiveDateToday)}
        onClick={goNext}
        aria-label="Next day"
      />
    </div>
  )
}
