import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'
import {
  buildSportFilters,
  getStaticFilters,
  updateLiveSports,
} from 'store/sports-filter/utils'

export const updateFilterPeriod = payload => ({
  type: ACTIONS.SPORTS_FILTER.UPDATE_PERIOD,
  payload,
})

export const getFilters = (isLive, period, betOnOur, sportId) => async dispatch => {
  try {
    if (isLive) {
      const { data: { sports = [], events_count = {} } } = await Api.getLiveSports(betOnOur, sportId)

      return dispatch({
        type:    ACTIONS.SPORTS_FILTER.UPDATE_LIVE,
        payload: [
          ...getStaticFilters(events_count),
          ...updateLiveSports(sports, events_count),
        ],
      })
    }

    const { data: { sports } } = await Api.getLineFilters(betOnOur)
    const { data: champs }     = await Api.getChampionships()

    dispatch({
      type:    ACTIONS.SPORTS_FILTER.UPDATE_LINE,
      payload: buildSportFilters(sports, champs, period),
    })
  } catch (e) {
    console.error(e)
  }
}
