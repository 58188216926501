import { ReactComponent as CrossIcon } from 'assets/images/clear.svg'
import { links } from 'components/Cookie/constants'
import 'components/Cookie/styles.scss'
import { ExternalLink } from 'components/ExternalLink'
import {
  useEffect,
  useState,
} from 'react'

const COOKIE_KEY = 'cookie'

const isCookieAccepted = !!localStorage.getItem(COOKIE_KEY) || !!sessionStorage.getItem(COOKIE_KEY)

export const CookieModal = ({ loading }) => {
  const [isOpen, setIsOpen] = useState(false)

  const isVisible = !isCookieAccepted && !loading

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setIsOpen(true), 1000)
    }
  }, [isVisible])

  const handleAcceptClick = () => {
    localStorage.setItem(COOKIE_KEY, '1')
    setIsOpen(false)
  }

  const handleRejectClick = () => {
    sessionStorage.setItem(COOKIE_KEY, '1')
    setIsOpen(false)
  }

  return (
    isOpen &&
    <div className="cookie">
      <CrossIcon
        className="cookie__reject"
        onClick={handleRejectClick}
      />
      <div className="cookie__wrapper">
        <div style={{ paddingRight: '40px' }}>
          Мы используем файлы cookie для обеспечения Вашего удобства. Пользуясь сайтом, Вы соглашаетесь с нашей&nbsp;
          <ExternalLink
            to="https://cdn-01.maxline.by/files/cookies_ru.pdf"
            className="cookie__link external"
            title="Политикой cookies."
          />
        </div>
        <div className="cookie__description">
          Подробнее о параметрах управления куки можно ознакомиться, перейдя по внешним ссылкам, ведущим на
          соответствующие страницы сайтов основных браузеров:
        </div>
        <div className="cookie__browsers">
          {links.map(({ id, icon, link }) => (
            <ExternalLink
              key={id}
              to={link}
            >
              {icon}
            </ExternalLink>
          ))}
        </div>
        <button
          className="cookie__button"
          onClick={handleAcceptClick}
        >
          Принять
        </button>
      </div>
    </div>
  )
}
