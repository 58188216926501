import LineBySport from 'pages/Home/Line/BySport'
import Championship from 'pages/Home/Line/Championship'
import { parseSportId } from 'pages/Home/Line/DynamicPage/utils'
import { useParams } from 'react-router-dom'

export const LineDynamicPage = () => {
  const { id } = useParams()

  const { isChamp, champId } = parseSportId(id)

  return (
    isChamp ? <Championship id={champId} /> : <LineBySport id={id} />
  )
}
