import { ReactComponent as SuccessIcon } from 'assets/images/sign-up-modals/success.svg'
import { SignUpModalWrapper } from 'components/SignUpModals/Wrapper'
import styles from './styles.module.scss'

export const DocsUploaded = ({ onClose }) => (
  <SignUpModalWrapper
    onClose={onClose}
    className={styles.successModal}
  >
    <SuccessIcon />
    <div className={styles.text}>
      <div className={styles.title}>Спасибо!</div>
      <div className={styles.message}>
        Документы успешно загружены.
        <br />
        Обычно рассмотрение занимает до 15 минут.
      </div>
    </div>
    <button
      className="ml-button"
      onClick={onClose}
    >
      Продолжить
    </button>
  </SignUpModalWrapper>
)
