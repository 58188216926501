import { sportsSetting } from 'utils/settings'

const NOTIFIED_SPORTS = [1, 4, 14, 31, 117, 119]

const TEAM_IMG_URI = 'https://maxline.by/assets/club-images/'

const findClubImg = (clubs, id) => clubs.find(({ club_id }) => +club_id === +id)?.image
const getTeamImg  = image => image ? `${TEAM_IMG_URI}${image}` : ''

export const extractTeamImages = (description, clubs) => {
  let team1Img = ''
  let team2Img = ''

  const { '1': club1Id, '2': club2Id } = JSON.parse(description || '{}').clubs || {}

  if ((club1Id || club2Id) && clubs) {
    team1Img = getTeamImg(findClubImg(clubs, club1Id))
    team2Img = getTeamImg(findClubImg(clubs, club2Id))
  }

  return { team1Img, team2Img }
}

export const eventHandler = ({
  id,
  team1 = '',
  team2 = '',
  time,
  description,
  type,
  sport_id,
  childs,
} = {}, clubs) => {
  const more                   = JSON.parse(description || '{}')
  const isLive                 = +type === 1
  const { team1Img, team2Img } = extractTeamImages(description, clubs)

  return {
    id,
    teams:            [{ name: team1, index: 1, img: team1Img }, { name: team2, index: 2, img: team2Img }],
    time,
    statisticsId:     more.stat,
    hasTvLink:        !!more['tv-link'],
    needNotification: NOTIFIED_SPORTS.includes(+sport_id),
    isLive,
    type:             isLive ? 'live' : 'line',
    sportId:          sport_id,
    isDraw:           sportsSetting[sport_id]?.isDraw,
    link:             `/${isLive ? 'live' : 'line'}-event/${id}`,
  }
}

export const getEventProps = ({ event, results, factors, league = {}, clubs }) => {
  const eventFactors = factors?.[event.id] || {}

  const coeffs = []
  eventFactors.values.forEach((item) => {
    item.rows?.forEach((row) => {
      row.values?.forEach((value) => coeffs.push(value))
    })
  })

  return {
    key:        event.id,
    event:      eventHandler(event, clubs),
    leagueName: league.name,
    isFinished: !!+results?.[event.id]?.finished,
    results:    results?.[event.id] || {},
    addFactors: eventFactors.countAdditional || 0,
    coeffs,
  }
}


export const getCoeffName = coeff => {
  if (coeff?.name === 'Меньше') {
    return `ТМ ${coeff?.label}`
  }

  if (coeff?.name === 'Больше') {
    return `ТБ ${coeff?.label}`
  }

  if (coeff?.name === 'Ф1' || coeff?.name === 'Ф2') {
    return `${coeff?.name} ${coeff?.label}`
  }

  if (coeff?.name === 'Победит') {
    return `Да ${coeff?.label}`
  }

  if (coeff?.name === 'Не победит') {
    return `Нет ${coeff?.label}`
  }

  return coeff?.name
}
