import { ROOT } from 'constants/ids'
import { Children } from 'react'
import { createPortal } from 'react-dom'

const root = document.getElementById(ROOT)

export const Portal = ({ children, selector }) => {
  const hasChildren = Children.count(children)
  const container   = selector ? document.querySelector(selector) : root

  return (hasChildren && container) ? createPortal(children, container) : null
}
