import logo from 'assets/images/app-banner/logo.png'
import { Portal } from 'components/Portal'
import { AppLink } from 'components/SmartBanner/AppLink'
import { AppBannerText } from 'components/SmartBanner/utils'
import styles from './styles.module.scss'

export const GuestBanner = ({ onClose }) => (
  <Portal selector="#smart-banner">
    <div className={styles.guestBanner}>
      <img
        src={logo}
        className={styles.logo}
        alt="MaxLine"
      />
      <div className={styles.text}>
        <div className={styles.title}>
          {AppBannerText.TITLE}
        </div>
        <div className={styles.description}>
          {AppBannerText.DESCRIPTION}
        </div>
      </div>
      <AppLink />
      <button
        onClick={onClose}
        className={styles.closeBtn}
        aria-label="Hide banner"
      />
    </div>
  </Portal>
)
