import { MaxlinePaysTypes } from 'store/maxline-pays/types'

const initialState = {
  bets:  [],
  count: 0,
  total: 0,
}

export const maxlinePaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case MaxlinePaysTypes.SET: {
      return { ...state, ...action.payload }
    }

    default: {
      return state
    }
  }
}
