import { ReactComponent as AndroidIcon } from 'assets/images/left-menu/android.svg'
import { ReactComponent as BonusesIcon } from 'assets/images/left-menu/bonuses.svg'
import { ReactComponent as CasinoLiveIcon } from 'assets/images/left-menu/casino-live.svg'
import { ReactComponent as ChatIcon } from 'assets/images/left-menu/chat.svg'
import { ReactComponent as FaqIcon } from 'assets/images/left-menu/faq.svg'
import { ReactComponent as FastGamesIcon } from 'assets/images/left-menu/fast-games.svg'
import { ReactComponent as ForecastIcon } from 'assets/images/left-menu/forecast.svg'
import { ReactComponent as HighlightsIcon } from 'assets/images/left-menu/highlights.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/left-menu/instagram.svg'
import { ReactComponent as IosIcon } from 'assets/images/left-menu/ios.svg'
import { ReactComponent as NewsIcon } from 'assets/images/left-menu/news.svg'
import { ReactComponent as PaymentsIcon } from 'assets/images/left-menu/payments.svg'
import { ReactComponent as PhoneIcon } from 'assets/images/left-menu/phone.svg'
import { ReactComponent as PpsIcon } from 'assets/images/left-menu/pps.svg'
import { ReactComponent as ResultsIcon } from 'assets/images/left-menu/results.svg'
import { ReactComponent as SearchIcon } from 'assets/images/left-menu/search-event.svg'
import { ReactComponent as SlotsIcon } from 'assets/images/left-menu/slots.svg'
import { ReactComponent as TelegramIcon } from 'assets/images/left-menu/telegram.svg'
import { ReactComponent as TopExpressIcon } from 'assets/images/left-menu/top-express.svg'
import { ReactComponent as TournamentsIcon } from 'assets/images/left-menu/tournaments.svg'
import { ReactComponent as TvGamesIcon } from 'assets/images/left-menu/tv-games.svg'
import { ReactComponent as VipCashbackIcon } from 'assets/images/left-menu/vip-cashback.svg'
import { ReactComponent as VirtualSportIcon } from 'assets/images/left-menu/virtual-sport.svg'
import { ReactComponent as VkIcon } from 'assets/images/left-menu/vk.svg'
import { ReactComponent as YoutubeIcon } from 'assets/images/left-menu/youtube.svg'
import { ReactComponent as JetXIcon } from 'assets/images/top-menu/jetx.svg'
import { WagerProgress } from 'components/WagerProgress'
import {
  ANDROID_APP_URL,
  IOS_APP_URL,
} from 'constants/links'
import { paths } from 'constants/paths'
import {
  isAndroid,
  isIos,
  isWebView,
} from 'constants/platform'
import { cn } from 'utils'
import styles from './styles.module.scss'

const Divider = ({ title }) => (
  <div className={styles.divider}>
    {title}
  </div>
)

export const openChat = () => {
  window.jivo_api?.open()
  window.jivo_container?.addEventListener?.('click', window.linksHandler)
}

export const getMenuItems = ({ tournamentsCount, maxlinePaysCount }) => [
  {
    title:     'Поиск',
    Icon:      SearchIcon,
    link:      '/search-event',
    className: styles.search,
  },
  {
    title:     'ТУРНИРЫ',
    subtitle:  'Участвуй и побеждай!',
    link:      paths.TOURNAMENTS,
    Icon:      TournamentsIcon,
    className: styles.primary,
    badge:     tournamentsCount,
  },
  {
    title:     'VIP CashBack',
    subtitle:  'Получай возврат до 20%',
    link:      '/vip-cashback',
    Icon:      VipCashbackIcon,
    className: styles.primary,
  },
  {
    title:     '1 000 000 BYN',
    subtitle:  'За верный прогноз',
    link:      '/forecast/create',
    Icon:      ForecastIcon,
    className: styles.primary,
  },
  // TODO Delete as needed
  // {
  //   title:     'MAXLINE ПЛАТИТ',
  //   subtitle:  'Возвращаем проигрышные ставки',
  //   link:      '/maxline-pay-return',
  //   Icon:      MaxlinePayReturnIcon,
  //   className: styles.primary,
  //   badge:     maxlinePaysCount,
  // },
  {
    title:     'Топ-экспрессы',
    subtitle:  'Популярные экспрессы',
    Icon:      TopExpressIcon,
    link:      '/top-express',
    className: styles.primary,
  },
  {
    title:     'ОБЗОРЫ',
    subtitle:  'Лучшие моменты матчей',
    Icon:      HighlightsIcon,
    link:      '/highlights',
    className: styles.primary,
  },
  {
    component: <Divider
                 key="casino-divider"
                 title="Казино"
               />,
  },
  {
    component: <WagerProgress key="wager-progress" />,
  },
  {
    title:        'Слоты',
    Icon:         SlotsIcon,
    link:         paths.CASINO_SLOTS,
    className:    styles.primary,
    strictActive: true,
  },
  {
    title:     'Live Казино',
    Icon:      CasinoLiveIcon,
    link:      paths.CASINO_LIVE,
    className: styles.primary,
  },
  {
    title:     'Быстрые игры',
    Icon:      FastGamesIcon,
    link:      paths.QUICK_GAMES,
    className: styles.primary,
    unmarked:  true,
  },
  {
    title:     'Виртуальный спорт',
    Icon:      VirtualSportIcon,
    link:      paths.VIRTUAL_SPORT,
    className: styles.primary,
  },
  {
    title:     'Jet',
    Icon:      JetXIcon,
    link:      paths.JET_X,
    className: cn(styles.primary, 'jetx'),
    unmarked:  true,
  },
  { title: 'TV-игры', Icon: TvGamesIcon, link: paths.TV_GAMES, className: styles.primary },
  {
    component: <Divider
                 key="additional-divider"
                 title="Дополнительно"
               />,
  },
  { title: 'Результаты', link: '/results', Icon: ResultsIcon },
  !isWebView && {
    title: 'Скачать приложение',
    link:  (isIos && IOS_APP_URL) || (isAndroid && ANDROID_APP_URL) || paths.MOBILE_APP,
    Icon:  isIos && IosIcon || isAndroid && AndroidIcon || PhoneIcon,
  },
  { title: 'Платежи', Icon: PaymentsIcon, link: '/page/payments' },
  { title: 'Бонусы', Icon: BonusesIcon, link: '/pages/bonuses' },
  { title: 'Новости', Icon: NewsIcon, link: paths.NEWS },
  { title: 'Пункты приёма ставок', Icon: PpsIcon, link: '/page/pps' },
  { title: 'F.A.Q.', Icon: FaqIcon, link: '/faq' },
  { title: 'Online чат', onClick: openChat, Icon: ChatIcon, link: '/chat' },
].filter(Boolean)

export const socialNetworks = [
  { title: 'YouTube', link: 'https://www.youtube.com/channel/UC9PLwT3BtDbbZAuExTA5RFQ', Icon: YoutubeIcon },
  { title: 'VK', link: 'https://vk.com/maxlineby', Icon: VkIcon },
  { title: 'Instagram', link: 'https://www.instagram.com/maxlineofficial', Icon: InstagramIcon },
  { title: 'Telegram', link: 'https://t.me/maxlineofficial', Icon: TelegramIcon },
]
