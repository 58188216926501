import { BetInput } from 'components/Coupon/BetInput'
import Keyboard from 'components/Keyboard'
import {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { changeAmount } from 'store/one-click-bets/actions'
import styles from './styles.module.scss'

const OneClickBetsSettings = ({ isActive, changeAmount, initialAmount }) => {
  const [isNumpad, setIsNumpad] = useState(false)
  const [amount, setAmount]     = useState('')

  useEffect(() => {
    if (+initialAmount) {
      setAmount(initialAmount)
    }
  }, [])

  const onNumpadClose = useCallback(() => {
    changeAmount(amount)
    setIsNumpad(false)
  }, [amount])

  return (
    isActive &&
    <div className={styles.settings}>
      <div className={styles.description}>
        Функция «Ставка в 1 клик» позволяет делать ставки одним нажатием. Сумма каждой ставки,
        размещенной таким образом, будет равна сумме, которую Вы укажите в поле ниже.
      </div>
      <div className={styles.inputWrapper}>
        <div>
          Введите сумму ставки
        </div>
        <BetInput
          value={amount}
          isOpen={isNumpad}
          onOpen={() => setIsNumpad(true)}
          uncontrolled
        />
      </div>
      {isNumpad &&
        <Keyboard
          min={0.1}
          max={false}
          onClose={onNumpadClose}
          onChange={nextAmount => setAmount(nextAmount)}
          value={amount}
        />}
    </div>
  )
}

const mapStateToProps = state => ({
  initialAmount: state.oneClickBets.amount,
})

const mapDispatchToProps = {
  changeAmount,
}

export default connect(mapStateToProps, mapDispatchToProps)(OneClickBetsSettings)
