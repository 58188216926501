import { useDefineLocation } from 'hooks/useDefineLocation'
import { getFilters } from 'pages/Home/components/PeriodsFilter/utils'
import styles from 'pages/Home/styles.module.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { updateFilterPeriod } from 'store/sports-filter/actions'
import { cn } from 'utils'

const PeriodsFilter = ({ value, onChange, orderBy }) => {
  const { isLine, isChampionship } = useDefineLocation()

  return (
    (isLine && !isChampionship) ?
      <div className="ml-filter__box">
        <Link
          to="/search-event"
          className={styles.searchLink}
        />
        <div className="ml-filter ml-filter__inline">
          {getFilters(orderBy).map(filter => (
            <div
              key={filter.title}
              className={cn('ml-filter-item', value === filter.value && 'ml-filter-item__active')}
              onClick={() => onChange(filter.value)}
            >
              {filter.title}
            </div>
          ))}
        </div>
      </div> : null
  )
}

const mapStateToProps = state => ({
  value:   state.sportsFilter.period,
  orderBy: state.config.line.default_sort,
})

const mapDispatchToProps = {
  onChange: updateFilterPeriod,
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodsFilter)
