import { PromoCode } from 'pages/PromoCodes/PromoCode'
import styles from './styles.module.scss'

export const AvailablePromoCodes = ({ list, onUpdate, onExpired }) => !!list?.length && (
  <div className={styles.availablePromoCodes}>
    <div className={styles.title}>Доступные промокоды:</div>
    <div className={styles.list}>
      {list.map(promoCode => (
        <PromoCode
          key={promoCode.code}
          onUpdate={onUpdate}
          onExpired={onExpired}
          {...promoCode} />
      ))}
    </div>
  </div>
)
