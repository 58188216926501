export const LINE_TAB_IDS = {
  SINGLE:  1,
  EXPRESS: 2,
  SYSTEM:  3,
}

export const LINE_COUPON_TYPE   = 'line'
export const COUPON_KEYBOARD_ID = 'coupon-keyboard'

export const configureBets = (array, values, propName) => array?.reduce((acc, { [propName]: id }) => ({
  ...acc,
  [id]: (values[id] || ''),
}), {}) || {}

export const buildTabs = (express, system) => [
  (express || system) && { title: 'Одиночные', value: LINE_TAB_IDS.SINGLE },
  express && { title: 'Экспресс', value: LINE_TAB_IDS.EXPRESS },
  system && { title: 'Системы', value: LINE_TAB_IDS.SYSTEM },
].filter(Boolean)

export const getTotalSingle = ({ items }, single) => items.reduce((total, {
  value,
  idEvent,
}) => total + (value * (single?.[idEvent] ?? 0)), 0)

export const getTotalSystem = ({ systems }, system) => systems.reduce((total, {
  total:  value,
  system: id,
}) => total + (value * (system?.[id] ?? 0)), 0)

export const getTotalExpress = ({ totalValue }, express) => +express * totalValue

export const prepareSingle = single => Object
  .keys(single)
  .reduce((acc, key) => +single[key] ? ({ ...acc, [`single[${key}]`]: +single[key] }) : acc, {})

export const prepareSystem = system => Object
  .keys(system)
  .reduce((acc, key, idx) => +system[key] ? ({ ...acc, [`system[${idx}]`]: +system[key] }) : acc, {})

export const prepareExpress = express => +express ? { express } : {}
