import styles from 'components/OplatiTerms/styles.module.scss'
import { instructionTypes } from 'components/OplatiTerms/utils'
import SwipeModal from 'components/SwipeModal'
import { useState } from 'react'

export const OplatiTerms = ({ type, className }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { title, content } = instructionTypes[type]

  return (
    <div
      className={className}
      onClick={() => setIsOpen(true)}
    >
      <div>{title}</div>
      <SwipeModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className={styles.modal}
      >
        <div className={styles.content}>
          {content}
        </div>
      </SwipeModal>
    </div>
  )
}
