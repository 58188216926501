import {
  AllFilter,
  FreeSpinsCategory,
  PopCategory,
} from 'pages/Casino/constants'

export const appendStaticFilters = (filters, { count_all = 0, count_popular = 0, free_spins = 0 }) => {
  const pop       = { ...PopCategory, count: count_popular }
  const freeSpins = { ...FreeSpinsCategory, count: free_spins }
  const all       = { ...AllFilter, count: count_all }

  return [all, pop, free_spins && freeSpins, ...filters].filter(Boolean)
}
