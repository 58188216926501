import overtajm from 'assets/images/event/statistics/additional_time.svg'
import kasaniyamyachavrataremzapredelamishtrafnojploshchadi from 'assets/images/event/statistics/ball-touches.svg'
import seriyabullitov from 'assets/images/event/statistics/bullets.svg'
import uglovye from 'assets/images/event/statistics/corners.svg'
import kompensirovannoevremya from 'assets/images/event/statistics/extra-time.svg'
import foly from 'assets/images/event/statistics/faults.svg'
import ofsajdy from 'assets/images/event/statistics/offside.svg'
import vbrosautov from 'assets/images/event/statistics/outs.svg'
import seriyapenalti from 'assets/images/event/statistics/penalty.svg'
import broskivstvor from 'assets/images/event/statistics/shots-on-goal.svg'
import udaryvstvor from 'assets/images/event/statistics/shots.svg'
import shtangiiliperekladiny from 'assets/images/event/statistics/shtangiiliperekladiny.svg'
import kolvominudalenij from 'assets/images/event/statistics/two-minutes-foal.svg'
import udaryotvorot from 'assets/images/event/statistics/udaryotvorot.svg'
import videoprosmotry from 'assets/images/event/statistics/videoprosmotry.svg'
import vyhodmedbrigadynapole from 'assets/images/event/statistics/vyhodmedbrigadynapole.svg'
import jeltyekarty from 'assets/images/event/statistics/yellow-cards.svg'

export const statIcons = {
  dopolnitelnoevremya: overtajm,
  golyvbolshinstve:    udaryvstvor,
  jeltyekarty,
  seriyapenalti,
  foly,
  uglovye,
  vbrosautov,
  kolvominudalenij,
  broskivstvor,
  overtajm,
  ofsajdy,
  udaryvstvor,
  seriyabullitov,
  udaryotvorot,
  shtangiiliperekladiny,
  videoprosmotry,
  vyhodmedbrigadynapole,
  kasaniyamyachavrataremzapredelamishtrafnojploshchadi,
  kompensirovannoevremya,
}
