import React, {
  useEffect,
  useState,
} from 'react'
import { cn } from 'utils'

const DEFAULT_DURATION = 180
const prettifyTime     = time => `${time > 9 ? '' : '0'}${time}`

const Timeout = ({ duration, initialDuration, isActive, setIsActive, onRefresh }) => {
  const [seconds, setSeconds] = useState(duration || 0)

  useEffect(() => {
    let timeout

    if (isActive && seconds) {
      timeout = setTimeout(() => setSeconds(prevSec => prevSec - 1), 1000)
    } else {
      setSeconds(initialDuration || DEFAULT_DURATION)
      setIsActive(false)
    }
    return () => {
      clearInterval(timeout)
    }
  }, [isActive, seconds])

  const timeoutText = `${prettifyTime(Math.floor(seconds / 60))}:${prettifyTime(seconds % 60)}`

  return (
    seconds ? (
      <div
        className={cn('font-12 gray text-center mt-22', !isActive && 'underline')}
        {...(!isActive && { onClick: onRefresh })}>
        {isActive ?
          `Отправить код подтверждения повторно можно будет через: ${timeoutText}` :
          'Отправить код подтверждения ещё раз'}
      </div>
    ) : null
  )
}

export default Timeout
