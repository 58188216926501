import { GooseAnimation } from 'components/GooseAnimation'
import styles from 'components/Preloader/styles.module.scss'
import { connect } from 'react-redux'

export const Preloader = ({ active }) => active ? (
  <div className={styles.preloader}>
    <GooseAnimation className={styles.gooseAnimation} />
  </div>
) : null

const mapStateToProps = state => ({
  active: state.preloader.active,
})

export default connect(mapStateToProps)(Preloader)
