import { TAX_FACTOR } from './utils'

export const Tax = ({ amount }) => {
  const tax = amount * TAX_FACTOR

  return (
    <div className="payment-info">
      <div
        data-title="Налог:"
        data-value={`${+tax.toFixed(2)} BYN`}
      />
      <div
        data-title="Зачисление:"
        data-value={`${(+amount - +tax).toFixed(2)} BYN`}
        data-profit={true}
      />
    </div>
  )
}
