import { Filters } from 'components/Casino/Filters/Filters'
import { Select } from 'components/Casino/SelectBlock/Select'
import {
  CasinoFilter,
  useGamesFilter,
  useSelectContext,
} from 'components/Casino/SelectBlock/utils'
import SwipeModal from 'components/SwipeModal'
import { AllFilter } from 'pages/Casino/constants'
import { providersToFilters } from 'pages/Casino/utils'
import { useSelector } from 'react-redux'
import {
  selectCounters,
  selectProviders,
} from 'store/casino/selectors'

export const ProvidersSelect = () => {
  const [activeID, setFilter]         = useGamesFilter(CasinoFilter.PROVIDER)
  const { isOpen, onChange, onClose } = useSelectContext(CasinoFilter.PROVIDER)

  const providers = useSelector(selectProviders)

  const { count_all = 0 } = useSelector(selectCounters)

  const filters  = [{ ...AllFilter, count: count_all }, ...providersToFilters(providers)]
  const provider = filters.find(({ id }) => String(id) === String(activeID))

  const onClick = provider => {
    setFilter(provider)
    onClose()
  }

  return (
    <Select
      title="Провайдер"
      subTitle={provider?.name}
      onClick={() => onChange(CasinoFilter.PROVIDER)}
    >
      <SwipeModal
        title="Провайдер"
        isOpen={isOpen}
        onClose={onClose}
        className="casino-swipe-modal"
      >
        <Filters
          onClick={onClick}
          items={filters}
          activeId={activeID}
        />
      </SwipeModal>
    </Select>
  )
}
