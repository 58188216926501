import { Api } from 'api/Api'
import { OplatiTerms } from 'components/OplatiTerms'
import { Option } from 'components/Option'
import { showErrorModal } from 'components/Popup'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import { SubmitBtn } from 'components/SubmitBtn'
import SwipeModal from 'components/SwipeModal'
import { SwipeModalTrigger } from 'components/SwipeModalTrigger'
import { STATUSES } from 'constants/statuses'
import { AmountInput } from 'pages/Takemoney/Forms/Card/AmountInput'
import { Tax } from 'pages/Takemoney/Forms/Card/Tax'
import {
  getIsError,
  PAYOUT_EXTRA_LOCK_MS,
} from 'pages/Takemoney/Forms/Card/utils'
import { onSuccessPayout } from 'pages/Takemoney/Forms/utils'
import React, {
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import TEXT from 'store/constants/text'
import { getDetails } from 'store/payments/actions'
import { sleep } from 'utils/time'

const OplatiForm = ({ minAmount, getDetails }) => {
  const [amount, setAmount]               = useState(minAmount)
  const [selectedPurse, setSelectedPurse] = useState({})
  const [purses, setPurses]               = useState([])
  const [isFormOpen, setIsFormOpen]       = useState(false)

  const isDisabled = getIsError(amount) || !selectedPurse.id

  const { id: card, cardNumber } = selectedPurse

  useEffect(() => {
    Api.getOplatiPurses()
      .then(({ data }) => setPurses(data?.purses || data))
  }, [])

  const onPurseChange = selectedPurse => {
    setSelectedPurse(selectedPurse)
    setIsFormOpen(false)
  }

  const onSubmit = async () => {
    showPreloader(TEXT.pays.paysTitle)

    try {
      const { data: { messages }, status } = await Api.getOplatiWithdraw({ amount, card, cardNumber })

      if (status === STATUSES.SUCCESS) {
        onSuccessPayout()
        getDetails()
        setSelectedPurse({})
        setAmount(minAmount)
        return await sleep(PAYOUT_EXTRA_LOCK_MS)
      }

      showErrorModal({
        message:   messages.toString(),
        submitBtn: { title: 'Продолжить' },
      })
    } catch (e) {
      console.error(e)
      showErrorModal({ message: 'Что-то пошло не так...', title: 'Продолжить' })
    } finally {
      hidePreloader()
    }
  }

  return (
    <>
      <AmountInput
        value={amount}
        onChange={setAmount}
      />
      <SwipeModalTrigger
        onOpen={() => setIsFormOpen(true)}
        value={selectedPurse.cardNumber}
        placeholder="Кошелек для выплаты"
      />
      {!isDisabled && <Tax amount={amount} />}
      <SubmitBtn
        className="ml-button mt-14"
        onClick={onSubmit}
        disabled={isDisabled}
      >
        Получить выигрыш
      </SubmitBtn>
      <OplatiTerms
        type="payout"
        className="mt-14 gray font-12 underline"
      />
      <SwipeModal
        isOpen={isFormOpen}
        title="Кошелек для выплаты"
        onClose={() => setIsFormOpen(false)}
      >
        {purses?.length ? purses.map(purse => (
          <Option
            key={purse.id}
            title={purse.cardNumber}
            action={() => onPurseChange(purse)}
            checked={selectedPurse.id === purse.id}
          />
        )) : <div className="text-center gray font-12">У вас нет привязанных счетов</div>}
      </SwipeModal>
    </>
  )
}

const mapStateToProps = state => ({
  theme:     state.data.theme,
  minAmount: state.service.paymentLimits.minWithdraw,
})

const mapDispatchToProps = {
  getDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(OplatiForm)
