import { useEffect } from 'react'

const html = document.documentElement

const onResize = () => {
  html.style.setProperty('--vh', `${(window.innerHeight * 0.01).toFixed(3)}px`)
  html.style.setProperty('--vw', `${(window.innerWidth * 0.01).toFixed(3)}px`)
}

export const useDimensionsObserver = () => {
  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])
}
