import { ExternalLink } from 'components/ExternalLink'
import styles from 'components/PaymentModal/Oplati/OplatiLink/styles.module.scss'

export const OplatiLink = ({ to }) => (
  <div className={styles.oplatiLink}>
    <div>Чтобы завершить пополнение счёта, перейдите в приложение <b>«Оплати»</b></div>
    <ExternalLink
      to={to}
      title="Перейти в"
      className={styles.link}
    />
  </div>
)
