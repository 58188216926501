import cardcard from 'assets/images/banks/belcard.png'
import unknownCardIcon from 'assets/images/banks/credit-card.svg'
import mastercard from 'assets/images/banks/mastercard.svg'
import visa from 'assets/images/banks/visa.svg'
import add from 'assets/images/svg/add.svg'

const cardIcon = {
  mastercard,
  visa,
  add,
  cardcard,
}

export const getCardIcon = alias => alias && cardIcon[alias.toLowerCase()] || unknownCardIcon
