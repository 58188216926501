import { cn } from 'utils'
import styles from './styles.module.scss'

export const Backdrop = ({ className, onClose, zIndex = 180 }) => (
  <div
    className={cn(styles.backdrop, className)}
    style={{ zIndex }}
    onClick={onClose}
    role="presentation"
  />
)
