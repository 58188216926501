import { Api } from 'api/Api'
import { Checkbox } from 'components/Checkbox'
import {
  BonusType,
  getBonuses,
} from 'components/PaymentModal/Bonus22/utils'
import { paths } from 'constants/paths'
import { usePaymentsState } from 'hooks/usePaymentsState'
import { Link } from 'react-router-dom'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const Bonus22 = () => {
  const {
          state: { bonusType, is_blocked_casino, is_blocked_sport },
          setState,
          closeModal,
        } = usePaymentsState()

  const isAvailable = !is_blocked_casino || !is_blocked_sport
  const isRejected  = +bonusType === BonusType.REJECTED

  const onChange = bonusType => setState({ bonusType })

  const onChangeType = async value => {
    onChange(value)
    await Api.changeBonus22(value)
  }

  const toggleIsEnabled = async () => {
    const nextType = isRejected ? BonusType.CASINO : BonusType.REJECTED

    onChange(nextType)
    await Api.changeBonus22(nextType)
  }

  return isAvailable ? (
    <div>
      <div className={styles.header}>
        <div className={styles.title}>ВЫБЕРИТЕ БОНУС:</div>
        <Link
          to={paths.BONUS22}
          onClick={closeModal}
          className={styles.aboutLink}
        >
          Подробнее
        </Link>
      </div>
      <div className={styles.bonusBtns}>
        {getBonuses(is_blocked_casino, is_blocked_sport).map(({ type, title, subtitle, blocked }) => (
          <button
            key={type}
            className={cn(styles.bonusBtn, (blocked && styles.blocked) || (type === bonusType && styles.selected))}
            onClick={() => onChangeType(type)}
            disabled={blocked || isRejected}
          >
            <div className={styles.bonusBtnTitle}>{title}</div>
            <div className={styles.bonusBtnSubtitle}>{subtitle}</div>
          </button>
        ))}
      </div>
      <Checkbox
        title="Отказаться от бонуса"
        className={styles.rejectCheckbox}
        checked={isRejected}
        onChange={toggleIsEnabled}
        left
      />
    </div>
  ) : null
}
