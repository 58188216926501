import { Api } from 'api/Api'
import { Page } from 'components/Page'
import { Spinner } from 'components/Spinner'
import { useRequest } from 'hooks/useRequest'
import { PpsItem } from 'pages/Pps/PpsItem'
import TEXT from 'store/constants/text'

export const PpsPage = () => {
  const { data: ppsList = [], isLoading } = useRequest(Api.getPpsList)

  return (
    <Page
      title={TEXT.pps.title}
      bg="gray"
    >
      <div className="p-8">
        {isLoading ? <Spinner text="Загрузка..." /> : ppsList.map(pps => <PpsItem key={pps.id} {...pps} />)}
      </div>
    </Page>
  )
}
