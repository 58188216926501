import { CardsSelect } from 'components/PaymentModal/CardController/CardsSelect'
import { ExpirationInput } from 'components/PaymentModal/CardController/ExpirationInput'
import styles from 'components/PaymentModal/CardController/styles.module.scss'

export const CardController = ({ value, onChange, options }) => options?.length > 1 && (
  <div className={styles.cardController}>
    <CardsSelect
      options={options}
      value={value?.id}
      onChange={card => onChange({ card })}
    />
    <ExpirationInput
      value={value?.exp}
      onChange={exp => onChange({ card: ({ ...value, exp, updated: true }) })}
    />
  </div>
)
