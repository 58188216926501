import { ReactComponent as PendingIcon } from 'assets/images/success-pay/pending.svg'
import styles from './styles.module.scss'

export const HASH_KEY          = 'hash'
export const PAYMENT_COMPLETED = 'paymentCompleted'

export const PaymentPending = {
  title:     'Идет обработка платежа',
  subtitle:  'Пожалуйста дождитесь завершения',
  icon:      PendingIcon,
  className: styles.pending,
}
