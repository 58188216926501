import { FilterItem } from 'components/Filter'
import { FilterGroup } from 'components/FilterGroup'
import { MLReturn } from 'pages/MaxinePayReturn/Status/TakeReturn'
import { SwitchToggle } from 'pages/Settings/SwitchToggle'
import BetsHistory from 'pages/UserHistoryPage/Bets/History'
import {
  periods,
  types,
} from 'pages/UserHistoryPage/Casino/History/utils'
import {
  INITIAL_FILTER,
  INITIAL_IS_NOT_CALC,
  INITIAL_PERIOD,
  INITIAL_TYPE,
} from 'pages/UserHistoryPage/utils'
import { useState } from 'react'
import styles from './styles.module.scss'

export const BetsHistoryPage = () => {
  const [period, setPeriod]       = useState(INITIAL_PERIOD)
  const [filter, setFilter]       = useState(INITIAL_FILTER)
  const [type, setType]           = useState(INITIAL_TYPE)
  const [isNotCalc, setIsNotCalc] = useState(INITIAL_IS_NOT_CALC)

  return (
    <>
      <MLReturn className={styles.takeReturn} />
      <SwitchToggle
        title="Только нерассчитанные"
        className={styles.notCalcToggle}
        onChange={() => setIsNotCalc(!isNotCalc)}
        checked={isNotCalc}
      />
      <FilterGroup>
        <FilterItem onClick={() => setFilter('type')}>
          {types[type]}
        </FilterItem>
        <FilterItem onClick={() => setFilter('period')}>
          {periods[period]}
        </FilterItem>
      </FilterGroup>
      <BetsHistory
        period={period}
        filter={filter}
        type={type}
        setFilter={setFilter}
        setPeriod={setPeriod}
        setType={setType}
        notCalc={isNotCalc}
      />
    </>
  )
}
