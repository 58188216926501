import { Portal } from 'components/Portal'
import styles from './styles.module.scss'

export const BankPage = ({ href, onClose }) => (
  href ? (
    <Portal>
      <div className={styles.bankPage}>
        <div className={styles.header}>
          <div className={styles.title}>Пополнение счёта</div>
          <button
            onClick={onClose}
            className={styles.closeBtn}
            aria-label="Close"
          />
        </div>
        <iframe
          src={href}
          className={styles.iframe}
        />
      </div>
    </Portal>
  ) : null
)
