export const metaByPathname = {
  '/':                {
    title:       'Букмекерская контора Макслайн | Maxline',
    description: 'Официальный сайт букмекерской конторы Maxline в Беларуси. Вход в личный кабинет БК Mакслайн',
    canonical:   'https://maxline.by',
  },
  '/live/top':        {
    title:       'Ставки на спорт Live, большой выбор ставок на футбол, баскетбол, теннис и другие виды спорта',
    description: 'Предпочитаете делать ставки в процессе игры? На нашем сайте доступны ставки на спорт в формате live. Делайте прогнозы после начала трансляции и повышайте свои шансы на успех',
    canonical:   'https://maxline.by',
  },
  '/live/1':          {
    title:       'Лайв (LIVE) ставки на спорт онлайн ',
    description: 'Поставить лайв (LIVE) ставки на все виды спорта: футбол, хоккей, теннис, баскетбол, волейбол в букмекерской конторе в Беларуси',
    canonical:   'https://maxline.by/live-sport/soccer',
  },
  '/line/all-sports': {
    title:       'Онлайн ставки на спорт. Широкая линия ставок на спортивные события',
    description: 'Сделать ставку на спорт в букмекерской конторе онлайн. Букмекерская линия ставок на спортивные события в Live или Прематч',
    canonical:   'https://maxline.by/line-sport/all',
  },
  '/casino':          {
    title:       'Онлайн казино на реальные деньги в Беларуси',
    description: 'Играть в онлайн казино на деньги с выводом. Лучшее онлайн казино с большим выбором игр и прозрачными условиями',
    canonical:   'https://maxline.by/casino',
  },
  '/casino/slots':    {
    title:       'Лайв (LIVE) казино с реальными крупье',
    description: 'Играть в лайв (LIVE) казино на деньги с живыми дилерами. Большой выбор игр разных категорий: блэкджек, рулетка, баккара',
    canonical:   'https://maxline.by/casino',
  },
  '/casino/live':     {
    title:       'Лайв (LIVE) казино с реальными крупье',
    description: 'Играть в лайв (LIVE) казино на деньги с живыми дилерами. Большой выбор игр разных категорий: блэкджек, рулетка, баккара',
    canonical:   'https://maxline.by/casino/live',
  },
  '/results':         {
    title:       'Результаты матчей, турниров, чемпионатов на сегодня | Maxline',
    description: 'Результаты, статистика матчей по КХЛ, футболу, хоккею, Лиги чемпионов, НХЛ, теннису и др. Турнирная таблица чемпионатов, календарь с расписанием игр 2024 - 2025',
    canonical:   'https://maxline.by/result/live',
  },
  '/top-express':     {
    title:       'Экспресс ставки на спорт онлайн с большими коэффициентами на сегодня',
    description: 'Экспресс ставки на спорт с БК Макслайн на сегодня ⚽ Онлайн экспресс ставки с большим коэффициентом и высокой проходимостью на футбол, теннис, хоккей, баскетбол в Maxline◼',
    canonical:   'https://maxline.by/top-express',
  },
  '/line/1':          {
    title:       'Ставки на Футбол Онлайн | Ставки на спорт Макслайн в Беларуси',
    description: 'Сделать ставку на футбол онлайн. ⚽ Широкая линия и высокие коэффициенты ставок на футбол в букмекерской конторе Макслайн (Maxline) ➤ Смотреть турнирную таблицу, календарь игр и расписание матчей по футболу онлайн.',
    canonical:   'https://maxline.by/line-sport/soccer',
  },
  '/line/3':          {
    title:       'Ставки на Большой Теннис Онлайн | Ставки на спорт Макслайн в Беларуси',
    description: 'Сделать ставку на теннис онлайн. 🎾 Широкая линия и высокие коэффициенты ставок на теннис в букмекерской конторе Макслайн (Maxline). ➤ Смотреть турнирную сетку и таблицу, календарь игр и расписание матчей по теннису онлайн.',
    canonical:   'https://maxline.by/line-sport/tennis',
  },
  '/line/2':          {
    title:       'Ставки на Баскетбол Онлайн | Ставки на спорт Макслайн в Беларуси',
    description: 'Сделать ставку на баскетбол онлайн. 🏀 Широкая линия и высокие коэффициенты ставок на баскетбол в букмекерской конторе Макслайн (Maxline). ➤ Смотреть турнирную сетку и таблицу, календарь игр и расписание матчей по баскетболу онлайн.',
    canonical:   'https://maxline.by/line-sport/basketball',
  },
  '/line/7':          {
    title:       'Ставки на Волейбол Онлайн | Ставки на спорт Макслайн в Беларуси',
    description: 'Сделать ставку на волейбол онлайн. 🏐 Широкая линия и высокие коэффициенты ставок на волейбол онлайн в букмекерской конторе Макслайн (Maxline). ➤ Смотреть расписание игр и турнирную таблицу соревнований по волейболу онлайн',
    canonical:   'https://maxline.by/line-sport/volleyball',
  },
  '/line/4':          {
    title:       'Ставки на Хоккей Онлайн | Ставки на спорт Макслайн в Беларуси',
    description: 'Сделать ставку на хоккей онлайн. 🏒 Широкая линия и высокие коэффициенты ставок на хоккей в букмекерской конторе Макслайн (Maxline) ✓ Смотреть расписание матчей, календарь игр и турнирную таблицу соревнований по хоккею',
    canonical:   'https://maxline.by/line-sport/hockey',
  },
  '/line/43':         {
    title:       'Ставки на Киберспорт Сегодня | CSGO, DOTA 2: БК Макслайн в Беларуси',
    description: 'Сделать ставку на киберспорт онлайн. Широкая линия и высокие коэффициенты ставок на киберспортивные матчи КС ГО и ДОТА 2 в букмекерской конторе Макслайн (Maxline) ➤ Смотреть турнирную таблицу соревнований, календарь игр и расписание матчей по киберспорту онлайн.',
    canonical:   'https://maxline.by/line-sport/eSports',
  },
  '/line/10':         {
    title:       'Ставки на Гандбол Онлайн | Ставки на спорт Макслайн в Беларуси',
    description: 'Сделать ставку на гандбол онлайн. Широкая линия и высокие коэффициенты ставок на игры по гандболу в букмекерской конторе Макслайн (Maxline). ✓ Смотреть турнирную таблицу соревнований, календарь игр и расписание матчей по гандболу онлайн.',
    canonical:   'https://maxline.by/line-sport/handball',
  },
  '/line/15':         {
    title:       'Ставки на Настольный Теннис Онлайн | Ставки на спорт Макслайн',
    description: 'Сделать ставку на настольный теннис онлайн. ➤Широкая линия и высокий коэффициент ставок на маленький теннис онлайн в букмекерской конторе Макслайн (Maxline).➤ Смотреть турнирную таблицу, календарь игр и расписание матчей по настольному теннису онлайн',
    canonical:   'https://maxline.by/line-sport/tabletennis',
  },
  '/casino/maxline_pay':                 {
    title:       'Большой выбор слотов разной тематики, выгодные условия игры в онлайн казино',
    description: 'Каждый день мы предоставляем 100% кэшбек за проигранные ставки в случайных слотах от различных провайдеров.',
    canonical:   'https://maxline.by/casino/maxline_pay',
  },
}
