import { ExternalLink } from 'components/ExternalLink'
import styles from 'components/Header/Navigation/styles.module.scss'
import {
  getMenuItems,
  socialNetworks,
} from 'components/Header/Navigation/utils'
import { useStopScroll } from 'hooks/useStopScroll'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { selectTournamentsCount } from 'store/config/selectors'
import { selectMaxlinePays } from 'store/maxline-pays/selectors'
import { cn } from 'utils'

export const Navigation = ({ opened, onClose }) => {
  const tournamentsCount            = useSelector(selectTournamentsCount)
  const { count: maxlinePaysCount } = useSelector(selectMaxlinePays)

  useStopScroll(opened)

  const onLinkClick = callback => {
    callback?.()
    onClose()
  }

  const menuItems = getMenuItems({ tournamentsCount, maxlinePaysCount })

  return opened && (
    <>
      <div className={styles.navigation}>
        {menuItems.map(({
          title,
          subtitle,
          link,
          onClick,
          Icon,
          className,
          badge,
          unmarked,
          component,
        }) => component || (
          <NavLink
            key={link}
            to={link}
            className={({ isActive }) => cn(styles.navItem, !unmarked && isActive && styles.active, className)}
            onClick={() => onLinkClick(onClick)}
          >
            {!!badge && <div className={styles.badge}>{badge}</div>}
            <Icon className={styles.icon} />
            <div className={styles.content}>
              <div className={styles.title}>
                {title}
              </div>
              {subtitle && (
                <div className={styles.subtitle}>
                  {subtitle}
                </div>
              )}
            </div>
          </NavLink>
        ))}
        <div className={styles.socialNetworks}>
          {socialNetworks.map(({ link, Icon }) => (
            <ExternalLink
              key={link}
              to={link}
              className={styles.socialNetworkLink}
            >
              <Icon />
            </ExternalLink>
          ))}
        </div>
      </div>
      <div
        role="presentation"
        onClick={onClose}
        className={styles.backdrop}
      />
    </>
  )
}
