import { sortBy } from 'lodash'

export const getTopEvents = events => events.filter(({ is_top }) => !!+is_top)

export const getMainEvents = events => {
  const childrenIds = events.reduce((acc, { childs = [] }) => [...acc, ...childs], [])

  return events.filter(({ id }) => !childrenIds.includes(id))
}

export const sortLiveLeagues = leagues => sortBy(leagues, 'name')
  .sort((a, b) => +b.order - +a.order)
