import { DocsUploaded } from 'components/SignUpModals/DocsUploaded'
import { GetBonus } from 'components/SignUpModals/GetBonus'
import { ResendDocs } from 'components/SignUpModals/ResendDocs'
import { SendDocs } from 'components/SignUpModals/SendDocs'
import { SignUp } from 'components/SignUpModals/SignUp'
import SignUpNew from 'components/SignUpModals/SignUpForm'
import { TopUp } from 'components/SignUpModals/TopUp'
import {
  addMinutes,
  isBefore,
} from 'date-fns'
import { getAccounts } from 'store/user/actions'
import { isNumber } from 'utils/guards'

export const ModalKeys = {
  SIGN_UP:       'SIGN_UP',
  SIGN_UP_FORM:  'SIGN_UP_FORM',
  SEND_DOCS:     'SEND_DOCS',
  RESEND_DOCS:   'RESEND_DOCS',
  DOCS_UPLOADED: 'DOCS_UPLOADED',
  TOP_UP:        'TOP_UP',
  GET_BONUS:     'GET_BONUS',
}

export const KeyByStep = {
  0: ModalKeys.SIGN_UP,
  1: ModalKeys.SEND_DOCS,
  2: ModalKeys.RESEND_DOCS,
  3: ModalKeys.TOP_UP,
  4: ModalKeys.GET_BONUS,
}

export const ComponentByKey = {
  [ModalKeys.SIGN_UP]:       SignUp,
  [ModalKeys.SIGN_UP_FORM]:  SignUpNew,
  [ModalKeys.SEND_DOCS]:     SendDocs,
  [ModalKeys.RESEND_DOCS]:   ResendDocs,
  [ModalKeys.DOCS_UPLOADED]: DocsUploaded,
  [ModalKeys.TOP_UP]:        TopUp,
  [ModalKeys.GET_BONUS]:     GetBonus,
}

export const handleModal = (step, setModalKey) => {
  const needModal = +step || (!+step && !getAccounts())

  if (needModal) {
    const key    = KeyByStep[step]
    const onOpen = () => setModalKey(key)

    const maxAge = +localStorage.getItem(key)

    if (maxAge) {
      const isExpired = isBefore(new Date(maxAge), new Date())

      if (isExpired) {
        onOpen()
        localStorage.removeItem(key)
      }
    } else {
      onOpen()
    }

    Object.values(ModalKeys).filter(k => k !== key).forEach(k => localStorage.removeItem(k))
  }
}

export const setMaxAge = (modalKey, minutes) => {
  const maxAge = String(addMinutes(new Date(), isNumber(minutes) ? minutes : 15).getTime())
  localStorage.setItem(modalKey, maxAge)
}
