import { Option } from 'components/Option'
import { Page } from 'components/Page'
import { Placeholder } from 'components/Placeholder'
import { PromoCodeInput } from 'components/SignUp/PromoCodeInput'
import SwipeModal from 'components/SwipeModal'
import { AvailablePromoCodes } from 'pages/PromoCodes/Available'
import { PromoCodesHistory } from 'pages/PromoCodes/History'
import styles from 'pages/PromoCodes/styles.module.scss'
import {
  filters,
  getUserPromoCodes,
  initialFilter,
  initialState,
} from 'pages/PromoCodes/utils'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import TEXT from 'store/constants/text'
import { cn } from 'utils'

export const PromoCodesPage = () => {
  const [promoCodes, setPromoCodes]     = useState(initialState)
  const [isModalOpen, setIsModalOpen]   = useState(false)
  const [activeFilter, setActiveFilter] = useState(initialFilter)

  const { history, future } = promoCodes

  const onUpdate = useCallback(() => {
    getUserPromoCodes(activeFilter.value).then(setPromoCodes)
  }, [activeFilter.value])

  useEffect(() => {
    onUpdate()
  }, [onUpdate])

  const onFilterChange = filter => {
    setActiveFilter(filter)
    setIsModalOpen(false)
  }

  const onExpired = pCode => setPromoCodes(({ future, history }) => ({
    history,
    future: future?.filter(({ code }) => code !== pCode),
  }))

  return (
    <Page title="Промокоды">
      <div className={styles.pageWrapper}>
        <AvailablePromoCodes
          list={future}
          onUpdate={onUpdate}
          onExpired={onExpired}
        />
        <div className={styles.historyWrapper}>
          <PromoCodeInput
            label="Применить промокод:"
            onChange={onUpdate}
            className={styles.input}
            activate
          />
          <section className={styles.history}>
            <div className={styles.title}>
              {TEXT.account.promoCodes.history}
            </div>
            <div className={styles.periodFilter}>
              <div
                className={cn('history-filter__value', styles.periodValue)}
                onClick={() => setIsModalOpen(true)}
              >
                {activeFilter.title}
              </div>
            </div>
            {!!history?.length ? <PromoCodesHistory list={history} /> : <Placeholder name="promoCodes" />}
            <SwipeModal
              title="Период"
              onClose={() => setIsModalOpen(false)}
              isOpen={isModalOpen}
            >
              {filters.map(filter => (
                <Option
                  key={filter.title}
                  title={filter.title}
                  checked={filter.value === activeFilter.value}
                  action={() => onFilterChange(filter)}
                />
              ))}
            </SwipeModal>
          </section>
        </div>
      </div>
    </Page>
  )
}
