import { scrollToElement } from 'components/SEOCollapse/utils'
import { useState } from 'react'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const SEOCollapse = ({ title, children, className, offset = 0 }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = ({ currentTarget }) => {
    if (!isOpen) {
      scrollToElement(currentTarget, offset)
    }

    setIsOpen(!isOpen)
  }

  return (
    <div className={cn(styles.seoCollapse, isOpen && styles.open, className)}>
      <div className={styles.main}>
        <div
          className={styles.toggle}
          onClick={toggle}
        >
          <h1 className={styles.title}>{title}</h1>
        </div>
        <div className={styles.text}>
          {children}
        </div>
      </div>
    </div>
  )
}
