import { metaByPathname } from 'components/SEO/utils'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

export const SEO = ({ children }) => {
  const { pathname } = useLocation()

  const { title, description, canonical } = metaByPathname[pathname] || metaByPathname['/']

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link
          rel="canonical"
          href={canonical}
        />
        <meta
          name="description"
          content={description}
        />
      </Helmet>
      {children}
    </>
  )
}
