import { Redirect } from 'components/Redirect'
import { paths } from 'constants/paths'
import { CasinoBannersPage } from 'pages/Casino/Banners'
import CasinoGamesPage from 'pages/Casino/Games'
import { CasinoLayout } from 'pages/Casino/Layout'
import { CasinoThemesPage } from 'pages/Casino/Themes'
import { CasinoTVGamePage } from 'pages/Casino/TVGame'
import { CasinoTVGamesPage } from 'pages/Casino/TVGames'
import { lazy } from 'react'

const CasinoMyGamesPage        = lazy(() => import('../../pages/Casino/MyGames'))
const CasinoFreeSpinsGamesPage = lazy(() => import('../../pages/Casino/FreeSpinsGames'))
const CasinoGamePage           = lazy(() => import('../../pages/Casino/Game'))
const CasinoLivePage           = lazy(() => import('../../pages/Casino/Live'))
const CasinoMaxlinePayPage     = lazy(() => import('../../pages/Casino/MaxlinePay'))
const CasinoProvidersPage      = lazy(() => import('../../pages/Casino/Providers'))
const CasinoSlotsPage          = lazy(() => import('../../pages/Casino/Slots'))
const CasinoVirtualSportPage   = lazy(() => import('../../pages/Casino/VirtualSport'))

export const casinoRoutes = [
  {
    path:     'casino',
    element:  <CasinoLayout />,
    children: [
      {
        index:   true,
        element: <CasinoBannersPage />,
      },
      {
        path:     'games',
        children: [
          {
            index:   true,
            element: <CasinoGamesPage />,
          },
          {
            path:    ':slug',
            element: <CasinoGamePage />,
          },
        ],
      },
      {
        path:    'live',
        element: <CasinoLivePage />,
      },
      {
        path:    'virtual-sport',
        element: <CasinoVirtualSportPage />,
      },
      {
        path:    'free-spins-games',
        element: <CasinoFreeSpinsGamesPage />,
      },
      {
        path:     'tv-games',
        children: [
          {
            index:   true,
            element: <CasinoTVGamesPage />,
          },

          {
            path:    ':slug',
            element: <CasinoTVGamePage />,
          },
        ],
      },
      {
        path:     'slots',
        children: [
          {
            index:   true,
            element: <CasinoSlotsPage />,
          },
          {
            path:    'providers',
            element: <CasinoProvidersPage />,
          },
          {
            path:    'themes',
            element: <CasinoThemesPage />,
          },
          {
            path:    'my-games',
            element: <CasinoMyGamesPage />,
          },
        ],
      },
      {
        path:    'maxline_pay',
        element: <CasinoMaxlinePayPage />,
      },
      {
        path:    'error',
        element: <Redirect to={paths.CASINO_SLOTS} />,
      },
    ],
  },
]

