import PropTypes from 'prop-types'
import React from 'react'
import { cn } from 'utils'

export const Checkbox = ({ title, checked, onChange, radio, className, disabled, left, invalid }) => (
  <div
    className={cn('ml-checkbox', radio && 'radio-btn', className, disabled && 'disabled', left && 'left')}
    onClick={onChange}
  >
    <div className="ml-checkbox__title">{title}</div>
    <div className={cn('ml-checkbox__checkmark', checked && 'checked', invalid && 'invalid')} />
  </div>
)

Checkbox.propTypes = {
  onChange:  PropTypes.func,
  checked:   PropTypes.bool,
  className: PropTypes.string,
  disabled:  PropTypes.bool,
  radio:     PropTypes.bool,
  left:      PropTypes.bool,
  title:     PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
}

Checkbox.defaultProps = {
  onChange:  () => null,
  checked:   false,
  className: '',
  disabled:  false,
  left:      false,
  radio:     false,
  title:     '',
}
