import { Api } from 'api/Api'
import { ReactComponent as TicketIcon } from 'assets/images/empty-history/topExpress.svg'
import {
  openPopup,
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import { STATUSES } from 'constants/statuses'
import { formatNumber } from 'utils/numbers'

export const getAmountText = (value, freeSpin, freeBet) => {
  const amount = formatNumber(value)
  return [(freeBet || !freeBet && !freeSpin) && `${amount} BYN`, freeSpin && `${amount} FS`].filter(Boolean)
}

const Conditions = ({ list }) => !!list?.length && (
  <>
    <div className="bold">Условия получения бонуса:</div>
    {list.map(rule => <div key={rule}>{rule}</div>)}
  </>
)

const openSuccessModal = ({ code, rules }) => {
  showSuccessModal({
    title:     `Промокод ${code} успешно активирован`,
    message:   <Conditions list={rules} />,
    submitBtn: { title: 'Ок' },
  })
}

const openErrorModal = (message = 'Проверьте введенный данные') => {
  showErrorModal({
    title:     'Не удалось активировать промокод',
    message,
    submitBtn: {
      title: false,
    },
  })
}

export const openPromoCodeInfo = (promoCode, props) => {
  const { code, rules, value, is_freespin, is_freebet } = promoCode

  const amount = getAmountText(value, is_freespin, is_freebet).map(value => `${value}`).join(' + ')

  return openPopup({
    icon:      TicketIcon,
    title:     `Промокод: ${code} (${amount})`,
    message:   <Conditions list={rules} />,
    submitBtn: {
      title: 'Ok',
    },
    ...props,
  })
}

export const activatePromoCode = async (promoCode, callback) => {
  try {
    const { status, messages } = await Api.activatePromoCode(promoCode.code)

    await callback()

    if (status === STATUSES.SUCCESS) {
      return openSuccessModal(promoCode)
    }

    openErrorModal(messages.join(' '))
  } catch {
    openErrorModal()
  }
}

export const showPromoCodeInfo = (promoCode, callback) => openPromoCodeInfo(promoCode, {
  submitBtn: {
    title: 'Активировать', callback: () => activatePromoCode(promoCode, callback),
  },
})
