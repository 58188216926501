import { Addon } from 'components/Addon'
import { Checkbox } from 'components/Checkbox'
import { BetInput } from 'components/Coupon/BetInput'
import { Booster } from 'components/Coupon/Booster'
import { BetExpress } from 'components/Coupon/Line/BetExpress'
import { BetSingle } from 'components/Coupon/Line/BetSingle'
import { BetSystem } from 'components/Coupon/Line/BetSystem'
import {
  buildTabs,
  configureBets,
  COUPON_KEYBOARD_ID,
  getTotalExpress,
  getTotalSingle,
  getTotalSystem,
  LINE_COUPON_TYPE,
  LINE_TAB_IDS,
  prepareExpress,
  prepareSingle,
  prepareSystem,
} from 'components/Coupon/Line/utils'
import { Addons } from 'components/Coupon/Live/Addons'
import { Error } from 'components/Coupon/Live/Error'
import { CouponControls } from 'components/Coupon/Live/Footer/Controls'
import { clearCouponModal } from 'components/Coupon/Live/utils'
import { MakeBetButton } from 'components/Coupon/MakeBetBtn'
import { MaxAmount } from 'components/Coupon/MaxAmount'
import { CouponStatus } from 'components/Coupon/Status'
import { TotalCoeff } from 'components/Coupon/TotalCoeff'
import { useCloseOnScroll } from 'components/Coupon/utils'
import { CouponWrapper } from 'components/Coupon/Wrapper'
import Keyboard from 'components/Keyboard'
import { Tabs } from 'components/Tabs'
import { usePrevious } from 'hooks/usePrevious'
import { Interval } from 'pages/Home/utils'
import { toBYN } from 'pages/UserHistoryPage/utils'
import {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  clearCoupon,
  getCouponLine,
  makeLineBet,
} from 'store/coupon/actions'
import { cn } from 'utils'
import styles from './styles.module.scss'

const CouponLine = ({
  isAuth,
  couponStorage: { coupon = {}, isEmpty, status, messages },
  addons,
  onGetCoupon,
  onClearCoupon,
  express_plus,
  onMakeBet,
  isGoldBet,
  loading,
}) => {
  const [isOpen, setIsOpen]             = useState(false)
  const [isNumpad, setIsNumpad]         = useState(false)
  const [isVip, setIsVip]               = useState(false)
  const [single, setSingle]             = useState({})
  const [express, setExpress]           = useState('')
  const [system, setSystem]             = useState({})
  const [isFetching, setIsFetching]     = useState(false)
  const [addonsValues, setAddonsValues] = useState({})
  const [activeTab, setActiveTab]       = useState(0)

  const betsCount     = Object.keys(coupon.items || {}).length
  const isCouponExist = !isEmpty && !!betsCount
  const hasExpress    = betsCount > 1
  const hasSystem     = isCouponExist && (coupon.systems.length > 0 && betsCount > 2)
  const { max }       = coupon

  const isOneBet  = betsCount === 1
  const isExpress = activeTab === LINE_TAB_IDS.EXPRESS
  const isSystem  = activeTab === LINE_TAB_IDS.SYSTEM
  const isSingle  = activeTab === LINE_TAB_IDS.SINGLE || isOneBet

  const totalAmount = () => {
    if (isSingle) {
      return Object.keys(single).reduce((acc, key) => acc + +single[key], 0)
    }

    if (isSystem) {
      return Object.keys(system).reduce((acc, key) => acc + +system[key], 0)
    }

    return +express
  }

  const totalPossibleWin = () => {
    if (isSingle) {
      return getTotalSingle(coupon, single)
    }

    if (isSystem) {
      return getTotalSystem(coupon, system)
    }

    return getTotalExpress(coupon, express)
  }

  const toggleCoupon = nextValue => {
    setIsNumpad(nextValue && betsCount <= 3)
    setIsOpen(nextValue)
  }

  const closeCoupon = () => toggleCoupon(false)
  const openCoupon  = () => toggleCoupon(true)

  const onOpen = () => {
    setActiveTab(betsCount > 1 ? LINE_TAB_IDS.EXPRESS : LINE_TAB_IDS.SINGLE)
    openCoupon()
  }

  const prevIsAuth    = usePrevious(isAuth)
  const prevBetsCount = usePrevious(betsCount)

  useCloseOnScroll(isOpen && isOneBet && !loading, closeCoupon)

  useEffect(() => {
    if (addons.length) {
      setAddonsValues(Addon.configureValues(addons))
    }
  }, [addons.length])

  useEffect(() => {
    if (!betsCount && isVip) {
      setIsVip(false)
    }
  }, [betsCount])

  useEffect(() => {
    setSingle({})
    setExpress('')
    setSystem({})
  }, [isGoldBet, isVip])

  useEffect(() => {
    if ((isExpress && betsCount < 2) || (isSystem && betsCount < 3)) {
      setActiveTab(LINE_TAB_IDS.SINGLE)
    }
  }, [betsCount, activeTab])

  useEffect(() => {
    if (!prevBetsCount && isOneBet && !isOpen && prevIsAuth) {
      openCoupon()
    }
  }, [isOneBet, prevBetsCount])

  useEffect(() => {
    setIsFetching(true)
    onGetCoupon(() => setIsFetching(false))

    const interval = setInterval(onGetCoupon, Interval.LINE)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const clearCoupon = useCallback(() => clearCouponModal(() => {
    closeCoupon()
    onClearCoupon(LINE_COUPON_TYPE)
  }), [])

  const makeBet = () => {
    setIsNumpad(false)

    const data = {
      ...(isSingle && prepareSingle(single)),
      ...(isSystem && prepareSystem(system)),
      ...(isExpress && prepareExpress(express)),
      gold: +coupon.isGoldBet,
      vip:  +isVip,
      express_plus,
    }

    onMakeBet(Addon.configureParams(data, addonsValues))
  }

  const onSingleChange = (id, amount) => {
    setSingle(prevState => ({ ...prevState, [id]: amount }))
  }

  const updateAmounts = () => {
    setSingle(configureBets(coupon.items, single, 'idEvent'))
    setSystem(configureBets(coupon.systems, system, 'system'))
  }

  useEffect(() => {
    updateAmounts()
  }, [JSON.stringify(coupon)])

  if (prevBetsCount === 1 && betsCount === 2 && isOpen) {
    closeCoupon()
  }

  const isFreeBet  = addonsValues.freebet?.value
  const isError    = !loading && status === 'error' && messages.length > 0
  const hasBooster = !isFreeBet && isExpress

  const couponStatus = (
    <CouponStatus
      isOpen={isOpen}
      isFetching={isFetching}
      onOpen={onOpen}
      freeBet={isFreeBet}
      booster={coupon.bonus}
      totalCoeff={coupon.totalValue}
      couponsCount={betsCount}
    />
  )

  return (
    isCouponExist && (
      <CouponWrapper
        opened={isOpen}
        single={isOneBet}
        status={couponStatus}
        onClose={closeCoupon}
        processing={loading}
      >
        <div className={cn(styles.couponData, isOneBet && styles.single)}>
          {hasExpress && (
            <Tabs
              list={buildTabs(hasExpress, hasSystem)}
              onChange={setActiveTab}
              value={activeTab}
              className={styles.couponTabs}
            />
          )}
          <div className={styles.bets}>
            {isSingle && coupon.items.map(item =>
              <BetSingle
                key={`${item.idEvent}_${item.f}`}
                item={item}
                value={single[item.idEvent] || ''}
                onChange={onSingleChange}
                isVip={isVip}
                count={coupon.items.length}
                onClose={closeCoupon}
              />)}
            {isExpress && hasExpress && coupon.items.map(item =>
              <BetExpress
                key={`${item.idEvent}_${item.f}`}
                item={item}
                onClose={closeCoupon}
              />)}
            {isSystem && coupon.systems.map(item =>
              <BetSystem
                key={`${item.m}_${item.n}`}
                value={system[item.system] || ''}
                onChange={(key, value) => setSystem({ ...system, [key]: value })}
                item={item}
                max={max}
                isVip={isVip}
                onClose={closeCoupon}
                totalCount={coupon.systems.length}
              />,
            )}
          </div>
          {isError && <Error message={messages} />}
          {hasBooster && (
            <Booster
              value={coupon?.bonus}
              full
            />
          )}
          <div
            className={styles.footer}
            data-single={isOneBet}
          >
            <div className={styles.left}>
              {(isExpress || hasExpress) && (
                <div className={styles.top}>
                  {isExpress ? (
                    <>
                      <BetInput
                        value={express}
                        max={coupon.max}
                        isOpen={isNumpad}
                        isVip={isVip}
                        onOpen={() => setIsNumpad(true)}
                        onChange={setExpress}
                      />
                      <div className="coupon__bet-count">
                        <div className="coupon__footer-info">
                          <TotalCoeff
                            value={coupon.totalValue}
                            booster={coupon.bonus}
                            freeBet={isFreeBet}
                          />
                          <MaxAmount
                            value={max}
                            onChange={setExpress}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="coupon__bet-count">
                      <div className="font-12 gray">
                        <span>Сумма ставок:</span>
                        <span className="ml-5">{toBYN(totalAmount())}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <Addons
                values={addonsValues}
                onChange={setAddonsValues}
                list={addons}
              >
                {!coupon.vip_disabled && (
                  <Checkbox
                    className="coupon__checkbox"
                    title="VIP ставка"
                    checked={isVip}
                    onChange={() => setIsVip(!isVip)}
                    left
                  />
                )}
              </Addons>
              <MakeBetButton
                onSubmit={makeBet}
                freeBet={isFreeBet}
                possibleWin={totalPossibleWin()}
                disabled={!+coupon?.max}
                {...(isExpress && { booster: coupon.bonus })}
              />
            </div>
            <CouponControls
              single={isOneBet}
              onClose={closeCoupon}
              onClear={clearCoupon}
            />
          </div>
          {isExpress && isNumpad && (
            <Keyboard
              value={express}
              onChange={setExpress}
              onClose={() => setIsNumpad(false)}
              max={max}
              isVip={isVip}
            />
          )}
          <div id={COUPON_KEYBOARD_ID} />
        </div>
      </CouponWrapper>
    )
  )
}

const mapStateToProps = state => ({
  isAuth:        state.auth.isAuth,
  couponStorage: state.coupon,
  addons:        state.coupon.coupon?.addons || [],
  express_plus:  state.line.express_plus,
  isGoldBet:     state.coupon.coupon && state.coupon.coupon.isGoldBet,
  money:         state.auth.money,
  loading:       state.bet_data.loading,
})

const mapDispatchToProps = {
  onGetCoupon:   getCouponLine,
  onClearCoupon: clearCoupon,
  onMakeBet:     makeLineBet,
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponLine)
