import { HighlightCard } from 'components/Highlights/Card'
import styles from 'components/Highlights/HighlightsList/styles.module.scss'
import { Placeholder } from 'components/Placeholder'
import { useState } from 'react'

const Highlight = ({ id, name, countryCode, events, searchString }) => {
  const [isOpen, setIsOpen] = useState(true)

  return events.length ? (
    <div
      key={id}
      className={styles.league}
    >
      <div
        className={styles.leagueInfo}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={`https://cdn-01.maxline.by/images/flags/${countryCode || 'def'}.png`}
          className={styles.leagueIcon}
          alt={name}
        />
        <div className={styles.leagueName}>
          {name}
        </div>
      </div>
      {isOpen && (
        <div className={styles.events}>
          {events.map(event => (
            <HighlightCard
              key={event.id}
              searchString={searchString}
              {...event}
            />
          ))}
        </div>
      )}
    </div>
  ) : null
}

export const HighlightsList = ({ events, leagues, searchString }) => {
  const content = leagues.length ? leagues.map(league => (
    <Highlight
      key={league.id}
      events={events.filter(({ league_id }) => league_id === league.id)}
      searchString={searchString}
      {...league}
    />
  )) : []

  const hasContent = content.some(Boolean)

  return (
    hasContent ? content : (
      <div className={styles.placeholder}>
        <Placeholder name={searchString ? 'resultLineSportFilter' : 'highlights'} />
      </div>
    )
  )
}