import { Api } from 'api/Api'
import mtsLogo from 'assets/images/mts.svg'
import { ExternalLink } from 'components/ExternalLink'
import Footer from 'components/Footer'
import { Page } from 'components/Page'
import { Contact } from 'pages/Contacts/Contact'
import SendMessage from 'pages/Contacts/SendMail'
import {
  buildMessengerLinks,
  socialLinks,
  supportLinks,
} from 'pages/Contacts/utils'
import React from 'react'
import TEXT from 'store/constants/text'
import styles from './styles.module.scss'

export const ContactsPage = () => (
  <Page
    title={TEXT.contacts.title}
    bg="black"
  >
    <div className={styles.contactsPage}>
      <Contact.divider title="Служба поддержки 24/7" />
      <div>
        {supportLinks.map(link => <Contact.item {...link} />)}
        <SendMessage
          mailTo="support@maxline.by"
          action={Api.sendMailToSupport}
        />
      </div>
      <Contact.divider title="Отдел развития и рекламы с 09:00 до 17:00" />
      <div>
        <Contact.item
          title={<a
            href="tel:+375295715555"
            className="text"
          >+375 (29) 571-55-55</a>}
          icon={mtsLogo}
          postfix={buildMessengerLinks({
            telegram: 'maxline_marketing',
            viber:    '375295715555',
            watsApp:  '375295715555',
          })}
        />
        <SendMessage
          mailTo="marketing@maxline.by"
          action={Api.sendMailToMarketing}
        />
      </div>
      <Contact.divider title="Бухгалтерия с 09:00 до 16:00" />
      <div>
        <Contact.item
          title={<a
            href="tel:+375297525555"
            className="text"
          >
            +375 (29) 752-55-55
          </a>}
          icon={mtsLogo}
        />
      </div>
      <Contact.divider title="Руководство компании" />
      <div>
        <SendMessage mailTo="head@maxline.by" />
      </div>
      <Contact.divider title="Социальные сети" />
      <div className={styles.socialNetworks}>
        {socialLinks.map(({ link, icon }) => (
          <ExternalLink
            className={styles.socialLink}
            key={link}
            to={link}
          >
            <img
              src={icon}
              alt={link}
            />
          </ExternalLink>
        ))}
      </div>
    </div>
    <Footer />
  </Page>
)
