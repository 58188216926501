const requestFullScreenKeys = [
  'webkitRequestFullScreen', 'requestFullscreen', 'webkitEnterFullScreen',
  'mozRequestFullScreen', 'msRequestFullscreen', 'requestFullScreen',
]

export const requestFullscreen = elem => {
  for (const key of requestFullScreenKeys) {
    if (elem[key]) {
      return elem[key]()
    }
  }
}
