import styles from 'components/PageHeader/styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils'

export const PageHeader = ({ title, children, onGoBack, className }) => {
  const navigate = useNavigate()

  return (
    (title || children) ? (
      <div className={cn(styles.pageHeader, className)}>
        <button
          onClick={onGoBack || (() => navigate(-1))}
          className={styles.backBtn}
          aria-label="back"
        />
        {title && <h1 className={styles.title}>{title}</h1>}
        {children}
      </div>
    ) : null
  )
}
