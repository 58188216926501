import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

export const useRequest = (fetcher, options = {}) => {
  const [data, setData]           = useState()
  const [meta, setMeta]           = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError]         = useState(null)
  const [isLoaded, setIsLoaded]   = useState(false)

  const {
          interval,
          initialData,
          condition            = true,
          layoutEffect         = false,
          clearIntervalOnError = true,
          deps                 = [],
        } = options

  const effect = useMemo(() => layoutEffect ? useLayoutEffect : useEffect, [layoutEffect])

  effect(() => {
    const controller = new AbortController()

    let intervalId
    let canceled = false

    if (condition) {
      const fetchData = async () => {
        setIsLoading(true)

        try {
          const { data, ...meta } = await fetcher(controller.signal)

          if (!canceled) {
            setError(null)
            setData(data)
            setMeta(meta)
          }
        } catch (e) {
          if (interval && clearIntervalOnError) {
            clearInterval(intervalId)
          }

          setError(new Error(e?.message ?? 'Unknown Api Error'))
        } finally {
          if (!canceled) {
            setIsLoaded(true)
            setIsLoading(false)
          }
        }
      }

      fetchData()

      if (interval) {
        intervalId = setInterval(fetchData, interval)
      }
    }

    return () => {
      if (condition) {
        canceled = true
        controller.abort()
        setIsLoaded(false)
        clearInterval(intervalId)
        setData(initialData)
        setIsLoading(false)
        setError(null)
      }
    }
  }, [...deps, condition])

  return {
    data:     data || initialData,
    meta,
    isLoading,
    isLoaded,
    error,
    canceled: (!!error && error.canceled),
  }
}
