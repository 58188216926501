import moment from 'moment'

const STORAGE_KEY = 'championship'

export class Championship {
  static get() {
    const current = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
    const isOver  = moment().isAfter(current.finished)

    if (isOver) {
      localStorage.removeItem(STORAGE_KEY)

      return {}
    }

    return current
  }

  static handle({ id, active, date_finished }) {
    const { id: currentId, finished } = Championship.get()
    const isOver                      = moment().isAfter(date_finished)

    if (active && !isOver && (id !== currentId || date_finished !== finished)) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ id, finished: date_finished }))
    }
  }
}
