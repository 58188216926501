import HeaderBalance from 'components/Header/Balance'
import { Navigation } from 'components/Header/Navigation'
import { HeaderPay } from 'components/Header/Pay'
import styles from 'components/Header/styles.module.scss'
import { onSmartBannerResize } from 'components/Header/utils'
import { Logo } from 'components/Logo'
import { ModalType } from 'components/SignInModal/utils'
import { paths } from 'constants/paths'
import { useAuthModal } from 'hooks/useAuthModal'
import { useResize } from 'hooks/useResize'
import { useToSignUp } from 'hooks/useToSignUp'
import {
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setModalKey } from 'store/new-sign-up/actions'
import { setUserId } from 'utils/google-analytics/EcommerceGTM'

const Header = ({ background, auth: { isAuth, id: userId } }) => {
  const [isNavOpened, setIsNavOpened] = useState(false)

  const openAuthModal = useAuthModal()
  const toSignUp      = useToSignUp()
  const { pathname }  = useLocation()

  const { ref: smartBannerRef } = useResize({ onResize: onSmartBannerResize })

  useEffect(() => {
    if (+userId > 0) {
      setUserId(userId)
    }
  }, [userId])

  const redirectURL = (pathname === paths.SIGN_UP || pathname === paths.FORGOT_PASSWORD) ? paths.HOME : ''

  return (
    <>
      <div
        id="smart-banner"
        ref={smartBannerRef}
        className={styles.smartBanner}
      />
      <header
        className={styles.header}
        data-auth={isAuth}
        {...background && { style: { background: `#111917 no-repeat center / cover url(${background})` } }}
      >
        <div className={styles.left}>
          <button
            className={styles.leftNavBtn}
            onClick={() => setIsNavOpened(!isNavOpened)}
            aria-label="Toggle navigation"
          />
          <Logo />
        </div>
        <div className={styles.right}>
          {isAuth ?
            <div className="header-account-userInfo">
              <HeaderBalance />
              <HeaderPay />
            </div> : (
              <div className={styles.authPanel}>
                <button
                  className={styles.loginBtn}
                  onClick={() => openAuthModal(ModalType.DEFAULT, { redirectURL })}
                >
                  Вход
                </button>
                <button
                  className={styles.signUpBtn}
                  onClick={toSignUp}
                >
                  Регистрация
                </button>
              </div>
            )}
        </div>
      </header>
      <Navigation
        opened={isNavOpened}
        onClose={() => setIsNavOpened(false)}
      />
    </>
  )
}

const mapStateToProps = state => ({
  auth:       state.auth,
  logo:       state.config.identity['1']?.url,
  background: state.config.identity['2']?.url,
})

const mapDispatchToProps = {
  setModalKey,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
