import { Api } from 'api/Api'
import ACTIONS from 'store/constants/actions'
import { openBetsHandler } from 'store/openedbets/utils'

export const getLiveOpenedBets = () => async dispatch => {
  try {
    const { data: { bets } } = await Api.getOpenedBets()

    dispatch({
      type: ACTIONS.COUPON.GET_OPENED_BETS_LIVE,
      data: openBetsHandler(bets),
    })
  } catch (e) {
    console.error(e)
  }
}
