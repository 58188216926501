export const ExternalLink = ({ to, title, className, children, ...props }) => (
  <a
    className={className}
    target="_blank"
    rel="noreferrer noopener"
    href={to}
    {...props}
  >
    {children}
    {title}
  </a>
)
