import { platform } from 'constants/platform'
import { STATUSES } from 'constants/statuses'
import { parseJSON } from 'utils/parse-json'
import emitter from 'utils/socket/emitter'

export const ClosingCode = {
  CLEAR: 4000,
  ERROR: 4004,
}

const CASINO_API_URL = process.env.REACT_APP_CASINO_API_URL || 'wss://maxline.by/socket/site'

export const buildCasinoURL = () => {
  const url   = new URL(`${CASINO_API_URL}?pl=${platform}`)
  const token = localStorage.getItem('token')

  if (token) {
    url.searchParams.append('sid', token)
  }

  return url.toString()
}

export const createCasinoSocket = () => {
  const ws = new WebSocket(buildCasinoURL())

  ws.onmessage = event => {
    const { type, status, data } = parseJSON(event.data)

    emitter.emit(type, data, status !== STATUSES.SUCCESS)
  }

  ws.onerror = error => {
    console.error(`WebSocket encountered an error: ${error.message}. Closing...`)
    ws.close(ClosingCode.ERROR)
  }

  ws.onclose = ({ code, reason }) => {
    if (code !== ClosingCode.CLEAR) {
      createCasinoSocket()
    }
  }

  ws.onopen = () => {
    emitter.setEntity(ws)
  }

  return ws
}
