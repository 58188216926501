import { Api } from 'api/Api'
import { format } from 'date-fns'
import { sortLeagues } from 'store/line/utils'

export const allSports = { id: 'all', name: 'Все' }
export const topSports = { id: 'top', name: 'Топ' }

export const SwipeModalFilter = {
  NONE:    'none',
  DATE:    'date',
  LEAGUES: 'league',
}

export const initialHighlights = {
  sports:  [],
  leagues: [],
  events:  [],
}

export const initialCounter = { top: 0, all: 0 }

export const getCounters = ({ events }) => ({
  top: events.reduce((acc, cv) => acc + +cv.is_top, 0),
  all: events.length,
})

export const filterEvents = (events, searchString) => searchString
  ? events.filter(({ team1, team2 }) => `${team1}${team2}`.toLowerCase().includes(searchString.trim().toLowerCase()))
  : events

export const getSportFilters = (sports, { top, all }) => [
  { ...topSports, counter: top }, { ...allSports, counter: all }, ...sports,
]

export const filterLeagues = (leagues, league) => league ? leagues.filter(({ id }) => id === league.id) : leagues

export const getHighlights = async (date, sportId, setLoading) => {
  setLoading(true)

  const searchParams = new URLSearchParams({
    ...(sportId === topSports.id && { is_top: 1 }),
    ...(+sportId > 0 && { sport_id: sportId }),
    ...date && { date: format(date, 'yyyy-MM-dd') },
  }).toString()

  try {
    const { data: { events = [], sports = [], leagues = [] } } = await Api.getHighlights(searchParams)
    return {
      events,
      sports,
      leagues: sortLeagues(leagues),
    }
  } catch {
    return initialHighlights
  } finally {
    setLoading(false)
  }
}