import { MatchItem } from 'pages/MaxinePayReturn/MatchList/MatchItem'

export const MatchResult = ({ league_name, sport_id, amount, time, ...restProps }) => (
  <MatchItem
    sportId={sport_id}
    leagueName={league_name}
    date={time}
    outcome={<div className="gray">Сумма: <span className="green bold">{`${amount} BYN`}</span></div>}
    {...restProps}
  />
)
