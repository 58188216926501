import { usePaymentsState } from 'hooks/usePaymentsState'
import { useResize } from 'hooks/useResize'
import {
  useEffect,
  useRef,
} from 'react'
import styles from './styles.module.scss'
import { tabs } from './utils'

export const PaymentTabs = () => {
  const { state: { method }, setState } = usePaymentsState()

  const indicatorRef = useRef()
  const activeTabRef = useRef()

  const updatePosition = () => {
    if (activeTabRef.current) {
      indicatorRef.current.style.left  = `${activeTabRef.current.offsetLeft}px`
      indicatorRef.current.style.width = `${activeTabRef.current.clientWidth}px`
    }
  }

  const { ref: containerRef } = useResize({ onResize: updatePosition })

  useEffect(() => {
    updatePosition()
  }, [method])

  return (
    <div
      className={styles.tabs}
      ref={containerRef}
    >
      <div
        className={styles.indicator}
        ref={indicatorRef}
      />
      {tabs.map(({ id, icon: Icon }) => (
        <button
          key={id}
          onClick={() => setState({ method: id })}
          className={styles.tab}
          {...(+id === +method && { ref: activeTabRef })}
        >
          <Icon />
        </button>
      ))}
    </div>
  )
}
