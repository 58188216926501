import { Pay } from 'components/OplatiTerms/Pay'
import { Payout } from 'components/OplatiTerms/Payout'

export const instructionTypes = {
  payout: {
    title:   'Как вывести с помощью «Оплати»?',
    content: <Payout />,
  },
  pay:    {
    title:   'Как пополнить счёт с помощью «Оплати»?',
    content: <Pay />,
  },
}
