import { PaymentItem } from 'pages/Payments/components/PaymentItem'
import { getPayInWaysData } from 'pages/Payments/components/PayOutWays/data'
import React from 'react'

export const PayOutWays = ({ theme }) => getPayInWaysData(theme).map(({ iconSrc, title, content }) => (
  <PaymentItem
    key={iconSrc}
    title={title}
    icon={iconSrc}
  >
    {content}
  </PaymentItem>
))
