import { PhoneInput as PhoneInputComponent } from 'components/PhoneInput'
import { useState } from 'react'

export const usePhoneInput = () => {
  const [phone, setPhone]               = useState('')
  const [isPhoneValid, setIsPhoneValid] = useState(false)

  return {
    PhoneInput: <PhoneInputComponent
                  onChange={setPhone}
                  value={phone}
                  onValidChange={setIsPhoneValid}
                />,
    phone,
    isPhoneValid,
    setPhone,
  }
}
