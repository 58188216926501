import { Page } from 'components/Page'
import { Redirect } from 'components/Redirect'
import { paths } from 'constants/paths'
import { tabs } from 'pages/UserHistoryPage/utils'
import { useSelector } from 'react-redux'
import {
  NavLink,
  useOutlet,
} from 'react-router-dom'
import { selectIsCasinoDefault } from 'store/auth/selectors'
import { cn } from 'utils'
import styles from './styles.module.scss'

export const UserHistoryPage = () => {
  const outlet = useOutlet()

  const isCasinoDefault = useSelector(selectIsCasinoDefault)

  return outlet ? (
    <Page bg="gray">
      <div className={styles.tabs}>
        {tabs.map(({ title, link }) => (
          <NavLink
            key={title}
            to={link}
            className={({ isActive }) => cn(styles.tab, isActive && styles.selected)}
            replace
          >
            {title}
          </NavLink>
        ))}
      </div>
      <section className={styles.history}>
        {outlet}
      </section>
    </Page>
  ) : <Redirect to={isCasinoDefault ? paths.CASINO_HISTORY : paths.BETS_HISTORY} />
}
