import { Placeholder } from 'components/Placeholder'
import SportIcon from 'components/SportIcon'
import SwipeModal from 'components/SwipeModal'
import React, { useState } from 'react'
import { KeyListener } from 'utils'
import styles from './styles.module.scss'

export const SearchSports = ({ open, sports, activeSportId, onCancel, onSelect }) => {
  const [query, setQuery] = useState('')

  const onClose = () => {
    setQuery('')
    onCancel?.()
  }

  const onSelected = sportId => {
    setQuery('')
    onSelect?.(sportId)
  }

  const filteredSports = sports.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))

  return (
    <SwipeModal
      title={<>
        <div>Вид спорта</div>
        <div className="search-container__inputWrapper">
          <input
            className="search-container__input"
            value={query}
            onKeyDown={KeyListener.Enter(event => event.target.blur())}
            onChange={e => setQuery(e.target.value)}
            type="text"
            placeholder="Поиск..."
          />
        </div>
      </>}
      isOpen={open}
      onClose={onClose}
      className="search-container"
    >
      <div className={styles.sports}>
        {query.length && !filteredSports.length ?
          <Placeholder name="resultLineSportFilter" /> : filteredSports.map(({ id, name }) => (
            <button
              key={id}
              data-active={id === activeSportId}
              className={styles.sport}
              onClick={() => onSelected(id)}
            >
              <SportIcon
                id={id}
                size={18}
              />
              <div className={styles.sportName}>{name}</div>
            </button>
          ))}
      </div>
    </SwipeModal>
  )
}
