import styles from 'components/Field/Password/styles.module.scss'
import TextInput from 'components/Field/TextInput'
import { useState } from 'react'

export const PasswordInput = ({ value, label, onChange, validateFn, defaultVisible = false, ...restProps }) => {
  const [isVisible, setIsVisible] = useState(defaultVisible)

  return (
    <TextInput
      value={value}
      onChange={onChange}
      className={styles.passwordInput}
      label={label}
      type={isVisible ? 'text' : 'password'}
      validateFn={validateFn}
      {...restProps}
    >
      <button
        onClick={() => setIsVisible(!isVisible)}
        className={styles.visibilityBtn}
      />
    </TextInput>
  )
}
