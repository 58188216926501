import {
  format,
  isAfter,
  isBefore,
} from 'date-fns'
import moment from 'moment/moment'

export const getFullDate = ts => {
  try {
    return format(new Date(ts), 'dd MMM HH:mm').replace('.', '')
  } catch (error) {
    return console.error(error)
  }
}

export const formatTime = time => moment(time).format('DD.MM.YYYY HH:mm')

export const getUTCDate = (value = '') => new Date(value.replace(' ', 'T'))

export const isNowBetween = (startDate, endDate) => {
  const now = new Date()
  return isAfter(now, startDate) && isBefore(now, endDate)
}

export const sleep = ms => new Promise(res => setTimeout(res, ms))
