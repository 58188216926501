import {
  isMobile,
  isSafari,
} from 'constants/platform'
import {
  add,
  isAfter,
} from 'date-fns'

const MAX_SHOW_IN_PERIOD = 5
const BANNER_KEY         = 'mobile-app-banner'
const BANNER_COUNTER_KEY = 'mobile-app-banner-counter'

export const BANNER_DELAY = 5000

const getPeriodValue = () => {
  const expiredTime = localStorage.getItem(BANNER_KEY)
  const isExpired   = isAfter(new Date(), new Date(+expiredTime))

  return isExpired ? localStorage.removeItem(BANNER_KEY) : expiredTime
}

export const shouldShowBanner = () => {
  if (!isMobile || isSafari) {
    return false
  }

  const sessionValue = sessionStorage.getItem(BANNER_KEY)
  const periodValue  = getPeriodValue()

  return !sessionValue && !periodValue
}

export const updateStorageValues = () => {
  const nextCloseCount = (+localStorage.getItem(BANNER_COUNTER_KEY) ?? 0) + 1

  if (nextCloseCount >= MAX_SHOW_IN_PERIOD) {
    const maxAge = String(add(new Date(), { months: 1 }).getTime())
    localStorage.setItem(BANNER_KEY, maxAge)
    localStorage.removeItem(BANNER_COUNTER_KEY)
  } else {
    localStorage.setItem(BANNER_COUNTER_KEY, nextCloseCount)
    sessionStorage.setItem(BANNER_KEY, '1')
  }
}

export const AppBannerText = {
  TITLE:       'Скачай приложение',
  DESCRIPTION: 'Maxline всегда под рукой',
}
