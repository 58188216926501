import { getCardIcon } from 'components/CardItem/utils'
import styles from 'components/PaymentModal/CardController/CardsSelect/Card/styles.module.scss'
import { cn } from 'utils'

export const Card = ({ icon, number, onClick, className }) => (
  <button
    onClick={onClick}
    className={cn(styles.card, className)}
  >
    <img
      className={styles.icon}
      src={getCardIcon(icon)}
      alt={number}
    />
    <div className={styles.number}>{number}</div>
  </button>
)
