import a1Logo from 'assets/images/a1.svg'
import chatIcon from 'assets/images/chat.svg'
import instaLogo from 'assets/images/contacts/instagram.svg'
import vkLogo from 'assets/images/contacts/vk.svg'
import youtubeLogo from 'assets/images/contacts/youtube.svg'
import telegramMinLogo from 'assets/images/mobile/telegram.svg'
import viberLogo from 'assets/images/mobile/viber.svg'
import whatsappLogo from 'assets/images/mobile/whatsapp.svg'
import mtsLogo from 'assets/images/mts.svg'
import { openChat } from 'components/Header/Navigation/utils'
import styles from './styles.module.scss'

const redirectUrls = {
  whatsApp: 'https://wa.me/',
  viber:    'viber://chat?number=%2B$',
  telegram: ' https://t.me/',
}

const onLocationChange = (path, value) => window.location = `${path}${value}`

export const buildMessengerLinks = ({ telegram, viber, watsApp }) => (
  <div className="messengers-icons">
    <img
      onClick={() => onLocationChange(redirectUrls.telegram, telegram)}
      src={telegramMinLogo}
      alt="telegram"
    />
    <img
      onClick={() => onLocationChange(redirectUrls.viber, viber)}
      src={viberLogo}
      alt="viber"
    />
    <img
      onClick={() => onLocationChange(redirectUrls.whatsApp, watsApp)}
      src={whatsappLogo}
      alt="whatsapp"
    />
  </div>
)

export const socialLinks = [
  { link: 'https://www.youtube.com/channel/UC9PLwT3BtDbbZAuExTA5RFQ', icon: youtubeLogo },
  { link: 'https://vk.com/maxlineby', icon: vkLogo },
  { link: 'https://www.instagram.com/maxlineofficial', icon: instaLogo },
  { link: 'https://t.me/maxlineofficial', icon: telegramMinLogo },
]

export const supportLinks = [
  {
    key:   '1',
    title: <button
             onClick={openChat}
             className={styles.chatBtn}
           >
             Онлайн чат
           </button>,
    icon:  chatIcon,
  },
  {
    key:     '2',
    title:   <a
               href="tel:+375295207777"
               className="text"
             >
               +375 (29) 520-77-77
             </a>,
    icon:    mtsLogo,
    postfix: buildMessengerLinks({ telegram: 'maxlinesupport', viber: '375295207777', watsApp: '375295207777' }),
  },
  {
    key:     '3',
    title:   <a
               href="tel:+375445207777"
               className="text"
             >
               +375 (44) 520-77-77
             </a>,
    icon:    a1Logo,
    postfix: buildMessengerLinks({ telegram: 'maxlinesupport', viber: '375445207777', watsApp: '375445207777' }),
  },
]
