import { Api } from 'api/Api'
import { CasinoSpinner } from 'components/Casino/Spinner'
import { Page } from 'components/Page'
import { paths } from 'constants/paths'
import { useAuth } from 'hooks/useAuth'
import styles from 'pages/Casino/TVGame/TvBet/styles.module.scss'
import { buildTvBetSrc } from 'pages/Casino/TVGame/TvBet/utils'
import React, {
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils'

export const TVBetPage = ({ id }) => {
  const [src, setSrc]           = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  const isAuth   = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    setSrc('')

    Api.getTVBetData()
       .then(({ data: { token } }) => setSrc(buildTvBetSrc(token, id)))
       .catch(console.error)
  }, [isAuth])

  const isIframeReady = src && isLoaded

  return (
    <Page
      title="TVBet"
      onGoBack={() => navigate(paths.TV_GAMES)}
      bg="black"
    >
      <iframe
        src={src}
        className={cn(styles.tvBetIframe, isIframeReady && styles.ready)}
        onLoad={() => setIsLoaded(true)}
        allowFullScreen
        allow="autoplay"
      />
      {!isIframeReady && <CasinoSpinner />}
    </Page>
  )
}
