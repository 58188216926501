import { ReactComponent as ChromeIcon } from 'assets/images/cookie/chrome.svg'
import { ReactComponent as ExplorerIcon } from 'assets/images/cookie/explorer.svg'
import { ReactComponent as FirefoxIcon } from 'assets/images/cookie/firefox.svg'
import { ReactComponent as MicrosoftIcon } from 'assets/images/cookie/microsoft.svg'
import { ReactComponent as OperaIcon } from 'assets/images/cookie/opera.svg'
import { ReactComponent as SafariIcon } from 'assets/images/cookie/safari.svg'

export const links = [
  {
    id:   0,
    icon: <ChromeIcon />,
    link: 'https://support.google.com/chrome/answer/95647?hl=ru',
  },
  {
    id:   1,
    icon: <SafariIcon />,
    link: 'https://support.apple.com/ru-ru/guide/safari/sfri11471/mac',
  },
  {
    id:   2,
    icon: <OperaIcon />,
    link: 'https://help.opera.com/ru/latest/web-preferences/#%D0%A3%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%B0%D0%BC%D0%B8-cookie',
  },
  {
    id:   3,
    icon: <FirefoxIcon />,
    link: 'https://support.mozilla.org/ru/kb/udalenie-kukov-dlya-udaleniya-informacii-kotoruyu-',
  },
  {
    id:   4,
    icon: <MicrosoftIcon />,
    link: 'https://support.microsoft.com/ru-ru/microsoft-edge/%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2-cookie-%D0%B2-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09',
  },
  {
    id:   5,
    icon: <ExplorerIcon />,
    link: 'https://support.microsoft.com/ru-ru/windows/%D1%83%D0%B4%D0%B0%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D1%84%D0%B0%D0%B9%D0%BB%D0%BE%D0%B2-cookie-%D0%B8-%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B8%D0%BC%D0%B8-168dab11-0753-043d-7c16-ede5947fc64d',
  },
]
