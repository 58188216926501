import { useDispatch } from 'react-redux'
import {
  addModal,
  closeModal,
} from 'store/modal/actions'

export const useModal = modalId => {
  const dispatch = useDispatch()

  return {
    openModal:  content => dispatch(addModal(modalId, content)),
    closeModal: () => dispatch(closeModal(modalId)),
  }
}
