import { SignInModal } from 'components/SignInModal'
import { LOGIN_MODAL_ID } from 'components/SignInModal/utils'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  addModal,
  closeModal,
} from 'store/modal/actions'

export const useAuthModal = () => {
  const dispatch = useDispatch()

  return useCallback((type = 'default', { onSuccess, redirectURL } = {}) => {
    dispatch(addModal(LOGIN_MODAL_ID, <SignInModal
      type={type}
      onSuccess={onSuccess}
      redirectURL={redirectURL}
      onClose={() => dispatch(closeModal(LOGIN_MODAL_ID))}
    />))
  }, [])
}
