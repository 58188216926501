import { formatTime } from 'utils/time'

export const openBetsHandler = (response = []) => {
  const bets = {}

  response.forEach(({ id, number, amount, team1, team2, bet_name: name, v, time, info, status, event_id, raw }) => {
    const timer = +raw?.timer

    const detail = {
      event:   `${team1} ${team2 ? `- ${team2}` : ''}`,
      name,
      v:       +v,
      status:  +status,
      eventId: event_id,
      ...(timer && { timer: Math.floor(timer / 60) }),
    }

    if (!bets[number]) {
      bets[number] = {
        amount:  (+amount).toFixed(2),
        number:  number,
        v:       1,
        details: [],
      }
    }

    bets[number].id   = id
    bets[number].v *= +v
    bets[number].time = formatTime(time)
    bets[number].details.push(detail)
    bets[number].info = JSON.parse(info || '{}')
  })

  return Object.values(bets).map(({ details, v, ...other }) => ({
    typeStr: details.length === 1 ? details[0].name : 'Экспресс',
    v:       v.toFixed(2),
    details,
    ...other,
  })).sort((a, b) => b.number - a.number)
}
