import { openChat } from 'components/Header/Navigation/utils'
import { HideBtn } from 'components/SignUpModals/HideBtn'
import { ModalKeys } from 'components/SignUpModals/utils'
import { SignUpModalWrapper } from 'components/SignUpModals/Wrapper'
import Upload from 'components/Upload'
import { paths } from 'constants/paths'
import { useLocation } from 'react-router-dom'
import styles from './styles.module.scss'

export const SendDocs = ({ failed, onClose, onDoNotShow, onChange }) => {
  const { pathname } = useLocation()

  return pathname !== paths.VERIFICATION && (
    <SignUpModalWrapper
      onClose={onClose}
      className={styles.uploadDocsModal}
    >
      <div className={styles.header}>
        {failed ? (
          <>
            <h2
              className={styles.title}
              data-failed="true"
            >
              Верификация не пройдена
            </h2>
            <div>Загрузите документы повторно</div>
          </>
        ) : <h2 className={styles.title}>Пройдите верификацию</h2>}
      </div>
      <div>
        Для получения возможности совершать игровые и денежные операции необходимо загрузить фото
        <b> 31-й, 33-й и 25-й страниц паспорта</b> (для иностранных граждан - страница с серией и номером паспорта и
        страница с регистрацией), а также <b>Ваше персональное фото с паспортом (селфи)</b>.
      </div>
      <div className={styles.info}>Общий размер фотографий не должен превышать 30mb.</div>
      <Upload
        buttonTitle="Продолжить"
        onSuccess={() => {
          onDoNotShow(failed ? 60 : 6000)
          onChange(ModalKeys.DOCS_UPLOADED)
        }}
      />
      {failed && (
        <button
          onClick={openChat}
          className={styles.chatBtn}
        >
          Почему мне не одобрили верификацию?
        </button>
      )}
      <HideBtn onClick={onDoNotShow} />
    </SignUpModalWrapper>
  )
}
