import {
  addonTypes,
  getAddonTitle,
} from 'components/Addon/utils'
import React from 'react'
import { cn } from 'utils'

export class Addon {
  bind     = {}
  name     = ''
  type     = ''
  value    = null
  required = false

  constructor({ data, onChange, value, nextValue, className }) {
    const { bind, key, name, required, type } = data

    this.bind      = bind
    this.key       = key
    this.name      = name
    this.type      = type
    this.value     = value
    this.className = className
    this.onChange  = () => onChange({ [key]: { value: nextValue, required } })
  }

  static configureValues = (addons = []) => {
    const values = {}

    addons.forEach(({ key, required = false, default_checked }) => values[key] = {
      required,
      value: default_checked,
    })

    return values
  }

  static configureParams = (data = {}, addonsValues = {}) => {
    const params = { ...data }

    Object.entries(addonsValues).forEach(([key, { value }]) => params[`addons[${key}]`] = value)

    return params
  }

  buildComponent = () => {
    const { Tag, props: { className, ...props } } = addonTypes.get(this)

    return (
      <Tag
        key={this.key}
        title={getAddonTitle(this.name, this.bind)}
        onChange={this.onChange}
        className={cn(className, this.className)}
        {...props}
      />
    )
  }
}

