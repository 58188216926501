import { ReactComponent as FavoriteIcon } from 'assets/images/event/event-preview-favorite.svg'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  addToFavorites,
  removeFromFavorites,
} from 'store/favorites/actions'
import styles from './styles.module.scss'

export const EventHeader = ({ id, hasTvLink, leagueName }) => {
  const favorites = useSelector(state => state.favorites.ids)
  const dispatch  = useDispatch()

  const isFavorite = favorites.includes(id)

  const onChange = event => {
    event.preventDefault()
    dispatch((isFavorite ? removeFromFavorites : addToFavorites)(id))
  }

  return (
    <div className={styles.header}>
      <button
        className={styles.favoriteBtn}
        onClick={onChange}
        data-favorite={isFavorite}
        aria-label="Toggle favorite"
      >
        <FavoriteIcon className={styles.favoriteIcon} />
      </button>
      {hasTvLink && <div className={styles.streamIcon} />}
      <div className={styles.leagueName}>
        {leagueName}
      </div>
    </div>
  )
}
