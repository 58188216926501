import notFoundImg from 'assets/images/svg/not-found.svg'
import { Option } from 'components/Option'
import { DocumentReadyState } from 'constants/event'
import { postMessageToRN } from 'utils/react-native'

export const renderOptions = options => options.map(option => <Option key={option.title} {...option} />)

export const cardNumberHandler = number => number
  ? [number.slice(0, 4), '****', '****', number.slice(-4)].join(' ')
  : ''

export const getCardsInfo = cards => cards.map(({ id, icon, cardNumber, pan, expiration }) => {
  const number = pan || cardNumber.match(/\d+\*+\d+/)[0]
  const exp    = expiration || cardNumber.match(/\d{2}\/\d{2}/)[0]

  return {
    id,
    exp,
    icon,
    number:      cardNumberHandler(number),
    description: cardNumber,
  }
})

export const importImage = path => {
  try {
    return require(`../assets/images/${path}`)
  } catch {
    return notFoundImg
  }
}

const ru = {
  'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i', 'к': 'k',
  'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'h',
  'ц': 'c', 'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya', 'ъ': '', 'ь': '', 'й': 'j',
}

export const transliterate = (str = '') => str
  .toLowerCase()
  .split('')
  .map(char => ru[char])
  .join('')

export const cn = (...args) => {
  const nextClasses = []

  args.forEach(arg => {
    if (!arg) {
      return
    }

    if (typeof arg === 'object') {
      Object.entries(arg || {}).forEach(([key, value]) => !!value && nextClasses.push(key))
    }

    if (typeof arg === 'string') {
      nextClasses.push(arg)
    }
  })

  return nextClasses.join(' ')
}

export class KeyListener {
  static Enter = callback => event => {
    if (event.key === 'Enter') {
      callback(event)
    }
  }
}

export const DEFAULT_SCROLL_OPTIONS = { block: 'end', inline: 'center', behavior: 'smooth' }

export const scrollToCenter = (selector, options = DEFAULT_SCROLL_OPTIONS) => document
  .querySelector(selector)
  ?.scrollIntoView(options)

export const getCookie = (name = '') => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`))

  return match ? match[2] : ''
}

export const round = (value = 0) => Math.round(+value * 100) / 100

export const generateId = () => Date.now().toString(16)

export const scrollTop = () => {
  setTimeout(() => document.documentElement.scrollTo({ top: 0, behavior: 'smooth' }), 200)
}

export const postInitMessage = () => {
  const event = { mlReadyState: DocumentReadyState.LOADING }

  if (window.self !== window.top) {
    window.parent.postMessage(event, '*')
  }

  postMessageToRN(event)
}
