import { ExternalLink } from 'components/ExternalLink'
import { OplatiTitle } from 'pages/Payments/components/Oplati'
import { OplatiAppLinks } from 'pages/Payments/components/Oplati/AppLinks'
import styles from 'pages/Payments/components/styles.module.scss'
import React from 'react'
import { importImage } from 'utils'

const getPayInWaysData = (theme = 'light', full = true) => [
  full && {
    iconSrc: importImage(`banks/${theme}/card.svg`),
    title:   'VISA / MasterCard / Белкарт',
    content:
             <section>
               <p>Для пополнения счета на нашем сайте:</p>
               <ol className="ml-15">
                 <li>Зайдите в раздел "Пополнить счет".</li>
                 <li>Укажите сумму платежа и выберите карту.</li>
                 <li>Будете перенаправлены на защищенную страницу оплаты.</li>
                 <li>Заполните данные Вашей банковской карты.</li>
               </ol>
               <p>
                 При пополнении с новой карты необходимо ввести пароль "3-D Secure", который можно получить через
                 интернет-банкинг или по SMS на Ваш номер мобильного телефона, привязанный к карте.
               </p>
               <div className={styles.attention}>
                 <div className="font-14 bold">Внимание!</div>
                 <div className="mt-10">
                   Карта, при помощи которой Вы желаете пополнить игровой счет, должна быть изготовлена банком
                   Республики Беларусь. Кроме того, карта должна поддерживать технологию «3-D Secure».
                 </div>
               </div>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Мин. сумма:"
                   data-value="2 BYN"
                 />
                 <div
                   data-title="Зачисление:"
                   data-value="5 мин"
                 />
               </div>
             </section>,
  },
  full && {
    iconSrc: importImage(`banks/${theme}/oplati.svg`),
    title:   <OplatiTitle />,
    content: <>
               <p>
                 Вам необходимо нажать на кнопку «Пополнить счёт» и подтвердить платеж в приложении «Оплати».
               </p>
               <OplatiAppLinks />
               <p>
                 Также для пополнения игрового счета можно воспользоваться следующими этапами:
                 В приложении «Оплати» в разделе "Платежи" выберите пункты: Система "Расчет" (ЕРИП), затем
                 "Интернет-магазины / сервисы - Букмекерские конторы - Пополнение баланса Maxline.by”.
                 Введите номер Вашего игрового счета и сумму.
               </p>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Зачисление:"
                   data-value="5 мин"
                 />
               </div>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/qiwi.svg`),
    title:   'Qiwi',
    content:
             <>
               <p>
                 Вы можете пополнить свой игровой счет при помощи Qiwi-терминала.
                 <br />
                 Вам следует войти в раздел "Оплата услуг", затем перейти в раздел "Букмекеры, игры и лотереи", после
                 чего выбрать "Maxline".
                 <br />
                 В открывшемся окне Вам следует ввести номер вашего игрового счета и нажать клавишу "Вперед".
                 <br />
                 Вам осталось лишь вставить купюры в купюроприемник и подтвердить указанную сумму.
               </p>
               <a
                 className={styles.videoGuide}
                 target="_blank"
                 href={'https://www.youtube.com/watch?v=71wlaZSGayg'}
               >
                 Видеоинструкция
               </a>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/erip.svg`),
    title:   'ЕРИП (Система «Расчёт»)',
    content:
             <>
               <p>
                 Вы можете оплачивать услуги в 27 банках Республики Беларусь, воспользоваться свыше 15 500 пунктов
                 банковского обслуживания: банкоматы, инфокиоски, устройства приема наличных денег (cash-in).
                 Наибольшие удобства система «Расчет» создает для пользователей Интернет-банка и для систем электронных
                 денег.
               </p>
               <p className="bold">Игровой счёт можно пополнить в пунктах банковского обслуживания:</p>
               <ul>
                 <li>ОАО «Белагропромбанк»</li>
                 <li>ОАО «Приорбанк»</li>
                 <li>ОАО «БПС-Сбербанк»</li>
                 <li>ОАО «Белинвестбанк»</li>
                 <li>ОАО «Технобанк»</li>
                 <li>ОАО «Паритетбанк»</li>
                 <li>ОАО «Белгазпромбанк»</li>
                 <li>ЗАО «Идея Банк»</li>
                 <li>ЗАО «РРБ-Банк»</li>
                 <li>ЗАО «МТБанк»</li>
                 <li>ЗАО «Трастбанк»</li>
                 <li>ЗАО «Дельта Банк»</li>
                 <li>ОАО «Банк БелВЭБ»</li>
                 <li>ЗАО «ИнтерПэйБанк»</li>
                 <li>ЗАО «БТА Банк»</li>
                 <li>ОАО «АСБ Беларусбанк»</li>
                 <li>ОАО «Банк Дабрабыт»</li>
                 <li>ОАО «Хоум Кредит Банк»</li>
                 <li>ЗАО «Банк ВТБ» (Беларусь)</li>
                 <li>ОАО «БНБ-Банк»</li>
                 <li>ОАО «Франсабанк»</li>
                 <li>ЗАО «Евробанк»</li>
                 <li>ЗАО «Цептер Банк»</li>
                 <li>ЗАО «АБСОЛЮТБАНК»</li>
                 <li>ЗАО «БСБ Банк»</li>
                 <li>ЗАО «Альфа-Банк»</li>
                 <li>ОАО «Евроторгинвестбанк»</li>
               </ul>
               <p><b>Инфокиоски, банкоматы, iPay, BelQI</b></p>
               <div className="font-12">
                 <p>
                   В разделе "Платежи" выберите пункты: Система "Расчет" (ЕРИП), затем "Интернет-магазины / сервисы -
                   Виртуальные игорные заведения - Maxline.by".
                 </p>
                 <p>ИЛИ</p>
                 <p>
                   "Интернет-магазины / сервисы - Интернет-казино - Maxline.by".
                   <br />
                   "Интернет-магазины / сервисы - Букмекерские конторы - Maxline.by".
                 </p>
                 <p>Введите номер Вашего игрового счета и сумму.</p>
               </div>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Мин. сумма:"
                   data-value="2 BYN"
                 />
                 <div
                   data-title="Зачисление:"
                   data-value="5 мин"
                 />
               </div>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/mobile.svg`),
    title:   'МТС Деньги',
    content: <>
               <p>
                 Произвести пополнение можете на сайте:
                 {' '}
                 <ExternalLink
                   to="https://dengi.mts.by/"
                   className="black underline"
                 >
                   {'https://dengi.mts.by/'}
                 </ExternalLink>
                 {' '}
                 в разделе "Онлайн-платежи".
               </p>
               <p>Стоимость услуги МТС Деньги составляет 0,14 рублей в сутки.</p>
               <div className={styles.attention}>
                 <span className="font-14 bold">
                   При подключении на номер услуги МТС Деньги впервые предоставляется бесплатный 30-дневный период.
                 </span>
               </div>
               <p className="bold">Отключить услугу МТС Деньги можно:</p>
               <ul>
                 <li>
                   {'при нажатии кнопки <Выйти на всех устройствах> в разделе <Настройки> сайта или в меню <Профиль> мобильного приложения;'}
                 </li>
                 <li>при обращении в контактный центр оператора или Салоны связи МТС;</li>
                 <li>через мобильное приложение Мой МТС;</li>
                 <li>через USSD-запрос *377*0#.</li>
               </ul>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Мин. сумма:"
                   data-value="2 BYN"
                 />
                 <div
                   data-title="Зачисление:"
                   data-value="<1 мин"
                 />
               </div>
             </>,
  },
  {
    iconSrc: importImage(`banks/${theme}/maxline.svg`),
    title:   'Пункты приёма ставок Maxline',
    content: <>
               <p>
                 Игровой счет на сайте будет пополнен моментально.
               </p>
               <p className="bold">Список ППС:</p>
               <ul>
                 <li>Брест, ул. ДЗЕРЖИНСКОГО, д. 50, часть изолированного помещения (комната №15)</li>
                 <li>Витебск, ул. Замковая, 5-11</li>
                 <li>Гомель, ул. Красноармейская 7, помещение 7</li>
                 <li>Гродно, пр. Клецкова, д. 21б-2, часть № 2, помещение № 23</li>
                 <li>Минск, ул. Веры Хоружей, 6А-1Б</li>
                 <li>Могилёв, пер. Пожарный 11, 2-й этаж</li>
                 <li>Орша, ул. Советская, 2В-24, часть изолированного помещения</li>
               </ul>
               <div className="payment-info border-top">
                 <div
                   data-title="Комиссия:"
                   data-value="0%"
                   data-profit={true}
                 />
                 <div
                   data-title="Мин. сумма:"
                   data-value="0.01 BYN"
                 />
                 <div
                   data-title="Зачисление:"
                   data-value="<1 мин"
                 />
               </div>
             </>,
  },
].filter(Boolean)

export default getPayInWaysData
