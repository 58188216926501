import {
  useEffect,
  useRef,
} from 'react'

export const useDidUpdate = (callback, dep) => {
  const isMounted = useRef(false)

  useEffect(() => {
    if (isMounted.current) {
      callback()
    }
  }, dep)

  useEffect(() => {
    isMounted.current = true
  }, [])
}
