import Footer from 'components/Footer'
import { Page } from 'components/Page'
import React from 'react'
import TEXT from 'store/constants/text'

export const SecurityPage = () => (
  <Page
    title={TEXT.security.title}
    bg="black"
    className="security"
  >
    <section className="security__content">
      <div className="security__content__title">
        Правила оплаты и безопасность платежей, конфиденциальность информации
      </div>
      <div className="security__content__text">
        <div>
          Оплата банковскими картами осуществляется через ЗАО «Альфа-Банк». К оплате принимаются карты международных
          платежных систем VISA, MasterCard, платежной системы БЕЛКАРТ.
          Безопасность совершения платежа обеспечивается
          современными методами проверки, шифрования и передачи данных по закрытым каналам связи.
        </div>
        <div>
          Ввод данных карточки осуществляется на защищенной авторизационной странице банка. Для оплаты необходимо ввести
          реквизиты карточки: номер, имя держателя, срок действия и трехзначный код безопасности. Трёхзначный код
          безопасности (CVV2 для VISA, CVC2 для MasterCard) — это три цифры, находящиеся на обратной стороне карточки.
          Если карточка поддерживает технологию 3DSecure или Интернет-пароль для держателей карточек БЕЛКАРТ, Вы будете
          перенаправлены на страницу банка, выпустившего карточку, для ввода кода безопасности.
        </div>
        <div>
          Предоставляемая Вами персональная информация (например: имя, адрес, телефон, e-mail, номер банковской карты и
          прочее) является конфиденциальной и не подлежит разглашению. Данные карточки передаются только в зашифрованном
          виде и не сохраняются на данном интернет-ресурсе.
        </div>
        <p>
          Посещение интернет-ресурса может быть незаконным на территории страны, где находится держатель карточки.
        </p>
        <p>
          Держатель карточки несет ответственность за невыполнение законов своей страны при посещении данного
          интернет-ресурса, если таковые запрещены законодательством страны, где находится держатель карточки.
        </p>
        <p>
          Держатель карточки несет ответственность за знание применимых в его случае законов, регулирующие азартные
          онлайн-игры.
        </p>
      </div>
      <div className="security__content__image" />
    </section>
    <Footer />
  </Page>
)
