import { ReactComponent as WarningIcon } from 'assets/images/popup/warning.svg'
import {
  showErrorModal,
  showSuccessModal,
} from 'components/Popup'
import { paths } from 'constants/paths'

export const showForecastErrorModal = (message = 'Неизвестная ошибка. Попробуйте повторить позже.', callback) => showErrorModal({
  title:     'Внимание!',
  icon:      WarningIcon,
  submitBtn: {
    title: 'Пополнить счёт',
    callback,
  },
  message,
})

export const showForecastSuccessModal = () => showSuccessModal({
  title:   'Ваш прогноз принят!',
  message: 'Информация о Ваших прогнозах отображается в личном кабинете',
})
