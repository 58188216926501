import { Centrifuge } from 'centrifuge'
import { getToken } from 'utils/centrifugo/utils'

export class Centrifugo extends Centrifuge {
  static endpoint = 'wss://s.maxline.by/connection/websocket'

  constructor() {
    super(Centrifugo.endpoint, { getToken })
  }

  hasChannel(channel) {
    return Boolean(this.subscriptions()?.[channel])
  }

  subscribe(channel, onMessage, onError) {
    if (!this.hasChannel(channel)) {
      const sub = super.newSubscription(channel)

      sub.on('publication', onMessage)

      if (onError) {
        sub.on('error', onError)
      }

      sub.subscribe()
    }
  }

  unsubscribe(channel) {
    if (this.hasChannel(channel)) {
      const sub = this.getSubscription(channel)
      sub.unsubscribe()
      this.removeSubscription(sub)
    }
  }
}
