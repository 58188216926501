import { cn } from 'utils'
import styles from './styles.module.scss'

export const Tabs = ({ list, value, onChange, className }) => (
  <div className={cn(styles.tabs, className)}>
    {list.map(({ title, value: tabValue, label }) => (
      <button
        key={title}
        className={cn(styles.tab, tabValue === value && styles.selected)}
        data-selected={tabValue === value}
        onClick={() => onChange(tabValue)}
      >
        {title}
        {label && <span className={styles.label}>{label}</span>}
      </button>
    ))}
  </div>
)
