import { VideoBox } from 'components/VideoBox'

export const HighlightPlayer = ({ src }) => (
  <div className="highlights-modal">
    <VideoBox
      isPlayer
      isOpen
      src={src}
    />
  </div>
)
