import { Api } from 'api/Api'
import { FavoritesActionTypes } from 'store/favorites/types'
import { getFavorites } from 'store/favorites/utils'
import { sortLiveLeagues } from 'store/live-by-sport/utils'

const getFavoriteIDs = () => localStorage.getItem('favorites')?.replace(/\s/gi, '-')

const updateFavorites = payload => ({ type: FavoritesActionTypes.SET_IDS, payload: payload })

export const addToFavorites = eventId => dispatch => {
  const favorites = getFavorites()

  if (!favorites.includes(eventId)) {
    const nextFavorites = [...favorites, eventId]

    dispatch(updateFavorites(nextFavorites))
  }
}

export const removeFromFavorites = eventId => dispatch => {
  const favorites = getFavorites()

  if (favorites.length) {
    const nextFavorites = favorites.filter(id => id !== eventId)

    dispatch(updateFavorites(nextFavorites))
  }
}

export const clearFavorites = () => dispatch => dispatch(updateFavorites([]))

export const checkFavorites = () => async dispatch => {
  const ids = getFavoriteIDs()

  if (ids) {
    try {
      const { data } = await Api.getFavorites(ids)

      if (!data.events.length) {
        return dispatch(updateFavorites([]))
      }

      const eventsIds     = data.events.map(({ id } = {}) => id)
      const nextFavorites = getFavorites().filter(favorite => eventsIds.includes(favorite))

      dispatch(updateFavorites(nextFavorites))
    } catch (error) {
      console.error(error)
    }
  }
}

const responseHandler = ({
  sports:  rawSports = [],
  leagues: rawLeagues = [],
  events = [],
  factors,
  results,
  clubs,
}) => {
  const sports     = []
  const leaguesObj = sortLiveLeagues(rawLeagues)
    .reduce((acc, league) => ({ ...acc, [league.sport_id]: [...(acc[league.sport_id] || []), league] }), {})

  const eventsByLeague = events
    .reduce((acc, event) => ({ ...acc, [event.league_id]: [...(acc[event.league_id] || []), event] }), {})

  rawSports.forEach(sport => {
    const nextSport = { ...sport, leagues: [] }

    leaguesObj[sport.id]?.forEach(league => {
      const leagueEvents = eventsByLeague[league.id]

      if (leagueEvents.length) {
        nextSport.leagues.push({ ...league, events: leagueEvents })
      }
    })

    sports.push(nextSport)
  })

  return { sports, factors, results, clubs }
}

export const getUserFavorites = () => async dispatch => {
  const ids = getFavoriteIDs()

  if (ids) {
    try {
      const { data } = await Api.getFavorites(ids)

      dispatch({
        type:    FavoritesActionTypes.SET_DATA,
        payload: responseHandler(data),
      })
    } catch (error) {
      console.error(error)
    }
  }
}
