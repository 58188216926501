import { AllSportsActionTypes } from 'store/all-sports/types'

const initialState = {
  sports:      [],
  isFetching:  false,
  fetchedWhen: null,
}

export const allSportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case AllSportsActionTypes.UPDATE: {
      return { ...state, ...action.payload }
    }

    default: {
      return state
    }
  }
}
