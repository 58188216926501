import { useIsScrolling } from 'hooks/useIsScrolling'
import {
  useEffect,
  useRef,
  useState,
} from 'react'

export const BetStatus = {
  DELETED: 1,
  DOWN:    2,
  UP:      3,
  STOPPED: 4,
}

export const getIsBetOutdated = status => +status === BetStatus.DELETED || +status === BetStatus.STOPPED

export const useCouponKeyboard = () => {
  const [hasKeyboard, setHasKeyboard] = useState(false)

  const onClose = () => setHasKeyboard(false)

  const inputRef    = useRef()
  const keyboardRef = useRef()

  useEffect(() => {
    if (hasKeyboard) {
      const onClick = ({ target }) => {
        if (![inputRef, keyboardRef].some(ref => ref.current?.contains(target))) {
          onClose()
        }
      }

      document.addEventListener('click', onClick)
      return () => {
        document.removeEventListener('click', onClick)
      }
    }
  }, [hasKeyboard])

  return { inputRef, keyboardRef, hasKeyboard, setHasKeyboard }
}

export const useCloseOnScroll = (condition, onClose) => {
  const isScrolling = useIsScrolling(window, condition)

  useEffect(() => {
    if (isScrolling) {
      onClose()
    }
  }, [isScrolling])
}
