import ACTIONS from "store/constants/actions";


const initialState = {
	user:{}
};

export default function user_account(state = initialState, action) {
	switch ( action.type ) {
		case ACTIONS.USER.GET_PERSONAL_DATA:
			return {...state, user: action.data.user};
	}
	return state;
}







