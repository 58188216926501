import { paths } from 'constants/paths'
import { useAuth } from 'hooks/useAuth'
import { Link } from 'react-router-dom'
import { cn } from 'utils'

export const AllFavorites = ({ className }) => {
  const isAuth = useAuth()

  return isAuth ? (
    <Link
      to={paths.CASINO_MY_GAMES}
      className={cn('casino__all-favorites', className)}
    >
      Мои игры
    </Link>
  ) : null
}
