import SwipeModal from 'components/SwipeModal'
import { getBetTime } from 'pages/UserHistoryPage/utils'
import { useState } from 'react'
import { Details } from './Details'
import { BetItemModal } from './Modal'
import styles from './styles.module.scss'

export const BetItemMain = ({ bet, onRefresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { count_bets, isVip, type, number, ts } = bet || {}

  const modalTitle = (
    <div className={styles.title}>
      <div>{`${count_bets === 1 && 'Ординар' || type} №${number}`}</div>
      {isVip && <div className={styles.vip} />}
    </div>
  )

  return (
    <>
      <div
        className={styles.details}
        onClick={() => setIsModalOpen(true)}
      >
        <Details {...bet} />
      </div>
      <SwipeModal
        title={modalTitle}
        subTitle={getBetTime(new Date(ts * 1000))}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <BetItemModal onRefresh={onRefresh} {...bet}>
          <Details expanded {...bet} />
        </BetItemModal>
      </SwipeModal>
    </>
  )
}
