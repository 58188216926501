import styles from 'components/Page/styles.module.scss'
import {
  PAGE_CONTENT_ID,
  SCROLLING_KEY,
} from 'components/Page/utils'
import { PageHeader } from 'components/PageHeader'
import { ScrollUpBtn } from 'components/ScrollTopBtn'
import { isWebView } from 'constants/platform'
import { useIsScrolling } from 'hooks/useIsScrolling'
import { cn } from 'utils'

export const Page = ({
  headerContent,
  children,
  className,
  title,
  onGoBack,
  bg,
  scrollTopBtn,
  trackScroll,
}) => {
  const hasScrollSub = isWebView || trackScroll
  const isScrolling  = useIsScrolling(window, hasScrollSub)

  return (
    <>
      {(headerContent || title) && (
        <div className={styles.fixedContent}>
          <PageHeader
            title={title}
            onGoBack={onGoBack}
          >
            {headerContent}
          </PageHeader>
        </div>
      )}
      <main
        id={PAGE_CONTENT_ID}
        className={cn(styles.content, styles[bg], className)}
        {...(isWebView && { ['data-webview']: true })}
        {...(hasScrollSub && { [SCROLLING_KEY]: isScrolling })}
      >
        {children}
      </main>
      {scrollTopBtn && <ScrollUpBtn />}
    </>
  )
}
