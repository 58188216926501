import styles from 'components/CurrencyInput/styles.module.scss'
import { getIsValid } from 'components/CurrencyInput/utils'
import React from 'react'
import { cn } from 'utils'

export const CurrencyInput = ({ value, onChange, className, maxLength, invalid, ...restProps }) => {
  const handleOnChange = event => {
    const nextValue = event.target.value.replace(',', '.')

    if (getIsValid(nextValue, maxLength)) {
      onChange(nextValue)
    }
  }

  return (
    <div
      className={cn(styles.moneyInput, className)}
      data-invalid={invalid}
    >
      <input
        inputMode="decimal"
        value={value}
        onChange={handleOnChange}
        className={styles.input}
        {...restProps}
      />
    </div>
  )
}
