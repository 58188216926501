export const FilterItem = ({ onClick, children }) => (
  <div className="history-filter">
    <div
      onClick={onClick}
      className="history-filter__value"
    >
      {children}
    </div>
  </div>
)
