import ACTIONS from 'store/constants/actions'

const initialState = {
  groups:     [],
  categories: [],
  current:    '',
  isFetching: false,
}

export default function championship(state = initialState, action = {}) {
  const { type, payload } = action

  switch (type) {
    case ACTIONS.CHAMPIONSHIP.GROUPS:
      return { ...state, groups: payload }

    case ACTIONS.CHAMPIONSHIP.CATEGORIES:
      return { ...state, categories: payload }

    case ACTIONS.CHAMPIONSHIP.CURRENT:
      return { ...state, current: payload }

    case ACTIONS.CHAMPIONSHIP.FETCHING:
      return { ...state, isFetching: payload }

    default:
      return state
  }
}
