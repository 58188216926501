import { ReactComponent as BetsIcon } from 'assets/images/bottom-menu/bets.svg'
import { ReactComponent as CasinoIcon } from 'assets/images/bottom-menu/casino.svg'
import { ReactComponent as LiveIcon } from 'assets/images/bottom-menu/live.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/bottom-menu/profile.svg'
import { ReactComponent as SportIcon } from 'assets/images/bottom-menu/sport.svg'
import { ReactComponent as TopIcon } from 'assets/images/bottom-menu/top.svg'
import { paths } from 'constants/paths'

export const getTabs = (pathname, notCalcBets) => [
  {
    title:  'Топ',
    link:   paths.HOME,
    icon:   TopIcon,
    active: pathname === '/',
  },
  {
    title:  'Лайв',
    link:   paths.LIVE_TOP,
    icon:   LiveIcon,
    active: pathname.startsWith('/live'),
  },
  {
    title:  'Спорт',
    link:   paths.LINE_ALL,
    icon:   SportIcon,
    active: pathname.startsWith('/line'),
  },
  {
    title:  'Казино',
    link:   paths.CASINO_HOME,
    icon:   CasinoIcon,
    active: pathname.startsWith('/casino'),
  },
  {
    title:    'История',
    link:     paths.USER_HISTORY,
    icon:     BetsIcon,
    active:   pathname.startsWith('/user/history/'),
    badge:    notCalcBets,
    needAuth: true,
  },
  {
    title:    'Профиль',
    link:     paths.ACCOUNT,
    icon:     ProfileIcon,
    active:   pathname === paths.ACCOUNT,
    needAuth: true,
  },
]
