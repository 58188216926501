import { Tabs } from 'components/Tabs'
import {
  useCallback,
  useState,
} from 'react'

export const useTabs = (tabs, { initialValue = tabs[0].value, onBeforeChange } = {}) => {
  const [value, setValue] = useState(initialValue)

  const onChange = nextValue => {
    onBeforeChange?.(nextValue)
    setValue(nextValue)
  }

  const Component = useCallback(props => (
    <Tabs
      list={tabs}
      onChange={onChange}
      value={value}
      {...props}
    />
  ), [value])

  return [Component, value, setValue]
}
