import { useStopScroll } from 'hooks/useStopScroll'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { closeModal } from 'store/modal/actions'
import styles from './styles.module.scss'

const ModalWrapper = ({ modals, closeModal }) => {
  const { pathname } = useLocation()

  const hasModals = !!modals.length

  useStopScroll(hasModals)

  useEffect(() => {
    if (hasModals) {
      closeModal()
    }
  }, [pathname])

  return (
    hasModals ?
      modals.map(modal => (
        <div
          key={modal.id}
          className={styles.modalWrapper}
        >
          <div
            className={styles.backdrop}
            onClick={() => closeModal(modal.id)}
          />
          <div className={styles.content}>
            {modal.content}
          </div>
        </div>
      )) : null
  )
}

const mapStateToProps = state => ({
  modals: state.modals.list,
})

const mapDispatchToProps = {
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalWrapper)
