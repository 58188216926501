import { Api } from 'api/Api'
import {
  hidePreloader,
  showPreloader,
} from 'components/Preloader/utils'
import ACTIONS from 'store/constants/actions'

export const getLineResults = (date, sports) => async dispatch => {
  showPreloader()

  try {
    const data = await Api.getLineResultsNew(date, sports)

    dispatch({
      type: ACTIONS.RESULT_FROM_MARAPHON.GET_LINE,
      data,
    })
  } catch (e) {
    console.error(e)
  } finally {
    hidePreloader()
  }
}

export const getLineResultsSports = (date) => dispatch => {
  Api.getLineResults(date).then((resp) => {
    dispatch({
      type: ACTIONS.RESULT_FROM_MARAPHON.GET_SPORTS_LINE,
      data: resp && resp.data ? resp.data : [],
    })
  })
}
