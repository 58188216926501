import { parseJSON } from 'utils/parse-json'

const { userAgent } = navigator

export const DEFAULT_PLATFORM = 'mobile'

const isWebViewEnv = process.env.REACT_APP_IS_WEB_VIEW === 'true'

const mockedRNDevice = JSON.stringify({
  device: {
    id:       'mocked-device-id',
    platform: 'AndroidRN',
    trusted:  true,
  },
})

const RegExp = {
  SAFARI:  /^((?!chrome|android|crios).)*safari/i,
  IOS:     /(iPad|iPhone|iPod)/i,
  ANDROID: /(android)/i,
}

export const getIsAndroid = string => RegExp.ANDROID.test(string)

const injectedJSON    = isWebViewEnv ? mockedRNDevice : window.ReactNativeWebView?.injectedObjectJson?.()
export const device   = parseJSON(injectedJSON)?.device
export const platform = device?.platform || DEFAULT_PLATFORM

export const isWeb     = platform === DEFAULT_PLATFORM
export const isIos     = RegExp.IOS.test(userAgent)
export const isAndroid = getIsAndroid(userAgent)
export const isSafari  = RegExp.SAFARI.test(navigator.userAgent)
export const isMobile  = isIos || isAndroid
export const isWebView = getIsAndroid(platform)
