import ACTIONS from 'store/constants/actions'

const initialState = {
  list:     [],
  lastAuth: '',
  dateFrom: new Date(),
  dateTo:   new Date(),
}

export default function auth_history(state = initialState, action) {
  switch (action.type) {
    case  ACTIONS.AUTH.AUTH_HISTORY: {
      return { ...state, ...action.payload, lastAuth: action.payload.lastAuth.date }
    }
    case  ACTIONS.AUTH.AUTH_DATE_FROM: {
      return { ...state, dateFrom: action.date }
    }
    case  ACTIONS.AUTH.AUTH_DATE_TO: {
      return { ...state, dateTo: action.date }
    }
    default: {
      return state
    }
  }
}
