import {
  format,
  isToday,
  isTomorrow,
} from 'date-fns'
import { getUTCDate } from 'utils/time'

const formatTime = dirtyTime => {
  const time          = getUTCDate(dirtyTime)
  const formattedTime = format(time, 'HH:mm')

  return (isToday(time) && `Сегодня в ${formattedTime}`) || (isTomorrow(time) && `Завтра в ${formattedTime}`) ||
    format(time, 'dd.MM.yyyy в HH:mm')
}

export const converter = ({ top_express = [], top_express_data = {} }) => {
  const { events, leagues, sports } = top_express_data

  top_express_data.events = (events => {
    const out = {}

    events.forEach(event => {
      out[event.id] = {
        ...event,
        teams:     `${event.team1}${event.team2 ? ` - ${event.team2}` : ''}`,
        date_time: formatTime(event.time),
      }
    })

    return out
  })(events)

  top_express_data.leagues = ((leagues) => {
    const out = {}
    leagues.forEach(league => out[league.id] = league)
    return out
  })(leagues)

  top_express_data.sports = ((sports) => {
    const out = {}
    sports.forEach(sport => out[sport.id] = sport)
    return out
  })(sports)

  return {
    top_express,
    top_express_data,
  }
}
