import { DEFAULT_ICON_URL } from 'pages/Casino/constants'
import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetCasinoState } from 'store/casino/creators'

export const providersToFilters = providers => providers.map(({ id, value, label, count = 0, ...restProps }) => ({
  id,
  icon: `https://cdn-01.maxline.by/images/casino/providers/m/${value}.svg`,
  name: label,
  count,
  ...restProps,
}))

export const themesToFilters = (themes, { count }) => themes.map(({ id, image, name }) => ({
  id,
  icon:  `https://maxline.by/assets/images/casino/themes/${image}`,
  name,
  count: count[id] || 0,
}))

export const categoriesToFilters = categories => categories.map(({ label, count = 0, image, ...rest }) => ({
  count,
  name: label,
  icon: image || DEFAULT_ICON_URL,
  ...rest,
}))

export const useClearCasinoState = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(resetCasinoState())
  }, [])
}
