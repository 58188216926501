import { EventTimer } from 'components/EventTimer'
import { StartDate } from 'pages/Home/components/Event/StartDate'
import styles from './styles.module.scss'

export const AdditionalInfo = ({ live, finished, goal, seconds, startedAt, timeDirection }) => live ? (
  <div className={styles.additionalInfo}>
    {(finished && <div className={styles.finished} />) ||
      (goal && <div className={styles.goal} />) || (
        <EventTimer
          time={seconds}
          direction={timeDirection}
          className={styles.timer}
        />
      )}
  </div>
) : <StartDate value={startedAt} />
