import { CasinoSearchInput } from 'components/Casino/Search/Input'
import { debounce } from 'lodash'
import { GamesParamKey } from 'pages/Casino/Games/utils'
import {
  useEffect,
  useMemo,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { changeSearch } from 'store/casino/creators'
import { selectGamesSearch } from 'store/casino/selectors'

export const Search = ({ onEnter, className }) => {
  const dispatch                        = useDispatch()
  const searchString                    = useSelector(selectGamesSearch)
  const [searchParams, setSearchParams] = useSearchParams()

  const prevSearch  = searchParams.get(GamesParamKey.SEARCH)
  const isAutoFocus = !!searchParams.get(GamesParamKey.AUTO_FOCUS)

  const onSearch = useMemo(() => debounce(onEnter, 300), [])

  const onChangeParams = (key, value) => {
    if (value) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }

    setSearchParams(searchParams, { replace: true })
  }

  useEffect(() => {
    if (prevSearch) {
      dispatch(changeSearch(prevSearch))
      onEnter(prevSearch)
    }

    if (isAutoFocus) {
      onChangeParams(GamesParamKey.AUTO_FOCUS)
    }
  }, [])

  const onChange = (value = '', debounced = true) => {
    onChangeParams(GamesParamKey.SEARCH, value)
    dispatch(changeSearch(value))
    return debounced ? onSearch() : onEnter()
  }

  return (
    <CasinoSearchInput
      value={searchString}
      onChange={onChange}
      autoFocus={isAutoFocus}
      className={className}
    />
  )
}
