import { FavoritesActionTypes } from 'store/favorites/types'
import { setFavorites } from 'store/favorites/utils'

const initialState = {
  ids:  [],
  data: {
    sports:  [],
    factors: {},
    results: {},
    clubs:   [],
  },
}

export default function favoritesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FavoritesActionTypes.SET_IDS:
      setFavorites(payload)

      return { ...state, ids: payload }

    case FavoritesActionTypes.SET_DATA: {
      return { ...state, data: payload }
    }

    default:
      return state
  }
}
