import { padTime } from 'components/EventTimer/utils'
import {
  useEffect,
  useState,
} from 'react'

const MINUTES = 60
const HOURS   = MINUTES * 60
const DAYS    = HOURS * 24

export const useTimer = (deadlineSec, { intervalSec = 1, onEnd } = {}) => {
  const [timespan, setTimespan] = useState(0)

  useEffect(() => {
    setTimespan(deadlineSec)
  }, [deadlineSec])

  useEffect(() => {
    if (timespan > 0) {
      const nextTimespan = timespan - intervalSec

      const callback = () => {
        setTimespan(nextTimespan)

        if (nextTimespan <= 0) {
          onEnd?.()
        }
      }

      const timeoutID = setTimeout(callback, intervalSec * 1000)

      return () => clearTimeout(timeoutID)
    }
  }, [timespan, intervalSec])

  const seconds   = padTime(timespan % 60)
  const minutes   = padTime(Math.floor(timespan / MINUTES % 60))
  const hours     = padTime(Math.floor(timespan / HOURS % 60))
  const fullHours = padTime(Math.floor(timespan / 60 / 60))
  const days      = Math.floor(timespan / DAYS)

  return { days, hours, minutes, seconds, fullHours }
}
