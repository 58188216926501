import { Backdrop } from 'components/Backdrop'
import { ModalKeys } from 'components/SignUpModals/utils'
import styles from './styles.module.scss'

export const SignUp = ({ onClose, onChange }) => (
  <>
    <Backdrop
      onClose={onClose}
      zIndex={230}
    />
    <div className={styles.signUpModal}>
      <button
        aria-label="Close"
        onClick={onClose}
        className={styles.closeBtn}
      />
      <h2 className={styles.title}>222 FS или 77 BYN</h2>
      <div className={styles.subtitle}>новым игрокам</div>
      <button
        className="ml-button mt-22"
        onClick={() => onChange(ModalKeys.SIGN_UP_FORM)}
      >
        Зарегистрироваться
      </button>
    </div>
  </>
)
