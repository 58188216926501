import ACTIONS from 'store/constants/actions'

const defaultData = {
  info:            {
    currency:        'BYN',
    email:           '',
    first_name:      '',
    id:              0,
    last_name:       '',
    login:           '',
    middle_name:     '',
    money:           0,
    ordered_payment: 0,
    web_id:          0,
    has_scan:        '0',
  },
  login:           {
    auth:   false,
    atoken: '',
  },
  messages:        [],
  info_checked:    {
    login:    false,
    password: false,
  },
  promoCodesCount: 0,
}

const dataFromLs   = localStorage.getItem('user')
const initialState = dataFromLs ? JSON.parse(dataFromLs) : defaultData

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN: {

      if (action.data.hasOwnProperty('messages')) {
        return Object.assign({}, state, { messages: action.data.messages })
      }

      let out = Object.assign({}, state)
      if (action.data.auth) {
        out = Object.assign(out, { login: action.data })
        localStorage.setItem('token', action.data.atoken)
        localStorage.setItem('user', JSON.stringify(out))
      }

      return out
    }

    case ACTIONS.USER.LOGOUT: {
      return defaultData
    }

    case ACTIONS.USER.GET_INFO: {
      if (action.data.hasOwnProperty('user')) {
        const newState = {
          ...state, info: {
            ...action.data.user,
            ordered_payment: state.info.ordered_payment,
          },
        }
        localStorage.setItem('user', JSON.stringify(newState))
        return newState
      }

      return state
    }

    case ACTIONS.USER.SET_ORDERED_PAYMENT: {
      const newState = {
        ...state,
        info: {
          ...state.info, ordered_payment: action.amount,
        },
      }
      localStorage.setItem('user', JSON.stringify(newState))
      return newState
    }

    case ACTIONS.USER.SET_PROMO_CODES_COUNT: {
      return { ...state, promoCodesCount: action.payload }
    }

    default:
      return state
  }
}
