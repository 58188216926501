import ACTIONS from 'store/constants/actions'

const initialState = {
	data     : [],
	messages : []
};

export default function line(state = initialState, action) {
	switch ( action.type ) {
		case ACTIONS.SEARCH_EVENT.GET_SEARCH_EVENT: {
			return { ...state, data: action.data, messages: action.messages || [] }
		}

		case ACTIONS.SEARCH_EVENT.GET_SEARCH_EVENT_ERROR: {
			return {...state, data : [], messages : action.messages};
		}
		case ACTIONS.SEARCH_EVENT.CLEAR_SEARCH_MESSAGES: {
			return initialState;
		}
	}
	return state;
}
